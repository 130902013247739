import {Component, Inject, OnInit} from '@angular/core';
import {Price} from '../models/Price';
import {Upgrade} from '../models/Upgrade';
import {FormControl} from '@angular/forms';
import {recipeData} from '../data/recipes';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {EarthService} from '../service/earth.service';
import {RecipeGroup} from '../models/RecipeGroup';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {CreateRecipeComponent} from './dialogs/create-recipe/create-recipe.component';
import {CreateItemComponent} from './dialogs/create-item/create-item.component';
import {ExportDataComponent} from './dialogs/export-data/export-data.component';
import {ImportDataComponent} from './dialogs/import-data/import-data.component';
import {ResetDataComponent} from './dialogs/reset-data/reset-data.component';
import {Recipe} from '../models/Recipe';
import {RecipeIngredient} from "../models/RecipeIngredient";
import {Item} from '../models/Item';


@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    prices: Price[] = [];
    upgrades: Upgrade[] = [];
    storedData = {prices: [], upgrades: [], recipes: []};
    recipeData = recipeData;

    autoCompleteValue = '';
    myControl = new FormControl();
    currencySelect = new FormControl();
    playerSelect = new FormControl();
    calorieCost = new FormControl();
    profitMargin = new FormControl();
    filteredRecipes: Recipe[] = [];



    constructor(
        public earthService: EarthService,
        public createRecipeDialog: MatDialog,
        public createItemDialog: MatDialog,
        public exportDialog: MatDialog,
        public resetDialog: MatDialog,
        public importDialog: MatDialog
    ) {
        const allRecipeGroup = new RecipeGroup('all');
        // allRecipeGroup.recipes = this.loadRecipeData();
        this.earthService.allRecipes = allRecipeGroup;
    }

    ngOnInit(): void {

        this.myControl.valueChanges.subscribe(autoCompleteValue => {
            this.earthService.autoCompleteValue.next(autoCompleteValue);
        });

        this.earthService.selectedCurrency.subscribe(selectedPlayer => {
            this.currencySelect.setValue(this.earthService.selectedCurrency.getValue());
        });
        this.currencySelect.setValue(this.earthService.selectedCurrency.getValue());
        this.currencySelect.valueChanges.subscribe(newCurrency => {
            if (this.earthService.selectedCurrency.getValue() !== newCurrency) {
                this.earthService.selectedCurrency.next(newCurrency);
            }
        });

        this.earthService.selectedPlayer.subscribe(selectedPlayer => {
            this.playerSelect.setValue(this.earthService.selectedPlayer.getValue());
        });
        this.playerSelect.setValue(this.earthService.selectedPlayer.getValue());
        this.playerSelect.valueChanges.subscribe(newPlayer => {
            if (this.earthService.selectedPlayer.getValue() !== newPlayer) {
                this.earthService.selectedPlayer.next(newPlayer);
            }
        });

        this.earthService.calorieCost.subscribe(calorieCost => {
            this.calorieCost.setValue(this.earthService.calorieCost.getValue());
        });
        this.calorieCost.setValue(this.earthService.calorieCost.getValue());
        this.calorieCost.valueChanges.subscribe(calorieCost => {
            if (this.earthService.calorieCost.getValue() !== calorieCost) {
                this.earthService.calorieCost.next(calorieCost);
            }
        });

        this.earthService.profitMargin.subscribe(calorieCost => {
            this.profitMargin.setValue(this.earthService.profitMargin.getValue());
        });
        this.profitMargin.setValue(this.earthService.profitMargin.getValue());
        this.profitMargin.valueChanges.subscribe(profitMargin => {
            if (this.earthService.profitMargin.getValue() !== profitMargin) {
                this.earthService.profitMargin.next(profitMargin);
            }
        });
    }

    addRecipe(event: MatAutocompleteSelectedEvent, id: string): void {
        const recipe = this.earthService.recipes.getValue().find(v => v.id === id);
        this.earthService.addWatchedRecipe(recipe);
        this.myControl.setValue('');
    }


    drop(event: CdkDragDrop<Recipe[]>): void {

        const watchedRecipes = this.earthService.watchedRecipes.getValue();
        moveItemInArray(watchedRecipes, event.previousIndex, event.currentIndex);
        this.onUpdate(watchedRecipes);
    }

    onUpdate(watchedRecipes: Recipe[]): void {
        watchedRecipes.forEach( (r, index) => r.position = index);
        this.earthService.updateWatchedRecipes(watchedRecipes);
    }

    exportData(): void {
        const dialogRef = this.exportDialog.open(ExportDataComponent, {
            width: '500px'
        });
    }

    importData(): void {
        const dialogRef = this.exportDialog.open(ImportDataComponent, {
            width: '500px'
        });

        dialogRef.afterClosed().subscribe(result => {
        });
    }

    resetData(): void {
        const dialogRef = this.resetDialog.open(ResetDataComponent, {
            width: '500px'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result === true) {
                this.earthService.resetLocalStorage();
                this.earthService.loadLocalStorageData();
            }
        });

    }

}
