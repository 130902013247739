export const sampleRecipes = {
    "Recipes": [
        {
            "Key": "Glassworking Skill Book",
            "Untranslated": "Glassworking Skill Book Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 2400,
            "BaseXPGain": 1,
            "CraftingTable": "Research Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Glassworking Skill Book",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Masonry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Glassworking",
                    "Name": "Glassworking Skill Book",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Geology Research Paper Basic",
                            "Ammount": 20,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Geology Research Paper Advanced",
                            "Ammount": 10,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Engineering Research Paper Advanced",
                            "Ammount": 10,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Basic Research",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Glassworking Skill Book",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Modern Machete",
            "Untranslated": "Modern Machete Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 250,
            "BaseXPGain": 0.5,
            "CraftingTable": "Power Hammer",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Modern Machete",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "ModernMachete",
                    "Name": "Modern Machete",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Fiberglass",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 25,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Synthetic Rubber",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Modern Machete",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Oil Drilling Skill Book",
            "Untranslated": "Oil Drilling Skill Book Recipe",
            "BaseCraftTime": 42,
            "BaseLaborCost": 6000,
            "BaseXPGain": 1,
            "CraftingTable": "Laboratory",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Oil Drilling Skill Book",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "OilDrilling",
                    "Name": "Oil Drilling Skill Book",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Agriculture Research Paper Advanced",
                            "Ammount": 20,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Geology Research Paper Modern",
                            "Ammount": 20,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Dendrology Research Paper Modern",
                            "Ammount": 20,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Engineering Research Paper Modern",
                            "Ammount": 20,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Basic Research",
                            "Name": "",
                            "Ammount": 45,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Advanced Research",
                            "Name": "",
                            "Ammount": 30,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Oil Drilling Skill Book",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Advanced Baking Skill Book",
            "Untranslated": "Advanced Baking Skill Book Recipe",
            "BaseCraftTime": 42,
            "BaseLaborCost": 6000,
            "BaseXPGain": 1,
            "CraftingTable": "Laboratory",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Advanced Baking Skill Book",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Baking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "AdvancedBaking",
                    "Name": "Advanced Baking Skill Book",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Culinary Research Paper Advanced",
                            "Ammount": 30,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Dendrology Research Paper Modern",
                            "Ammount": 15,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Geology Research Paper Modern",
                            "Ammount": 15,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Basic Research",
                            "Name": "",
                            "Ammount": 45,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Advanced Research",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Modern Research",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Advanced Baking Skill Book",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wool Carpet",
            "Untranslated": "Wool Carpet Recipe",
            "BaseCraftTime": 0.89599997,
            "BaseLaborCost": 180,
            "BaseXPGain": 1.5,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Wool Carpet",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "WoolCarpet",
                    "Name": "Wool Carpet",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Epoxy",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wool Fabric",
                            "Ammount": 3,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wool Carpet",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Medium Shipyard",
            "Untranslated": "Medium Shipyard Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 800,
            "BaseXPGain": 6,
            "CraftingTable": "Machinist Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Medium Shipyard",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "MediumShipyard",
                    "Name": "Medium Shipyard",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Plate",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Paper",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Hemp Mooring Rope",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Medium Shipyard",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Paper Milling Skill Book",
            "Untranslated": "Paper Milling Skill Book Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 2400,
            "BaseXPGain": 1,
            "CraftingTable": "Research Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Paper Milling Skill Book",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "PaperMilling",
                    "Name": "Paper Milling Skill Book",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Dendrology Research Paper Advanced",
                            "Ammount": 10,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gathering Research Paper Basic",
                            "Ammount": 10,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Basic Research",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Paper Milling Skill Book",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Painting Skill Book",
            "Untranslated": "Painting Skill Book Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 600,
            "BaseXPGain": 1,
            "CraftingTable": "Research Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Painting Skill Book",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Basic Engineering",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Painting",
                    "Name": "Painting Skill Book",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Engineering Research Paper Advanced",
                            "Ammount": 15,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gathering Research Paper Basic",
                            "Ammount": 15,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Basic Research",
                            "Name": "",
                            "Ammount": 30,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Painting Skill Book",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Electronics Skill Book",
            "Untranslated": "Electronics Skill Book Recipe",
            "BaseCraftTime": 42,
            "BaseLaborCost": 6000,
            "BaseXPGain": 1,
            "CraftingTable": "Laboratory",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Electronics Skill Book",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Electronics",
                    "Name": "Electronics Skill Book",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Metallurgy Research Paper Advanced",
                            "Ammount": 15,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Engineering Research Paper Modern",
                            "Ammount": 15,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Metallurgy Research Paper Modern",
                            "Ammount": 30,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Basic Research",
                            "Name": "",
                            "Ammount": 45,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Advanced Research",
                            "Name": "",
                            "Ammount": 30,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Modern Research",
                            "Name": "",
                            "Ammount": 30,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Electronics Skill Book",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Cooking Skill Book",
            "Untranslated": "Cooking Skill Book Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 2400,
            "BaseXPGain": 1,
            "CraftingTable": "Research Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Cooking Skill Book",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Cooking",
                    "Name": "Cooking Skill Book",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Culinary Research Paper Basic",
                            "Ammount": 15,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Metallurgy Research Paper Basic",
                            "Ammount": 10,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Basic Research",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Cooking Skill Book",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Masonry Skill Book",
            "Untranslated": "Masonry Skill Book Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 300,
            "BaseXPGain": 1,
            "CraftingTable": "Research Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Masonry Skill Book",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Masonry",
                    "Name": "Masonry Skill Book",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Geology Research Paper Basic",
                            "Ammount": 6,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gathering Research Paper Basic",
                            "Ammount": 6,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Masonry Skill Book",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Blacksmith Skill Book",
            "Untranslated": "Blacksmith Skill Book Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 2400,
            "BaseXPGain": 1,
            "CraftingTable": "Research Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Blacksmith Skill Book",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Smelting",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Blacksmith",
                    "Name": "Blacksmith Skill Book",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Metallurgy Research Paper Basic",
                            "Ammount": 15,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Dendrology Research Paper Advanced",
                            "Ammount": 10,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Geology Research Paper Advanced",
                            "Ammount": 10,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Basic Research",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Blacksmith Skill Book",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Cutting Edge Cooking Skill Book",
            "Untranslated": "Cutting Edge Cooking Skill Book Recipe",
            "BaseCraftTime": 84,
            "BaseLaborCost": 6000,
            "BaseXPGain": 1,
            "CraftingTable": "Laboratory",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Cutting Edge Cooking Skill Book",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Cooking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CuttingEdgeCooking",
                    "Name": "Cutting Edge Cooking Skill Book",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Culinary Research Paper Advanced",
                            "Ammount": 30,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Culinary Research Paper Modern",
                            "Ammount": 30,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Metallurgy Research Paper Modern",
                            "Ammount": 15,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Agriculture Research Paper Modern",
                            "Ammount": 15,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Basic Research",
                            "Name": "",
                            "Ammount": 45,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Advanced Research",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Cutting Edge Cooking Skill Book",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Tailoring Skill Book",
            "Untranslated": "Tailoring Skill Book Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 600,
            "BaseXPGain": 1,
            "CraftingTable": "Research Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Tailoring Skill Book",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Gathering",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Tailoring",
                    "Name": "Tailoring Skill Book",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gathering Research Paper Basic",
                            "Ammount": 10,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Basic Research",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Tailoring Skill Book",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Pottery Skill Book",
            "Untranslated": "Pottery Skill Book Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 2400,
            "BaseXPGain": 1,
            "CraftingTable": "Research Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Pottery Skill Book",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Masonry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Pottery",
                    "Name": "Pottery Skill Book",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Geology Research Paper Basic",
                            "Ammount": 15,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Geology Research Paper Advanced",
                            "Ammount": 10,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Engineering Research Paper Advanced",
                            "Ammount": 10,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Basic Research",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Pottery Skill Book",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Smelting Skill Book",
            "Untranslated": "Smelting Skill Book Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 2400,
            "BaseXPGain": 1,
            "CraftingTable": "Research Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Smelting Skill Book",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Smelting",
                    "Name": "Smelting Skill Book",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Metallurgy Research Paper Basic",
                            "Ammount": 15,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Dendrology Research Paper Advanced",
                            "Ammount": 10,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Geology Research Paper Advanced",
                            "Ammount": 10,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Basic Research",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Smelting Skill Book",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Shipwright Skill Book",
            "Untranslated": "Shipwright Skill Book Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 2400,
            "BaseXPGain": 1,
            "CraftingTable": "Research Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Shipwright Skill Book",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Logging",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Shipwright",
                    "Name": "Shipwright Skill Book",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Dendrology Research Paper Basic",
                            "Ammount": 10,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gathering Research Paper Basic",
                            "Ammount": 10,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Basic Research",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Shipwright Skill Book",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Mechanics Skill Book",
            "Untranslated": "Mechanics Skill Book Recipe",
            "BaseCraftTime": 42,
            "BaseLaborCost": 6000,
            "BaseXPGain": 1,
            "CraftingTable": "Research Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Mechanics Skill Book",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Basic Engineering",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Mechanics",
                    "Name": "Mechanics Skill Book",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Engineering Research Paper Advanced",
                            "Ammount": 15,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Metallurgy Research Paper Advanced",
                            "Ammount": 15,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Basic Research",
                            "Name": "",
                            "Ammount": 30,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Advanced Research",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Mechanics Skill Book",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Farming Skill Book",
            "Untranslated": "Farming Skill Book Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 300,
            "BaseXPGain": 1,
            "CraftingTable": "Research Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Farming Skill Book",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Gathering",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Farming",
                    "Name": "Farming Skill Book",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gathering Research Paper Basic",
                            "Ammount": 4,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Geology Research Paper Basic",
                            "Ammount": 2,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Farming Skill Book",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Advanced Cooking Skill Book",
            "Untranslated": "Advanced Cooking Skill Book Recipe",
            "BaseCraftTime": 42,
            "BaseLaborCost": 6000,
            "BaseXPGain": 1,
            "CraftingTable": "Laboratory",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Advanced Cooking Skill Book",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Cooking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "AdvancedCooking",
                    "Name": "Advanced Cooking Skill Book",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Culinary Research Paper Advanced",
                            "Ammount": 30,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Dendrology Research Paper Modern",
                            "Ammount": 15,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Geology Research Paper Modern",
                            "Ammount": 15,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Basic Research",
                            "Name": "",
                            "Ammount": 45,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Advanced Research",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Modern Research",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Advanced Cooking Skill Book",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Fertilizers Skill Book",
            "Untranslated": "Fertilizers Skill Book Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 600,
            "BaseXPGain": 1,
            "CraftingTable": "Research Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Fertilizers Skill Book",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Farming",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Fertilizers",
                    "Name": "Fertilizers Skill Book",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Agriculture Research Paper Advanced",
                            "Ammount": 5,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Geology Research Paper Basic",
                            "Ammount": 5,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Basic Research",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Fertilizers Skill Book",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Stone Hammer",
            "Untranslated": "Stone Hammer Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 10,
            "BaseXPGain": 1,
            "CraftingTable": "Tool Bench",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Stone Hammer",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "StoneHammer",
                    "Name": "Stone Hammer",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Rock",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Stone Hammer",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Milling Skill Book",
            "Untranslated": "Milling Skill Book Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 3000,
            "BaseXPGain": 1,
            "CraftingTable": "Research Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Milling Skill Book",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Farming",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Milling",
                    "Name": "Milling Skill Book",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Dendrology Research Paper Basic",
                            "Ammount": 10,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Geology Research Paper Basic",
                            "Ammount": 10,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Culinary Research Paper Basic",
                            "Ammount": 10,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gathering Research Paper Basic",
                            "Ammount": 5,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Basic Research",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Milling Skill Book",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Industry Skill Book",
            "Untranslated": "Industry Skill Book Recipe",
            "BaseCraftTime": 42,
            "BaseLaborCost": 6000,
            "BaseXPGain": 1,
            "CraftingTable": "Laboratory",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Industry Skill Book",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Industry",
                    "Name": "Industry Skill Book",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Engineering Research Paper Advanced",
                            "Ammount": 10,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Engineering Research Paper Modern",
                            "Ammount": 20,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Metallurgy Research Paper Modern",
                            "Ammount": 20,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Basic Research",
                            "Name": "",
                            "Ammount": 30,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Advanced Research",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Modern Research",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Industry Skill Book",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Advanced Masonry Skill Book",
            "Untranslated": "Advanced Masonry Skill Book Recipe",
            "BaseCraftTime": 42,
            "BaseLaborCost": 6000,
            "BaseXPGain": 1,
            "CraftingTable": "Laboratory",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Advanced Masonry Skill Book",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Pottery",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "AdvancedMasonry",
                    "Name": "Advanced Masonry Skill Book",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Geology Research Paper Advanced",
                            "Ammount": 30,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Geology Research Paper Modern",
                            "Ammount": 15,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Metallurgy Research Paper Modern",
                            "Ammount": 15,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Engineering Research Paper Modern",
                            "Ammount": 15,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Basic Research",
                            "Name": "",
                            "Ammount": 45,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Advanced Research",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Advanced Masonry Skill Book",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Stone Axe",
            "Untranslated": "Stone Axe Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 10,
            "BaseXPGain": 1,
            "CraftingTable": "Tool Bench",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Stone Axe",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "StoneAxe",
                    "Name": "Stone Axe",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Rock",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Stone Axe",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Composites Skill Book",
            "Untranslated": "Composites Skill Book Recipe",
            "BaseCraftTime": 42,
            "BaseLaborCost": 6000,
            "BaseXPGain": 1,
            "CraftingTable": "Laboratory",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Composites Skill Book",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Composites",
                    "Name": "Composites Skill Book",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Dendrology Research Paper Advanced",
                            "Ammount": 30,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Dendrology Research Paper Modern",
                            "Ammount": 15,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Metallurgy Research Paper Modern",
                            "Ammount": 15,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Engineering Research Paper Modern",
                            "Ammount": 15,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Basic Research",
                            "Name": "",
                            "Ammount": 45,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Advanced Research",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composites Skill Book",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Iron Axe",
            "Untranslated": "Iron Axe Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 250,
            "BaseXPGain": 0.5,
            "CraftingTable": "Grindstone",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Iron Axe",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "IronAxe",
                    "Name": "Iron Axe",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Iron Axe",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Iron Shovel",
            "Untranslated": "Iron Shovel Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 250,
            "BaseXPGain": 0.5,
            "CraftingTable": "Grindstone",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Iron Shovel",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "IronShovel",
                    "Name": "Iron Shovel",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Iron Shovel",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Recurve Bow",
            "Untranslated": "Recurve Bow Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 250,
            "BaseXPGain": 1,
            "CraftingTable": "Fletching Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Recurve Bow",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Hunting",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "RecurveBow",
                    "Name": "Recurve Bow",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Linen Yarn",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Recurve Bow",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Steel Pickaxe",
            "Untranslated": "Steel Pickaxe Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 250,
            "BaseXPGain": 0.5,
            "CraftingTable": "Power Hammer",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Steel Pickaxe",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "SteelPickaxe",
                    "Name": "Steel Pickaxe",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Steel Pickaxe",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Basic Engineering Skill Book",
            "Untranslated": "Basic Engineering Skill Book Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 600,
            "BaseXPGain": 1,
            "CraftingTable": "Research Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Basic Engineering Skill Book",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "BasicEngineering",
                    "Name": "Basic Engineering Skill Book",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Dendrology Research Paper Advanced",
                            "Ammount": 6,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Geology Research Paper Advanced",
                            "Ammount": 6,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Basic Research",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Basic Engineering Skill Book",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Iron Pickaxe",
            "Untranslated": "Iron Pickaxe Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 250,
            "BaseXPGain": 0.5,
            "CraftingTable": "Grindstone",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Iron Pickaxe",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "IronPickaxe",
                    "Name": "Iron Pickaxe",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Iron Pickaxe",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Iron Hoe",
            "Untranslated": "Iron Hoe Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 250,
            "BaseXPGain": 0.5,
            "CraftingTable": "Grindstone",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Iron Hoe",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "IronHoe",
                    "Name": "Iron Hoe",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Iron Hoe",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Handheld Camera",
            "Untranslated": "Handheld Camera Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 250,
            "BaseXPGain": 0.5,
            "CraftingTable": "Electronics Assembly",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Handheld Camera",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Electronics",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "HandheldCamera",
                    "Name": "Handheld Camera",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Fiberglass",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plastic",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Synthetic Rubber",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Glass Lens",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Light Bulb",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Basic Circuit",
                            "Ammount": 2,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Handheld Camera",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Modern Hoe",
            "Untranslated": "Modern Hoe Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 250,
            "BaseXPGain": 0.5,
            "CraftingTable": "Power Hammer",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Modern Hoe",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "ModernHoe",
                    "Name": "Modern Hoe",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Fiberglass",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 25,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Synthetic Rubber",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Modern Hoe",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Baking Skill Book",
            "Untranslated": "Baking Skill Book Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 2400,
            "BaseXPGain": 1,
            "CraftingTable": "Research Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Baking Skill Book",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Baking",
                    "Name": "Baking Skill Book",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Culinary Research Paper Basic",
                            "Ammount": 15,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Metallurgy Research Paper Basic",
                            "Ammount": 10,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Basic Research",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Baking Skill Book",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Modern Hammer",
            "Untranslated": "Modern Hammer Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 250,
            "BaseXPGain": 0.5,
            "CraftingTable": "Power Hammer",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Modern Hammer",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "ModernHammer",
                    "Name": "Modern Hammer",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Fiberglass",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 25,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Synthetic Rubber",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Modern Hammer",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Iron Hammer",
            "Untranslated": "Iron Hammer Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 250,
            "BaseXPGain": 0.5,
            "CraftingTable": "Grindstone",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Iron Hammer",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "IronHammer",
                    "Name": "Iron Hammer",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Iron Hammer",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Modern Scythe",
            "Untranslated": "Modern Scythe Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 250,
            "BaseXPGain": 0.5,
            "CraftingTable": "Power Hammer",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Modern Scythe",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "ModernScythe",
                    "Name": "Modern Scythe",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Fiberglass",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 25,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Synthetic Rubber",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Modern Scythe",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Modern Pickaxe",
            "Untranslated": "Modern Pickaxe Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 250,
            "BaseXPGain": 0.5,
            "CraftingTable": "Power Hammer",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Modern Pickaxe",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "ModernPickaxe",
                    "Name": "Modern Pickaxe",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Fiberglass",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 25,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Synthetic Rubber",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Modern Pickaxe",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Butchery Skill Book",
            "Untranslated": "Butchery Skill Book Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 600,
            "BaseXPGain": 1,
            "CraftingTable": "Research Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Butchery Skill Book",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Hunting",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Butchery",
                    "Name": "Butchery Skill Book",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Culinary Research Paper Basic",
                            "Ammount": 6,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Butchery Skill Book",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Composite Bow",
            "Untranslated": "Composite Bow Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 250,
            "BaseXPGain": 1,
            "CraftingTable": "Fletching Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Composite Bow",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Hunting",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "CompositeBow",
                    "Name": "Composite Bow",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Fiberglass",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nylon Thread",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Bow",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wooden Bow",
            "Untranslated": "Wooden Bow Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 10,
            "BaseXPGain": 1,
            "CraftingTable": "Tool Bench",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Wooden Bow",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "WoodenBow",
                    "Name": "Wooden Bow",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plant Fibers",
                            "Ammount": 30,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 24,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wooden Bow",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Modern Shovel",
            "Untranslated": "Modern Shovel Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 250,
            "BaseXPGain": 0.5,
            "CraftingTable": "Power Hammer",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Modern Shovel",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "ModernShovel",
                    "Name": "Modern Shovel",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Fiberglass",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 25,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Synthetic Rubber",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Modern Shovel",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Advanced Smelting Skill Book",
            "Untranslated": "Advanced Smelting Skill Book Recipe",
            "BaseCraftTime": 42,
            "BaseLaborCost": 6000,
            "BaseXPGain": 1,
            "CraftingTable": "Laboratory",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Advanced Smelting Skill Book",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Smelting",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "AdvancedSmelting",
                    "Name": "Advanced Smelting Skill Book",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Metallurgy Research Paper Basic",
                            "Ammount": 30,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Metallurgy Research Paper Advanced",
                            "Ammount": 30,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Basic Research",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Advanced Smelting Skill Book",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Stone Sickle",
            "Untranslated": "Stone Sickle Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 10,
            "BaseXPGain": 1,
            "CraftingTable": "Tool Bench",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Stone Sickle",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "StoneSickle",
                    "Name": "Stone Sickle",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Rock",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Stone Sickle",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Stone Machete",
            "Untranslated": "Stone Machete Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 10,
            "BaseXPGain": 1,
            "CraftingTable": "Tool Bench",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Stone Machete",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "StoneMachete",
                    "Name": "Stone Machete",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Rock",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Stone Machete",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wooden Shovel",
            "Untranslated": "Wooden Shovel Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 10,
            "BaseXPGain": 1,
            "CraftingTable": "Tool Bench",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Wooden Shovel",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "WoodenShovel",
                    "Name": "Wooden Shovel",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wooden Shovel",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Modern Paint Sprayer",
            "Untranslated": "Modern Paint Sprayer Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 250,
            "BaseXPGain": 0.1,
            "CraftingTable": "Power Hammer",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Modern Paint Sprayer",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "ModernPaintSprayer",
                    "Name": "Modern Paint Sprayer",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Fiberglass",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Synthetic Rubber",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Basic Circuit",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Modern Paint Sprayer",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Steel Machete",
            "Untranslated": "Steel Machete Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 250,
            "BaseXPGain": 0.5,
            "CraftingTable": "Power Hammer",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Steel Machete",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "SteelMachete",
                    "Name": "Steel Machete",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Steel Machete",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wooden Hoe",
            "Untranslated": "Wooden Hoe Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 10,
            "BaseXPGain": 1,
            "CraftingTable": "Tool Bench",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Wooden Hoe",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "WoodenHoe",
                    "Name": "Wooden Hoe",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wooden Hoe",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Iron Road Tool",
            "Untranslated": "Iron Road Tool Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 25,
            "BaseXPGain": 0.5,
            "CraftingTable": "Wainwright Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Iron Road Tool",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Basic Engineering",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "IronRoadTool",
                    "Name": "Iron Road Tool",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 16,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Iron Road Tool",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Stone Road Tool",
            "Untranslated": "Stone Road Tool Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 10,
            "BaseXPGain": 0.1,
            "CraftingTable": "Tool Bench",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Stone Road Tool",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Basic Engineering",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "StoneRoadTool",
                    "Name": "Stone Road Tool",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Rock",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Stone Road Tool",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Carpentry Skill Book",
            "Untranslated": "Carpentry Skill Book Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 300,
            "BaseXPGain": 1,
            "CraftingTable": "Research Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Carpentry Skill Book",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Logging",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Carpentry",
                    "Name": "Carpentry Skill Book",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Dendrology Research Paper Basic",
                            "Ammount": 6,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gathering Research Paper Basic",
                            "Ammount": 6,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Carpentry Skill Book",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Steel Shovel",
            "Untranslated": "Steel Shovel Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 250,
            "BaseXPGain": 0.5,
            "CraftingTable": "Power Hammer",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Steel Shovel",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "SteelShovel",
                    "Name": "Steel Shovel",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Steel Shovel",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Stone Pickaxe",
            "Untranslated": "Stone Pickaxe Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 10,
            "BaseXPGain": 1,
            "CraftingTable": "Tool Bench",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Stone Pickaxe",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "StonePickaxe",
                    "Name": "Stone Pickaxe",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Rock",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Stone Pickaxe",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Light Backpack",
            "Untranslated": "Light Backpack Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 600,
            "BaseXPGain": 3,
            "CraftingTable": "Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Light Backpack",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "LightBackpack",
                    "Name": "Light Backpack",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cellulose Fiber",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fabric",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Light Backpack",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Smith Shirt",
            "Untranslated": "Smith Shirt Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 1200,
            "BaseXPGain": 3,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Smith Shirt",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "SmithShirt",
                    "Name": "Smith Shirt",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 30,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Smith Shirt",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Modern Axe",
            "Untranslated": "Modern Axe Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 250,
            "BaseXPGain": 0.5,
            "CraftingTable": "Power Hammer",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Modern Axe",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "ModernAxe",
                    "Name": "Modern Axe",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Fiberglass",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 25,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Synthetic Rubber",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Modern Axe",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Steel Hammer",
            "Untranslated": "Steel Hammer Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 250,
            "BaseXPGain": 0.5,
            "CraftingTable": "Power Hammer",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Steel Hammer",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "SteelHammer",
                    "Name": "Steel Hammer",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Steel Hammer",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Iron Machete",
            "Untranslated": "Iron Machete Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 250,
            "BaseXPGain": 0.5,
            "CraftingTable": "Grindstone",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Iron Machete",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "IronMachete",
                    "Name": "Iron Machete",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Iron Machete",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Chef Hat",
            "Untranslated": "Chef Hat Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 1200,
            "BaseXPGain": 5,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Chef Hat",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "ChefHat",
                    "Name": "Chef Hat",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Chef Hat",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Tailor Pants",
            "Untranslated": "Tailor Pants Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 1200,
            "BaseXPGain": 5,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Tailor Pants",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "TailorPants",
                    "Name": "Tailor Pants",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 40,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Tailor Pants",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Iron Sickle",
            "Untranslated": "Iron Sickle Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 250,
            "BaseXPGain": 0.5,
            "CraftingTable": "Grindstone",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Iron Sickle",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "IronSickle",
                    "Name": "Iron Sickle",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Iron Sickle",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Builder Overalls",
            "Untranslated": "Builder Overalls Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 1200,
            "BaseXPGain": 5,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Builder Overalls",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "BuilderOveralls",
                    "Name": "Builder Overalls",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nylon Fabric",
                            "Ammount": 40,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Builder Overalls",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Modern Rock Drill",
            "Untranslated": "Modern Rock Drill Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 250,
            "BaseXPGain": 1,
            "CraftingTable": "Power Hammer",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Modern Rock Drill",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "ModernRockDrill",
                    "Name": "Modern Rock Drill",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Gear",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Synthetic Rubber",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Combustion Engine",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Modern Rock Drill",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Smith Bandana",
            "Untranslated": "Smith Bandana Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 1200,
            "BaseXPGain": 5,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Smith Bandana",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "SmithBandana",
                    "Name": "Smith Bandana",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 25,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Smith Bandana",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Chainsaw",
            "Untranslated": "Chainsaw Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 250,
            "BaseXPGain": 0.5,
            "CraftingTable": "Power Hammer",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Chainsaw",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "Chainsaw",
                    "Name": "Chainsaw",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Fiberglass",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Synthetic Rubber",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Combustion Engine",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Chainsaw",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Steel Sickle",
            "Untranslated": "Steel Sickle Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 250,
            "BaseXPGain": 0.5,
            "CraftingTable": "Power Hammer",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Steel Sickle",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "SteelSickle",
                    "Name": "Steel Sickle",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Steel Sickle",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Gigot Sleeve Shirt",
            "Untranslated": "Gigot Sleeve Shirt Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 40,
            "BaseXPGain": 3,
            "CraftingTable": "Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Gigot Sleeve Shirt",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "GigotSleeveShirt",
                    "Name": "Gigot Sleeve Shirt",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plant Fibers",
                            "Ammount": 30,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Gigot Sleeve Shirt",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Chef Shoes",
            "Untranslated": "Chef Shoes Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 1200,
            "BaseXPGain": 5,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Chef Shoes",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "ChefShoes",
                    "Name": "Chef Shoes",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Synthetic Rubber",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nylon Fabric",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Chef Shoes",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Camo Shirt",
            "Untranslated": "Camo Shirt Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 600,
            "BaseXPGain": 3,
            "CraftingTable": "Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Camo Shirt",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "CamoShirt",
                    "Name": "Camo Shirt",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cellulose Fiber",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fabric",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Camo Shirt",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Smith Pants",
            "Untranslated": "Smith Pants Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 1200,
            "BaseXPGain": 5,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Smith Pants",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "SmithPants",
                    "Name": "Smith Pants",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 40,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Smith Pants",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Iron Paint Sprayer",
            "Untranslated": "Iron Paint Sprayer Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 250,
            "BaseXPGain": 0.1,
            "CraftingTable": "Blacksmith Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Iron Paint Sprayer",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "IronPaintSprayer",
                    "Name": "Iron Paint Sprayer",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Plate",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Glass",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Iron Paint Sprayer",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Cloth Belt",
            "Untranslated": "Cloth Belt Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 40,
            "BaseXPGain": 3,
            "CraftingTable": "Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Cloth Belt",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "ClothBelt",
                    "Name": "Cloth Belt",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fabric",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Cloth Belt",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Iron Rock Drill",
            "Untranslated": "Iron Rock Drill Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 250,
            "BaseXPGain": 0.5,
            "CraftingTable": "Grindstone",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Iron Rock Drill",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "IronRockDrill",
                    "Name": "Iron Rock Drill",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Iron Rock Drill",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Steel Hoe",
            "Untranslated": "Steel Hoe Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 250,
            "BaseXPGain": 0.5,
            "CraftingTable": "Power Hammer",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Steel Hoe",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "SteelHoe",
                    "Name": "Steel Hoe",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Steel Hoe",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Running Shoes",
            "Untranslated": "Running Shoes Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 600,
            "BaseXPGain": 3,
            "CraftingTable": "Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Running Shoes",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "RunningShoes",
                    "Name": "Running Shoes",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fabric",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Running Shoes",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Carbide Helmet Lamp",
            "Untranslated": "Carbide Helmet Lamp Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 1200,
            "BaseXPGain": 3,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Carbide Helmet Lamp",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "CarbideHelmetLamp",
                    "Name": "Carbide Helmet Lamp",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Plate",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Carbide Helmet Lamp",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Farmer Boots",
            "Untranslated": "Farmer Boots Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 1200,
            "BaseXPGain": 5,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Farmer Boots",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "FarmerBoots",
                    "Name": "Farmer Boots",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Synthetic Rubber",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nylon Fabric",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Farmer Boots",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Shipwright Overalls",
            "Untranslated": "Shipwright Overalls Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 1200,
            "BaseXPGain": 3,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Shipwright Overalls",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "ShipwrightOveralls",
                    "Name": "Shipwright Overalls",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 40,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Shipwright Overalls",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Bandana",
            "Untranslated": "Bandana Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 600,
            "BaseXPGain": 3,
            "CraftingTable": "Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Bandana",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "Bandana",
                    "Name": "Bandana",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 15,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fabric",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Bandana",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Sandals",
            "Untranslated": "Sandals Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 150,
            "BaseXPGain": 1,
            "CraftingTable": "Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Sandals",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Sandals",
                    "Name": "Sandals",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Sandals",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Steel Axe",
            "Untranslated": "Steel Axe Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 250,
            "BaseXPGain": 0.5,
            "CraftingTable": "Power Hammer",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Steel Axe",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "SteelAxe",
                    "Name": "Steel Axe",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Steel Axe",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Builder Shirt",
            "Untranslated": "Builder Shirt Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 1200,
            "BaseXPGain": 5,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Builder Shirt",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "BuilderShirt",
                    "Name": "Builder Shirt",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nylon Fabric",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Builder Shirt",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Shorts",
            "Untranslated": "Shorts Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 40,
            "BaseXPGain": 3,
            "CraftingTable": "Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Shorts",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Shorts",
                    "Name": "Shorts",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fabric",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Shorts",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Explorer Boots",
            "Untranslated": "Explorer Boots Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 1200,
            "BaseXPGain": 5,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Explorer Boots",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "ExplorerBoots",
                    "Name": "Explorer Boots",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Synthetic Rubber",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nylon Fabric",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Explorer Boots",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Chef Pants",
            "Untranslated": "Chef Pants Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 1200,
            "BaseXPGain": 5,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Chef Pants",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "ChefPants",
                    "Name": "Chef Pants",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Chef Pants",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Bearpack",
            "Untranslated": "Bearpack Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 1200,
            "BaseXPGain": 3,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Bearpack",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "Bearpack",
                    "Name": "Bearpack",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cellulose Fiber",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Fur Pelt",
                            "Ammount": 25,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fabric",
                            "Name": "",
                            "Ammount": 30,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Bearpack",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Builder Helmet",
            "Untranslated": "Builder Helmet Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 1200,
            "BaseXPGain": 5,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Builder Helmet",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "BuilderHelmet",
                    "Name": "Builder Helmet",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plastic",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nylon Fabric",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Builder Helmet",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Tall Boots",
            "Untranslated": "Tall Boots Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 150,
            "BaseXPGain": 3,
            "CraftingTable": "Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Tall Boots",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "TallBoots",
                    "Name": "Tall Boots",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Fur Pelt",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Tall Boots",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Smith Boots",
            "Untranslated": "Smith Boots Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 1200,
            "BaseXPGain": 5,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Smith Boots",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "SmithBoots",
                    "Name": "Smith Boots",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Smith Boots",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Tailor Shirt",
            "Untranslated": "Tailor Shirt Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 1200,
            "BaseXPGain": 5,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Tailor Shirt",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "TailorShirt",
                    "Name": "Tailor Shirt",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nylon Fabric",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 30,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Tailor Shirt",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Explorer Shirt",
            "Untranslated": "Explorer Shirt Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 1200,
            "BaseXPGain": 5,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Explorer Shirt",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "ExplorerShirt",
                    "Name": "Explorer Shirt",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nylon Fabric",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Explorer Shirt",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Capris",
            "Untranslated": "Capris Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 40,
            "BaseXPGain": 3,
            "CraftingTable": "Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Capris",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Capris",
                    "Name": "Capris",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 3,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fabric",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Capris",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wooden Paint Sprayer",
            "Untranslated": "Wooden Paint Sprayer Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 250,
            "BaseXPGain": 0.1,
            "CraftingTable": "Anvil",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Wooden Paint Sprayer",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "WoodenPaintSprayer",
                    "Name": "Wooden Paint Sprayer",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wooden Paint Sprayer",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Farmer Overalls",
            "Untranslated": "Farmer Overalls Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 1200,
            "BaseXPGain": 5,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Farmer Overalls",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "FarmerOveralls",
                    "Name": "Farmer Overalls",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 40,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Farmer Overalls",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Farmer Hat",
            "Untranslated": "Farmer Hat Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 1200,
            "BaseXPGain": 5,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Farmer Hat",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "FarmerHat",
                    "Name": "Farmer Hat",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plant Fibers",
                            "Ammount": 60,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Farmer Hat",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Trousers",
            "Untranslated": "Trousers Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 40,
            "BaseXPGain": 3,
            "CraftingTable": "Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Trousers",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Trousers",
                    "Name": "Trousers",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Fur Pelt",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fabric",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Trousers",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Builder Boots",
            "Untranslated": "Builder Boots Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 1200,
            "BaseXPGain": 5,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Builder Boots",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "BuilderBoots",
                    "Name": "Builder Boots",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nylon Fabric",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Builder Boots",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Explorer Hat",
            "Untranslated": "Explorer Hat Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 1200,
            "BaseXPGain": 5,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Explorer Hat",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "ExplorerHat",
                    "Name": "Explorer Hat",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nylon Fabric",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Explorer Hat",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Basic Backpack",
            "Untranslated": "Basic Backpack Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 120,
            "BaseXPGain": 1,
            "CraftingTable": "Fiber Scutching Station",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Basic Backpack",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Gathering",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "BasicBackpack",
                    "Name": "Basic Backpack",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plant Fibers",
                            "Ammount": 30,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Boll",
                            "Ammount": 24,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Basic Backpack",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Explorer Gloves",
            "Untranslated": "Explorer Gloves Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 1200,
            "BaseXPGain": 5,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Explorer Gloves",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "ExplorerGloves",
                    "Name": "Explorer Gloves",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nylon Fabric",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Explorer Gloves",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Garden Boots",
            "Untranslated": "Garden Boots Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 150,
            "BaseXPGain": 3,
            "CraftingTable": "Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Garden Boots",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "GardenBoots",
                    "Name": "Garden Boots",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Fur Pelt",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Garden Boots",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Henley",
            "Untranslated": "Henley Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 40,
            "BaseXPGain": 3,
            "CraftingTable": "Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Henley",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Henley",
                    "Name": "Henley",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plant Fibers",
                            "Ammount": 25,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Henley",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Farmer Shirt",
            "Untranslated": "Farmer Shirt Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 1200,
            "BaseXPGain": 5,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Farmer Shirt",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "FarmerShirt",
                    "Name": "Farmer Shirt",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wool Fabric",
                            "Ammount": 30,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Farmer Shirt",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Tailor Bowler Hat",
            "Untranslated": "Tailor Bowler Hat Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 1200,
            "BaseXPGain": 5,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Tailor Bowler Hat",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "TailorBowlerHat",
                    "Name": "Tailor Bowler Hat",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Fur Pelt",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Tailor Bowler Hat",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Big Backpack",
            "Untranslated": "Big Backpack Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 600,
            "BaseXPGain": 3,
            "CraftingTable": "Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Big Backpack",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "BigBackpack",
                    "Name": "Big Backpack",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fabric",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Big Backpack",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Farmer Gloves",
            "Untranslated": "Farmer Gloves Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 1200,
            "BaseXPGain": 5,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Farmer Gloves",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "FarmerGloves",
                    "Name": "Farmer Gloves",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nylon Fabric",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Farmer Gloves",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Mechanics Advanced Upgrade",
            "Untranslated": "Mechanics Advanced Upgrade Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 6000,
            "BaseXPGain": 4,
            "CraftingTable": "Machinist Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Mechanics Advanced Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "MechanicsAdvancedUpgrade",
                    "Name": "Mechanics Advanced Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Advanced Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Mechanics Advanced Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Modern Upgrade 2",
            "Untranslated": "Modern Upgrade 2 Recipe",
            "BaseCraftTime": 8.4,
            "BaseLaborCost": 600,
            "BaseXPGain": 1,
            "CraftingTable": "Laboratory",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Modern Upgrade 2",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "ModernUpgradeLvl2",
                    "Name": "Modern Upgrade 2",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Gear",
                            "Ammount": 5,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Culinary Research Paper Modern",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Modern Upgrade 1",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Basic Research",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Modern Upgrade 2",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Camo Pants",
            "Untranslated": "Camo Pants Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 600,
            "BaseXPGain": 3,
            "CraftingTable": "Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Camo Pants",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "CamoPants",
                    "Name": "Camo Pants",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cellulose Fiber",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fabric",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Camo Pants",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Explorer Pants",
            "Untranslated": "Explorer Pants Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 1200,
            "BaseXPGain": 5,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Explorer Pants",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "ExplorerPants",
                    "Name": "Explorer Pants",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nylon Fabric",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 30,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Explorer Pants",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Smith Gloves",
            "Untranslated": "Smith Gloves Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 1200,
            "BaseXPGain": 5,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Smith Gloves",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "SmithGloves",
                    "Name": "Smith Gloves",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Smith Gloves",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Chef Shirt",
            "Untranslated": "Chef Shirt Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 1200,
            "BaseXPGain": 5,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Chef Shirt",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "ChefShirt",
                    "Name": "Chef Shirt",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 40,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Chef Shirt",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Builder Gloves",
            "Untranslated": "Builder Gloves Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 1200,
            "BaseXPGain": 5,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Builder Gloves",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "BuilderGloves",
                    "Name": "Builder Gloves",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nylon Fabric",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Builder Gloves",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Smith Apron",
            "Untranslated": "Smith Apron Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 1200,
            "BaseXPGain": 5,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Smith Apron",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "SmithApron",
                    "Name": "Smith Apron",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 30,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Smith Apron",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Shipwright Hat",
            "Untranslated": "Shipwright Hat Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 1200,
            "BaseXPGain": 3,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Shipwright Hat",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "ShipwrightHat",
                    "Name": "Shipwright Hat",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 25,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Shipwright Hat",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Square Belt",
            "Untranslated": "Square Belt Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 40,
            "BaseXPGain": 3,
            "CraftingTable": "Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Square Belt",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "SquareBelt",
                    "Name": "Square Belt",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Square Belt",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Shipwright Sandals",
            "Untranslated": "Shipwright Sandals Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 1200,
            "BaseXPGain": 3,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Shipwright Sandals",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "ShipwrightSandals",
                    "Name": "Shipwright Sandals",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Shipwright Sandals",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Work Boots",
            "Untranslated": "Work Boots Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 600,
            "BaseXPGain": 3,
            "CraftingTable": "Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Work Boots",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "WorkBoots",
                    "Name": "Work Boots",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cellulose Fiber",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fabric",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Work Boots",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Advanced Upgrade 3",
            "Untranslated": "Advanced Upgrade 3 Recipe",
            "BaseCraftTime": 12.599999,
            "BaseLaborCost": 90,
            "BaseXPGain": 1,
            "CraftingTable": "Laboratory",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Advanced Upgrade 3",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "AdvancedUpgradeLvl3",
                    "Name": "Advanced Upgrade 3",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Metallurgy Research Paper Advanced",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Culinary Research Paper Advanced",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Advanced Upgrade 2",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Basic Research",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Advanced Upgrade 3",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Cloth Cape",
            "Untranslated": "Cloth Cape Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 600,
            "BaseXPGain": 3,
            "CraftingTable": "Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Cloth Cape",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "ClothCape",
                    "Name": "Cloth Cape",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cellulose Fiber",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 30,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fabric",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Cloth Cape",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Tailor Shoes",
            "Untranslated": "Tailor Shoes Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 1200,
            "BaseXPGain": 5,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Tailor Shoes",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "TailorShoes",
                    "Name": "Tailor Shoes",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nylon Fabric",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Tailor Shoes",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Advanced Upgrade 4",
            "Untranslated": "Advanced Upgrade 4 Recipe",
            "BaseCraftTime": 16.8,
            "BaseLaborCost": 90,
            "BaseXPGain": 1,
            "CraftingTable": "Laboratory",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Advanced Upgrade 4",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "AdvancedUpgradeLvl4",
                    "Name": "Advanced Upgrade 4",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Dendrology Research Paper Modern",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Engineering Research Paper Modern",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Advanced Upgrade 3",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Basic Research",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Advanced Upgrade 4",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Shipwright Shirt",
            "Untranslated": "Shipwright Shirt Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 1200,
            "BaseXPGain": 3,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Shipwright Shirt",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "ShipwrightShirt",
                    "Name": "Shipwright Shirt",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 30,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Shipwright Shirt",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Gathering Advanced Upgrade",
            "Untranslated": "Gathering Advanced Upgrade Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 6000,
            "BaseXPGain": 4,
            "CraftingTable": "Pottery Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Gathering Advanced Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Gathering",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "GatheringAdvancedUpgrade",
                    "Name": "Gathering Advanced Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Advanced Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Gathering Advanced Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Modern Upgrade 3",
            "Untranslated": "Modern Upgrade 3 Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 600,
            "BaseXPGain": 1,
            "CraftingTable": "Laboratory",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Modern Upgrade 3",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "ModernUpgradeLvl3",
                    "Name": "Modern Upgrade 3",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Plate",
                            "Ammount": 8,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Rivet",
                            "Ammount": 5,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Modern Upgrade 2",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Basic Research",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Composite Lumber",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Modern Upgrade 3",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Oil Head Lamp",
            "Untranslated": "Oil Head Lamp Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 1200,
            "BaseXPGain": 3,
            "CraftingTable": "Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Oil Head Lamp",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "OilHeadLamp",
                    "Name": "Oil Head Lamp",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Plate",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Oil Head Lamp",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Work Backpack",
            "Untranslated": "Work Backpack Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 600,
            "BaseXPGain": 3,
            "CraftingTable": "Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Work Backpack",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "WorkBackpack",
                    "Name": "Work Backpack",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cellulose Fiber",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fabric",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Work Backpack",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Composites Upgrade",
            "Untranslated": "Composites Upgrade Recipe",
            "BaseCraftTime": 25.199999,
            "BaseLaborCost": 9000,
            "BaseXPGain": 4,
            "CraftingTable": "Advanced Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Composites Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Composites",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "CompositesUpgrade",
                    "Name": "Composites Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Modern Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composites Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Basic Upgrade 2",
            "Untranslated": "Basic Upgrade 2 Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 60,
            "BaseXPGain": 1,
            "CraftingTable": "Research Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Basic Upgrade 2",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "BasicUpgradeLvl2",
                    "Name": "Basic Upgrade 2",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gathering Research Paper Basic",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Metallurgy Research Paper Basic",
                            "Ammount": 2,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wooden Hull Planks",
                            "Ammount": 4,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Basic Upgrade 1",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Basic Upgrade 2",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Tailoring Modern Upgrade",
            "Untranslated": "Tailoring Modern Upgrade Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 9000,
            "BaseXPGain": 4,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Tailoring Modern Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "TailoringModernUpgrade",
                    "Name": "Tailoring Modern Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Modern Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Tailoring Modern Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Low Top Shoes",
            "Untranslated": "Low Top Shoes Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 150,
            "BaseXPGain": 3,
            "CraftingTable": "Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Low Top Shoes",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "LowTopShoes",
                    "Name": "Low Top Shoes",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Low Top Shoes",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Glassworking Modern Upgrade",
            "Untranslated": "Glassworking Modern Upgrade Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 6000,
            "BaseXPGain": 4,
            "CraftingTable": "Electronics Assembly",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Glassworking Modern Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Glassworking",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "GlassworkingModernUpgrade",
                    "Name": "Glassworking Modern Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Modern Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Glassworking Modern Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Farming Upgrade",
            "Untranslated": "Farming Upgrade Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 3000,
            "BaseXPGain": 4,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Farming Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Farming",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "FarmingUpgrade",
                    "Name": "Farming Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Basic Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Farming Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Carpentry Advanced Upgrade",
            "Untranslated": "Carpentry Advanced Upgrade Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 6000,
            "BaseXPGain": 4,
            "CraftingTable": "Sawmill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Carpentry Advanced Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "CarpentryAdvancedUpgrade",
                    "Name": "Carpentry Advanced Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Advanced Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Carpentry Advanced Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Derpy Hat",
            "Untranslated": "Derpy Hat Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 40,
            "BaseXPGain": 3,
            "CraftingTable": "Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Derpy Hat",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "DerpyHat",
                    "Name": "Derpy Hat",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fabric",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Derpy Hat",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Steel Pipe",
            "Untranslated": "Steel Pipe Recipe",
            "BaseCraftTime": 2.1,
            "BaseLaborCost": 60,
            "BaseXPGain": 0.5,
            "CraftingTable": "Blast Furnace",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Steel Pipe",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Smelting",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "SteelPipe",
                    "Name": "Steel Pipe",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Steel Pipe",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Basic Upgrade 3",
            "Untranslated": "Basic Upgrade 3 Recipe",
            "BaseCraftTime": 8.4,
            "BaseLaborCost": 60,
            "BaseXPGain": 1,
            "CraftingTable": "Research Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Basic Upgrade 3",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "BasicUpgradeLvl3",
                    "Name": "Basic Upgrade 3",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Culinary Research Paper Basic",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Dendrology Research Paper Advanced",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Agriculture Research Paper Advanced",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Basic Upgrade 2",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Basic Upgrade 3",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Modern Upgrade 4",
            "Untranslated": "Modern Upgrade 4 Recipe",
            "BaseCraftTime": 19.6,
            "BaseLaborCost": 600,
            "BaseXPGain": 1,
            "CraftingTable": "Laboratory",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Modern Upgrade 4",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "ModernUpgradeLvl4",
                    "Name": "Modern Upgrade 4",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Basic Circuit",
                            "Ammount": 4,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plastic",
                            "Ammount": 7,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Epoxy",
                            "Ammount": 5,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Modern Upgrade 3",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Basic Research",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Modern Upgrade 4",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Advanced Cooking Upgrade",
            "Untranslated": "Advanced Cooking Upgrade Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 6000,
            "BaseXPGain": 4,
            "CraftingTable": "Kitchen",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Advanced Cooking Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Cooking",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "AdvancedCookingUpgrade",
                    "Name": "Advanced Cooking Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Advanced Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Advanced Cooking Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Mining Modern Upgrade",
            "Untranslated": "Mining Modern Upgrade Recipe",
            "BaseCraftTime": 25.199999,
            "BaseLaborCost": 9000,
            "BaseXPGain": 4,
            "CraftingTable": "Froth Floatation Cell",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Mining Modern Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "MiningModernUpgrade",
                    "Name": "Mining Modern Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Modern Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Mining Modern Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Mining Advanced Upgrade",
            "Untranslated": "Mining Advanced Upgrade Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 6000,
            "BaseXPGain": 4,
            "CraftingTable": "Screening Machine",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Mining Advanced Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "MiningAdvancedUpgrade",
                    "Name": "Mining Advanced Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Advanced Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Mining Advanced Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Painting Upgrade",
            "Untranslated": "Painting Upgrade Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 3000,
            "BaseXPGain": 4,
            "CraftingTable": "Paint Mixer",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Painting Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Painting",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "PaintingUpgrade",
                    "Name": "Painting Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Basic Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Painting Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Gathering Basic Upgrade",
            "Untranslated": "Gathering Basic Upgrade Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 3000,
            "BaseXPGain": 4,
            "CraftingTable": "Fiber Scutching Station",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Gathering Basic Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Gathering",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "GatheringBasicUpgrade",
                    "Name": "Gathering Basic Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Basic Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Gathering Basic Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Small Court",
            "Untranslated": "Small Court Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 2000,
            "BaseXPGain": 1,
            "CraftingTable": "Settlement Crafting Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Small Court",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "SmallCourt",
                    "Name": "Small Court",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Mortared Stone",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Court",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Advanced Baking Upgrade",
            "Untranslated": "Advanced Baking Upgrade Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 6000,
            "BaseXPGain": 4,
            "CraftingTable": "Bakery Oven",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Advanced Baking Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Baking",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "AdvancedBakingUpgrade",
                    "Name": "Advanced Baking Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Advanced Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Advanced Baking Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Advanced Masonry Upgrade",
            "Untranslated": "Advanced Masonry Upgrade Recipe",
            "BaseCraftTime": 25.199999,
            "BaseLaborCost": 9000,
            "BaseXPGain": 4,
            "CraftingTable": "Advanced Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Advanced Masonry Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Masonry",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "AdvancedMasonryUpgrade",
                    "Name": "Advanced Masonry Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Modern Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Advanced Masonry Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Small Paper Machine",
            "Untranslated": "Small Paper Machine Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 240,
            "BaseXPGain": 1,
            "CraftingTable": "Machinist Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Small Paper Machine",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "SmallPaperMachine",
                    "Name": "Small Paper Machine",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Gear",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Plate",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Paper Machine",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Basic Upgrade 4",
            "Untranslated": "Basic Upgrade 4 Recipe",
            "BaseCraftTime": 11.2,
            "BaseLaborCost": 60,
            "BaseXPGain": 1,
            "CraftingTable": "Research Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Basic Upgrade 4",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "BasicUpgradeLvl4",
                    "Name": "Basic Upgrade 4",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Geology Research Paper Advanced",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Engineering Research Paper Advanced",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Basic Upgrade 3",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Basic Research",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Basic Upgrade 4",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Paper Milling Upgrade",
            "Untranslated": "Paper Milling Upgrade Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 6000,
            "BaseXPGain": 4,
            "CraftingTable": "Small Paper Machine",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Paper Milling Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Paper Milling",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "PaperMillingUpgrade",
                    "Name": "Paper Milling Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Basic Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Paper Milling Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Hunting Upgrade",
            "Untranslated": "Hunting Upgrade Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 3000,
            "BaseXPGain": 4,
            "CraftingTable": "Fishery",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Hunting Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Hunting",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "HuntingUpgrade",
                    "Name": "Hunting Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Basic Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Hunting Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Hewn Log",
            "Untranslated": "Hewn Log Recipe",
            "BaseCraftTime": 0.22399999,
            "BaseLaborCost": 20,
            "BaseXPGain": 0.5,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Hewn Log",
            "NumberOfVariants": 3,
            "SkillNeeds": [
                {
                    "Skill": "Logging",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "HewnLog",
                    "Name": "Hewn Log",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Dowel",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Hewn Log",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SoftwoodHewnLog",
                    "Name": "Softwood Hewn Log",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Dowel",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Softwood",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Softwood Hewn Log",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "HardwoodHewnLog",
                    "Name": "Hardwood Hewn Log",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Dowel",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hardwood",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Hardwood Hewn Log",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Shipwright Advanced Upgrade",
            "Untranslated": "Shipwright Advanced Upgrade Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 6000,
            "BaseXPGain": 4,
            "CraftingTable": "Medium Shipyard",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Shipwright Advanced Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Shipwright",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "ShipwrightAdvancedUpgrade",
                    "Name": "Shipwright Advanced Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Advanced Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Shipwright Advanced Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Crushed Coal",
            "Untranslated": "Crushed Coal Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 50,
            "BaseXPGain": 0.5,
            "CraftingTable": "Arrastra",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Crushed Coal",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CrushedCoal",
                    "Name": "Crushed Coal",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Coal",
                            "Ammount": 12,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Crushed Coal",
                            "Ammount": 2
                        },
                        {
                            "Name": "Crushed Mixed Rock",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Masonry Advanced Upgrade",
            "Untranslated": "Masonry Advanced Upgrade Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 6000,
            "BaseXPGain": 4,
            "CraftingTable": "Cement Kiln",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Masonry Advanced Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Masonry",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "MasonryAdvancedUpgrade",
                    "Name": "Masonry Advanced Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Advanced Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Masonry Advanced Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Advanced Smelting Upgrade",
            "Untranslated": "Advanced Smelting Upgrade Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 6000,
            "BaseXPGain": 4,
            "CraftingTable": "Blast Furnace",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Advanced Smelting Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Smelting",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "AdvancedSmeltingUpgrade",
                    "Name": "Advanced Smelting Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Advanced Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Advanced Smelting Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Reinforced Concrete",
            "Untranslated": "Reinforced Concrete Recipe",
            "BaseCraftTime": 0.89599997,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Cement Kiln",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Reinforced Concrete",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Masonry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "ReinforcedConcrete",
                    "Name": "Reinforced Concrete",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cement",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Rebar",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Sand",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Crushed Rock",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Reinforced Concrete",
                            "Ammount": 5
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Cutting Edge Cooking Upgrade",
            "Untranslated": "Cutting Edge Cooking Upgrade Recipe",
            "BaseCraftTime": 25.199999,
            "BaseLaborCost": 9000,
            "BaseXPGain": 4,
            "CraftingTable": "Laboratory",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Cutting Edge Cooking Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Cutting Edge Cooking",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "CuttingEdgeCookingUpgrade",
                    "Name": "Cutting Edge Cooking Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Modern Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Cutting Edge Cooking Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Industry Upgrade",
            "Untranslated": "Industry Upgrade Recipe",
            "BaseCraftTime": 25.199999,
            "BaseLaborCost": 9000,
            "BaseXPGain": 4,
            "CraftingTable": "Robotic Assembly Line",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Industry Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Industry",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "IndustryUpgrade",
                    "Name": "Industry Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Modern Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Industry Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Stamp Mill",
            "Untranslated": "Stamp Mill Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 120,
            "BaseXPGain": 5,
            "CraftingTable": "Machinist Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Stamp Mill",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "StampMill",
                    "Name": "Stamp Mill",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 14,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Gear",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 14,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Stamp Mill",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Shipwright Basic Upgrade",
            "Untranslated": "Shipwright Basic Upgrade Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 3000,
            "BaseXPGain": 4,
            "CraftingTable": "Small Shipyard",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Shipwright Basic Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Shipwright",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "ShipwrightBasicUpgrade",
                    "Name": "Shipwright Basic Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Basic Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Shipwright Basic Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Smelting Basic Upgrade",
            "Untranslated": "Smelting Basic Upgrade Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 3000,
            "BaseXPGain": 4,
            "CraftingTable": "Bloomery",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Smelting Basic Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Smelting",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "SmeltingBasicUpgrade",
                    "Name": "Smelting Basic Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Basic Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Smelting Basic Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Glassworking Advanced Upgrade",
            "Untranslated": "Glassworking Advanced Upgrade Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 6000,
            "BaseXPGain": 4,
            "CraftingTable": "Glassworks",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Glassworking Advanced Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Glassworking",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "GlassworkingAdvancedUpgrade",
                    "Name": "Glassworking Advanced Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Advanced Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Glassworking Advanced Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Smelting Upgrade",
            "Untranslated": "Smelting Upgrade Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 6000,
            "BaseXPGain": 4,
            "CraftingTable": "Blast Furnace",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Smelting Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Smelting",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "SmeltingUpgrade",
                    "Name": "Smelting Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Advanced Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Smelting Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Basic Upgrade 1",
            "Untranslated": "Basic Upgrade 1 Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 60,
            "BaseXPGain": 1,
            "CraftingTable": "Research Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Basic Upgrade 1",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "BasicUpgradeLvl1",
                    "Name": "Basic Upgrade 1",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Dendrology Research Paper Basic",
                            "Ammount": 2,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Geology Research Paper Basic",
                            "Ammount": 3,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Basic Upgrade 1",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Oil Drilling Upgrade",
            "Untranslated": "Oil Drilling Upgrade Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 6000,
            "BaseXPGain": 4,
            "CraftingTable": "Oil Refinery",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Oil Drilling Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Oil Drilling",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "OilDrillingUpgrade",
                    "Name": "Oil Drilling Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Modern Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Oil Drilling Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Modern Upgrade 1",
            "Untranslated": "Modern Upgrade 1 Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 600,
            "BaseXPGain": 1,
            "CraftingTable": "Laboratory",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Modern Upgrade 1",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "ModernUpgradeLvl1",
                    "Name": "Modern Upgrade 1",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 3,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Rivet",
                            "Ammount": 10,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Basic Research",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Modern Upgrade 1",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Mining Basic Upgrade",
            "Untranslated": "Mining Basic Upgrade Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 3000,
            "BaseXPGain": 4,
            "CraftingTable": "Rocker Box",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Mining Basic Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "MiningBasicUpgrade",
                    "Name": "Mining Basic Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Basic Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Mining Basic Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Blacksmith Upgrade",
            "Untranslated": "Blacksmith Upgrade Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 3000,
            "BaseXPGain": 4,
            "CraftingTable": "Blacksmith Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Blacksmith Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "BlacksmithUpgrade",
                    "Name": "Blacksmith Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Basic Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Blacksmith Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Blacksmith Advanced Upgrade",
            "Untranslated": "Blacksmith Advanced Upgrade Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 6000,
            "BaseXPGain": 4,
            "CraftingTable": "Blacksmith Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Blacksmith Advanced Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "BlacksmithAdvancedUpgrade",
                    "Name": "Blacksmith Advanced Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Advanced Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Blacksmith Advanced Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Butchery Upgrade",
            "Untranslated": "Butchery Upgrade Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 3000,
            "BaseXPGain": 4,
            "CraftingTable": "Butchery Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Butchery Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Butchery",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "ButcheryUpgrade",
                    "Name": "Butchery Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Basic Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Butchery Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Mechanics Modern Upgrade",
            "Untranslated": "Mechanics Modern Upgrade Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 6000,
            "BaseXPGain": 4,
            "CraftingTable": "Electric Machinist Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Mechanics Modern Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "MechanicsModernUpgrade",
                    "Name": "Mechanics Modern Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Modern Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Mechanics Modern Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Masonry Basic Upgrade",
            "Untranslated": "Masonry Basic Upgrade Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 3000,
            "BaseXPGain": 4,
            "CraftingTable": "Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Masonry Basic Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Masonry",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "MasonryBasicUpgrade",
                    "Name": "Masonry Basic Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Basic Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Masonry Basic Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Ashlar Basalt",
            "Untranslated": "Ashlar Basalt Recipe",
            "BaseCraftTime": 0.89599997,
            "BaseLaborCost": 180,
            "BaseXPGain": 1.5,
            "CraftingTable": "Advanced Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Ashlar Basalt",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Masonry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "AshlarBasalt",
                    "Name": "Ashlar Basalt",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Basalt",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cement",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Basalt",
                            "Ammount": 2
                        },
                        {
                            "Name": "Crushed Basalt",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Prickly Pear Seed",
            "Untranslated": "Prickly Pear Seed Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 20,
            "BaseXPGain": 1,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Prickly Pear Seed",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Farming",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "PricklyPearSeed",
                    "Name": "Prickly Pear Seed",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Prickly Pear Fruit",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Prickly Pear Seed",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Tailoring Basic Upgrade",
            "Untranslated": "Tailoring Basic Upgrade Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 3000,
            "BaseXPGain": 4,
            "CraftingTable": "Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Tailoring Basic Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "TailoringUpgrade",
                    "Name": "Tailoring Basic Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Basic Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Tailoring Basic Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Electronics Upgrade",
            "Untranslated": "Electronics Upgrade Recipe",
            "BaseCraftTime": 25.199999,
            "BaseLaborCost": 9000,
            "BaseXPGain": 4,
            "CraftingTable": "Electronics Assembly",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Electronics Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Electronics",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "ElectronicsUpgrade",
                    "Name": "Electronics Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Modern Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Electronics Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Milling Upgrade",
            "Untranslated": "Milling Upgrade Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 3000,
            "BaseXPGain": 4,
            "CraftingTable": "Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Milling Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "MillingUpgrade",
                    "Name": "Milling Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Basic Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Milling Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Campfire Cooking Upgrade",
            "Untranslated": "Campfire Cooking Upgrade Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 3000,
            "BaseXPGain": 4,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Campfire Cooking Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "CampfireCookingUpgrade",
                    "Name": "Campfire Cooking Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Basic Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Campfire Cooking Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wheat Seed",
            "Untranslated": "Wheat Seed Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 20,
            "BaseXPGain": 1,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Wheat Seed",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Farming",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "WheatSeed",
                    "Name": "Wheat Seed",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wheat",
                            "Ammount": 0.5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wheat Seed",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Cooking Upgrade",
            "Untranslated": "Cooking Upgrade Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 6000,
            "BaseXPGain": 4,
            "CraftingTable": "Cast Iron Stove",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Cooking Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Cooking",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "CookingUpgrade",
                    "Name": "Cooking Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Advanced Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Cooking Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Advanced Upgrade 1",
            "Untranslated": "Advanced Upgrade 1 Recipe",
            "BaseCraftTime": 4.2,
            "BaseLaborCost": 90,
            "BaseXPGain": 1,
            "CraftingTable": "Laboratory",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Advanced Upgrade 1",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "AdvancedUpgradeLvl1",
                    "Name": "Advanced Upgrade 1",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Geology Research Paper Modern",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Basic Research",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Advanced Upgrade 1",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Logging Basic Upgrade",
            "Untranslated": "Logging Basic Upgrade Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 3000,
            "BaseXPGain": 4,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Logging Basic Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Logging",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "LoggingBasicUpgrade",
                    "Name": "Logging Basic Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Basic Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Logging Basic Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Baking Upgrade",
            "Untranslated": "Baking Upgrade Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 6000,
            "BaseXPGain": 4,
            "CraftingTable": "Bakery Oven",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Baking Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Baking",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "BakingUpgrade",
                    "Name": "Baking Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Advanced Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Baking Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Fertilizers Upgrade",
            "Untranslated": "Fertilizers Upgrade Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 6000,
            "BaseXPGain": 4,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Fertilizers Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Fertilizers",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "FertilizersUpgrade",
                    "Name": "Fertilizers Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Basic Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Fertilizers Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Corn Seed",
            "Untranslated": "Corn Seed Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 20,
            "BaseXPGain": 1,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Corn Seed",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Farming",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CornSeed",
                    "Name": "Corn Seed",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Corn",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Corn Seed",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Logging Advanced Upgrade",
            "Untranslated": "Logging Advanced Upgrade Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 3000,
            "BaseXPGain": 4,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Logging Advanced Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Logging",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "LoggingAdvancedUpgrade",
                    "Name": "Logging Advanced Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Advanced Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Logging Advanced Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Basic Engineering Upgrade",
            "Untranslated": "Basic Engineering Upgrade Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 3000,
            "BaseXPGain": 4,
            "CraftingTable": "Wainwright Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Basic Engineering Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Basic Engineering",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "BasicEngineeringUpgrade",
                    "Name": "Basic Engineering Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Basic Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Basic Engineering Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Pottery Upgrade",
            "Untranslated": "Pottery Upgrade Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 6000,
            "BaseXPGain": 4,
            "CraftingTable": "Pottery Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Pottery Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Pottery",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "PotteryUpgrade",
                    "Name": "Pottery Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Advanced Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Pottery Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Tomato Seed",
            "Untranslated": "Tomato Seed Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 20,
            "BaseXPGain": 1,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Tomato Seed",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Farming",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "TomatoSeed",
                    "Name": "Tomato Seed",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Tomato",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Tomato Seed",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Advanced Upgrade 2",
            "Untranslated": "Advanced Upgrade 2 Recipe",
            "BaseCraftTime": 8.4,
            "BaseLaborCost": 90,
            "BaseXPGain": 1,
            "CraftingTable": "Laboratory",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Advanced Upgrade 2",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "AdvancedUpgradeLvl2",
                    "Name": "Advanced Upgrade 2",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Agriculture Research Paper Modern",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Advanced Upgrade 1",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Basic Research",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Advanced Upgrade 2",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Carpentry Basic Upgrade",
            "Untranslated": "Carpentry Basic Upgrade Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 3000,
            "BaseXPGain": 4,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Carpentry Basic Upgrade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "CarpentryBasicUpgrade",
                    "Name": "Carpentry Basic Upgrade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Basic Upgrade 4",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Carpentry Basic Upgrade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Trillium Seed",
            "Untranslated": "Trillium Seed Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 20,
            "BaseXPGain": 1,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Trillium Seed",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Farming",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "TrilliumSeed",
                    "Name": "Trillium Seed",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Trillium Flower",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Trillium Seed",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Taro Seed",
            "Untranslated": "Taro Seed Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 20,
            "BaseXPGain": 1,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Taro Seed",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Farming",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "TaroSeed",
                    "Name": "Taro Seed",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Taro Root",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Taro Seed",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Pineapple Seed",
            "Untranslated": "Pineapple Seed Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 20,
            "BaseXPGain": 1,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Pineapple Seed",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Farming",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "PineappleSeed",
                    "Name": "Pineapple Seed",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Pineapple",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Pineapple Seed",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Beet Seed",
            "Untranslated": "Beet Seed Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 20,
            "BaseXPGain": 1,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Beet Seed",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Farming",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "BeetSeed",
                    "Name": "Beet Seed",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Beet",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Beet Seed",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Huge Banner Sign",
            "Untranslated": "Huge Banner Sign Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 60,
            "BaseXPGain": 1,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Huge Banner Sign",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Logging",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "HugeBannerSign",
                    "Name": "Huge Banner Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Linen Yarn",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Huge Banner Sign",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Stuffed Alligator",
            "Untranslated": "Stuffed Alligator Recipe",
            "BaseCraftTime": 16.8,
            "BaseLaborCost": 300,
            "BaseXPGain": 4,
            "CraftingTable": "Butchery Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Stuffed Alligator",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Hunting",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "StuffedAlligator",
                    "Name": "Stuffed Alligator",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fabric",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Alligator Carcass",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Stuffed Alligator",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Storage Chest",
            "Untranslated": "Storage Chest Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Workbench",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Storage Chest",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "StorageChest",
                    "Name": "Storage Chest",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Storage Chest",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wooden Ceiling Light",
            "Untranslated": "Wooden Ceiling Light Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 120,
            "BaseXPGain": 3,
            "CraftingTable": "Sawmill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Wooden Ceiling Light",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "WoodenCeilingLight",
                    "Name": "Wooden Ceiling Light",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Wiring",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fabric",
                            "Name": "",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Light Bulb",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wooden Ceiling Light",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Fireweed Seed",
            "Untranslated": "Fireweed Seed Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 20,
            "BaseXPGain": 1,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Fireweed Seed",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Farming",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "FireweedSeed",
                    "Name": "Fireweed Seed",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Fireweed Shoots",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Fireweed Seed",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Fern Spore",
            "Untranslated": "Fern Spore Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 20,
            "BaseXPGain": 1,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Fern Spore",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Farming",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "FernSpore",
                    "Name": "Fern Spore",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Fiddleheads",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Fern Spore",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Cookeina Mushroom Spores",
            "Untranslated": "Cookeina Mushroom Spores Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 20,
            "BaseXPGain": 1,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Cookeina Mushroom Spores",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Farming",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CookeinaMushroomSpores",
                    "Name": "Cookeina Mushroom Spores",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cookeina Mushrooms",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Cookeina Mushroom Spores",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Papaya Seed",
            "Untranslated": "Papaya Seed Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 20,
            "BaseXPGain": 1,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Papaya Seed",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Farming",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "PapayaSeed",
                    "Name": "Papaya Seed",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Papaya",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Papaya Seed",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Amanita Mushroom Spores",
            "Untranslated": "Amanita Mushroom Spores Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 20,
            "BaseXPGain": 1,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Amanita Mushroom Spores",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Farming",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "AmanitaMushroomSpores",
                    "Name": "Amanita Mushroom Spores",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Amanita Mushrooms",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Amanita Mushroom Spores",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Crimini Mushroom Spores",
            "Untranslated": "Crimini Mushroom Spores Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 20,
            "BaseXPGain": 1,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Crimini Mushroom Spores",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Farming",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CriminiMushroomSpores",
                    "Name": "Crimini Mushroom Spores",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Crimini Mushrooms",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Crimini Mushroom Spores",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Flax Seed",
            "Untranslated": "Flax Seed Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 20,
            "BaseXPGain": 1,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Flax Seed",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Farming",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "FlaxSeed",
                    "Name": "Flax Seed",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Flax Stem",
                            "Ammount": 0.5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Flax Seed",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Creosote Bush Seed",
            "Untranslated": "Creosote Bush Seed Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 20,
            "BaseXPGain": 1,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Creosote Bush Seed",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Farming",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CreosoteBushSeed",
                    "Name": "Creosote Bush Seed",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Creosote Flower",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Creosote Bush Seed",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Pumpkin Seed",
            "Untranslated": "Pumpkin Seed Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 20,
            "BaseXPGain": 1,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Pumpkin Seed",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Farming",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "PumpkinSeed",
                    "Name": "Pumpkin Seed",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Pumpkin",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Pumpkin Seed",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Cotton Seed",
            "Untranslated": "Cotton Seed Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 20,
            "BaseXPGain": 1,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Cotton Seed",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Farming",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CottonSeed",
                    "Name": "Cotton Seed",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Boll",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Cotton Seed",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Agave Seed",
            "Untranslated": "Agave Seed Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 20,
            "BaseXPGain": 1,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Agave Seed",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Farming",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "AgaveSeed",
                    "Name": "Agave Seed",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Agave Leaves",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Agave Seed",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Crushed Sandstone",
            "Untranslated": "Crushed Sandstone Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 30,
            "BaseXPGain": 0.5,
            "CraftingTable": "Arrastra",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Crushed Sandstone",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CrushedSandstone",
                    "Name": "Crushed Sandstone",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Sandstone",
                            "Ammount": 12,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Crushed Sandstone",
                            "Ammount": 3
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Bolete Mushroom Spores",
            "Untranslated": "Bolete Mushroom Spores Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 20,
            "BaseXPGain": 1,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Bolete Mushroom Spores",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Farming",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "BoleteMushroomSpores",
                    "Name": "Bolete Mushroom Spores",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Bolete Mushrooms",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Bolete Mushroom Spores",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Iron Pipe",
            "Untranslated": "Iron Pipe Recipe",
            "BaseCraftTime": 1.12,
            "BaseLaborCost": 15,
            "BaseXPGain": 0.5,
            "CraftingTable": "Anvil",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Iron Pipe",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Smelting",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "IronPipe",
                    "Name": "Iron Pipe",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Iron Pipe",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Adobe",
            "Untranslated": "Adobe Recipe",
            "BaseCraftTime": 0.22399999,
            "BaseLaborCost": 20,
            "BaseXPGain": 1,
            "CraftingTable": "Workbench",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Adobe",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "Adobe",
                    "Name": "Adobe",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Dirt",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Adobe",
                            "Ammount": 4
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Crushed Limestone",
            "Untranslated": "Crushed Limestone Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 50,
            "BaseXPGain": 0.5,
            "CraftingTable": "Arrastra",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Crushed Limestone",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CrushedLimestone",
                    "Name": "Crushed Limestone",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Limestone",
                            "Ammount": 12,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Crushed Limestone",
                            "Ammount": 3
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Copper Bar",
            "Untranslated": "Copper Bar Recipe",
            "BaseCraftTime": 0.84000003,
            "BaseLaborCost": 60,
            "BaseXPGain": 2,
            "CraftingTable": "Blast Furnace",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Copper Bar",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Smelting",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "CopperBar",
                    "Name": "Copper Bar",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Concentrate",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Clay Mold",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Copper Bar",
                            "Ammount": 4
                        },
                        {
                            "Name": "Slag",
                            "Ammount": 1
                        },
                        {
                            "Name": "Clay Mold",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Iron Bar",
            "Untranslated": "Iron Bar Recipe",
            "BaseCraftTime": 0.84000003,
            "BaseLaborCost": 60,
            "BaseXPGain": 2,
            "CraftingTable": "Blast Furnace",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Iron Bar",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Smelting",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "IronBar",
                    "Name": "Iron Bar",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Concentrate",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Clay Mold",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Iron Bar",
                            "Ammount": 4
                        },
                        {
                            "Name": "Slag",
                            "Ammount": 1
                        },
                        {
                            "Name": "Clay Mold",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Nylon Carpet",
            "Untranslated": "Nylon Carpet Recipe",
            "BaseCraftTime": 0.89599997,
            "BaseLaborCost": 180,
            "BaseXPGain": 1.5,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Nylon Carpet",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "NylonCarpet",
                    "Name": "Nylon Carpet",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Epoxy",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nylon Fabric",
                            "Ammount": 3,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Nylon Carpet",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wall Mounted Chest",
            "Untranslated": "Wall Mounted Chest Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 40,
            "BaseXPGain": 1,
            "CraftingTable": "Workbench",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Wall Mounted Chest",
            "NumberOfVariants": 2,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "WallMountedChest",
                    "Name": "Wall Mounted Chest",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wall Mounted Chest",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "WallMountedTrunk",
                    "Name": "Wall Mounted Trunk",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wall Mounted Trunk",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Huckleberry Seed",
            "Untranslated": "Huckleberry Seed Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 20,
            "BaseXPGain": 1,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Huckleberry Seed",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Farming",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "HuckleberrySeed",
                    "Name": "Huckleberry Seed",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Huckleberries",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Huckleberry Seed",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Sunflower Seed",
            "Untranslated": "Sunflower Seed Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 20,
            "BaseXPGain": 1,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Sunflower Seed",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Farming",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "SunflowerSeed",
                    "Name": "Sunflower Seed",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Sunflower",
                            "Ammount": 0.5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Sunflower Seed",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Corrugated Steel Door",
            "Untranslated": "Corrugated Steel Door Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 300,
            "BaseXPGain": 0.5,
            "CraftingTable": "Rolling Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Corrugated Steel Door",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Smelting",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "CorrugatedSteelDoor",
                    "Name": "Corrugated Steel Door",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Corrugated Steel",
                            "Ammount": 2,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Corrugated Steel Door",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Gasoline",
            "Untranslated": "Gasoline Recipe",
            "BaseCraftTime": 1.12,
            "BaseLaborCost": 120,
            "BaseXPGain": 0.5,
            "CraftingTable": "Oil Refinery",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Gasoline",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Oil Drilling",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "Gasoline",
                    "Name": "Gasoline",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Petroleum",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Gasoline",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Town Bell",
            "Untranslated": "Town Bell Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 600,
            "BaseXPGain": 2,
            "CraftingTable": "Bloomery",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Town Bell",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Smelting",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "TownBell",
                    "Name": "Town Bell",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Bar",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Bar",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Town Bell",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Brick",
            "Untranslated": "Brick Recipe",
            "BaseCraftTime": 0.44799998,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Kiln",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Brick",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Pottery",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Brick",
                    "Name": "Brick",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wet Brick",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Mortar",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Brick",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Flat Steel",
            "Untranslated": "Flat Steel Recipe",
            "BaseCraftTime": 0.89599997,
            "BaseLaborCost": 120,
            "BaseXPGain": 1.5,
            "CraftingTable": "Rolling Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Flat Steel",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Smelting",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "FlatSteel",
                    "Name": "Flat Steel",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 3,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Epoxy",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Flat Steel",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Crushed Granite",
            "Untranslated": "Crushed Granite Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 70,
            "BaseXPGain": 0.5,
            "CraftingTable": "Arrastra",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Crushed Granite",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CrushedGranite",
                    "Name": "Crushed Granite",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Granite",
                            "Ammount": 12,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Crushed Granite",
                            "Ammount": 3
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Cotton Curtains",
            "Untranslated": "Cotton Curtains Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 120,
            "BaseXPGain": 1.5,
            "CraftingTable": "Loom",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Cotton Curtains",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "CottonCurtains",
                    "Name": "Cotton Curtains",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Thread",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Cotton Curtains",
                            "Ammount": 4
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Crushed Copper Ore",
            "Untranslated": "Crushed Copper Ore Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 70,
            "BaseXPGain": 0.5,
            "CraftingTable": "Arrastra",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Crushed Copper Ore",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CrushedCopperOre",
                    "Name": "Crushed Copper Ore",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Ore",
                            "Ammount": 12,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Crushed Copper Ore",
                            "Ammount": 2
                        },
                        {
                            "Name": "Crushed Granite",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Ashlar Gneiss",
            "Untranslated": "Ashlar Gneiss Recipe",
            "BaseCraftTime": 0.89599997,
            "BaseLaborCost": 180,
            "BaseXPGain": 1.5,
            "CraftingTable": "Advanced Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Ashlar Gneiss",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Masonry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "AshlarGneiss",
                    "Name": "Ashlar Gneiss",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gneiss",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cement",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Gneiss",
                            "Ammount": 2
                        },
                        {
                            "Name": "Crushed Gneiss",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Orchid Seed",
            "Untranslated": "Orchid Seed Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 20,
            "BaseXPGain": 1,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Orchid Seed",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Farming",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "OrchidSeed",
                    "Name": "Orchid Seed",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Orchid",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Orchid Seed",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wool Curtains",
            "Untranslated": "Wool Curtains Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 120,
            "BaseXPGain": 1.5,
            "CraftingTable": "Loom",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Wool Curtains",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "WoolCurtains",
                    "Name": "Wool Curtains",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wool Fabric",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Thread",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wool Curtains",
                            "Ammount": 4
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Asphalt Concrete",
            "Untranslated": "Asphalt Concrete Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 180,
            "BaseXPGain": 1.5,
            "CraftingTable": "Wainwright Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Asphalt Concrete",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Basic Engineering",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "AsphaltConcrete",
                    "Name": "Asphalt Concrete",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cement",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Sand",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Crushed Rock",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Asphalt Concrete",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Barrel",
            "Untranslated": "Barrel Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 720,
            "BaseXPGain": 2,
            "CraftingTable": "Rolling Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Barrel",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Smelting",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Barrel",
                    "Name": "Barrel",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Barrel",
                            "Ammount": 4
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Ashlar Shale",
            "Untranslated": "Ashlar Shale Recipe",
            "BaseCraftTime": 0.89599997,
            "BaseLaborCost": 180,
            "BaseXPGain": 1.5,
            "CraftingTable": "Advanced Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Ashlar Shale",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Masonry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "AshlarShale",
                    "Name": "Ashlar Shale",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Shale",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cement",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Shale",
                            "Ammount": 2
                        },
                        {
                            "Name": "Crushed Shale",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Crushed Shale",
            "Untranslated": "Crushed Shale Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 30,
            "BaseXPGain": 0.5,
            "CraftingTable": "Arrastra",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Crushed Shale",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CrushedShale",
                    "Name": "Crushed Shale",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Shale",
                            "Ammount": 12,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Crushed Shale",
                            "Ammount": 3
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Corrugated Steel",
            "Untranslated": "Corrugated Steel Recipe",
            "BaseCraftTime": 0.44799998,
            "BaseLaborCost": 25,
            "BaseXPGain": 0.5,
            "CraftingTable": "Rolling Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Corrugated Steel",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Smelting",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "CorrugatedSteel",
                    "Name": "Corrugated Steel",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 3,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Corrugated Steel",
                            "Ammount": 4
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Cotton Carpet",
            "Untranslated": "Cotton Carpet Recipe",
            "BaseCraftTime": 0.89599997,
            "BaseLaborCost": 180,
            "BaseXPGain": 1.5,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Cotton Carpet",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "CottonCarpet",
                    "Name": "Cotton Carpet",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Epoxy",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 3,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Cotton Carpet",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Gold Concentrate",
            "Untranslated": "Gold Concentrate Recipe",
            "BaseCraftTime": 2.1,
            "BaseLaborCost": 50,
            "BaseXPGain": 2,
            "CraftingTable": "Rocker Box",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Gold Concentrate",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "GoldConcentrate",
                    "Name": "Gold Concentrate",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Crushed Gold Ore",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Gold Concentrate",
                            "Ammount": 1
                        },
                        {
                            "Name": "Wet Tailings",
                            "Ammount": 4
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Petroleum",
            "Untranslated": "Petroleum Recipe",
            "BaseCraftTime": 0,
            "BaseLaborCost": 60,
            "BaseXPGain": 0.5,
            "CraftingTable": "Pump Jack",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Petroleum",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Oil Drilling",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Petroleum",
                    "Name": "Petroleum",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Barrel",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Petroleum",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Stone Road",
            "Untranslated": "Stone Road Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 60,
            "BaseXPGain": 1,
            "CraftingTable": "Wainwright Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Stone Road",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Basic Engineering",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "StoneRoad",
                    "Name": "Stone Road",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Mortar",
                            "Ammount": 3,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Crushed Rock",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Stone Road",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Iron Concentrate",
            "Untranslated": "Iron Concentrate Recipe",
            "BaseCraftTime": 2.1,
            "BaseLaborCost": 50,
            "BaseXPGain": 2,
            "CraftingTable": "Rocker Box",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Iron Concentrate",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "IronConcentrate",
                    "Name": "Iron Concentrate",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Crushed Iron Ore",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Iron Concentrate",
                            "Ammount": 1
                        },
                        {
                            "Name": "Wet Tailings",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Garden Gravel",
            "Untranslated": "Garden Gravel Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 50,
            "BaseXPGain": 0.5,
            "CraftingTable": "Arrastra",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Garden Gravel",
            "NumberOfVariants": 2,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "GardenGravel",
                    "Name": "Garden Gravel",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Rock",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Garden Gravel",
                            "Ammount": 3
                        }
                    ]
                },
                {
                    "Key": "ZenGarden",
                    "Name": "Zen Garden",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Rock",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Zen Garden",
                            "Ammount": 3
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Copper Pipe",
            "Untranslated": "Copper Pipe Recipe",
            "BaseCraftTime": 1.12,
            "BaseLaborCost": 15,
            "BaseXPGain": 0.5,
            "CraftingTable": "Anvil",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Copper Pipe",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Smelting",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "CopperPipe",
                    "Name": "Copper Pipe",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Bar",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Copper Pipe",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Biodiesel",
            "Untranslated": "Biodiesel Recipe",
            "BaseCraftTime": 1.12,
            "BaseLaborCost": 60,
            "BaseXPGain": 0.5,
            "CraftingTable": "Oil Refinery",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Biodiesel",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Oil Drilling",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "Biodiesel",
                    "Name": "Biodiesel",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ethanol",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fat",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Barrel",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Biodiesel",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Crushed Iron Ore",
            "Untranslated": "Crushed Iron Ore Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 50,
            "BaseXPGain": 0.5,
            "CraftingTable": "Arrastra",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Crushed Iron Ore",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CrushedIronOre",
                    "Name": "Crushed Iron Ore",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Ore",
                            "Ammount": 12,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Crushed Iron Ore",
                            "Ammount": 2
                        },
                        {
                            "Name": "Crushed Sandstone",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Mortared Stone",
            "Untranslated": "Mortared Stone Recipe",
            "BaseCraftTime": 0.22399999,
            "BaseLaborCost": 15,
            "BaseXPGain": 0.5,
            "CraftingTable": "Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Mortared Stone",
            "NumberOfVariants": 4,
            "SkillNeeds": [
                {
                    "Skill": "Masonry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "MortaredStone",
                    "Name": "Mortared Stone",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Mortar",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Rock",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Mortared Stone",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "MortaredLimestone",
                    "Name": "Mortared Limestone",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Limestone",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Mortar",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Mortared Limestone",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "MortaredSandstone",
                    "Name": "Mortared Sandstone",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Sandstone",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Mortar",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Mortared Sandstone",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "MortaredGranite",
                    "Name": "Mortared Granite",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Granite",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Mortar",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Mortared Granite",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Computer Lab",
            "Untranslated": "Computer Lab Recipe",
            "BaseCraftTime": 168,
            "BaseLaborCost": 3000,
            "BaseXPGain": 40,
            "CraftingTable": "Electronics Assembly",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Computer Lab",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Electronics",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "ComputerLab",
                    "Name": "Computer Lab",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Advanced Masonry Upgrade",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Composites Upgrade",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Electronics Upgrade",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Industry Upgrade",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Oil Drilling Upgrade",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Advanced Smelting Upgrade",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Advanced Circuit",
                            "Ammount": 100,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plastic",
                            "Ammount": 100,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Reinforced Concrete",
                            "Ammount": 200,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Composite Lumber",
                            "Name": "",
                            "Ammount": 200,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Computer Lab",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Ashlar Granite",
            "Untranslated": "Ashlar Granite Recipe",
            "BaseCraftTime": 0.89599997,
            "BaseLaborCost": 180,
            "BaseXPGain": 1.5,
            "CraftingTable": "Advanced Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Ashlar Granite",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Masonry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "AshlarGranite",
                    "Name": "Ashlar Granite",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Granite",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cement",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Granite",
                            "Ammount": 2
                        },
                        {
                            "Name": "Crushed Granite",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Gold Bar",
            "Untranslated": "Gold Bar Recipe",
            "BaseCraftTime": 0.84000003,
            "BaseLaborCost": 60,
            "BaseXPGain": 2,
            "CraftingTable": "Blast Furnace",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Gold Bar",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Smelting",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "GoldBar",
                    "Name": "Gold Bar",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Concentrate",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Clay Mold",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Gold Bar",
                            "Ammount": 4
                        },
                        {
                            "Name": "Slag",
                            "Ammount": 1
                        },
                        {
                            "Name": "Clay Mold",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Glass",
            "Untranslated": "Glass Recipe",
            "BaseCraftTime": 1.6800001,
            "BaseLaborCost": 30,
            "BaseXPGain": 1,
            "CraftingTable": "Glassworks",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Glass",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Glassworking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Glass",
                    "Name": "Glass",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Sand",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Crushed Limestone",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Glass",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Ashlar Limestone",
            "Untranslated": "Ashlar Limestone Recipe",
            "BaseCraftTime": 0.89599997,
            "BaseLaborCost": 180,
            "BaseXPGain": 1.5,
            "CraftingTable": "Advanced Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Ashlar Limestone",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Masonry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "AshlarLimestone",
                    "Name": "Ashlar Limestone",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Limestone",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cement",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Limestone",
                            "Ammount": 2
                        },
                        {
                            "Name": "Crushed Limestone",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Dirt Ramp",
            "Untranslated": "Dirt Ramp Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Wainwright Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Dirt Ramp",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "DirtRamp",
                    "Name": "Dirt Ramp",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Dirt",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Dirt Ramp",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Ashlar Sandstone",
            "Untranslated": "Ashlar Sandstone Recipe",
            "BaseCraftTime": 0.89599997,
            "BaseLaborCost": 180,
            "BaseXPGain": 1.5,
            "CraftingTable": "Advanced Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Ashlar Sandstone",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Masonry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "AshlarSandstone",
                    "Name": "Ashlar Sandstone",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Sandstone",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cement",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Sandstone",
                            "Ammount": 2
                        },
                        {
                            "Name": "Crushed Sandstone",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Framed Glass",
            "Untranslated": "Framed Glass Recipe",
            "BaseCraftTime": 0.89599997,
            "BaseLaborCost": 120,
            "BaseXPGain": 1.5,
            "CraftingTable": "Glassworks",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Framed Glass",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Glassworking",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "FramedGlass",
                    "Name": "Framed Glass",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Glass",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Epoxy",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Framed Glass",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Paint Mixer",
            "Untranslated": "Paint Mixer Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 180,
            "BaseXPGain": 4,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Paint Mixer",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Logging",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "PaintMixer",
                    "Name": "Paint Mixer",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 6,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Paint Mixer",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Mechanical Water Pump",
            "Untranslated": "Mechanical Water Pump Recipe",
            "BaseCraftTime": 11.2,
            "BaseLaborCost": 60,
            "BaseXPGain": 5,
            "CraftingTable": "Wainwright Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Mechanical Water Pump",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Basic Engineering",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "MechanicalWaterPump",
                    "Name": "Mechanical Water Pump",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Pipe",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Mechanical Water Pump",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Lumber",
            "Untranslated": "Lumber Recipe",
            "BaseCraftTime": 0.44799998,
            "BaseLaborCost": 60,
            "BaseXPGain": 1,
            "CraftingTable": "Sawmill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Lumber",
            "NumberOfVariants": 4,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Lumber",
                    "Name": "Lumber",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nail",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Flaxseed Oil",
                            "Ammount": 0.5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Lumber",
                            "Ammount": 2
                        }
                    ]
                },
                {
                    "Key": "SoftwoodLumber",
                    "Name": "Softwood Lumber",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Softwood Board",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nail",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Flaxseed Oil",
                            "Ammount": 0.5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Softwood Lumber",
                            "Ammount": 2
                        }
                    ]
                },
                {
                    "Key": "FarEastLumber",
                    "Name": "Far East Lumber",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nail",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Flaxseed Oil",
                            "Ammount": 0.5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Far East Lumber",
                            "Ammount": 2
                        }
                    ]
                },
                {
                    "Key": "HardwoodLumber",
                    "Name": "Hardwood Lumber",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Hardwood Board",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nail",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Flaxseed Oil",
                            "Ammount": 0.5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Hardwood Lumber",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Composite Filler",
            "Untranslated": "Composite Filler Recipe",
            "BaseCraftTime": 0.42000002,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Composite Filler",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Fertilizers",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "CompositeFiller",
                    "Name": "Composite Filler",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Dirt",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Natural Fiber",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Filler",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Composite Lumber",
            "Untranslated": "Composite Lumber Recipe",
            "BaseCraftTime": 0.89599997,
            "BaseLaborCost": 120,
            "BaseXPGain": 1.5,
            "CraftingTable": "Advanced Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Composite Lumber",
            "NumberOfVariants": 11,
            "SkillNeeds": [
                {
                    "Skill": "Composites",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CompositeLumber",
                    "Name": "Composite Lumber",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plastic",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Epoxy",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Lumber",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeSaguaroLumber",
                    "Name": "Composite Saguaro Lumber",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Saguaro Rib",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plastic",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Epoxy",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Saguaro Lumber",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeRedwoodLumber",
                    "Name": "Composite Redwood Lumber",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Redwood Log",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plastic",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Epoxy",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Redwood Lumber",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeFirLumber",
                    "Name": "Composite Fir Lumber",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Fir Log",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plastic",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Epoxy",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Fir Lumber",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeSpruceLumber",
                    "Name": "Composite Spruce Lumber",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Spruce Log",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plastic",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Epoxy",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Spruce Lumber",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeBirchLumber",
                    "Name": "Composite Birch Lumber",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Birch Log",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plastic",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Epoxy",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Birch Lumber",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeOakLumber",
                    "Name": "Composite Oak Lumber",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Oak Log",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plastic",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Epoxy",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Oak Lumber",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositePalmLumber",
                    "Name": "Composite Palm Lumber",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Palm Log",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plastic",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Epoxy",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Palm Lumber",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeCedarLumber",
                    "Name": "Composite Cedar Lumber",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cedar Log",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plastic",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Epoxy",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Cedar Lumber",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeJoshuaLumber",
                    "Name": "Composite Joshua Lumber",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Joshua Log",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plastic",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Epoxy",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Joshua Lumber",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeCeibaLumber",
                    "Name": "Composite Ceiba Lumber",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ceiba Log",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plastic",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Epoxy",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Ceiba Lumber",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Ashlar Stone Bench",
            "Untranslated": "Ashlar Stone Bench Recipe",
            "BaseCraftTime": 8.4,
            "BaseLaborCost": 180,
            "BaseXPGain": 3,
            "CraftingTable": "Advanced Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Ashlar Stone Bench",
            "NumberOfVariants": 7,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Masonry",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "AshlarStoneBench",
                    "Name": "Ashlar Stone Bench",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Ashlar Stone",
                            "Name": "",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Stone Bench",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AshlarBasaltBench",
                    "Name": "Ashlar Basalt Bench",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Basalt",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Basalt Bench",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AshlarGneissBench",
                    "Name": "Ashlar Gneiss Bench",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Gneiss",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Gneiss Bench",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AshlarLimestoneBench",
                    "Name": "Ashlar Limestone Bench",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Limestone",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Limestone Bench",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AshlarShaleBench",
                    "Name": "Ashlar Shale Bench",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Shale",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Shale Bench",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AshlarGraniteBench",
                    "Name": "Ashlar Granite Bench",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Granite",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Granite Bench",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AshlarSandstoneBench",
                    "Name": "Ashlar Sandstone Bench",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Sandstone",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Sandstone Bench",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Phosphate Fertilizer",
            "Untranslated": "Phosphate Fertilizer Recipe",
            "BaseCraftTime": 0.42000002,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Phosphate Fertilizer",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Fertilizers",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "PhosphateFertilizer",
                    "Name": "Phosphate Fertilizer",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Crushed Limestone",
                            "Ammount": 3,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fertilizer Filler",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Phosphate Fertilizer",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Steel Bar",
            "Untranslated": "Steel Bar Recipe",
            "BaseCraftTime": 2.1,
            "BaseLaborCost": 60,
            "BaseXPGain": 2,
            "CraftingTable": "Blast Furnace",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Steel Bar",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Smelting",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "SteelBar",
                    "Name": "Steel Bar",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Concentrate",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ceramic Mold",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Quicklime",
                            "Ammount": 4,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Crushed Coal",
                            "Ammount": 4,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Steel Bar",
                            "Ammount": 4
                        },
                        {
                            "Name": "Slag",
                            "Ammount": 4
                        },
                        {
                            "Name": "Ceramic Mold",
                            "Ammount": 3
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Rivet",
            "Untranslated": "Rivet Recipe",
            "BaseCraftTime": 1.12,
            "BaseLaborCost": 85,
            "BaseXPGain": 1,
            "CraftingTable": "Blast Furnace",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Rivet",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Smelting",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "Rivet",
                    "Name": "Rivet",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Rivet",
                            "Ammount": 3
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Advanced Carpentry Table",
            "Untranslated": "Advanced Carpentry Table Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 600,
            "BaseXPGain": 10,
            "CraftingTable": "Electric Machinist Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Advanced Carpentry Table",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Industry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "AdvancedCarpentryTable",
                    "Name": "Advanced Carpentry Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Plate",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Saw Blade",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Advanced Carpentry Table",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Crushed Gold Ore",
            "Untranslated": "Crushed Gold Ore Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 70,
            "BaseXPGain": 0.5,
            "CraftingTable": "Arrastra",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Crushed Gold Ore",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CrushedGoldOre",
                    "Name": "Crushed Gold Ore",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Ore",
                            "Ammount": 12,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Crushed Gold Ore",
                            "Ammount": 2
                        },
                        {
                            "Name": "Crushed Granite",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Copper Concentrate",
            "Untranslated": "Copper Concentrate Recipe",
            "BaseCraftTime": 2.1,
            "BaseLaborCost": 50,
            "BaseXPGain": 2,
            "CraftingTable": "Rocker Box",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Copper Concentrate",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CopperConcentrate",
                    "Name": "Copper Concentrate",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Crushed Copper Ore",
                            "Ammount": 7,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Copper Concentrate",
                            "Ammount": 1
                        },
                        {
                            "Name": "Wet Tailings",
                            "Ammount": 3
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Fiber Filler",
            "Untranslated": "Fiber Filler Recipe",
            "BaseCraftTime": 0.42000002,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Fiber Filler",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Fertilizers",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "FiberFiller",
                    "Name": "Fiber Filler",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plant Fibers",
                            "Ammount": 15,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Dirt",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Fiber Filler",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Metallurgy Research Paper Basic",
            "Untranslated": "Metallurgy Research Paper Basic Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 30,
            "BaseXPGain": 1.5,
            "CraftingTable": "Research Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Metallurgy Research Paper Basic",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "MetallurgyResearchPaperBasic",
                    "Name": "Metallurgy Research Paper Basic",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Ore",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Metallurgy Research Paper Basic",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Metal Keel",
            "Untranslated": "Metal Keel Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 120,
            "BaseXPGain": 1,
            "CraftingTable": "Shaper",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Metal Keel",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "MetalKeel",
                    "Name": "Metal Keel",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Metal Keel",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Copper Wiring",
            "Untranslated": "Copper Wiring Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 60,
            "BaseXPGain": 1,
            "CraftingTable": "Machinist Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Copper Wiring",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CopperWiring",
                    "Name": "Copper Wiring",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Bar",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Copper Wiring",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Steel Plate",
            "Untranslated": "Steel Plate Recipe",
            "BaseCraftTime": 2.1,
            "BaseLaborCost": 120,
            "BaseXPGain": 1,
            "CraftingTable": "Electric Stamping Press",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Steel Plate",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Industry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "SteelPlate",
                    "Name": "Steel Plate",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 3,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Steel Plate",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wooden Mold",
            "Untranslated": "Wooden Mold Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 120,
            "BaseXPGain": 1,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Wooden Mold",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Logging",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "WoodenMold",
                    "Name": "Wooden Mold",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nail",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wooden Mold",
                            "Ammount": 4
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Pelt Fertilizer",
            "Untranslated": "Pelt Fertilizer Recipe",
            "BaseCraftTime": 0.42000002,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Pelt Fertilizer",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Fertilizers",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "PeltFertilizer",
                    "Name": "Pelt Fertilizer",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Fur Pelt",
                            "Ammount": 3,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fertilizer Filler",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Pelt Fertilizer",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Steel Saw Blade",
            "Untranslated": "Steel Saw Blade Recipe",
            "BaseCraftTime": 1.6800001,
            "BaseLaborCost": 180,
            "BaseXPGain": 2,
            "CraftingTable": "Power Hammer",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Steel Saw Blade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "SteelSawBlade",
                    "Name": "Steel Saw Blade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Steel Saw Blade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Treasury",
            "Untranslated": "Treasury Recipe",
            "BaseCraftTime": 35,
            "BaseLaborCost": 1500,
            "BaseXPGain": 1,
            "CraftingTable": "Settlement Crafting Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Treasury",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "Treasury",
                    "Name": "Treasury",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Brick",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Bar",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 30,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Treasury",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Immigration Desk",
            "Untranslated": "Immigration Desk Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 1200,
            "BaseXPGain": 1,
            "CraftingTable": "Settlement Crafting Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Immigration Desk",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "ImmigrationDesk",
                    "Name": "Immigration Desk",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Immigration Desk",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Currency Exchange",
            "Untranslated": "Currency Exchange Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 2500,
            "BaseXPGain": 25,
            "CraftingTable": "Blacksmith Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Currency Exchange",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "CurrencyExchange",
                    "Name": "Currency Exchange",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Bar",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 16,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Mortared Stone",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Currency Exchange",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Yellow Powder",
            "Untranslated": "Yellow Powder Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 24,
            "BaseXPGain": 0.5,
            "CraftingTable": "Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Yellow Powder",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "YellowPowder",
                    "Name": "Yellow Powder",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Sunflower",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Yellow Powder",
                            "Ammount": 5
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Cotton Lint",
            "Untranslated": "Cotton Lint Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 35,
            "BaseXPGain": 0.2,
            "CraftingTable": "Fiber Scutching Station",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Cotton Lint",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Gathering",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "CottonLint",
                    "Name": "Cotton Lint",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Boll",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Cotton Lint",
                            "Ammount": 1
                        },
                        {
                            "Name": "Plant Fibers",
                            "Ammount": 0.25
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Rubber Wheel",
            "Untranslated": "Rubber Wheel Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 60,
            "BaseXPGain": 3.5,
            "CraftingTable": "Electric Lathe",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Rubber Wheel",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Industry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "RubberWheel",
                    "Name": "Rubber Wheel",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Synthetic Rubber",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Rubber Wheel",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Base Paint",
            "Untranslated": "Base Paint Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 40,
            "BaseXPGain": 0.5,
            "CraftingTable": "Paint Mixer",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Base Paint",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Painting",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "BasePaint",
                    "Name": "Base Paint",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "White Powder",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 0.5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Oil",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Base Paint",
                            "Ammount": 10
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Iron Axle",
            "Untranslated": "Iron Axle Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 75,
            "BaseXPGain": 1,
            "CraftingTable": "Lathe",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Iron Axle",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "IronAxle",
                    "Name": "Iron Axle",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Iron Axle",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Steam Tractor Sower",
            "Untranslated": "Steam Tractor Sower Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 120,
            "BaseXPGain": 10,
            "CraftingTable": "Assembly Line",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Steam Tractor Sower",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "SteamTractorSower",
                    "Name": "Steam Tractor Sower",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Plate",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 18,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Pipe",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Steam Tractor Sower",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Icebox",
            "Untranslated": "Icebox Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 60,
            "BaseXPGain": 3,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Icebox",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Icebox",
                    "Name": "Icebox",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Icebox",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Flax Trawler Net",
            "Untranslated": "Flax Trawler Net Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 120,
            "BaseXPGain": 10,
            "CraftingTable": "Fishery",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Flax Trawler Net",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Hunting",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "FlaxTrawlerNet",
                    "Name": "Flax Trawler Net",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Linen Yarn",
                            "Ammount": 40,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Flax Trawler Net",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Dowel",
            "Untranslated": "Dowel Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 40,
            "BaseXPGain": 0.5,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Dowel",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Logging",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Dowel",
                    "Name": "Dowel",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Dowel",
                            "Ammount": 16
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Gathering Research Paper Basic",
            "Untranslated": "Gathering Research Paper Basic Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 30,
            "BaseXPGain": 1.5,
            "CraftingTable": "Research Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Gathering Research Paper Basic",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Gathering",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "GatheringResearchPaperBasic",
                    "Name": "Gathering Research Paper Basic",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plant Fibers",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Raw Food",
                            "Name": "",
                            "Ammount": 30,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Gathering Research Paper Basic",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Steam Tractor Harvester",
            "Untranslated": "Steam Tractor Harvester Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 120,
            "BaseXPGain": 10,
            "CraftingTable": "Assembly Line",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Steam Tractor Harvester",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "SteamTractorHarvester",
                    "Name": "Steam Tractor Harvester",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Plate",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Pipe",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Steam Tractor Harvester",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Hide Ash Fertilizer",
            "Untranslated": "Hide Ash Fertilizer Recipe",
            "BaseCraftTime": 0.42000002,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Hide Ash Fertilizer",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Fertilizers",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "HideAshFertilizer",
                    "Name": "Hide Ash Fertilizer",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 3,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fertilizer Filler",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Hide Ash Fertilizer",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Cotton Fabric",
            "Untranslated": "Cotton Fabric Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 100,
            "BaseXPGain": 0.5,
            "CraftingTable": "Loom",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Cotton Fabric",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CottonFabric",
                    "Name": "Cotton Fabric",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Thread",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Cotton Fabric",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wooden Wheel",
            "Untranslated": "Wooden Wheel Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 40,
            "BaseXPGain": 1,
            "CraftingTable": "Wainwright Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Wooden Wheel",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Basic Engineering",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "WoodenWheel",
                    "Name": "Wooden Wheel",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wooden Wheel",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Epoxy",
            "Untranslated": "Epoxy Recipe",
            "BaseCraftTime": 2.1,
            "BaseLaborCost": 180,
            "BaseXPGain": 1,
            "CraftingTable": "Oil Refinery",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Epoxy",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Oil Drilling",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Epoxy",
                    "Name": "Epoxy",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Petroleum",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Epoxy",
                            "Ammount": 2
                        },
                        {
                            "Name": "Barrel",
                            "Ammount": 3
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Glass Lens",
            "Untranslated": "Glass Lens Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 30,
            "BaseXPGain": 0.1,
            "CraftingTable": "Glassworks",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Glass Lens",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Glassworking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "GlassLens",
                    "Name": "Glass Lens",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Glass",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Glass Lens",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Tallow Candle",
            "Untranslated": "Tallow Candle Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 50,
            "BaseXPGain": 0.5,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Tallow Candle",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Farming",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "TallowCandle",
                    "Name": "Tallow Candle",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Tallow",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Thread",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Tallow Candle",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Rebar",
            "Untranslated": "Rebar Recipe",
            "BaseCraftTime": 0.28,
            "BaseLaborCost": 50,
            "BaseXPGain": 2,
            "CraftingTable": "Blast Furnace",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Rebar",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Smelting",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "Rebar",
                    "Name": "Rebar",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Rebar",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Sharpening Steel",
            "Untranslated": "Sharpening Steel Recipe",
            "BaseCraftTime": 0.28,
            "BaseLaborCost": 60,
            "BaseXPGain": 0.5,
            "CraftingTable": "Anvil",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Sharpening Steel",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "SharpeningSteel",
                    "Name": "Sharpening Steel",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Sharpening Steel",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Large Metal Ship Frame",
            "Untranslated": "Large Metal Ship Frame Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 240,
            "BaseXPGain": 2,
            "CraftingTable": "Medium Shipyard",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Large Metal Ship Frame",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Shipwright",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "LargeMetalShipFrame",
                    "Name": "Large Metal Ship Frame",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Rivet",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Metal Keel",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Metal Ship Frame",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Small Standing Lumber Sign",
            "Untranslated": "Small Standing Lumber Sign Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 60,
            "BaseXPGain": 2,
            "CraftingTable": "Sawmill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Small Standing Lumber Sign",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "SmallStandingLumberSign",
                    "Name": "Small Standing Lumber Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Standing Lumber Sign",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Boiler",
            "Untranslated": "Boiler Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 180,
            "BaseXPGain": 3,
            "CraftingTable": "Machinist Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Boiler",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Boiler",
                    "Name": "Boiler",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Plate",
                            "Ammount": 15,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Plate",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Boiler",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Portable Steam Engine",
            "Untranslated": "Portable Steam Engine Recipe",
            "BaseCraftTime": 11.2,
            "BaseLaborCost": 1200,
            "BaseXPGain": 10,
            "CraftingTable": "Machinist Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Portable Steam Engine",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "PortableSteamEngine",
                    "Name": "Portable Steam Engine",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Piston",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 18,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Plate",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Boiler",
                            "Ammount": 3,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Gear",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Pipe",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Portable Steam Engine",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Metal Rudder",
            "Untranslated": "Metal Rudder Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 120,
            "BaseXPGain": 1,
            "CraftingTable": "Medium Shipyard",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Metal Rudder",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Shipwright",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "MetalRudder",
                    "Name": "Metal Rudder",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Plate",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Gear",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Metal Rudder",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Steel Gearbox",
            "Untranslated": "Steel Gearbox Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 100,
            "BaseXPGain": 2.5,
            "CraftingTable": "Electric Planer",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Steel Gearbox",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Industry",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "SteelGearbox",
                    "Name": "Steel Gearbox",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Gear",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Steel Gearbox",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Pulp Filler",
            "Untranslated": "Pulp Filler Recipe",
            "BaseCraftTime": 0.42000002,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Pulp Filler",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Fertilizers",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "PulpFiller",
                    "Name": "Pulp Filler",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wood Pulp",
                            "Ammount": 15,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Dirt",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Pulp Filler",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Canvas",
            "Untranslated": "Canvas Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 60,
            "BaseXPGain": 1,
            "CraftingTable": "Loom",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Canvas",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "Canvas",
                    "Name": "Canvas",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Linen Fabric",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Canvas",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Stove",
            "Untranslated": "Stove Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 900,
            "BaseXPGain": 5,
            "CraftingTable": "Electric Machinist Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Stove",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "Stove",
                    "Name": "Stove",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Plate",
                            "Ammount": 16,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Rivet",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Basic Circuit",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Stove",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Nylon Thread",
            "Untranslated": "Nylon Thread Recipe",
            "BaseCraftTime": 1.05,
            "BaseLaborCost": 60,
            "BaseXPGain": 0.5,
            "CraftingTable": "Spin Melter",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Nylon Thread",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "NylonThread",
                    "Name": "Nylon Thread",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nylon",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Nylon Thread",
                            "Ammount": 4
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Printing Supplies",
            "Untranslated": "Printing Supplies Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 360,
            "BaseXPGain": 2,
            "CraftingTable": "Small Paper Machine",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Printing Supplies",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Paper Milling",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "PrintingSupplies",
                    "Name": "Printing Supplies",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ink",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Paper",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Printing Supplies",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Flax Fiber",
            "Untranslated": "Flax Fiber Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 35,
            "BaseXPGain": 0.2,
            "CraftingTable": "Fiber Scutching Station",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Flax Fiber",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Gathering",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "FlaxFiber",
                    "Name": "Flax Fiber",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Flax Stem",
                            "Ammount": 3,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Flax Fiber",
                            "Ammount": 1
                        },
                        {
                            "Name": "Plant Fibers",
                            "Ammount": 0.25
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Cement",
            "Untranslated": "Cement Recipe",
            "BaseCraftTime": 2.24,
            "BaseLaborCost": 70,
            "BaseXPGain": 1,
            "CraftingTable": "Cement Kiln",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Cement",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Masonry",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "Cement",
                    "Name": "Cement",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Quicklime",
                            "Ammount": 2.5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Clay",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Cement",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wool Yarn",
            "Untranslated": "Wool Yarn Recipe",
            "BaseCraftTime": 1.05,
            "BaseLaborCost": 60,
            "BaseXPGain": 0.5,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Wool Yarn",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "WoolYarn",
                    "Name": "Wool Yarn",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Shorn Wool",
                            "Ammount": 3,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wool Yarn",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Large Wooden Ship Frame",
            "Untranslated": "Large Wooden Ship Frame Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 200,
            "BaseXPGain": 2,
            "CraftingTable": "Medium Shipyard",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Large Wooden Ship Frame",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Shipwright",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "LargeWoodenShipFrame",
                    "Name": "Large Wooden Ship Frame",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 24,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wooden Keel",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Wooden Ship Frame",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wool Fabric",
            "Untranslated": "Wool Fabric Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 100,
            "BaseXPGain": 0.5,
            "CraftingTable": "Loom",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Wool Fabric",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "WoolFabric",
                    "Name": "Wool Fabric",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wool Yarn",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wool Fabric",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Geology Research Paper Modern",
            "Untranslated": "Geology Research Paper Modern Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 600,
            "BaseXPGain": 6,
            "CraftingTable": "Laboratory",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Geology Research Paper Modern",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Pottery",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "GeologyResearchPaperModern",
                    "Name": "Geology Research Paper Modern",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Brick",
                            "Ammount": 30,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ink",
                            "Ammount": 4,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Paper",
                            "Ammount": 20,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Geology Research Paper Modern",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Robotic Assembly Line",
            "Untranslated": "Robotic Assembly Line Recipe",
            "BaseCraftTime": 105,
            "BaseLaborCost": 1200,
            "BaseXPGain": 30,
            "CraftingTable": "Electronics Assembly",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Robotic Assembly Line",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Electronics",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "RoboticAssemblyLine",
                    "Name": "Robotic Assembly Line",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cement",
                            "Ammount": 30,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Electric Motor",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Servo",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 30,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Robotic Assembly Line",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Oil Paint",
            "Untranslated": "Oil Paint Recipe",
            "BaseCraftTime": 1.12,
            "BaseLaborCost": 40,
            "BaseXPGain": 0.5,
            "CraftingTable": "Paint Mixer",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Oil Paint",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Painting",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "OilPaint",
                    "Name": "Oil Paint",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Colored Powder",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Flaxseed Oil",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Oil Paint",
                            "Ammount": 4
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Berry Extract Fertilizer",
            "Untranslated": "Berry Extract Fertilizer Recipe",
            "BaseCraftTime": 0.42000002,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Berry Extract Fertilizer",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Fertilizers",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "BerryExtractFertilizer",
                    "Name": "Berry Extract Fertilizer",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Huckleberry Extract",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fertilizer Filler",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Berry Extract Fertilizer",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Board",
            "Untranslated": "Board Recipe",
            "BaseCraftTime": 0.28,
            "BaseLaborCost": 25,
            "BaseXPGain": 0.6,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Board",
            "NumberOfVariants": 3,
            "SkillNeeds": [
                {
                    "Skill": "Logging",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Board",
                    "Name": "Board",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Board",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SoftwoodBoard",
                    "Name": "Softwood Board",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Softwood",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Softwood Board",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "HardwoodBoard",
                    "Name": "Hardwood Board",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hardwood",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Hardwood Board",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Coarse Stone",
            "Untranslated": "Coarse Stone Recipe",
            "BaseCraftTime": 0.28,
            "BaseLaborCost": 20,
            "BaseXPGain": 1,
            "CraftingTable": "Tool Bench",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Coarse Stone",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "CoarseStone",
                    "Name": "Coarse Stone",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Rock",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Coarse Stone",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Engineering Research Paper Advanced",
            "Untranslated": "Engineering Research Paper Advanced Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 120,
            "BaseXPGain": 1.5,
            "CraftingTable": "Research Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Engineering Research Paper Advanced",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Basic Engineering",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "EngineeringResearchPaperAdvanced",
                    "Name": "Engineering Research Paper Advanced",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Waterwheel",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Windmill",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Engineering Research Paper Advanced",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Radiator",
            "Untranslated": "Radiator Recipe",
            "BaseCraftTime": 2.1,
            "BaseLaborCost": 35,
            "BaseXPGain": 3,
            "CraftingTable": "Electric Stamping Press",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Radiator",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Industry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Radiator",
                    "Name": "Radiator",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Heat Sink",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Wiring",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Radiator",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Dendrology Research Paper Advanced",
            "Untranslated": "Dendrology Research Paper Advanced Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 120,
            "BaseXPGain": 3,
            "CraftingTable": "Research Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Dendrology Research Paper Advanced",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "DendrologyResearchPaperAdvanced",
                    "Name": "Dendrology Research Paper Advanced",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Dendrology Research Paper Advanced",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Iron Gear",
            "Untranslated": "Iron Gear Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 75,
            "BaseXPGain": 1,
            "CraftingTable": "Shaper",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Iron Gear",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "IronGear",
                    "Name": "Iron Gear",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Iron Gear",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Electric Motor",
            "Untranslated": "Electric Motor Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 360,
            "BaseXPGain": 10,
            "CraftingTable": "Electronics Assembly",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Electric Motor",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Electronics",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "ElectricMotor",
                    "Name": "Electric Motor",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Basic Circuit",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Wiring",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Electric Motor",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Steam Tractor Plow",
            "Untranslated": "Steam Tractor Plow Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 120,
            "BaseXPGain": 10,
            "CraftingTable": "Assembly Line",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Steam Tractor Plow",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "SteamTractorPlow",
                    "Name": "Steam Tractor Plow",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Plate",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 18,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Steam Tractor Plow",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Charcoal",
            "Untranslated": "Charcoal Recipe",
            "BaseCraftTime": 1.6800001,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Kiln",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Charcoal",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Logging",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "Charcoal",
                    "Name": "Charcoal",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 7,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Charcoal",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Advanced Combustion Engine",
            "Untranslated": "Advanced Combustion Engine Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 600,
            "BaseXPGain": 20,
            "CraftingTable": "Robotic Assembly Line",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Advanced Combustion Engine",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Industry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "AdvancedCombustionEngine",
                    "Name": "Advanced Combustion Engine",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Plate",
                            "Ammount": 16,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Rivet",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Piston",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Valve",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Servo",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Advanced Circuit",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Radiator",
                            "Ammount": 3,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Advanced Combustion Engine",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Linen Yarn",
            "Untranslated": "Linen Yarn Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 30,
            "BaseXPGain": 0.1,
            "CraftingTable": "Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Linen Yarn",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "LinenYarn",
                    "Name": "Linen Yarn",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Flax Fiber",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Linen Yarn",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Clay Mold",
            "Untranslated": "Clay Mold Recipe",
            "BaseCraftTime": 0.28,
            "BaseLaborCost": 50,
            "BaseXPGain": 1,
            "CraftingTable": "Pottery Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Clay Mold",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Gathering",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "ClayMold",
                    "Name": "Clay Mold",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Clay",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Clay Mold",
                            "Ammount": 4
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Light Bulb",
            "Untranslated": "Light Bulb Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 60,
            "BaseXPGain": 2,
            "CraftingTable": "Glassworks",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Light Bulb",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Glassworking",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "LightBulb",
                    "Name": "Light Bulb",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Glass",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Wiring",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Light Bulb",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Dendrology Research Paper Modern",
            "Untranslated": "Dendrology Research Paper Modern Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 600,
            "BaseXPGain": 6,
            "CraftingTable": "Laboratory",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Dendrology Research Paper Modern",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "DendrologyResearchPaperModern",
                    "Name": "Dendrology Research Paper Modern",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 30,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ink",
                            "Ammount": 4,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Paper",
                            "Ammount": 20,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Dendrology Research Paper Modern",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Combustion Engine",
            "Untranslated": "Combustion Engine Recipe",
            "BaseCraftTime": 11.2,
            "BaseLaborCost": 300,
            "BaseXPGain": 15,
            "CraftingTable": "Assembly Line",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Combustion Engine",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "CombustionEngine",
                    "Name": "Combustion Engine",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Piston",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gearbox",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Plate",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Rivet",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Combustion Engine",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Iron Plate",
            "Untranslated": "Iron Plate Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 60,
            "BaseXPGain": 1,
            "CraftingTable": "Screw Press",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Iron Plate",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "IronPlate",
                    "Name": "Iron Plate",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Iron Plate",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Compost Fertilizer",
            "Untranslated": "Compost Fertilizer Recipe",
            "BaseCraftTime": 0.42000002,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Compost Fertilizer",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Fertilizers",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "CompostFertilizer",
                    "Name": "Compost Fertilizer",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Compost",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fertilizer Filler",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Compost Fertilizer",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Cotton Thread",
            "Untranslated": "Cotton Thread Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 30,
            "BaseXPGain": 0.1,
            "CraftingTable": "Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Cotton Thread",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CottonThread",
                    "Name": "Cotton Thread",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Lint",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Cotton Thread",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wooden Keel",
            "Untranslated": "Wooden Keel Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 60,
            "BaseXPGain": 1,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Wooden Keel",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Logging",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "WoodenKeel",
                    "Name": "Wooden Keel",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wooden Keel",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Nylon",
            "Untranslated": "Nylon Recipe",
            "BaseCraftTime": 2.1,
            "BaseLaborCost": 180,
            "BaseXPGain": 1,
            "CraftingTable": "Oil Refinery",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Nylon",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Oil Drilling",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Nylon",
                    "Name": "Nylon",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Petroleum",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Nylon",
                            "Ammount": 8
                        },
                        {
                            "Name": "Barrel",
                            "Ammount": 3
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Iron Oxide",
            "Untranslated": "Iron Oxide Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 24,
            "BaseXPGain": 0.5,
            "CraftingTable": "Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Iron Oxide",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "IronOxide",
                    "Name": "Iron Oxide",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Crushed Iron Ore",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Iron Oxide",
                            "Ammount": 8
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wet Brick",
            "Untranslated": "Wet Brick Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 100,
            "BaseXPGain": 0.5,
            "CraftingTable": "Pottery Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Wet Brick",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Pottery",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "WetBrick",
                    "Name": "Wet Brick",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Sand",
                            "Ammount": 3,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Clay",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wooden Mold",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wet Brick",
                            "Ammount": 4
                        },
                        {
                            "Name": "Wooden Mold",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Camas Ash Fertilizer",
            "Untranslated": "Camas Ash Fertilizer Recipe",
            "BaseCraftTime": 0.42000002,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Camas Ash Fertilizer",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Fertilizers",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "CamasAshFertilizer",
                    "Name": "Camas Ash Fertilizer",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Charred Camas Bulb",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fertilizer Filler",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Camas Ash Fertilizer",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Gold Flakes",
            "Untranslated": "Gold Flakes Recipe",
            "BaseCraftTime": 1.12,
            "BaseLaborCost": 75,
            "BaseXPGain": 1,
            "CraftingTable": "Electronics Assembly",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Gold Flakes",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Electronics",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "GoldFlakes",
                    "Name": "Gold Flakes",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Bar",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Gold Flakes",
                            "Ammount": 4
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Metallurgy Research Paper Modern",
            "Untranslated": "Metallurgy Research Paper Modern Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 600,
            "BaseXPGain": 6,
            "CraftingTable": "Laboratory",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Metallurgy Research Paper Modern",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Smelting",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "MetallurgyResearchPaperModern",
                    "Name": "Metallurgy Research Paper Modern",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Rebar",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ink",
                            "Ammount": 4,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Paper",
                            "Ammount": 20,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Metallurgy Research Paper Modern",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Whetstone",
            "Untranslated": "Whetstone Recipe",
            "BaseCraftTime": 0.28,
            "BaseLaborCost": 30,
            "BaseXPGain": 0.5,
            "CraftingTable": "Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Whetstone",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Masonry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Whetstone",
                    "Name": "Whetstone",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Rock",
                            "Name": "",
                            "Ammount": 3,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Whetstone",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Ink",
            "Untranslated": "Ink Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 180,
            "BaseXPGain": 1,
            "CraftingTable": "Paint Mixer",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Ink",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Painting",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "Ink",
                    "Name": "Ink",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Charcoal Powder",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Glass",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Oil",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ink",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Lumber Stockpile",
            "Untranslated": "Lumber Stockpile Recipe",
            "BaseCraftTime": 11.2,
            "BaseLaborCost": 600,
            "BaseXPGain": 3,
            "CraftingTable": "Sawmill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Lumber Stockpile",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "LumberStockpile",
                    "Name": "Lumber Stockpile",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Lumber Stockpile",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Camera Film",
            "Untranslated": "Camera Film Recipe",
            "BaseCraftTime": 0.28,
            "BaseLaborCost": 45,
            "BaseXPGain": 0.5,
            "CraftingTable": "Machinist Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Camera Film",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CameraFilm",
                    "Name": "Camera Film",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Paper",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Light Bulb",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Camera Film",
                            "Ammount": 10
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Gold Wiring",
            "Untranslated": "Gold Wiring Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 120,
            "BaseXPGain": 1,
            "CraftingTable": "Electric Machinist Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Gold Wiring",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "GoldWiring",
                    "Name": "Gold Wiring",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Bar",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Gold Wiring",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Steel Spring",
            "Untranslated": "Steel Spring Recipe",
            "BaseCraftTime": 1.12,
            "BaseLaborCost": 85,
            "BaseXPGain": 1,
            "CraftingTable": "Blast Furnace",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Steel Spring",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Smelting",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "SteelSpring",
                    "Name": "Steel Spring",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Steel Spring",
                            "Ammount": 3
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Store Sign",
            "Untranslated": "Store Sign Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 120,
            "BaseXPGain": 2,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Store Sign",
            "NumberOfVariants": 9,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "StoreSign",
                    "Name": "Store Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Store Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "TailorSign",
                    "Name": "Tailor Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Store Sign",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Tailor Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CarpenterSign",
                    "Name": "Carpenter Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Store Sign",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Carpenter Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "EngineerSign",
                    "Name": "Engineer Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Store Sign",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Engineer Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "BakerSign",
                    "Name": "Baker Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Store Sign",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Baker Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "ShipwrightSign",
                    "Name": "Shipwright Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Store Sign",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Shipwright Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "MasonSign",
                    "Name": "Mason Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Store Sign",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Mason Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "FoodSign",
                    "Name": "Food Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Store Sign",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Food Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "FarmerSign",
                    "Name": "Farmer Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Store Sign",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Farmer Sign",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Steel Axle",
            "Untranslated": "Steel Axle Recipe",
            "BaseCraftTime": 2.1,
            "BaseLaborCost": 60,
            "BaseXPGain": 1,
            "CraftingTable": "Electric Lathe",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Steel Axle",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Industry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "SteelAxle",
                    "Name": "Steel Axle",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Epoxy",
                            "Ammount": 3,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Steel Axle",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Mill Stone",
            "Untranslated": "Mill Stone Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 120,
            "BaseXPGain": 1,
            "CraftingTable": "Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Mill Stone",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Masonry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "MillStone",
                    "Name": "Mill Stone",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Rock",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Mill Stone",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Cellulose Fiber",
            "Untranslated": "Cellulose Fiber Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 60,
            "BaseXPGain": 0.5,
            "CraftingTable": "Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Cellulose Fiber",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CelluloseFiber",
                    "Name": "Cellulose Fiber",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Natural Fiber",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Cellulose Fiber",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Piston",
            "Untranslated": "Piston Recipe",
            "BaseCraftTime": 2.1,
            "BaseLaborCost": 60,
            "BaseXPGain": 2,
            "CraftingTable": "Screw Press",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Piston",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Piston",
                    "Name": "Piston",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Pipe",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Piston",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Dendrology Research Paper Basic",
            "Untranslated": "Dendrology Research Paper Basic Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 30,
            "BaseXPGain": 1.5,
            "CraftingTable": "Research Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Dendrology Research Paper Basic",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Logging",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "DendrologyResearchPaperBasic",
                    "Name": "Dendrology Research Paper Basic",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Dendrology Research Paper Basic",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Screws",
            "Untranslated": "Screws Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Lathe",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Screws",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Screws",
                    "Name": "Screws",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Screws",
                            "Ammount": 4
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Blood Meal Fertilizer",
            "Untranslated": "Blood Meal Fertilizer Recipe",
            "BaseCraftTime": 0.42000002,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Blood Meal Fertilizer",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Fertilizers",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "BloodMealFertilizer",
                    "Name": "Blood Meal Fertilizer",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Scrap Meat",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fertilizer Filler",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Blood Meal Fertilizer",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Nylon Mooring Rope",
            "Untranslated": "Nylon Mooring Rope Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 120,
            "BaseXPGain": 0.5,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Nylon Mooring Rope",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "NylonMooringRope",
                    "Name": "Nylon Mooring Rope",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nylon Thread",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Nylon Mooring Rope",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wooden Oar",
            "Untranslated": "Wooden Oar Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 60,
            "BaseXPGain": 0.5,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Wooden Oar",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Logging",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "WoodenOar",
                    "Name": "Wooden Oar",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wooden Oar",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Geology Research Paper Basic",
            "Untranslated": "Geology Research Paper Basic Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 30,
            "BaseXPGain": 1.5,
            "CraftingTable": "Research Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Geology Research Paper Basic",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "GeologyResearchPaperBasic",
                    "Name": "Geology Research Paper Basic",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Rock",
                            "Name": "",
                            "Ammount": 30,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Geology Research Paper Basic",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Basic Circuit",
            "Untranslated": "Basic Circuit Recipe",
            "BaseCraftTime": 1.12,
            "BaseLaborCost": 45,
            "BaseXPGain": 4,
            "CraftingTable": "Electronics Assembly",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Basic Circuit",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Electronics",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "BasicCircuit",
                    "Name": "Basic Circuit",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Wiring",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Flakes",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Substrate",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Basic Circuit",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Blue Powder",
            "Untranslated": "Blue Powder Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 24,
            "BaseXPGain": 0.5,
            "CraftingTable": "Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Blue Powder",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "BluePowder",
                    "Name": "Blue Powder",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Huckleberries",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Blue Powder",
                            "Ammount": 5
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Steam Tractor Scoop",
            "Untranslated": "Steam Tractor Scoop Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 120,
            "BaseXPGain": 10,
            "CraftingTable": "Assembly Line",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Steam Tractor Scoop",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "SteamTractorScoop",
                    "Name": "Steam Tractor Scoop",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Plate",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 18,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Steam Tractor Scoop",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Culinary Research Paper Basic",
            "Untranslated": "Culinary Research Paper Basic Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 30,
            "BaseXPGain": 1.5,
            "CraftingTable": "Research Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Culinary Research Paper Basic",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CulinaryResearchPaperBasic",
                    "Name": "Culinary Research Paper Basic",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fried Vegetable",
                            "Name": "",
                            "Ammount": 3,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Campfire Salad",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Culinary Research Paper Basic",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wooden Hull Planks",
            "Untranslated": "Wooden Hull Planks Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 60,
            "BaseXPGain": 0.5,
            "CraftingTable": "Small Shipyard",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Wooden Hull Planks",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Shipwright",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "WoodenHullPlanks",
                    "Name": "Wooden Hull Planks",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wooden Hull Planks",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Fiberglass",
            "Untranslated": "Fiberglass Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 90,
            "BaseXPGain": 1,
            "CraftingTable": "Electronics Assembly",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Fiberglass",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Glassworking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Fiberglass",
                    "Name": "Fiberglass",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plastic",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Glass",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Fiberglass",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Crab Pot",
            "Untranslated": "Crab Pot Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 120,
            "BaseXPGain": 3,
            "CraftingTable": "Fishery",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Crab Pot",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Hunting",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "CrabPot",
                    "Name": "Crab Pot",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 30,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Crab Pot",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Lumber Bench",
            "Untranslated": "Lumber Bench Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 60,
            "BaseXPGain": 3,
            "CraftingTable": "Sawmill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Lumber Bench",
            "NumberOfVariants": 3,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "LumberBench",
                    "Name": "Lumber Bench",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nail",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Lumber Bench",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "HardwoodLumberBench",
                    "Name": "Hardwood Lumber Bench",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Hardwood Lumber",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nail",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Hardwood Lumber Bench",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SoftwoodLumberBench",
                    "Name": "Softwood Lumber Bench",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Softwood Lumber",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nail",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Softwood Lumber Bench",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Iron Hull Sheet",
            "Untranslated": "Iron Hull Sheet Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 60,
            "BaseXPGain": 0.5,
            "CraftingTable": "Medium Shipyard",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Iron Hull Sheet",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Shipwright",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "IronHullSheet",
                    "Name": "Iron Hull Sheet",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Plate",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Iron Hull Sheet",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Advanced Circuit",
            "Untranslated": "Advanced Circuit Recipe",
            "BaseCraftTime": 1.12,
            "BaseLaborCost": 85,
            "BaseXPGain": 4,
            "CraftingTable": "Electronics Assembly",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Advanced Circuit",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Electronics",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "AdvancedCircuit",
                    "Name": "Advanced Circuit",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Wiring",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Wiring",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Flakes",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Substrate",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Advanced Circuit",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Medium Wooden Ship Frame",
            "Untranslated": "Medium Wooden Ship Frame Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 120,
            "BaseXPGain": 2,
            "CraftingTable": "Small Shipyard",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Medium Wooden Ship Frame",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Shipwright",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "MediumWoodenShipFrame",
                    "Name": "Medium Wooden Ship Frame",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 16,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wooden Keel",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Medium Wooden Ship Frame",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Servo",
            "Untranslated": "Servo Recipe",
            "BaseCraftTime": 4.2,
            "BaseLaborCost": 60,
            "BaseXPGain": 2,
            "CraftingTable": "Electric Machinist Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Servo",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "Servo",
                    "Name": "Servo",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Basic Circuit",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Fiberglass",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Servo",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Claim Tool",
            "Untranslated": "Claim Tool Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 200,
            "BaseXPGain": 1,
            "CraftingTable": "Tool Bench",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Claim Tool",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "ClaimTool",
                    "Name": "Claim Tool",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Claim Tool",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wooden Rudder",
            "Untranslated": "Wooden Rudder Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 40,
            "BaseXPGain": 1,
            "CraftingTable": "Sawmill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Wooden Rudder",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "WoodenRudder",
                    "Name": "Wooden Rudder",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Gear",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wooden Rudder",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Magenta Powder",
            "Untranslated": "Magenta Powder Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 24,
            "BaseXPGain": 0.5,
            "CraftingTable": "Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Magenta Powder",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "MagentaPowder",
                    "Name": "Magenta Powder",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Urchin",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Magenta Powder",
                            "Ammount": 5
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Large Standing Mortared Stone Sign",
            "Untranslated": "Large Standing Mortared Stone Sign Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 60,
            "BaseXPGain": 2,
            "CraftingTable": "Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Large Standing Mortared Stone Sign",
            "NumberOfVariants": 4,
            "SkillNeeds": [
                {
                    "Skill": "Masonry",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "LargeStandingMortaredStoneSign",
                    "Name": "Large Standing Mortared Stone Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Mortared Stone",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Standing Mortared Stone Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeStandingMortaredSandstoneSign",
                    "Name": "Large Standing Mortared Sandstone Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Mortared Sandstone",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Standing Mortared Sandstone Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeStandingMortaredLimestoneSign",
                    "Name": "Large Standing Mortared Limestone Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Mortared Limestone",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Standing Mortared Limestone Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeStandingMortaredGraniteSign",
                    "Name": "Large Standing Mortared Granite Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Mortared Granite",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Standing Mortared Granite Sign",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Polishing Paste",
            "Untranslated": "Polishing Paste Recipe",
            "BaseCraftTime": 0.28,
            "BaseLaborCost": 120,
            "BaseXPGain": 0.5,
            "CraftingTable": "Oil Refinery",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Polishing Paste",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Oil Drilling",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "PolishingPaste",
                    "Name": "Polishing Paste",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Lubricant",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Silica",
                            "Name": "",
                            "Ammount": 3,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Polishing Paste",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "White Powder",
            "Untranslated": "White Powder Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 24,
            "BaseXPGain": 0.5,
            "CraftingTable": "Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "White Powder",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "WhitePowder",
                    "Name": "White Powder",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Trillium Flower",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "White Powder",
                            "Ammount": 5
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Substrate",
            "Untranslated": "Substrate Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 60,
            "BaseXPGain": 2,
            "CraftingTable": "Electronics Assembly",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Substrate",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Electronics",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Substrate",
                    "Name": "Substrate",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Fiberglass",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Epoxy",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Substrate",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Stuffed Elk",
            "Untranslated": "Stuffed Elk Recipe",
            "BaseCraftTime": 16.8,
            "BaseLaborCost": 300,
            "BaseXPGain": 4,
            "CraftingTable": "Butchery Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Stuffed Elk",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Hunting",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "StuffedElk",
                    "Name": "Stuffed Elk",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fabric",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Elk Carcass",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Stuffed Elk",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Adobe Door",
            "Untranslated": "Adobe Door Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 60,
            "BaseXPGain": 1,
            "CraftingTable": "Workbench",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Adobe Door",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "AdobeDoor",
                    "Name": "Adobe Door",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Adobe",
                            "Ammount": 2,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Adobe Door",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Stuffed Jaguar",
            "Untranslated": "Stuffed Jaguar Recipe",
            "BaseCraftTime": 16.8,
            "BaseLaborCost": 300,
            "BaseXPGain": 4,
            "CraftingTable": "Butchery Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Stuffed Jaguar",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Hunting",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "StuffedJaguar",
                    "Name": "Stuffed Jaguar",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fabric",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Jaguar Carcass",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Stuffed Jaguar",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Composite Lumber Door",
            "Untranslated": "Composite Lumber Door Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 180,
            "BaseXPGain": 1,
            "CraftingTable": "Advanced Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Composite Lumber Door",
            "NumberOfVariants": 13,
            "SkillNeeds": [
                {
                    "Skill": "Composites",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CompositeLumberDoor",
                    "Name": "Composite Lumber Door",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 4,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Composite Lumber",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Lumber Door",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeFirDoor",
                    "Name": "Composite Fir Door",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Composite Fir Lumber",
                            "Ammount": 2,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 4,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Fir Door",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeJoshuaDoor",
                    "Name": "Composite Joshua Door",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Composite Joshua Lumber",
                            "Ammount": 2,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 4,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Joshua Door",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeSoftwoodDoor",
                    "Name": "Composite Softwood Door",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 4,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Softwood Lumber",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Softwood Door",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeCeibaDoor",
                    "Name": "Composite Ceiba Door",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Composite Ceiba Lumber",
                            "Ammount": 2,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 4,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Ceiba Door",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeSaguaroDoor",
                    "Name": "Composite Saguaro Door",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Composite Saguaro Lumber",
                            "Ammount": 2,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 4,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Saguaro Door",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeCedarDoor",
                    "Name": "Composite Cedar Door",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Composite Cedar Lumber",
                            "Ammount": 2,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 4,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Cedar Door",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeOakDoor",
                    "Name": "Composite Oak Door",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Composite Oak Lumber",
                            "Ammount": 2,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 4,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Oak Door",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeHardwoodDoor",
                    "Name": "Composite Hardwood Door",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 4,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hardwood Lumber",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Hardwood Door",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositePalmDoor",
                    "Name": "Composite Palm Door",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Composite Palm Lumber",
                            "Ammount": 2,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 4,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Palm Door",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeSpruceDoor",
                    "Name": "Composite Spruce Door",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Composite Spruce Lumber",
                            "Ammount": 2,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 4,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Spruce Door",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeBirchDoor",
                    "Name": "Composite Birch Door",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Composite Birch Lumber",
                            "Ammount": 2,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 4,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Birch Door",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeRedwoodDoor",
                    "Name": "Composite Redwood Door",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Composite Redwood Lumber",
                            "Ammount": 2,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 4,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Redwood Door",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Art Supplies",
            "Untranslated": "Art Supplies Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 40,
            "BaseXPGain": 0.5,
            "CraftingTable": "Paint Mixer",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Art Supplies",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Painting",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "ArtSupplies",
                    "Name": "Art Supplies",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Canvas",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Oil Paint",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Art Supplies",
                            "Ammount": 8
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wooden Gear",
            "Untranslated": "Wooden Gear Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 40,
            "BaseXPGain": 1,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Wooden Gear",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Basic Engineering",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "WoodenGear",
                    "Name": "Wooden Gear",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wooden Gear",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Iron Wheel",
            "Untranslated": "Iron Wheel Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 100,
            "BaseXPGain": 1,
            "CraftingTable": "Wainwright Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Iron Wheel",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Basic Engineering",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "IronWheel",
                    "Name": "Iron Wheel",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Iron Wheel",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wooden Floor Lamp",
            "Untranslated": "Wooden Floor Lamp Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 120,
            "BaseXPGain": 3,
            "CraftingTable": "Sawmill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Wooden Floor Lamp",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "WoodenFloorLamp",
                    "Name": "Wooden Floor Lamp",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Wiring",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fabric",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Light Bulb",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wooden Floor Lamp",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Ceramic Mold",
            "Untranslated": "Ceramic Mold Recipe",
            "BaseCraftTime": 0.28,
            "BaseLaborCost": 200,
            "BaseXPGain": 1,
            "CraftingTable": "Pottery Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Ceramic Mold",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Pottery",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CeramicMold",
                    "Name": "Ceramic Mold",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Clay",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Sand",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Silica",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ceramic Mold",
                            "Ammount": 8
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Culinary Research Paper Modern",
            "Untranslated": "Culinary Research Paper Modern Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 600,
            "BaseXPGain": 6,
            "CraftingTable": "Laboratory",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Culinary Research Paper Modern",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Cooking",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "CulinaryResearchPaperModern",
                    "Name": "Culinary Research Paper Modern",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Boiled Sausage",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Bread",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ink",
                            "Ammount": 4,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Paper",
                            "Ammount": 20,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Culinary Research Paper Modern",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Nail",
            "Untranslated": "Nail Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 50,
            "BaseXPGain": 1,
            "CraftingTable": "Anvil",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Nail",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Nail",
                    "Name": "Nail",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Nail",
                            "Ammount": 16
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Iron Anchor",
            "Untranslated": "Iron Anchor Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 120,
            "BaseXPGain": 1,
            "CraftingTable": "Blacksmith Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Iron Anchor",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "IronAnchor",
                    "Name": "Iron Anchor",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 16,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Iron Anchor",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Medium Metal Ship Frame",
            "Untranslated": "Medium Metal Ship Frame Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 160,
            "BaseXPGain": 2,
            "CraftingTable": "Medium Shipyard",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Medium Metal Ship Frame",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Shipwright",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "MediumMetalShipFrame",
                    "Name": "Medium Metal Ship Frame",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Metal Keel",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Medium Metal Ship Frame",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Colored Powder",
            "Untranslated": "Colored Powder Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 24,
            "BaseXPGain": 0.5,
            "CraftingTable": "Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Colored Powder",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "ColoredPowder",
                    "Name": "Colored Powder",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Petals",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Colored Powder",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Soil Sampler",
            "Untranslated": "Soil Sampler Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 50,
            "BaseXPGain": 1,
            "CraftingTable": "Tool Bench",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Soil Sampler",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Fertilizers",
                    "Level": 0
                }
            ],
            "Variants": [
                {
                    "Key": "SoilSampler",
                    "Name": "Soil Sampler",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Soil Sampler",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Lubricant",
            "Untranslated": "Lubricant Recipe",
            "BaseCraftTime": 2.1,
            "BaseLaborCost": 180,
            "BaseXPGain": 1,
            "CraftingTable": "Oil Refinery",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Lubricant",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Oil Drilling",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Lubricant",
                    "Name": "Lubricant",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Petroleum",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Lubricant",
                            "Ammount": 2
                        },
                        {
                            "Name": "Barrel",
                            "Ammount": 3
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Steel Abstract Fixture",
            "Untranslated": "Steel Abstract Fixture Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 120,
            "BaseXPGain": 5,
            "CraftingTable": "Robotic Assembly Line",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Steel Abstract Fixture",
            "NumberOfVariants": 3,
            "SkillNeeds": [
                {
                    "Skill": "Electronics",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "SteelAbstractFixture",
                    "Name": "Steel Abstract Fixture",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plastic",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Wiring",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Composite Lumber",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Light Bulb",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Steel Abstract Fixture",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SteelSquareFixture",
                    "Name": "Steel Square Fixture",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plastic",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Wiring",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Composite Lumber",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Light Bulb",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Steel Square Fixture",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SteelHangingFixture",
                    "Name": "Steel Hanging Fixture",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plastic",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Wiring",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Composite Lumber",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Light Bulb",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Steel Hanging Fixture",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Hemp Mooring Rope",
            "Untranslated": "Hemp Mooring Rope Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 120,
            "BaseXPGain": 0.5,
            "CraftingTable": "Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Hemp Mooring Rope",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "HempMooringRope",
                    "Name": "Hemp Mooring Rope",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Linen Yarn",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Hemp Mooring Rope",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Fishing Pole",
            "Untranslated": "Fishing Pole Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Tool Bench",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Fishing Pole",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "FishingPole",
                    "Name": "Fishing Pole",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plant Fibers",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Fishing Pole",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Torch",
            "Untranslated": "Torch Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 50,
            "BaseXPGain": 1,
            "CraftingTable": "Workbench",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Torch",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "Torch",
                    "Name": "Torch",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Torch",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Nylon Trawler Net",
            "Untranslated": "Nylon Trawler Net Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 200,
            "BaseXPGain": 10,
            "CraftingTable": "Fishery",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Nylon Trawler Net",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Hunting",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "NylonTrawlerNet",
                    "Name": "Nylon Trawler Net",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nylon Thread",
                            "Ammount": 40,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Nylon Trawler Net",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Cooking Utensils",
            "Untranslated": "Cooking Utensils Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 50,
            "BaseXPGain": 1,
            "CraftingTable": "Anvil",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Cooking Utensils",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CookingUtensils",
                    "Name": "Cooking Utensils",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Cooking Utensils",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Copper Plate",
            "Untranslated": "Copper Plate Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 60,
            "BaseXPGain": 1,
            "CraftingTable": "Screw Press",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Copper Plate",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CopperPlate",
                    "Name": "Copper Plate",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Bar",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Copper Plate",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Agriculture Research Paper Advanced",
            "Untranslated": "Agriculture Research Paper Advanced Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 240,
            "BaseXPGain": 3,
            "CraftingTable": "Research Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Agriculture Research Paper Advanced",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Farming",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "AgricultureResearchPaperAdvanced",
                    "Name": "Agriculture Research Paper Advanced",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Dirt",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Raw Food",
                            "Name": "",
                            "Ammount": 40,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Crop Seed",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Agriculture Research Paper Advanced",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Quicklime",
            "Untranslated": "Quicklime Recipe",
            "BaseCraftTime": 0.28,
            "BaseLaborCost": 50,
            "BaseXPGain": 0.1,
            "CraftingTable": "Blast Furnace",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Quicklime",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Masonry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Quicklime",
                    "Name": "Quicklime",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Crushed Limestone",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Quicklime",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Fruit Tart",
            "Untranslated": "Fruit Tart Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 45,
            "BaseXPGain": 1,
            "CraftingTable": "Bakery Oven",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Fruit Tart",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Baking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "FruitTart",
                    "Name": "Fruit Tart",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Pastry Dough",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fruit",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Fruit Tart",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Oil",
            "Untranslated": "Oil Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 15,
            "BaseXPGain": 0.5,
            "CraftingTable": "Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Oil",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Oil",
                    "Name": "Oil",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cereal Germ",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Oil",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Arrow",
            "Untranslated": "Arrow Recipe",
            "BaseCraftTime": 0.14,
            "BaseLaborCost": 30,
            "BaseXPGain": 1,
            "CraftingTable": "Tool Bench",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Arrow",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "Arrow",
                    "Name": "Arrow",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Arrow",
                            "Ammount": 4
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Bean Paste",
            "Untranslated": "Bean Paste Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 15,
            "BaseXPGain": 0.5,
            "CraftingTable": "Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Bean Paste",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "BeanPaste",
                    "Name": "Bean Paste",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Beans",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Bean Paste",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Steel Gear",
            "Untranslated": "Steel Gear Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 60,
            "BaseXPGain": 1,
            "CraftingTable": "Electric Planer",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Steel Gear",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Industry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "SteelGear",
                    "Name": "Steel Gear",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Epoxy",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Steel Gear",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Plastic Buoy",
            "Untranslated": "Plastic Buoy Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 240,
            "BaseXPGain": 0.5,
            "CraftingTable": "Electric Machinist Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Plastic Buoy",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Industry",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "PlasticBuoy",
                    "Name": "Plastic Buoy",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plastic",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Wiring",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Plastic Buoy",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Plastic",
            "Untranslated": "Plastic Recipe",
            "BaseCraftTime": 2.1,
            "BaseLaborCost": 180,
            "BaseXPGain": 1,
            "CraftingTable": "Oil Refinery",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Plastic",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Oil Drilling",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Plastic",
                    "Name": "Plastic",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Petroleum",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Plastic",
                            "Ammount": 2
                        },
                        {
                            "Name": "Barrel",
                            "Ammount": 3
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Baked Roast",
            "Untranslated": "Baked Roast Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Bakery Oven",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Baked Roast",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Baking",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "BakedRoast",
                    "Name": "Baked Roast",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Raw Roast",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Baked Roast",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Kelpy Crab Roll",
            "Untranslated": "Kelpy Crab Roll Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 65,
            "BaseXPGain": 1,
            "CraftingTable": "Kitchen",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Kelpy Crab Roll",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Cooking",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "KelpyCrabRoll",
                    "Name": "Kelpy Crab Roll",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Boiled Rice",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Kelp",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Crab Carcass",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Kelpy Crab Roll",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Iron Saw Blade",
            "Untranslated": "Iron Saw Blade Recipe",
            "BaseCraftTime": 1.6800001,
            "BaseLaborCost": 60,
            "BaseXPGain": 2,
            "CraftingTable": "Anvil",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Iron Saw Blade",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "IronSawBlade",
                    "Name": "Iron Saw Blade",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Iron Saw Blade",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Bearclaw",
            "Untranslated": "Bearclaw Recipe",
            "BaseCraftTime": 4.2,
            "BaseLaborCost": 45,
            "BaseXPGain": 1,
            "CraftingTable": "Bakery Oven",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Bearclaw",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Baking",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "Bearclaw",
                    "Name": "Bearclaw",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Pastry Dough",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Sugar",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Bearclaw",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Scrap Meat",
            "Untranslated": "Scrap Meat Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Butchery Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Scrap Meat",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Butchery",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "ScrapMeat",
                    "Name": "Scrap Meat",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Raw Meat",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Scrap Meat",
                            "Ammount": 3
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Bison Mount",
            "Untranslated": "Bison Mount Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 300,
            "BaseXPGain": 4,
            "CraftingTable": "Butchery Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Bison Mount",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Hunting",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "BisonMount",
                    "Name": "Bison Mount",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Composite Lumber",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fabric",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Bison Carcass",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Bison Mount",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Valve",
            "Untranslated": "Valve Recipe",
            "BaseCraftTime": 4.2,
            "BaseLaborCost": 60,
            "BaseXPGain": 4,
            "CraftingTable": "Electric Machinist Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Valve",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "Valve",
                    "Name": "Valve",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Pipe",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Plate",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Gearbox",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Valve",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Elk Taco",
            "Untranslated": "Elk Taco Recipe",
            "BaseCraftTime": 8.4,
            "BaseLaborCost": 65,
            "BaseXPGain": 1,
            "CraftingTable": "Stove",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Elk Taco",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Cooking",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "ElkTaco",
                    "Name": "Elk Taco",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Scrap Meat",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Tortilla",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wild Mix",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Elk Taco",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Plastic Outdoor Table",
            "Untranslated": "Plastic Outdoor Table Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 120,
            "BaseXPGain": 0.5,
            "CraftingTable": "Injection Mold Machine",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Plastic Outdoor Table",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Oil Drilling",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "PlasticOutdoorTable",
                    "Name": "Plastic Outdoor Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plastic",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Plastic Outdoor Table",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Meat Stock",
            "Untranslated": "Meat Stock Recipe",
            "BaseCraftTime": 11.2,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Cast Iron Stove",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Meat Stock",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Cooking",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "MeatStock",
                    "Name": "Meat Stock",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Scrap Meat",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Meat Stock",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Raw Roast",
            "Untranslated": "Raw Roast Recipe",
            "BaseCraftTime": 1.12,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Butchery Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Raw Roast",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Butchery",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "RawRoast",
                    "Name": "Raw Roast",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Raw Meat",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Raw Roast",
                            "Ammount": 1
                        },
                        {
                            "Name": "Scrap Meat",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Field Campfire Stew",
            "Untranslated": "Field Campfire Stew Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Field Campfire Stew",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "FieldCampfireStew",
                    "Name": "Field Campfire Stew",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Corn",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Tomato",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Scrap Meat",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Flour",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fat",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Field Campfire Stew",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Round Pot",
            "Untranslated": "Round Pot Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 45,
            "BaseXPGain": 1,
            "CraftingTable": "Pottery Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Round Pot",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Pottery",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "PlanterPotRound",
                    "Name": "Round Pot",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Clay",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Round Pot",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Charred Taro",
            "Untranslated": "Charred Taro Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 15,
            "BaseXPGain": 0.5,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Charred Taro",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 0
                }
            ],
            "Variants": [
                {
                    "Key": "CharredTaro",
                    "Name": "Charred Taro",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Taro Root",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Charred Taro",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Copper Hydroxide",
            "Untranslated": "Copper Hydroxide Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 24,
            "BaseXPGain": 0.5,
            "CraftingTable": "Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Copper Hydroxide",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "CopperHydroxide",
                    "Name": "Copper Hydroxide",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Crushed Copper Ore",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Copper Hydroxide",
                            "Ammount": 12
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Sensuous Sea Pizza",
            "Untranslated": "Sensuous Sea Pizza Recipe",
            "BaseCraftTime": 4.2,
            "BaseLaborCost": 45,
            "BaseXPGain": 1,
            "CraftingTable": "Bakery Oven",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Sensuous Sea Pizza",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Baking",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "SensuousSeaPizza",
                    "Name": "Sensuous Sea Pizza",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leavened Dough",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Raw Fish",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Kelp",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Sun Cheese",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Tomato",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Sensuous Sea Pizza",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Construction Post",
            "Untranslated": "Construction Post Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Workbench",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Construction Post",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "ConstructionPost",
                    "Name": "Construction Post",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Construction Post",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Zoning Office",
            "Untranslated": "Zoning Office Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 1200,
            "BaseXPGain": 1,
            "CraftingTable": "Settlement Crafting Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Zoning Office",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "ZoningOffice",
                    "Name": "Zoning Office",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Mortared Stone",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Zoning Office",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Adorned Ashlar Stone Chair",
            "Untranslated": "Adorned Ashlar Stone Chair Recipe",
            "BaseCraftTime": 8.4,
            "BaseLaborCost": 240,
            "BaseXPGain": 2,
            "CraftingTable": "Advanced Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Adorned Ashlar Stone Chair",
            "NumberOfVariants": 7,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Masonry",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "AdornedAshlarStoneChair",
                    "Name": "Adorned Ashlar Stone Chair",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Bar",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Ashlar Stone",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Adorned Ashlar Stone Chair",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AdornedAshlarSandstoneChair",
                    "Name": "Adorned Ashlar Sandstone Chair",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Sandstone",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Bar",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Adorned Ashlar Sandstone Chair",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AdornedAshlarBasaltChair",
                    "Name": "Adorned Ashlar Basalt Chair",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Basalt",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Bar",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Adorned Ashlar Basalt Chair",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AdornedAshlarLimestoneChair",
                    "Name": "Adorned Ashlar Limestone Chair",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Limestone",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Bar",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Adorned Ashlar Limestone Chair",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AdornedAshlarGneissChair",
                    "Name": "Adorned Ashlar Gneiss Chair",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Gneiss",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Bar",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Adorned Ashlar Gneiss Chair",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AdornedAshlarShaleChair",
                    "Name": "Adorned Ashlar Shale Chair",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Shale",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Bar",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Adorned Ashlar Shale Chair",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AdornedAshlarGraniteChair",
                    "Name": "Adorned Ashlar Granite Chair",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Granite",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Bar",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Adorned Ashlar Granite Chair",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Topped Porridge",
            "Untranslated": "Topped Porridge Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Topped Porridge",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "ToppedPorridge",
                    "Name": "Topped Porridge",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Boiled Grains",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fruit",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Topped Porridge",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Flour",
            "Untranslated": "Flour Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 15,
            "BaseXPGain": 0.5,
            "CraftingTable": "Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Flour",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Flour",
                    "Name": "Flour",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wheat",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Flour",
                            "Ammount": 1
                        },
                        {
                            "Name": "Cereal Germ",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Geology Research Paper Advanced",
            "Untranslated": "Geology Research Paper Advanced Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 120,
            "BaseXPGain": 3,
            "CraftingTable": "Research Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Geology Research Paper Advanced",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Masonry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "GeologyResearchPaperAdvanced",
                    "Name": "Geology Research Paper Advanced",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Mortared Stone",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Geology Research Paper Advanced",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Small Wooden Ship Frame",
            "Untranslated": "Small Wooden Ship Frame Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 60,
            "BaseXPGain": 1,
            "CraftingTable": "Small Shipyard",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Small Wooden Ship Frame",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Shipwright",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "SmallWoodenShipFrame",
                    "Name": "Small Wooden Ship Frame",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wooden Keel",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Wooden Ship Frame",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Synthetic Rubber",
            "Untranslated": "Synthetic Rubber Recipe",
            "BaseCraftTime": 2.1,
            "BaseLaborCost": 180,
            "BaseXPGain": 1,
            "CraftingTable": "Oil Refinery",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Synthetic Rubber",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Oil Drilling",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "SyntheticRubber",
                    "Name": "Synthetic Rubber",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Petroleum",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Synthetic Rubber",
                            "Ammount": 2
                        },
                        {
                            "Name": "Barrel",
                            "Ammount": 3
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Metallurgy Research Paper Advanced",
            "Untranslated": "Metallurgy Research Paper Advanced Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 120,
            "BaseXPGain": 3,
            "CraftingTable": "Research Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Metallurgy Research Paper Advanced",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Smelting",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "MetallurgyResearchPaperAdvanced",
                    "Name": "Metallurgy Research Paper Advanced",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Bar",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Metallurgy Research Paper Advanced",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Rustic Window Planter",
            "Untranslated": "Rustic Window Planter Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 360,
            "BaseXPGain": 0.5,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Rustic Window Planter",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Fertilizers",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "RusticWindowPlanter",
                    "Name": "Rustic Window Planter",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Rustic Window Planter",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Charred Papaya",
            "Untranslated": "Charred Papaya Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 15,
            "BaseXPGain": 0.5,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Charred Papaya",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 0
                }
            ],
            "Variants": [
                {
                    "Key": "CharredPapaya",
                    "Name": "Charred Papaya",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Papaya",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Charred Papaya",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Heat Sink",
            "Untranslated": "Heat Sink Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 60,
            "BaseXPGain": 2,
            "CraftingTable": "Shaper",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Heat Sink",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "HeatSink",
                    "Name": "Heat Sink",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Plate",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Wiring",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Heat Sink",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Charred Camas Bulb",
            "Untranslated": "Charred Camas Bulb Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 15,
            "BaseXPGain": 0.5,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Charred Camas Bulb",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 0
                }
            ],
            "Variants": [
                {
                    "Key": "CharredCamasBulb",
                    "Name": "Charred Camas Bulb",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Camas Bulb",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Charred Camas Bulb",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Charred Beans",
            "Untranslated": "Charred Beans Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 15,
            "BaseXPGain": 0.5,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Charred Beans",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 0
                }
            ],
            "Variants": [
                {
                    "Key": "CharredBeans",
                    "Name": "Charred Beans",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Beans",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Charred Beans",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Steel Buoy",
            "Untranslated": "Steel Buoy Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 240,
            "BaseXPGain": 0.5,
            "CraftingTable": "Machinist Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Steel Buoy",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "SteelBuoy",
                    "Name": "Steel Buoy",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Steel Buoy",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Charred Fish",
            "Untranslated": "Charred Fish Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 15,
            "BaseXPGain": 0.5,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Charred Fish",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 0
                }
            ],
            "Variants": [
                {
                    "Key": "CharredFish",
                    "Name": "Charred Fish",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Raw Fish",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Charred Fish",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Pineapple Friend Rice",
            "Untranslated": "Pineapple Friend Rice Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 65,
            "BaseXPGain": 1,
            "CraftingTable": "Kitchen",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Pineapple Friend Rice",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Cooking",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "PineappleFriendRice",
                    "Name": "Pineapple Friend Rice",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Charred Pineapple",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Boiled Rice",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Scrap Meat",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Pineapple Friend Rice",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Raw Bacon",
            "Untranslated": "Raw Bacon Recipe",
            "BaseCraftTime": 1.12,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Butchery Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Raw Bacon",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Butchery",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "RawBacon",
                    "Name": "Raw Bacon",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Raw Meat",
                            "Ammount": 3,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Raw Bacon",
                            "Ammount": 1
                        },
                        {
                            "Name": "Scrap Meat",
                            "Ammount": 3
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Fried Camas",
            "Untranslated": "Fried Camas Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Fried Camas",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "FriedCamas",
                    "Name": "Fried Camas",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Camas Bulb",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fat",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Fried Camas",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Charcoal Powder",
            "Untranslated": "Charcoal Powder Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 24,
            "BaseXPGain": 0.5,
            "CraftingTable": "Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Charcoal Powder",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "CharcoalPowder",
                    "Name": "Charcoal Powder",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Charcoal",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Charcoal Powder",
                            "Ammount": 5
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Agriculture Research Paper Modern",
            "Untranslated": "Agriculture Research Paper Modern Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 600,
            "BaseXPGain": 6,
            "CraftingTable": "Laboratory",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Agriculture Research Paper Modern",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Fertilizers",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "AgricultureResearchPaperModern",
                    "Name": "Agriculture Research Paper Modern",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Berry Extract Fertilizer",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Blood Meal Fertilizer",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Hide Ash Fertilizer",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Pelt Fertilizer",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Raw Food",
                            "Name": "",
                            "Ammount": 200,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ink",
                            "Ammount": 4,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Paper",
                            "Ammount": 20,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Agriculture Research Paper Modern",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Crimson Salad",
            "Untranslated": "Crimson Salad Recipe",
            "BaseCraftTime": 8.4,
            "BaseLaborCost": 65,
            "BaseXPGain": 1,
            "CraftingTable": "Kitchen",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Crimson Salad",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Cooking",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "CrimsonSalad",
                    "Name": "Crimson Salad",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Basic Salad",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Infused Oil",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Charred Beet",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Tomato",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Crimson Salad",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Jungle Campfire Salad",
            "Untranslated": "Jungle Campfire Salad Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Jungle Campfire Salad",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "JungleCampfireSalad",
                    "Name": "Jungle Campfire Salad",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Papaya",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Pineapple",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Heart Of Palm",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Greens",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Jungle Campfire Salad",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Couch",
            "Untranslated": "Couch Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 160,
            "BaseXPGain": 1,
            "CraftingTable": "Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Couch",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "Couch",
                    "Name": "Couch",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fabric",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Couch",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Vegetable Soup",
            "Untranslated": "Vegetable Soup Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Cast Iron Stove",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Vegetable Soup",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Cooking",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "VegetableSoup",
                    "Name": "Vegetable Soup",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Vegetable Stock",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Vegetable Medley",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Vegetable Soup",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Engineering Research Paper Modern",
            "Untranslated": "Engineering Research Paper Modern Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 600,
            "BaseXPGain": 3,
            "CraftingTable": "Laboratory",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Engineering Research Paper Modern",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "EngineeringResearchPaperModern",
                    "Name": "Engineering Research Paper Modern",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Axle",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Plate",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Gear",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ink",
                            "Ammount": 4,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Paper",
                            "Ammount": 20,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Engineering Research Paper Modern",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Paper",
            "Untranslated": "Paper Recipe",
            "BaseCraftTime": 0.14,
            "BaseLaborCost": 20,
            "BaseXPGain": 1,
            "CraftingTable": "Small Paper Machine",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Paper",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Paper Milling",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Paper",
                    "Name": "Paper",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cellulose Fiber",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Paper",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Vegetable Stock",
            "Untranslated": "Vegetable Stock Recipe",
            "BaseCraftTime": 11.2,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Cast Iron Stove",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Vegetable Stock",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Cooking",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "VegetableStock",
                    "Name": "Vegetable Stock",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Vegetable Medley",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Vegetable Stock",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Sheep Mount",
            "Untranslated": "Sheep Mount Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 300,
            "BaseXPGain": 4,
            "CraftingTable": "Butchery Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Sheep Mount",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Hunting",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "SheepMount",
                    "Name": "Sheep Mount",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fabric",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Bighorn Carcass",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Sheep Mount",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Small Sink",
            "Untranslated": "Small Sink Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 60,
            "BaseXPGain": 2,
            "CraftingTable": "Kiln",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Small Sink",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Pottery",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "SmallSink",
                    "Name": "Small Sink",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Clay",
                            "Ammount": 30,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "White Powder",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Sink",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Poke Bowl",
            "Untranslated": "Poke Bowl Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 45,
            "BaseXPGain": 1,
            "CraftingTable": "Stove",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Poke Bowl",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Cooking",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "PokeBowl",
                    "Name": "Poke Bowl",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Boiled Rice",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Beans",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Kelp",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Tuna",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Poke Bowl",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Mochi",
            "Untranslated": "Mochi Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Cast Iron Stove",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Mochi",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Cooking",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "Mochi",
                    "Name": "Mochi",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Rice Flour",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Sugar",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cornmeal",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Mochi",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Gearbox",
            "Untranslated": "Gearbox Recipe",
            "BaseCraftTime": 2.1,
            "BaseLaborCost": 45,
            "BaseXPGain": 2,
            "CraftingTable": "Shaper",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Gearbox",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Gearbox",
                    "Name": "Gearbox",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Gear",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Gearbox",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Corn Fritters",
            "Untranslated": "Corn Fritters Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 45,
            "BaseXPGain": 1,
            "CraftingTable": "Stove",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Corn Fritters",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Cooking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CornFritters",
                    "Name": "Corn Fritters",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cornmeal",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Corn",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fat",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Corn Fritters",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Yeast",
            "Untranslated": "Yeast Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Yeast",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "Yeast",
                    "Name": "Yeast",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Sugar",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Yeast",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Bean Sprout",
            "Untranslated": "Bean Sprout Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Bean Sprout",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Farming",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "BeanSprout",
                    "Name": "Bean Sprout",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Beans",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Bean Sprout",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Industrial Refrigerator",
            "Untranslated": "Industrial Refrigerator Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 240,
            "BaseXPGain": 8,
            "CraftingTable": "Robotic Assembly Line",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Industrial Refrigerator",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Industry",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "IndustrialRefrigerator",
                    "Name": "Industrial Refrigerator",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Plate",
                            "Ammount": 32,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Advanced Circuit",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Radiator",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Glass",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Industrial Refrigerator",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wild Mix",
            "Untranslated": "Wild Mix Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 45,
            "BaseXPGain": 1,
            "CraftingTable": "Kitchen",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Wild Mix",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Cooking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "WildMix",
                    "Name": "Wild Mix",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Basic Salad",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Huckleberry Extract",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wild Mix",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Culinary Research Paper Advanced",
            "Untranslated": "Culinary Research Paper Advanced Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 120,
            "BaseXPGain": 3,
            "CraftingTable": "Research Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Culinary Research Paper Advanced",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Cooking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CulinaryResearchPaperAdvanced",
                    "Name": "Culinary Research Paper Advanced",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Baked Vegetable",
                            "Name": "",
                            "Ammount": 16,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Salad",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Culinary Research Paper Advanced",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Millionaires Salad",
            "Untranslated": "Millionaires Salad Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 65,
            "BaseXPGain": 1,
            "CraftingTable": "Kitchen",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Millionaires Salad",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Cooking",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "MillionairesSalad",
                    "Name": "Millionaires Salad",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Heart Of Palm",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cookeina Mushrooms",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Papaya",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Pineapple",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Millionaires Salad",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Fried Fiddleheads",
            "Untranslated": "Fried Fiddleheads Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Fried Fiddleheads",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "FriedFiddleheads",
                    "Name": "Fried Fiddleheads",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Fiddleheads",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fat",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Fried Fiddleheads",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Pastry Dough",
            "Untranslated": "Pastry Dough Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Bakery Oven",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Pastry Dough",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Baking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "PastryDough",
                    "Name": "Pastry Dough",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Flour",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Yeast",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fat",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Pastry Dough",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Bread",
            "Untranslated": "Bread Recipe",
            "BaseCraftTime": 4.2,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Bakery Oven",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Bread",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Baking",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "Bread",
                    "Name": "Bread",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leavened Dough",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Bread",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Injection Mold Machine",
            "Untranslated": "Injection Mold Machine Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 560,
            "BaseXPGain": 1,
            "CraftingTable": "Electric Machinist Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Injection Mold Machine",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Industry",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "InjectionMoldMachine",
                    "Name": "Injection Mold Machine",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Plate",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Wiring",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Basic Circuit",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Electric Motor",
                            "Ammount": 2,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Injection Mold Machine",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Baked Heart Of Palm",
            "Untranslated": "Baked Heart Of Palm Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Bakery Oven",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Baked Heart Of Palm",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Baking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "BakedHeartOfPalm",
                    "Name": "Baked Heart Of Palm",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Heart Of Palm",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Baked Heart Of Palm",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Prepared Meat",
            "Untranslated": "Prepared Meat Recipe",
            "BaseCraftTime": 1.12,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Butchery Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Prepared Meat",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Butchery",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "PreparedMeat",
                    "Name": "Prepared Meat",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Raw Meat",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Prepared Meat",
                            "Ammount": 1
                        },
                        {
                            "Name": "Scrap Meat",
                            "Ammount": 4
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Boiled Sausage",
            "Untranslated": "Boiled Sausage Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 45,
            "BaseXPGain": 1,
            "CraftingTable": "Stove",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Boiled Sausage",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Cooking",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "BoiledSausage",
                    "Name": "Boiled Sausage",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Raw Sausage",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Meat Stock",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Boiled Sausage",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wilted Fiddleheads",
            "Untranslated": "Wilted Fiddleheads Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 15,
            "BaseXPGain": 0.5,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Wilted Fiddleheads",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 0
                }
            ],
            "Variants": [
                {
                    "Key": "WiltedFiddleheads",
                    "Name": "Wilted Fiddleheads",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Fiddleheads",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wilted Fiddleheads",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Cyan Powder",
            "Untranslated": "Cyan Powder Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 24,
            "BaseXPGain": 0.5,
            "CraftingTable": "Paint Mixer",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Cyan Powder",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Painting",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "CyanPowder",
                    "Name": "Cyan Powder",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Hydroxide",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Blue Powder",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "White Powder",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Cyan Powder",
                            "Ammount": 3
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Camas Bread",
            "Untranslated": "Camas Bread Recipe",
            "BaseCraftTime": 4.2,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Bakery Oven",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Camas Bread",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Baking",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "CamasBread",
                    "Name": "Camas Bread",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Camas Paste",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leavened Dough",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Camas Bread",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Jungle Campfire Stew",
            "Untranslated": "Jungle Campfire Stew Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Jungle Campfire Stew",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "JungleCampfireStew",
                    "Name": "Jungle Campfire Stew",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Papaya",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Scrap Meat",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Flour",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fat",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fungus",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Jungle Campfire Stew",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Bannock",
            "Untranslated": "Bannock Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Bannock",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "Bannock",
                    "Name": "Bannock",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wheat",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fat",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Bannock",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Fuse",
            "Untranslated": "Fuse Recipe",
            "BaseCraftTime": 0.28,
            "BaseLaborCost": 80,
            "BaseXPGain": 1,
            "CraftingTable": "Electronics Assembly",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Fuse",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Electronics",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Fuse",
                    "Name": "Fuse",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Glass",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Wiring",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Fuse",
                            "Ammount": 4
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Steel Kitchen Lamp",
            "Untranslated": "Steel Kitchen Lamp Recipe",
            "BaseCraftTime": 4.2,
            "BaseLaborCost": 120,
            "BaseXPGain": 4,
            "CraftingTable": "Robotic Assembly Line",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Steel Kitchen Lamp",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Electronics",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "SteelKitchenLamp",
                    "Name": "Steel Kitchen Lamp",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plastic",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Wiring",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Light Bulb",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Steel Kitchen Lamp",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Tasty Tropical Pizza",
            "Untranslated": "Tasty Tropical Pizza Recipe",
            "BaseCraftTime": 4.2,
            "BaseLaborCost": 45,
            "BaseXPGain": 1,
            "CraftingTable": "Bakery Oven",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Tasty Tropical Pizza",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Baking",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "TastyTropicalPizza",
                    "Name": "Tasty Tropical Pizza",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leavened Dough",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Raw Bacon",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Pineapple",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Sun Cheese",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Tomato",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Tasty Tropical Pizza",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Huckleberry Fritter",
            "Untranslated": "Huckleberry Fritter Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Bakery Oven",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Huckleberry Fritter",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Baking",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "HuckleberryFritter",
                    "Name": "Huckleberry Fritter",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Sugar",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Pastry Dough",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Huckleberry Extract",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Oil",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Huckleberry Fritter",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Charred Meat",
            "Untranslated": "Charred Meat Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 15,
            "BaseXPGain": 0.5,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Charred Meat",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 0
                }
            ],
            "Variants": [
                {
                    "Key": "CharredMeat",
                    "Name": "Charred Meat",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Raw Meat",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Charred Meat",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Fried Hearts Of Palm",
            "Untranslated": "Fried Hearts Of Palm Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Fried Hearts Of Palm",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "FriedHeartsOfPalm",
                    "Name": "Fried Hearts Of Palm",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Heart Of Palm",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fat",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Fried Hearts Of Palm",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Pupusas",
            "Untranslated": "Pupusas Recipe",
            "BaseCraftTime": 4.2,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Cast Iron Stove",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Pupusas",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Cooking",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "Pupusas",
                    "Name": "Pupusas",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cornmeal",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Tomato",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Beans",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Sun Cheese",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Pupusas",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Nylon Futon Couch",
            "Untranslated": "Nylon Futon Couch Recipe",
            "BaseCraftTime": 8.4,
            "BaseLaborCost": 180,
            "BaseXPGain": 2,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Nylon Futon Couch",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "NylonFutonCouch",
                    "Name": "Nylon Futon Couch",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nylon Fabric",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 15,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Nylon Futon Couch",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Camas Bulb Bake",
            "Untranslated": "Camas Bulb Bake Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Bakery Oven",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Camas Bulb Bake",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Baking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CamasBulbBake",
                    "Name": "Camas Bulb Bake",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Camas Bulb",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Camas Bulb Bake",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Linen Fabric",
            "Untranslated": "Linen Fabric Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 100,
            "BaseXPGain": 0.5,
            "CraftingTable": "Loom",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Linen Fabric",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "LinenFabric",
                    "Name": "Linen Fabric",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Linen Yarn",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Linen Fabric",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Charred Sausage",
            "Untranslated": "Charred Sausage Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Charred Sausage",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CharredSausage",
                    "Name": "Charred Sausage",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Raw Sausage",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Charred Sausage",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Simple Syrup",
            "Untranslated": "Simple Syrup Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Simple Syrup",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "SimpleSyrup",
                    "Name": "Simple Syrup",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Sugar",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Simple Syrup",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Charred Corn",
            "Untranslated": "Charred Corn Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 15,
            "BaseXPGain": 0.5,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Charred Corn",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 0
                }
            ],
            "Variants": [
                {
                    "Key": "CharredCorn",
                    "Name": "Charred Corn",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Corn",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Charred Corn",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Meaty Stew",
            "Untranslated": "Meaty Stew Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Meaty Stew",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "MeatyStew",
                    "Name": "Meaty Stew",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Charred Meat",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Scrap Meat",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Flour",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fat",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Meaty Stew",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Raw Sausage",
            "Untranslated": "Raw Sausage Recipe",
            "BaseCraftTime": 1.12,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Butchery Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Raw Sausage",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Butchery",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "RawSausage",
                    "Name": "Raw Sausage",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Scrap Meat",
                            "Ammount": 3,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Raw Sausage",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Loaded Taro Fries",
            "Untranslated": "Loaded Taro Fries Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Cast Iron Stove",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Loaded Taro Fries",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Cooking",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "LoadedTaroFries",
                    "Name": "Loaded Taro Fries",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Taro Fries",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Sun Cheese",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Prepared Meat",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Loaded Taro Fries",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Autumn Stew",
            "Untranslated": "Autumn Stew Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Cast Iron Stove",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Autumn Stew",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Cooking",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "AutumnStew",
                    "Name": "Autumn Stew",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Pumpkin",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Beans",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Greens",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Autumn Stew",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Banh Xeo",
            "Untranslated": "Banh Xeo Recipe",
            "BaseCraftTime": 4.2,
            "BaseLaborCost": 65,
            "BaseXPGain": 1,
            "CraftingTable": "Stove",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Banh Xeo",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Cooking",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "BanhXeo",
                    "Name": "Banh Xeo",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Rice Flour",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Prime Cut",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Bean Sprout",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Crab Carcass",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Oil",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Banh Xeo",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Baked Meat",
            "Untranslated": "Baked Meat Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Bakery Oven",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Baked Meat",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Baking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "BakedMeat",
                    "Name": "Baked Meat",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Raw Meat",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Baked Meat",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Charred Cactus Fruit",
            "Untranslated": "Charred Cactus Fruit Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 15,
            "BaseXPGain": 0.5,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Charred Cactus Fruit",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 0
                }
            ],
            "Variants": [
                {
                    "Key": "CharredCactusFruit",
                    "Name": "Charred Cactus Fruit",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Giant Cactus Fruit",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Charred Cactus Fruit",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Prime Cut",
            "Untranslated": "Prime Cut Recipe",
            "BaseCraftTime": 1.12,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Butchery Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Prime Cut",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Butchery",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "PrimeCut",
                    "Name": "Prime Cut",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Raw Meat",
                            "Ammount": 16,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Prime Cut",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Stone Plaque",
            "Untranslated": "Stone Plaque Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 80,
            "BaseXPGain": 1,
            "CraftingTable": "Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Stone Plaque",
            "NumberOfVariants": 2,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "StonePlaque",
                    "Name": "Stone Plaque",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Mortared Stone",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Rock",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Stone Plaque",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "OrnateStonePlaque",
                    "Name": "Ornate Stone Plaque",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Plate",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Stone Plaque",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ornate Stone Plaque",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Clam Chowder",
            "Untranslated": "Clam Chowder Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Cast Iron Stove",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Clam Chowder",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Cooking",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "ClamChowder",
                    "Name": "Clam Chowder",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Clam",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Taro Root",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Clam Chowder",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Baked Beet",
            "Untranslated": "Baked Beet Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Bakery Oven",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Baked Beet",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Baking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "BakedBeet",
                    "Name": "Baked Beet",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Beet",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Baked Beet",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Hosomaki",
            "Untranslated": "Hosomaki Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 65,
            "BaseXPGain": 1,
            "CraftingTable": "Kitchen",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Hosomaki",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Cooking",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "Hosomaki",
                    "Name": "Hosomaki",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Boiled Rice",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Kelp",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Raw Fish",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Hosomaki",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Beet Campfire Salad",
            "Untranslated": "Beet Campfire Salad Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Beet Campfire Salad",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "BeetCampfireSalad",
                    "Name": "Beet Campfire Salad",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Beet",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fruit",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Greens",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Beet Campfire Salad",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Large Hanging Lumber Sign",
            "Untranslated": "Large Hanging Lumber Sign Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 120,
            "BaseXPGain": 3,
            "CraftingTable": "Sawmill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Large Hanging Lumber Sign",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "LargeHangingLumberSign",
                    "Name": "Large Hanging Lumber Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Hanging Lumber Sign",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Sugar",
            "Untranslated": "Sugar Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 15,
            "BaseXPGain": 0.5,
            "CraftingTable": "Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Sugar",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Sugar",
                    "Name": "Sugar",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Huckleberries",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Sugar",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Flatbread",
            "Untranslated": "Flatbread Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Bakery Oven",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Flatbread",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Baking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Flatbread",
                    "Name": "Flatbread",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Flour",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Flatbread",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Rice Noodles",
            "Untranslated": "Rice Noodles Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 15,
            "BaseXPGain": 0.5,
            "CraftingTable": "Cast Iron Stove",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Rice Noodles",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Cooking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "RiceNoodles",
                    "Name": "Rice Noodles",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Rice Flour",
                            "Ammount": 3,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Rice Noodles",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Roast Pumpkin",
            "Untranslated": "Roast Pumpkin Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Bakery Oven",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Roast Pumpkin",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Baking",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "RoastPumpkin",
                    "Name": "Roast Pumpkin",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Pumpkin",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Sugar",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Roast Pumpkin",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Tortilla",
            "Untranslated": "Tortilla Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 45,
            "BaseXPGain": 1,
            "CraftingTable": "Kitchen",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Tortilla",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Cooking",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "Tortilla",
                    "Name": "Tortilla",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cornmeal",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Tortilla",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wild Stew",
            "Untranslated": "Wild Stew Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Wild Stew",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "WildStew",
                    "Name": "Wild Stew",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Huckleberries",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Beans",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Beet",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Flour",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Vegetable",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wild Stew",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Rice Flour",
            "Untranslated": "Rice Flour Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 15,
            "BaseXPGain": 0.5,
            "CraftingTable": "Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Rice Flour",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "RiceFlour",
                    "Name": "Rice Flour",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Rice",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Rice Flour",
                            "Ammount": 1
                        },
                        {
                            "Name": "Cereal Germ",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Sweet Salad",
            "Untranslated": "Sweet Salad Recipe",
            "BaseCraftTime": 8.4,
            "BaseLaborCost": 65,
            "BaseXPGain": 1,
            "CraftingTable": "Kitchen",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Sweet Salad",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Cooking",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "SweetSalad",
                    "Name": "Sweet Salad",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Basic Salad",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Fruit Salad",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Simple Syrup",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Sweet Salad",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Fried Taro",
            "Untranslated": "Fried Taro Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Fried Taro",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "FriedTaro",
                    "Name": "Fried Taro",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Taro Root",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fat",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Fried Taro",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Fern Campfire Salad",
            "Untranslated": "Fern Campfire Salad Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Fern Campfire Salad",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "FernCampfireSalad",
                    "Name": "Fern Campfire Salad",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Fiddleheads",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Huckleberries",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Vegetable",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Fern Campfire Salad",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Charred Pineapple",
            "Untranslated": "Charred Pineapple Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 15,
            "BaseXPGain": 0.5,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Charred Pineapple",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 0
                }
            ],
            "Variants": [
                {
                    "Key": "CharredPineapple",
                    "Name": "Charred Pineapple",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Pineapple",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Charred Pineapple",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Limestone Wolf Statue",
            "Untranslated": "Limestone Wolf Statue Recipe",
            "BaseCraftTime": 9.8,
            "BaseLaborCost": 60,
            "BaseXPGain": 2,
            "CraftingTable": "Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Limestone Wolf Statue",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Masonry",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "LimestoneWolfStatue",
                    "Name": "Limestone Wolf Statue",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Limestone",
                            "Ammount": 80,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Limestone Wolf Statue",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Fried Tomatoes",
            "Untranslated": "Fried Tomatoes Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Fried Tomatoes",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "FriedTomatoes",
                    "Name": "Fried Tomatoes",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Tomato",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fat",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Fried Tomatoes",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Acorn Powder",
            "Untranslated": "Acorn Powder Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Acorn Powder",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "AcornPowder",
                    "Name": "Acorn Powder",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Acorn",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Acorn Powder",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Spiky Roll",
            "Untranslated": "Spiky Roll Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 45,
            "BaseXPGain": 1,
            "CraftingTable": "Kitchen",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Spiky Roll",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Cooking",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "SpikyRoll",
                    "Name": "Spiky Roll",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Boiled Rice",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Urchin",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Spiky Roll",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Tapestry",
            "Untranslated": "Tapestry Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 60,
            "BaseXPGain": 2,
            "CraftingTable": "Loom",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Tapestry",
            "NumberOfVariants": 3,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "Tapestry",
                    "Name": "Tapestry",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fabric",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Tapestry",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "TapestrySunset",
                    "Name": "Tapestry Sunset",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Tapestry",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Tapestry Sunset",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "TapestryFish",
                    "Name": "Tapestry Fish",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Tapestry",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Tapestry Fish",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Seared Meat",
            "Untranslated": "Seared Meat Recipe",
            "BaseCraftTime": 4.2,
            "BaseLaborCost": 45,
            "BaseXPGain": 1,
            "CraftingTable": "Stove",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Seared Meat",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Cooking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "SearedMeat",
                    "Name": "Seared Meat",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Prime Cut",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Infused Oil",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Seared Meat",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Baked Tomato",
            "Untranslated": "Baked Tomato Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Bakery Oven",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Baked Tomato",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Baking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "BakedTomato",
                    "Name": "Baked Tomato",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Tomato",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Baked Tomato",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Boiled Rice",
            "Untranslated": "Boiled Rice Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Kitchen",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Boiled Rice",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Cooking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "BoiledRice",
                    "Name": "Boiled Rice",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Rice",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Boiled Rice",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Baked Corn",
            "Untranslated": "Baked Corn Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Bakery Oven",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Baked Corn",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Baking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "BakedCorn",
                    "Name": "Baked Corn",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Corn",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Baked Corn",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Stuffed Turkey",
            "Untranslated": "Stuffed Turkey Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 65,
            "BaseXPGain": 1,
            "CraftingTable": "Bakery Oven",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Stuffed Turkey",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Baking",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "StuffedTurkey",
                    "Name": "Stuffed Turkey",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Prime Cut",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Bread",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Vegetable Medley",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Stuffed Turkey",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Flaxseed Oil",
            "Untranslated": "Flaxseed Oil Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 25,
            "BaseXPGain": 0.5,
            "CraftingTable": "Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Flaxseed Oil",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "FlaxseedOil",
                    "Name": "Flaxseed Oil",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Flax Seed",
                            "Ammount": 16,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Flaxseed Oil",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Root Campfire Stew",
            "Untranslated": "Root Campfire Stew Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Root Campfire Stew",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "RootCampfireStew",
                    "Name": "Root Campfire Stew",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Beet",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Camas Bulb",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Scrap Meat",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Flour",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fat",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Root Campfire Stew",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Baked Taro",
            "Untranslated": "Baked Taro Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Bakery Oven",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Baked Taro",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Baking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "BakedTaro",
                    "Name": "Baked Taro",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Taro Root",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Baked Taro",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Fruit Muffin",
            "Untranslated": "Fruit Muffin Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Bakery Oven",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Fruit Muffin",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Baking",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "FruitMuffin",
                    "Name": "Fruit Muffin",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Flour",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fruit",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Fruit Muffin",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Leavened Dough",
            "Untranslated": "Leavened Dough Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Bakery Oven",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Leavened Dough",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Baking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "LeavenedDough",
                    "Name": "Leavened Dough",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Flour",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Yeast",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Leavened Dough",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Fantastic Forest Pizza",
            "Untranslated": "Fantastic Forest Pizza Recipe",
            "BaseCraftTime": 4.2,
            "BaseLaborCost": 65,
            "BaseXPGain": 1,
            "CraftingTable": "Bakery Oven",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Fantastic Forest Pizza",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Baking",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "FantasticForestPizza",
                    "Name": "Fantastic Forest Pizza",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leavened Dough",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Camas Bulb",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Sun Cheese",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Tomato",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Greens",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Fantastic Forest Pizza",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Bison Chow Fun",
            "Untranslated": "Bison Chow Fun Recipe",
            "BaseCraftTime": 4.2,
            "BaseLaborCost": 65,
            "BaseXPGain": 1,
            "CraftingTable": "Stove",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Bison Chow Fun",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Cooking",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "BisonChowFun",
                    "Name": "Bison Chow Fun",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Rice Noodles",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Bean Sprout",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Bison Carcass",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Oil",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Bison Chow Fun",
                            "Ammount": 4
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Agouti Enchiladas",
            "Untranslated": "Agouti Enchiladas Recipe",
            "BaseCraftTime": 4.2,
            "BaseLaborCost": 65,
            "BaseXPGain": 1,
            "CraftingTable": "Stove",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Agouti Enchiladas",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Cooking",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "AgoutiEnchiladas",
                    "Name": "Agouti Enchiladas",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cornmeal",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Tomato",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Papaya",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Sun Cheese",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Prime Cut",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Agouti Enchiladas",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Macarons",
            "Untranslated": "Macarons Recipe",
            "BaseCraftTime": 4.2,
            "BaseLaborCost": 65,
            "BaseXPGain": 1,
            "CraftingTable": "Bakery Oven",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Macarons",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Baking",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "Macarons",
                    "Name": "Macarons",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Pastry Dough",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Sugar",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Huckleberry Extract",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Macarons",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Glassworks",
            "Untranslated": "Glassworks Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 150,
            "BaseXPGain": 20,
            "CraftingTable": "Kiln",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Glassworks",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Pottery",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Glassworks",
                    "Name": "Glassworks",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Brick",
                            "Ammount": 16,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Glassworks",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Smooth Gut Noodle Roll",
            "Untranslated": "Smooth Gut Noodle Roll Recipe",
            "BaseCraftTime": 4.2,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Cast Iron Stove",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Smooth Gut Noodle Roll",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Cooking",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "SmoothGutNoodleRoll",
                    "Name": "Smooth Gut Noodle Roll",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Rice Noodles",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Prepared Meat",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Oil",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Smooth Gut Noodle Roll",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Meat Pie",
            "Untranslated": "Meat Pie Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Bakery Oven",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Meat Pie",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Baking",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "MeatPie",
                    "Name": "Meat Pie",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Flour",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Scrap Meat",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fat",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Meat Pie",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Huckleberry Pie",
            "Untranslated": "Huckleberry Pie Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Bakery Oven",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Huckleberry Pie",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Baking",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "HuckleberryPie",
                    "Name": "Huckleberry Pie",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Flour",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Huckleberries",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fat",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Huckleberry Pie",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Charred Beet",
            "Untranslated": "Charred Beet Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 15,
            "BaseXPGain": 0.5,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Charred Beet",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 0
                }
            ],
            "Variants": [
                {
                    "Key": "CharredBeet",
                    "Name": "Charred Beet",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Beet",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Charred Beet",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Plant Vase",
            "Untranslated": "Plant Vase Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 90,
            "BaseXPGain": 1,
            "CraftingTable": "Glassworks",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Plant Vase",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Glassworking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "PlantVase",
                    "Name": "Plant Vase",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Glass",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cyan Powder",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Oxide",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Plant Vase",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Infused Oil",
            "Untranslated": "Infused Oil Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Kitchen",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Infused Oil",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Cooking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "InfusedOil",
                    "Name": "Infused Oil",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Huckleberry Extract",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Oil",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Infused Oil",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Simmered Meat",
            "Untranslated": "Simmered Meat Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Cast Iron Stove",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Simmered Meat",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Cooking",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "SimmeredMeat",
                    "Name": "Simmered Meat",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Prepared Meat",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Meat Stock",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Simmered Meat",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Charred Agave",
            "Untranslated": "Charred Agave Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 15,
            "BaseXPGain": 0.5,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Charred Agave",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 0
                }
            ],
            "Variants": [
                {
                    "Key": "CharredAgave",
                    "Name": "Charred Agave",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Agave Leaves",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Charred Agave",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Charred Tomato",
            "Untranslated": "Charred Tomato Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 15,
            "BaseXPGain": 0.5,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Charred Tomato",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 0
                }
            ],
            "Variants": [
                {
                    "Key": "CharredTomato",
                    "Name": "Charred Tomato",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Tomato",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Charred Tomato",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Sand Concentrate Lv3",
            "Untranslated": "Sand Concentrate Lv3 Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 150,
            "BaseXPGain": 1.5,
            "CraftingTable": "Sensor Based Belt Sorter",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Sand Concentrate Lv3",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "SandConcentrateLv3",
                    "Name": "Sand Concentrate Lv3",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Silica",
                            "Name": "",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Sand",
                            "Ammount": 3
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Exotic Salad",
            "Untranslated": "Exotic Salad Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Cast Iron Stove",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Exotic Salad",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Cooking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "ExoticSalad",
                    "Name": "Exotic Salad",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Prickly Pear Fruit",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Crimini Mushrooms",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Rice",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Basic Salad",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Charred Heart Of Palm",
            "Untranslated": "Charred Heart Of Palm Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 15,
            "BaseXPGain": 0.5,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Charred Heart Of Palm",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 0
                }
            ],
            "Variants": [
                {
                    "Key": "CharredHeartOfPalm",
                    "Name": "Charred Heart Of Palm",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Heart Of Palm",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Charred Heart Of Palm",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Butcher Tiny Fur Animal",
            "Untranslated": "Butcher Tiny Fur Animal Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 25,
            "BaseXPGain": 2,
            "CraftingTable": "Butchery Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Butcher Tiny Fur Animal",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Butchery",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "ButcherTinyFurAnimal",
                    "Name": "Butcher Tiny Fur Animal",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Tiny Fur Carcass",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Raw Meat",
                            "Ammount": 1
                        },
                        {
                            "Name": "Fur Pelt",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Shred Kelp",
            "Untranslated": "Shred Kelp Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Fishery",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Shred Kelp",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Hunting",
                    "Level": 0
                }
            ],
            "Variants": [
                {
                    "Key": "ShredKelp",
                    "Name": "Shred Kelp",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Kelp",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Plant Fibers",
                            "Ammount": 7
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Decorative Hanging Buoy",
            "Untranslated": "Decorative Hanging Buoy Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 120,
            "BaseXPGain": 0.5,
            "CraftingTable": "Small Shipyard",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Decorative Hanging Buoy",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Shipwright",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "DecorativeHangingBuoy",
                    "Name": "Decorative Hanging Buoy",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plastic",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Decorative Hanging Buoy",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Crushed Basalt Lv3",
            "Untranslated": "Crushed Basalt Lv3 Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 180,
            "BaseXPGain": 0.5,
            "CraftingTable": "Jaw Crusher",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Crushed Basalt Lv3",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "CrushedBasaltLv3",
                    "Name": "Crushed Basalt Lv3",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Basalt",
                            "Ammount": 20,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Crushed Basalt",
                            "Ammount": 5
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Compost Adobe",
            "Untranslated": "Compost Adobe Recipe",
            "BaseCraftTime": 2.1,
            "BaseLaborCost": 40,
            "BaseXPGain": 1,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Compost Adobe",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Fertilizers",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "CompostAdobe",
                    "Name": "Compost Adobe",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Adobe",
                            "Ammount": 20,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Compost Fertilizer",
                            "Ammount": 1
                        },
                        {
                            "Name": "Dirt",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Seeded Camas Roll",
            "Untranslated": "Seeded Camas Roll Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 65,
            "BaseXPGain": 1,
            "CraftingTable": "Kitchen",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Seeded Camas Roll",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Cooking",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "SeededCamasRoll",
                    "Name": "Seeded Camas Roll",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Boiled Rice",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Kelp",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Camas Paste",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Fiddleheads",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Seeded Camas Roll",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Pirozhok",
            "Untranslated": "Pirozhok Recipe",
            "BaseCraftTime": 4.2,
            "BaseLaborCost": 45,
            "BaseXPGain": 1,
            "CraftingTable": "Bakery Oven",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Pirozhok",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Baking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Pirozhok",
                    "Name": "Pirozhok",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Pastry Dough",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Prepared Meat",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fungus",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Pirozhok",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Butcher Hare",
            "Untranslated": "Butcher Hare Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Butchery Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Butcher Hare",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Butchery",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "ButcherHare",
                    "Name": "Butcher Hare",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Hare Carcass",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Raw Meat",
                            "Ammount": 1
                        },
                        {
                            "Name": "Fur Pelt",
                            "Ammount": 1
                        },
                        {
                            "Name": "Shorn Wool",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Mortared Stone Table",
            "Untranslated": "Mortared Stone Table Recipe",
            "BaseCraftTime": 8.4,
            "BaseLaborCost": 30,
            "BaseXPGain": 2,
            "CraftingTable": "Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Mortared Stone Table",
            "NumberOfVariants": 4,
            "SkillNeeds": [
                {
                    "Skill": "Masonry",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "MortaredStoneTable",
                    "Name": "Mortared Stone Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Mortared Stone",
                            "Name": "",
                            "Ammount": 22,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Mortared Stone Table",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "MortaredGraniteTable",
                    "Name": "Mortared Granite Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Mortared Granite",
                            "Ammount": 22,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Mortared Granite Table",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "MortaredLimestoneTable",
                    "Name": "Mortared Limestone Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Mortared Limestone",
                            "Ammount": 22,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Mortared Limestone Table",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "MortaredSandstoneTable",
                    "Name": "Mortared Sandstone Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Mortared Sandstone",
                            "Ammount": 22,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Mortared Sandstone Table",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Charred Mushrooms",
            "Untranslated": "Charred Mushrooms Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 15,
            "BaseXPGain": 0.5,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Charred Mushrooms",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 0
                }
            ],
            "Variants": [
                {
                    "Key": "CharredMushrooms",
                    "Name": "Charred Mushrooms",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fungus",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Charred Mushrooms",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Culinary Research Paper Basic Meat",
            "Untranslated": "Culinary Research Paper Basic Meat Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 30,
            "BaseXPGain": 0.5,
            "CraftingTable": "Research Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Culinary Research Paper Basic Meat",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Hunting",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CulinaryResearchPaperBasicMeat",
                    "Name": "Culinary Research Paper Basic Meat",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Dried Meat",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Culinary Research Paper Basic",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Bear S U P R E M E",
            "Untranslated": "Bear S U P R E M E Recipe",
            "BaseCraftTime": 11.2,
            "BaseLaborCost": 65,
            "BaseXPGain": 1,
            "CraftingTable": "Stove",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Bear S U P R E M E",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Cooking",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "BearSUPREME",
                    "Name": "Bear S U P R E M E",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Prime Cut",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Vegetable Medley",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Meat Stock",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Infused Oil",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Bear S U P R E M E",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Crushed Copper Lv2",
            "Untranslated": "Crushed Copper Lv2 Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 90,
            "BaseXPGain": 0.5,
            "CraftingTable": "Stamp Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Crushed Copper Lv2",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CrushedCopperLv2",
                    "Name": "Crushed Copper Lv2",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Ore",
                            "Ammount": 20,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Crushed Copper Ore",
                            "Ammount": 4
                        },
                        {
                            "Name": "Crushed Granite",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Clean Large Fish",
            "Untranslated": "Clean Large Fish Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 20,
            "BaseXPGain": 1,
            "CraftingTable": "Fishery",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Clean Large Fish",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Hunting",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CleanLargeFish",
                    "Name": "Clean Large Fish",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Large Fish",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Raw Fish",
                            "Ammount": 4
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Grassland Salad",
            "Untranslated": "Grassland Salad Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Cast Iron Stove",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Grassland Salad",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Cooking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "GrasslandSalad",
                    "Name": "Grassland Salad",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Corn",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Tomato",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Beet",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Basic Salad",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Fish Stew",
            "Untranslated": "Fish Stew Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Fish Stew",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "FishStew",
                    "Name": "Fish Stew",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Charred Fish",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Flour",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fat",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Meaty Stew",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Shark Fillet Soup",
            "Untranslated": "Shark Fillet Soup Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Cast Iron Stove",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Shark Fillet Soup",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Cooking",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "SharkFilletSoup",
                    "Name": "Shark Fillet Soup",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Meat Stock",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Blue Shark",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Shark Fillet Soup",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Campfire Roast",
            "Untranslated": "Campfire Roast Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Campfire Roast",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "CampfireRoast",
                    "Name": "Campfire Roast",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Raw Roast",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Campfire Roast",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Towel Rack",
            "Untranslated": "Towel Rack Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 60,
            "BaseXPGain": 2,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Towel Rack",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "TowelRack",
                    "Name": "Towel Rack",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 40,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Towel Rack",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Saw Boards",
            "Untranslated": "Saw Boards Recipe",
            "BaseCraftTime": 0.22399999,
            "BaseLaborCost": 20,
            "BaseXPGain": 0.5,
            "CraftingTable": "Sawmill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Saw Boards",
            "NumberOfVariants": 3,
            "SkillNeeds": [
                {
                    "Skill": "Logging",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "SawBoards",
                    "Name": "Saw Boards",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Board",
                            "Ammount": 3
                        }
                    ]
                },
                {
                    "Key": "SawHardwoodBoards",
                    "Name": "Saw Hardwood Boards",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hardwood",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Hardwood Board",
                            "Ammount": 3
                        }
                    ]
                },
                {
                    "Key": "SawSoftwoodBoards",
                    "Name": "Saw Softwood Boards",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Softwood",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Softwood Board",
                            "Ammount": 3
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Processed Iron Oxide",
            "Untranslated": "Processed Iron Oxide Recipe",
            "BaseCraftTime": 0.28,
            "BaseLaborCost": 65,
            "BaseXPGain": 0.5,
            "CraftingTable": "Industrial Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Processed Iron Oxide",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "ProcessedIronOxide",
                    "Name": "Processed Iron Oxide",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Crushed Iron Ore",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Iron Oxide",
                            "Ammount": 20
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Taro Fries",
            "Untranslated": "Taro Fries Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Cast Iron Stove",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Taro Fries",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Cooking",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "TaroFries",
                    "Name": "Taro Fries",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Taro Root",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Oil",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Taro Fries",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Huckleberry Extract",
            "Untranslated": "Huckleberry Extract Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Huckleberry Extract",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "HuckleberryExtract",
                    "Name": "Huckleberry Extract",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Huckleberries",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Huckleberry Extract",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wooden Wall Torch Stand",
            "Untranslated": "Wooden Wall Torch Stand Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 10,
            "BaseXPGain": 1,
            "CraftingTable": "Workbench",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Wooden Wall Torch Stand",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "WoodenWallTorchStand",
                    "Name": "Wooden Wall Torch Stand",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plant Fibers",
                            "Ammount": 15,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wooden Wall Torch Stand",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Processed Rice Flour",
            "Untranslated": "Processed Rice Flour Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 100,
            "BaseXPGain": 0.5,
            "CraftingTable": "Industrial Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Processed Rice Flour",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "ProcessedRiceFlour",
                    "Name": "Processed Rice Flour",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Rice",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Rice Flour",
                            "Ammount": 6
                        },
                        {
                            "Name": "Cereal Germ",
                            "Ammount": 5
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Square Pot",
            "Untranslated": "Square Pot Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 45,
            "BaseXPGain": 1,
            "CraftingTable": "Pottery Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Square Pot",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Pottery",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "PlanterPotSquare",
                    "Name": "Square Pot",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Clay",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Square Pot",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Crushed Mortared Stone",
            "Untranslated": "Crushed Mortared Stone Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 90,
            "BaseXPGain": 0.5,
            "CraftingTable": "Stamp Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Crushed Mortared Stone",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "CrushedMortaredStone",
                    "Name": "Crushed Mortared Stone",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Mortared Stone",
                            "Name": "",
                            "Ammount": 12,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Crushed Mixed Rock",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Crushed Coal Lv3",
            "Untranslated": "Crushed Coal Lv3 Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 90,
            "BaseXPGain": 0.5,
            "CraftingTable": "Jaw Crusher",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Crushed Coal Lv3",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "CrushedCoalLv3",
                    "Name": "Crushed Coal Lv3",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Coal",
                            "Ammount": 20,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Crushed Coal",
                            "Ammount": 5
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wheat Mortar",
            "Untranslated": "Wheat Mortar Recipe",
            "BaseCraftTime": 0.14,
            "BaseLaborCost": 25,
            "BaseXPGain": 0.5,
            "CraftingTable": "Bakery Oven",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Wheat Mortar",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Masonry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "WheatMortar",
                    "Name": "Wheat Mortar",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wheat",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Mortar",
                            "Ammount": 3
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Campfire Animal Tiny",
            "Untranslated": "Campfire Animal Tiny Recipe",
            "BaseCraftTime": 1.12,
            "BaseLaborCost": 25,
            "BaseXPGain": 0.5,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Campfire Animal Tiny",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 0
                }
            ],
            "Variants": [
                {
                    "Key": "CampfireAnimalTiny",
                    "Name": "Campfire Animal Tiny",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Tiny Carcass",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Charred Meat",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Cornmeal",
            "Untranslated": "Cornmeal Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 15,
            "BaseXPGain": 0.5,
            "CraftingTable": "Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Cornmeal",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Cornmeal",
                    "Name": "Cornmeal",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Corn",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Cornmeal",
                            "Ammount": 1
                        },
                        {
                            "Name": "Cereal Germ",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Root Campfire Salad",
            "Untranslated": "Root Campfire Salad Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Root Campfire Salad",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "RootCampfireSalad",
                    "Name": "Root Campfire Salad",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Camas Bulb",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Taro Root",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Greens",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Root Campfire Salad",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Weave Nylon Curtains",
            "Untranslated": "Weave Nylon Curtains Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 60,
            "BaseXPGain": 1.5,
            "CraftingTable": "Automatic Loom",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Weave Nylon Curtains",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "WeaveNylonCurtains",
                    "Name": "Weave Nylon Curtains",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nylon Fabric",
                            "Ammount": 3,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nylon Thread",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Nylon Curtains",
                            "Ammount": 4
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Charred Fireweed Shoots",
            "Untranslated": "Charred Fireweed Shoots Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 15,
            "BaseXPGain": 0.5,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Charred Fireweed Shoots",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 0
                }
            ],
            "Variants": [
                {
                    "Key": "CharredFireweedShoots",
                    "Name": "Charred Fireweed Shoots",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Fireweed Shoots",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Charred Fireweed Shoots",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Exotic Fruit Salad",
            "Untranslated": "Exotic Fruit Salad Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Cast Iron Stove",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Exotic Fruit Salad",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Cooking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "ExoticFruitSalad",
                    "Name": "Exotic Fruit Salad",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Prickly Pear Fruit",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Pumpkin",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Fruit Salad",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Baked Mortar",
            "Untranslated": "Baked Mortar Recipe",
            "BaseCraftTime": 0.14,
            "BaseLaborCost": 60,
            "BaseXPGain": 0.5,
            "CraftingTable": "Bakery Oven",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Baked Mortar",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Masonry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "BakedMortar",
                    "Name": "Baked Mortar",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wood Pulp",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Mortar",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Fried Hare Haunches",
            "Untranslated": "Fried Hare Haunches Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 65,
            "BaseXPGain": 1,
            "CraftingTable": "Stove",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Fried Hare Haunches",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Cooking",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "FriedHareHaunches",
                    "Name": "Fried Hare Haunches",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Flour",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Prepared Meat",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Oil",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Fried Hare Haunches",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Concentrate Copper Lv2",
            "Untranslated": "Concentrate Copper Lv2 Recipe",
            "BaseCraftTime": 1.12,
            "BaseLaborCost": 180,
            "BaseXPGain": 1,
            "CraftingTable": "Froth Floatation Cell",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Concentrate Copper Lv2",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "ConcentrateCopperLv2",
                    "Name": "Concentrate Copper Lv2",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Crushed Copper Ore",
                            "Ammount": 7,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Copper Concentrate",
                            "Ammount": 2
                        },
                        {
                            "Name": "Wet Tailings",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Mixed Vegetable Medley",
            "Untranslated": "Mixed Vegetable Medley Recipe",
            "BaseCraftTime": 1.12,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Cast Iron Stove",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Mixed Vegetable Medley",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Cooking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "MixedVegetableMedley",
                    "Name": "Mixed Vegetable Medley",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Corn",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Camas Bulb",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Vegetable Medley",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Boiled Grains",
            "Untranslated": "Boiled Grains Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 15,
            "BaseXPGain": 0.5,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Boiled Grains",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 0
                }
            ],
            "Variants": [
                {
                    "Key": "BoiledGrains",
                    "Name": "Boiled Grains",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Grain",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Boiled Grains",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Butcher Medium Animal",
            "Untranslated": "Butcher Medium Animal Recipe",
            "BaseCraftTime": 2.1,
            "BaseLaborCost": 50,
            "BaseXPGain": 4,
            "CraftingTable": "Butchery Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Butcher Medium Animal",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Butchery",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "ButcherMediumAnimal",
                    "Name": "Butcher Medium Animal",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Medium Leather Carcass",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Raw Meat",
                            "Ammount": 5
                        },
                        {
                            "Name": "Leather Hide",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Processed Charcoal Powder",
            "Untranslated": "Processed Charcoal Powder Recipe",
            "BaseCraftTime": 0.28,
            "BaseLaborCost": 65,
            "BaseXPGain": 0.5,
            "CraftingTable": "Industrial Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Processed Charcoal Powder",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "ProcessedCharcoalPowder",
                    "Name": "Processed Charcoal Powder",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Charcoal",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Charcoal Powder",
                            "Ammount": 12
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Clean Moon Jellyfish",
            "Untranslated": "Clean Moon Jellyfish Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 20,
            "BaseXPGain": 1,
            "CraftingTable": "Fishery",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Clean Moon Jellyfish",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Hunting",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CleanMoonJellyfish",
                    "Name": "Clean Moon Jellyfish",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Moon Jellyfish",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Raw Fish",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Processed Sunflower Oil",
            "Untranslated": "Processed Sunflower Oil Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 100,
            "BaseXPGain": 0.5,
            "CraftingTable": "Industrial Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Processed Sunflower Oil",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "ProcessedSunflowerOil",
                    "Name": "Processed Sunflower Oil",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Sunflower Seed",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Oil",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Worldly Donut",
            "Untranslated": "Worldly Donut Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Bakery Oven",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Worldly Donut",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Baking",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "WorldlyDonut",
                    "Name": "Worldly Donut",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Sugar",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Pastry Dough",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Oil",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Worldly Donut",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Easel",
            "Untranslated": "Easel Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 160,
            "BaseXPGain": 1,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Easel",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "Easel",
                    "Name": "Easel",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Canvas",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Easel",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Lathe",
            "Untranslated": "Lathe Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 150,
            "BaseXPGain": 20,
            "CraftingTable": "Machinist Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Lathe",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Lathe",
                    "Name": "Lathe",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Wheel",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Plate",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Lathe",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Concentrate Dry Iron",
            "Untranslated": "Concentrate Dry Iron Recipe",
            "BaseCraftTime": 1.6800001,
            "BaseLaborCost": 120,
            "BaseXPGain": 1,
            "CraftingTable": "Screening Machine",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Concentrate Dry Iron",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "ConcentrateDryIron",
                    "Name": "Concentrate Dry Iron",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Crushed Iron Ore",
                            "Ammount": 3,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Iron Concentrate",
                            "Ammount": 1
                        },
                        {
                            "Name": "Tailings",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Processed Camas Paste",
            "Untranslated": "Processed Camas Paste Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 100,
            "BaseXPGain": 0.5,
            "CraftingTable": "Industrial Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Processed Camas Paste",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "ProcessedCamasPaste",
                    "Name": "Processed Camas Paste",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Camas Bulb",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Camas Paste",
                            "Ammount": 6
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Crispy Bacon",
            "Untranslated": "Crispy Bacon Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Cast Iron Stove",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Crispy Bacon",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Cooking",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "CrispyBacon",
                    "Name": "Crispy Bacon",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Raw Bacon",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Crispy Bacon",
                            "Ammount": 1
                        },
                        {
                            "Name": "Tallow",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Compost Spoiled Food",
            "Untranslated": "Compost Spoiled Food Recipe",
            "BaseCraftTime": 1.6800001,
            "BaseLaborCost": 20,
            "BaseXPGain": 0.5,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Compost Spoiled Food",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Fertilizers",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "CompostSpoiledFood",
                    "Name": "Compost Spoiled Food",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Spoiled Food",
                            "Ammount": 6,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Dirt",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Compost Fertilizer",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Butcher Tiny Leather Animal",
            "Untranslated": "Butcher Tiny Leather Animal Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Butchery Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Butcher Tiny Leather Animal",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Butchery",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "ButcherTinyLeatherAnimal",
                    "Name": "Butcher Tiny Leather Animal",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Tiny Leather Carcass",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Raw Meat",
                            "Ammount": 1
                        },
                        {
                            "Name": "Leather Hide",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Camas Paste",
            "Untranslated": "Camas Paste Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 15,
            "BaseXPGain": 0.5,
            "CraftingTable": "Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Camas Paste",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "CamasPaste",
                    "Name": "Camas Paste",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Camas Bulb",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Camas Paste",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Weave Cotton Curtains",
            "Untranslated": "Weave Cotton Curtains Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 60,
            "BaseXPGain": 1.5,
            "CraftingTable": "Automatic Loom",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Weave Cotton Curtains",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "WeaveCottonCurtains",
                    "Name": "Weave Cotton Curtains",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Thread",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Cotton Curtains",
                            "Ammount": 4
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Phad Thai",
            "Untranslated": "Phad Thai Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Cast Iron Stove",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Phad Thai",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Cooking",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "PhadThai",
                    "Name": "Phad Thai",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Rice Noodles",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Prepared Meat",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Bean Sprout",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Sugar",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Acorn",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Oil",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Phad Thai",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Shuck Clams",
            "Untranslated": "Shuck Clams Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Fishery",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Shuck Clams",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Hunting",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "ShuckClams",
                    "Name": "Shuck Clams",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Clam",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Raw Fish",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Exotic Vegetable Medley",
            "Untranslated": "Exotic Vegetable Medley Recipe",
            "BaseCraftTime": 1.12,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Cast Iron Stove",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Exotic Vegetable Medley",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Cooking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "ExoticVegetableMedley",
                    "Name": "Exotic Vegetable Medley",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Beans",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Tomato",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Beet",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Vegetable Medley",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Sunflower Oil",
            "Untranslated": "Sunflower Oil Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 20,
            "BaseXPGain": 0.5,
            "CraftingTable": "Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Sunflower Oil",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "SunflowerOil",
                    "Name": "Sunflower Oil",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Sunflower Seed",
                            "Ammount": 16,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Oil",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Weave Linen Canvas",
            "Untranslated": "Weave Linen Canvas Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 60,
            "BaseXPGain": 1,
            "CraftingTable": "Automatic Loom",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Weave Linen Canvas",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "WeaveLinenCanvas",
                    "Name": "Weave Linen Canvas",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Linen Fabric",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Canvas",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Baked Agave",
            "Untranslated": "Baked Agave Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Bakery Oven",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Baked Agave",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Baking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "BakedAgave",
                    "Name": "Baked Agave",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Agave Leaves",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Baked Agave",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Sun Cheese",
            "Untranslated": "Sun Cheese Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 15,
            "BaseXPGain": 0.5,
            "CraftingTable": "Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Sun Cheese",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "SunCheese",
                    "Name": "Sun Cheese",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Sunflower Seed",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Yeast",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Rice",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Oil",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Sun Cheese",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Recycle Hewn Log",
            "Untranslated": "Recycle Hewn Log Recipe",
            "BaseCraftTime": 0.28,
            "BaseLaborCost": 20,
            "BaseXPGain": 0.1,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Recycle Hewn Log",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Logging",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "RecycleHewnLog",
                    "Name": "Recycle Hewn Log",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wood Pulp",
                            "Ammount": 10
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Processed Yeast",
            "Untranslated": "Processed Yeast Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 100,
            "BaseXPGain": 1,
            "CraftingTable": "Industrial Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Processed Yeast",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "ProcessedYeast",
                    "Name": "Processed Yeast",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Sugar",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Yeast",
                            "Ammount": 6
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Fish Stock",
            "Untranslated": "Fish Stock Recipe",
            "BaseCraftTime": 11.2,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Cast Iron Stove",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Fish Stock",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Cooking",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "FishStock",
                    "Name": "Fish Stock",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Raw Fish",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Meat Stock",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Dirt Ramp Basic",
            "Untranslated": "Dirt Ramp Basic Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 20,
            "BaseXPGain": 1,
            "CraftingTable": "Workbench",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Dirt Ramp Basic",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "DirtRampBasic",
                    "Name": "Dirt Ramp Basic",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Dirt",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Dirt Ramp",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Processed Cottonseed Oil",
            "Untranslated": "Processed Cottonseed Oil Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 75,
            "BaseXPGain": 0.5,
            "CraftingTable": "Industrial Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Processed Cottonseed Oil",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "ProcessedCottonseedOil",
                    "Name": "Processed Cottonseed Oil",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Seed",
                            "Ammount": 13,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Oil",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Processsed Flaxseed Oil",
            "Untranslated": "Processsed Flaxseed Oil Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 100,
            "BaseXPGain": 0.5,
            "CraftingTable": "Industrial Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Processsed Flaxseed Oil",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "ProcesssedFlaxseedOil",
                    "Name": "Processsed Flaxseed Oil",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Flax Seed",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Flaxseed Oil",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Decorative Glass Buoy Blue",
            "Untranslated": "Decorative Glass Buoy Blue Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 120,
            "BaseXPGain": 0.5,
            "CraftingTable": "Glassworks",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Decorative Glass Buoy Blue",
            "NumberOfVariants": 5,
            "SkillNeeds": [
                {
                    "Skill": "Glassworking",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "DecorativeGlassBuoyBlue",
                    "Name": "Decorative Glass Buoy Blue",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Hemp Mooring Rope",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Glass",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Decorative Glass Buoy Blue",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "DecorativeGlassBuoyRed",
                    "Name": "Decorative Glass Buoy Red",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Hemp Mooring Rope",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Glass",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Decorative Glass Buoy Red",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "DecorativeGlassBuoyGreen",
                    "Name": "Decorative Glass Buoy Green",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Hemp Mooring Rope",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Glass",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Decorative Glass Buoy Green",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "DecorativeGlassBuoyViolet",
                    "Name": "Decorative Glass Buoy Violet",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Hemp Mooring Rope",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Glass",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Decorative Glass Buoy Violet",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "DecorativeGlassBuoyYellow",
                    "Name": "Decorative Glass Buoy Yellow",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Hemp Mooring Rope",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Glass",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Decorative Glass Buoy Yellow",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Scrap Tool",
            "Untranslated": "Scrap Tool Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 120,
            "BaseXPGain": 1,
            "CraftingTable": "Workbench",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Scrap Tool",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "ScrapTool",
                    "Name": "Scrap Tool",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Tool",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Garbage",
                            "Ammount": 0.1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Smelt Iron",
            "Untranslated": "Smelt Iron Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 60,
            "BaseXPGain": 2,
            "CraftingTable": "Bloomery",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Smelt Iron",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Smelting",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "SmeltIron",
                    "Name": "Smelt Iron",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Concentrate",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Clay Mold",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Iron Bar",
                            "Ammount": 6
                        },
                        {
                            "Name": "Slag",
                            "Ammount": 2
                        },
                        {
                            "Name": "Clay Mold",
                            "Ammount": 3
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Processed Oil",
            "Untranslated": "Processed Oil Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 75,
            "BaseXPGain": 0.5,
            "CraftingTable": "Industrial Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Processed Oil",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "ProcessedOil",
                    "Name": "Processed Oil",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cereal Germ",
                            "Ammount": 25,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Oil",
                            "Ammount": 4
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Smelt Copper",
            "Untranslated": "Smelt Copper Recipe",
            "BaseCraftTime": 8.4,
            "BaseLaborCost": 60,
            "BaseXPGain": 2,
            "CraftingTable": "Bloomery",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Smelt Copper",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Smelting",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "SmeltCopper",
                    "Name": "Smelt Copper",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Concentrate",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Clay Mold",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Copper Bar",
                            "Ammount": 6
                        },
                        {
                            "Name": "Slag",
                            "Ammount": 2
                        },
                        {
                            "Name": "Clay Mold",
                            "Ammount": 3
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Melting Fat",
            "Untranslated": "Melting Fat Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 15,
            "BaseXPGain": 0.5,
            "CraftingTable": "Bakery Oven",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Melting Fat",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Baking",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "MeltingFat",
                    "Name": "Melting Fat",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Scrap Meat",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Tallow",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Mixed Fruit Salad",
            "Untranslated": "Mixed Fruit Salad Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Cast Iron Stove",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Mixed Fruit Salad",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Cooking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "MixedFruitSalad",
                    "Name": "Mixed Fruit Salad",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Huckleberries",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Beet",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Fruit Salad",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Sand Concentrate Lv2",
            "Untranslated": "Sand Concentrate Lv2 Recipe",
            "BaseCraftTime": 0.97999996,
            "BaseLaborCost": 100,
            "BaseXPGain": 1,
            "CraftingTable": "Screening Machine",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Sand Concentrate Lv2",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "SandConcentrateLv2",
                    "Name": "Sand Concentrate Lv2",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Silica",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Sand",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Processed Fish Oil",
            "Untranslated": "Processed Fish Oil Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 75,
            "BaseXPGain": 0.5,
            "CraftingTable": "Industrial Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Processed Fish Oil",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "ProcessedFishOil",
                    "Name": "Processed Fish Oil",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Raw Fish",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Oil",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Charred Mortar",
            "Untranslated": "Charred Mortar Recipe",
            "BaseCraftTime": 0.22399999,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Charred Mortar",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "CharredMortar",
                    "Name": "Charred Mortar",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Natural Fiber",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Mortar",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Processed Sugar",
            "Untranslated": "Processed Sugar Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 100,
            "BaseXPGain": 0.5,
            "CraftingTable": "Industrial Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Processed Sugar",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "ProcessedSugar",
                    "Name": "Processed Sugar",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Huckleberries",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Sugar",
                            "Ammount": 4
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Concentrate Dry Iron Lv2",
            "Untranslated": "Concentrate Dry Iron Lv2 Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 150,
            "BaseXPGain": 1,
            "CraftingTable": "Sensor Based Belt Sorter",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Concentrate Dry Iron Lv2",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "ConcentrateDryIronLv2",
                    "Name": "Concentrate Dry Iron Lv2",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Crushed Iron Ore",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Iron Concentrate",
                            "Ammount": 2
                        },
                        {
                            "Name": "Tailings",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Clean Pacific Sardine",
            "Untranslated": "Clean Pacific Sardine Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 20,
            "BaseXPGain": 1,
            "CraftingTable": "Fishery",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Clean Pacific Sardine",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Hunting",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CleanPacificSardine",
                    "Name": "Clean Pacific Sardine",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Pacific Sardine",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Raw Fish",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Crushed Gneiss Lv3",
            "Untranslated": "Crushed Gneiss Lv3 Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 150,
            "BaseXPGain": 0.5,
            "CraftingTable": "Jaw Crusher",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Crushed Gneiss Lv3",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "CrushedGneissLv3",
                    "Name": "Crushed Gneiss Lv3",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gneiss",
                            "Ammount": 20,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Crushed Gneiss",
                            "Ammount": 5
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Dry Meat",
            "Untranslated": "Dry Meat Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 25,
            "BaseXPGain": 0.5,
            "CraftingTable": "Fish Rack",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Dry Meat",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Hunting",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "DryMeat",
                    "Name": "Dry Meat",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Raw Meat",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Dried Meat",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Incinerate Garbage",
            "Untranslated": "Incinerate Garbage Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 500,
            "BaseXPGain": 1,
            "CraftingTable": "Blast Furnace",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Incinerate Garbage",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "IncinerateGarbage",
                    "Name": "Incinerate Garbage",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Garbage",
                            "Ammount": 4,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Dirt",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Crushed Shale Lv3",
            "Untranslated": "Crushed Shale Lv3 Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 70,
            "BaseXPGain": 0.5,
            "CraftingTable": "Jaw Crusher",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Crushed Shale Lv3",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "CrushedShaleLv3",
                    "Name": "Crushed Shale Lv3",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Shale",
                            "Ammount": 20,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Crushed Shale",
                            "Ammount": 5
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Rainforest Salad",
            "Untranslated": "Rainforest Salad Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Cast Iron Stove",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Rainforest Salad",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Cooking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "RainforestSalad",
                    "Name": "Rainforest Salad",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Taro Root",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Bolete Mushrooms",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Papaya",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Basic Salad",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Boards",
            "Untranslated": "Boards Recipe",
            "BaseCraftTime": 0.28,
            "BaseLaborCost": 40,
            "BaseXPGain": 1,
            "CraftingTable": "Workbench",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Boards",
            "NumberOfVariants": 3,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "Boards",
                    "Name": "Boards",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Board",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "HardwoodBoards",
                    "Name": "Hardwood Boards",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hardwood",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Hardwood Board",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SoftwoodBoards",
                    "Name": "Softwood Boards",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Softwood",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Softwood Board",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Spin Wool Yarn",
            "Untranslated": "Spin Wool Yarn Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 60,
            "BaseXPGain": 0.5,
            "CraftingTable": "Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Spin Wool Yarn",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "SpinWoolYarn",
                    "Name": "Spin Wool Yarn",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Shorn Wool",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wool Yarn",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Campfire Animal Small",
            "Untranslated": "Campfire Animal Small Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 25,
            "BaseXPGain": 0.5,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Campfire Animal Small",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 0
                }
            ],
            "Variants": [
                {
                    "Key": "CampfireAnimalSmall",
                    "Name": "Campfire Animal Small",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Small Carcass",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Charred Meat",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Weave Large Cotton Bunting Red",
            "Untranslated": "Weave Large Cotton Bunting Red Recipe",
            "BaseCraftTime": 2.1,
            "BaseLaborCost": 240,
            "BaseXPGain": 2,
            "CraftingTable": "Automatic Loom",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Weave Large Cotton Bunting Red",
            "NumberOfVariants": 4,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "WeaveLargeCottonBuntingRed",
                    "Name": "Weave Large Cotton Bunting Red",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Cotton Bunting Red",
                            "Ammount": 2
                        }
                    ]
                },
                {
                    "Key": "WeaveLargeCottonBuntingRegal",
                    "Name": "Weave Large Cotton Bunting Regal",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Cotton Bunting Regal",
                            "Ammount": 2
                        }
                    ]
                },
                {
                    "Key": "WeaveLargeCottonBuntingVibrant",
                    "Name": "Weave Large Cotton Bunting Vibrant",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Cotton Bunting Vibrant",
                            "Ammount": 2
                        }
                    ]
                },
                {
                    "Key": "WeaveLargeCottonBuntingFestive",
                    "Name": "Weave Large Cotton Bunting Festive",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Cotton Bunting Festive",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Smelt Gold",
            "Untranslated": "Smelt Gold Recipe",
            "BaseCraftTime": 8.4,
            "BaseLaborCost": 60,
            "BaseXPGain": 2,
            "CraftingTable": "Bloomery",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Smelt Gold",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Smelting",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "SmeltGold",
                    "Name": "Smelt Gold",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Concentrate",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Clay Mold",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Gold Bar",
                            "Ammount": 6
                        },
                        {
                            "Name": "Slag",
                            "Ammount": 2
                        },
                        {
                            "Name": "Clay Mold",
                            "Ammount": 3
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Fish N Chips",
            "Untranslated": "Fish N Chips Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 45,
            "BaseXPGain": 1,
            "CraftingTable": "Kitchen",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Fish N Chips",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Cooking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "FishNChips",
                    "Name": "Fish N Chips",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Raw Fish",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Taro Fries",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Flour",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Oil",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Fish N Chips",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Crushed Shale Lv2",
            "Untranslated": "Crushed Shale Lv2 Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 50,
            "BaseXPGain": 0.5,
            "CraftingTable": "Stamp Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Crushed Shale Lv2",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CrushedShaleLv2",
                    "Name": "Crushed Shale Lv2",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Shale",
                            "Ammount": 20,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Crushed Shale",
                            "Ammount": 5
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Refrigerator",
            "Untranslated": "Refrigerator Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 120,
            "BaseXPGain": 6,
            "CraftingTable": "Robotic Assembly Line",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Refrigerator",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Industry",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "Refrigerator",
                    "Name": "Refrigerator",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Plate",
                            "Ammount": 16,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Basic Circuit",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Radiator",
                            "Ammount": 3,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Refrigerator",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Tanning Leather",
            "Untranslated": "Tanning Leather Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Butchery Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Tanning Leather",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Butchery",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "TanningLeather",
                    "Name": "Tanning Leather",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Fur Pelt",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Leather Hide",
                            "Ammount": 1
                        },
                        {
                            "Name": "Tallow",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Processed White Powder",
            "Untranslated": "Processed White Powder Recipe",
            "BaseCraftTime": 0.28,
            "BaseLaborCost": 65,
            "BaseXPGain": 0.5,
            "CraftingTable": "Industrial Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Processed White Powder",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "ProcessedWhitePowder",
                    "Name": "Processed White Powder",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Trillium Flower",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "White Powder",
                            "Ammount": 14
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Processed Bean Paste",
            "Untranslated": "Processed Bean Paste Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 100,
            "BaseXPGain": 0.5,
            "CraftingTable": "Industrial Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Processed Bean Paste",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "ProcessedBeanPaste",
                    "Name": "Processed Bean Paste",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Beans",
                            "Ammount": 18,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Bean Paste",
                            "Ammount": 4
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Crushed Gneiss Lv2",
            "Untranslated": "Crushed Gneiss Lv2 Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 120,
            "BaseXPGain": 0.5,
            "CraftingTable": "Stamp Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Crushed Gneiss Lv2",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CrushedGneissLv2",
                    "Name": "Crushed Gneiss Lv2",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gneiss",
                            "Ammount": 20,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Crushed Gneiss",
                            "Ammount": 5
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Shale Brick",
            "Untranslated": "Shale Brick Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 200,
            "BaseXPGain": 1,
            "CraftingTable": "Pottery Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Shale Brick",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Pottery",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "ShaleBrick",
                    "Name": "Shale Brick",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Sand",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Crushed Shale",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Clay",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wooden Mold",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wet Brick",
                            "Ammount": 8
                        },
                        {
                            "Name": "Wooden Mold",
                            "Ammount": 4
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Processed Beet Sugar",
            "Untranslated": "Processed Beet Sugar Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 100,
            "BaseXPGain": 0.5,
            "CraftingTable": "Industrial Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Processed Beet Sugar",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "ProcessedBeetSugar",
                    "Name": "Processed Beet Sugar",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Beet",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Sugar",
                            "Ammount": 12
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Charcoal Steel",
            "Untranslated": "Charcoal Steel Recipe",
            "BaseCraftTime": 2.1,
            "BaseLaborCost": 60,
            "BaseXPGain": 2,
            "CraftingTable": "Blast Furnace",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Charcoal Steel",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Smelting",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CharcoalSteel",
                    "Name": "Charcoal Steel",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Concentrate",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ceramic Mold",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Quicklime",
                            "Ammount": 4,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Charcoal",
                            "Ammount": 8,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Steel Bar",
                            "Ammount": 4
                        },
                        {
                            "Name": "Slag",
                            "Ammount": 4
                        },
                        {
                            "Name": "Ceramic Mold",
                            "Ammount": 3
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Campfire Fish Large",
            "Untranslated": "Campfire Fish Large Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 25,
            "BaseXPGain": 0.5,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Campfire Fish Large",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 0
                }
            ],
            "Variants": [
                {
                    "Key": "CampfireFishLarge",
                    "Name": "Campfire Fish Large",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Large Fish",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Charred Fish",
                            "Ammount": 5
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Crushed Granite Lv3",
            "Untranslated": "Crushed Granite Lv3 Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 120,
            "BaseXPGain": 0.5,
            "CraftingTable": "Jaw Crusher",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Crushed Granite Lv3",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "CrushedGraniteLv3",
                    "Name": "Crushed Granite Lv3",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Granite",
                            "Ammount": 20,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Crushed Granite",
                            "Ammount": 5
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Dry Fish",
            "Untranslated": "Dry Fish Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 25,
            "BaseXPGain": 0.5,
            "CraftingTable": "Fish Rack",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Dry Fish",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Hunting",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "DryFish",
                    "Name": "Dry Fish",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Raw Fish",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Dried Fish",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Masonry Mortar",
            "Untranslated": "Masonry Mortar Recipe",
            "BaseCraftTime": 0.28,
            "BaseLaborCost": 25,
            "BaseXPGain": 0.5,
            "CraftingTable": "Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Masonry Mortar",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Masonry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "MasonryMortar",
                    "Name": "Masonry Mortar",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Sand",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Mortar",
                            "Ammount": 3
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Culinary Research Paper Advanced Meat",
            "Untranslated": "Culinary Research Paper Advanced Meat Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 120,
            "BaseXPGain": 0.5,
            "CraftingTable": "Research Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Culinary Research Paper Advanced Meat",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Baking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CulinaryResearchPaperAdvancedMeat",
                    "Name": "Culinary Research Paper Advanced Meat",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Baked Meat",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Simmered Meat",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Culinary Research Paper Advanced",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Cotton Canvas",
            "Untranslated": "Cotton Canvas Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 60,
            "BaseXPGain": 1,
            "CraftingTable": "Loom",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Cotton Canvas",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "CottonCanvas",
                    "Name": "Cotton Canvas",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Canvas",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Hearty Hometown Pizza",
            "Untranslated": "Hearty Hometown Pizza Recipe",
            "BaseCraftTime": 4.2,
            "BaseLaborCost": 45,
            "BaseXPGain": 1,
            "CraftingTable": "Bakery Oven",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Hearty Hometown Pizza",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Baking",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "HeartyHometownPizza",
                    "Name": "Hearty Hometown Pizza",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leavened Dough",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Crimini Mushrooms",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Raw Sausage",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Sun Cheese",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Tomato",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Hearty Hometown Pizza",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Processed Creosote",
            "Untranslated": "Processed Creosote Recipe",
            "BaseCraftTime": 0.28,
            "BaseLaborCost": 65,
            "BaseXPGain": 0.5,
            "CraftingTable": "Industrial Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Processed Creosote",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "ProcessedCreosote",
                    "Name": "Processed Creosote",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Creosote Flower",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Yellow Powder",
                            "Ammount": 14
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Cast Iron Chair",
            "Untranslated": "Cast Iron Chair Recipe",
            "BaseCraftTime": 4.2,
            "BaseLaborCost": 60,
            "BaseXPGain": 2,
            "CraftingTable": "Bloomery",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Cast Iron Chair",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Smelting",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "CastIronChair",
                    "Name": "Cast Iron Chair",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Clay Mold",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Cast Iron Chair",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Crushed Slag Lv3",
            "Untranslated": "Crushed Slag Lv3 Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 150,
            "BaseXPGain": 0.5,
            "CraftingTable": "Jaw Crusher",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Crushed Slag Lv3",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "CrushedSlagLv3",
                    "Name": "Crushed Slag Lv3",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Slag",
                            "Ammount": 20,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Crushed Slag",
                            "Ammount": 5
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Grease",
            "Untranslated": "Grease Recipe",
            "BaseCraftTime": 2.1,
            "BaseLaborCost": 180,
            "BaseXPGain": 1,
            "CraftingTable": "Butchery Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Grease",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Butchery",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Grease",
                    "Name": "Grease",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Tallow",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Lubricant",
                            "Ammount": 4
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Crushed Granite Lv2",
            "Untranslated": "Crushed Granite Lv2 Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 90,
            "BaseXPGain": 0.5,
            "CraftingTable": "Stamp Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Crushed Granite Lv2",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CrushedGraniteLv2",
                    "Name": "Crushed Granite Lv2",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Granite",
                            "Ammount": 20,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Crushed Granite",
                            "Ammount": 5
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Render Fat",
            "Untranslated": "Render Fat Recipe",
            "BaseCraftTime": 1.12,
            "BaseLaborCost": 20,
            "BaseXPGain": 0.5,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Render Fat",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "RenderFat",
                    "Name": "Render Fat",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Scrap Meat",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Tallow",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Campfire Bison",
            "Untranslated": "Campfire Bison Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 25,
            "BaseXPGain": 0.5,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Campfire Bison",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 0
                }
            ],
            "Variants": [
                {
                    "Key": "CampfireBison",
                    "Name": "Campfire Bison",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Bison Carcass",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Charred Meat",
                            "Ammount": 8
                        },
                        {
                            "Name": "Tallow",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Plastic Utensils",
            "Untranslated": "Plastic Utensils Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 50,
            "BaseXPGain": 1,
            "CraftingTable": "Injection Mold Machine",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Plastic Utensils",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Oil Drilling",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "PlasticUtensils",
                    "Name": "Plastic Utensils",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plastic",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Cooking Utensils",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Cast Iron Bed",
            "Untranslated": "Cast Iron Bed Recipe",
            "BaseCraftTime": 8.4,
            "BaseLaborCost": 180,
            "BaseXPGain": 10,
            "CraftingTable": "Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Cast Iron Bed",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "CastIronBed",
                    "Name": "Cast Iron Bed",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 40,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 16,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Cast Iron Bed",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Processed Flour",
            "Untranslated": "Processed Flour Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 100,
            "BaseXPGain": 0.5,
            "CraftingTable": "Industrial Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Processed Flour",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "ProcessedFlour",
                    "Name": "Processed Flour",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wheat",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Flour",
                            "Ammount": 6
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Rainforest Fruit Salad",
            "Untranslated": "Rainforest Fruit Salad Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Cast Iron Stove",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Rainforest Fruit Salad",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Cooking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "RainforestFruitSalad",
                    "Name": "Rainforest Fruit Salad",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Papaya",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Pineapple",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Fruit Salad",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Processed Yellow Powder",
            "Untranslated": "Processed Yellow Powder Recipe",
            "BaseCraftTime": 0.28,
            "BaseLaborCost": 65,
            "BaseXPGain": 0.5,
            "CraftingTable": "Industrial Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Processed Yellow Powder",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "ProcessedYellowPowder",
                    "Name": "Processed Yellow Powder",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Sunflower",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Yellow Powder",
                            "Ammount": 14
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Geology Research Paper Modern Glass",
            "Untranslated": "Geology Research Paper Modern Glass Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 600,
            "BaseXPGain": 6,
            "CraftingTable": "Laboratory",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Geology Research Paper Modern Glass",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Glassworking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "GeologyResearchPaperModernGlass",
                    "Name": "Geology Research Paper Modern Glass",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Glass",
                            "Ammount": 30,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ink",
                            "Ammount": 4,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Paper",
                            "Ammount": 20,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Geology Research Paper Modern",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Mushroom Medley",
            "Untranslated": "Mushroom Medley Recipe",
            "BaseCraftTime": 1.12,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Cast Iron Stove",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Mushroom Medley",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Cooking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "MushroomMedley",
                    "Name": "Mushroom Medley",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Crimini Mushrooms",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cookeina Mushrooms",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Bolete Mushrooms",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Vegetable Medley",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Clean Urchins",
            "Untranslated": "Clean Urchins Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Fishery",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Clean Urchins",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Hunting",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CleanUrchins",
                    "Name": "Clean Urchins",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Urchin",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Raw Fish",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Street Sign",
            "Untranslated": "Street Sign Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 60,
            "BaseXPGain": 1,
            "CraftingTable": "Wainwright Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Street Sign",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Basic Engineering",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "StreetSign",
                    "Name": "Street Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Street Sign",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Crushed Stone Road",
            "Untranslated": "Crushed Stone Road Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 90,
            "BaseXPGain": 0.5,
            "CraftingTable": "Stamp Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Crushed Stone Road",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "CrushedStoneRoad",
                    "Name": "Crushed Stone Road",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Stone Road",
                            "Ammount": 5,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Crushed Mixed Rock",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Processed Cornmeal",
            "Untranslated": "Processed Cornmeal Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 100,
            "BaseXPGain": 0.5,
            "CraftingTable": "Industrial Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Processed Cornmeal",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "ProcessedCornmeal",
                    "Name": "Processed Cornmeal",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Corn",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Cornmeal",
                            "Ammount": 6
                        },
                        {
                            "Name": "Cereal Germ",
                            "Ammount": 12
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Butcher Bison",
            "Untranslated": "Butcher Bison Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 70,
            "BaseXPGain": 5,
            "CraftingTable": "Butchery Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Butcher Bison",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Butchery",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "ButcherBison",
                    "Name": "Butcher Bison",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Bison Carcass",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Raw Meat",
                            "Ammount": 10
                        },
                        {
                            "Name": "Leather Hide",
                            "Ammount": 2
                        },
                        {
                            "Name": "Shorn Wool",
                            "Ammount": 3
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Crushed Copper Lv3",
            "Untranslated": "Crushed Copper Lv3 Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 120,
            "BaseXPGain": 0.5,
            "CraftingTable": "Jaw Crusher",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Crushed Copper Lv3",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "CrushedCopperLv3",
                    "Name": "Crushed Copper Lv3",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Ore",
                            "Ammount": 20,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Crushed Copper Ore",
                            "Ammount": 5
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Elk Wellington",
            "Untranslated": "Elk Wellington Recipe",
            "BaseCraftTime": 4.2,
            "BaseLaborCost": 65,
            "BaseXPGain": 1,
            "CraftingTable": "Bakery Oven",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Elk Wellington",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Baking",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "ElkWellington",
                    "Name": "Elk Wellington",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leavened Dough",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Prime Cut",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Elk Wellington",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Crushed Sandstone Lv3",
            "Untranslated": "Crushed Sandstone Lv3 Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 70,
            "BaseXPGain": 0.5,
            "CraftingTable": "Jaw Crusher",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Crushed Sandstone Lv3",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "CrushedSandstoneLv3",
                    "Name": "Crushed Sandstone Lv3",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Sandstone",
                            "Ammount": 20,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Crushed Sandstone",
                            "Ammount": 5
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Weave Linen Fabric",
            "Untranslated": "Weave Linen Fabric Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 80,
            "BaseXPGain": 0.5,
            "CraftingTable": "Automatic Loom",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Weave Linen Fabric",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "WeaveLinenFabric",
                    "Name": "Weave Linen Fabric",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Linen Yarn",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Linen Fabric",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Processed Acorn Powder",
            "Untranslated": "Processed Acorn Powder Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 100,
            "BaseXPGain": 1,
            "CraftingTable": "Industrial Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Processed Acorn Powder",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "ProcessedAcornPowder",
                    "Name": "Processed Acorn Powder",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Acorn",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Acorn Powder",
                            "Ammount": 6
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Forest Salad",
            "Untranslated": "Forest Salad Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Cast Iron Stove",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Forest Salad",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Cooking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "ForestSalad",
                    "Name": "Forest Salad",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Fiddleheads",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Huckleberries",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Beans",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Basic Salad",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Processed Copper Hydroxide",
            "Untranslated": "Processed Copper Hydroxide Recipe",
            "BaseCraftTime": 0.28,
            "BaseLaborCost": 65,
            "BaseXPGain": 0.5,
            "CraftingTable": "Industrial Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Processed Copper Hydroxide",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "ProcessedCopperHydroxide",
                    "Name": "Processed Copper Hydroxide",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Crushed Copper Ore",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Copper Hydroxide",
                            "Ammount": 26
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Weave Cotton Canvas",
            "Untranslated": "Weave Cotton Canvas Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 60,
            "BaseXPGain": 1,
            "CraftingTable": "Automatic Loom",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Weave Cotton Canvas",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "WeaveCottonCanvas",
                    "Name": "Weave Cotton Canvas",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Canvas",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Laboratory",
            "Untranslated": "Laboratory Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 120,
            "BaseXPGain": 20,
            "CraftingTable": "Machinist Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Laboratory",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Laboratory",
                    "Name": "Laboratory",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Glass",
                            "Ammount": 15,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Paper",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Laboratory",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Mixed Salad",
            "Untranslated": "Mixed Salad Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Cast Iron Stove",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Mixed Salad",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Cooking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "MixedSalad",
                    "Name": "Mixed Salad",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Fiddleheads",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Tomato",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Fireweed Shoots",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Basic Salad",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Refine Tallow",
            "Untranslated": "Refine Tallow Recipe",
            "BaseCraftTime": 1.12,
            "BaseLaborCost": 20,
            "BaseXPGain": 0.5,
            "CraftingTable": "Stove",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Refine Tallow",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Cooking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "RefineTallow",
                    "Name": "Refine Tallow",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Tallow",
                            "Ammount": 18,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Oil",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Concentrate Iron Lv2",
            "Untranslated": "Concentrate Iron Lv2 Recipe",
            "BaseCraftTime": 1.12,
            "BaseLaborCost": 180,
            "BaseXPGain": 1,
            "CraftingTable": "Froth Floatation Cell",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Concentrate Iron Lv2",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "ConcentrateIronLv2",
                    "Name": "Concentrate Iron Lv2",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Crushed Iron Ore",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Iron Concentrate",
                            "Ammount": 2
                        },
                        {
                            "Name": "Wet Tailings",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Campfire Fish Small",
            "Untranslated": "Campfire Fish Small Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 25,
            "BaseXPGain": 0.5,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Campfire Fish Small",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 0
                }
            ],
            "Variants": [
                {
                    "Key": "CampfireFishSmall",
                    "Name": "Campfire Fish Small",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Small Fish",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Charred Fish",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Campfire Pacific Sardine",
            "Untranslated": "Campfire Pacific Sardine Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 25,
            "BaseXPGain": 0.5,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Campfire Pacific Sardine",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 0
                }
            ],
            "Variants": [
                {
                    "Key": "CampfirePacificSardine",
                    "Name": "Campfire Pacific Sardine",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Pacific Sardine",
                            "Ammount": 3,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Charred Fish",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Cottonseed Oil",
            "Untranslated": "Cottonseed Oil Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 40,
            "BaseXPGain": 0.5,
            "CraftingTable": "Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Cottonseed Oil",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "CottonseedOil",
                    "Name": "Cottonseed Oil",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Seed",
                            "Ammount": 16,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Oil",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Powdered Creosote",
            "Untranslated": "Powdered Creosote Recipe",
            "BaseCraftTime": 0.56,
            "BaseLaborCost": 45,
            "BaseXPGain": 0.5,
            "CraftingTable": "Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Powdered Creosote",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "PowderedCreosote",
                    "Name": "Powdered Creosote",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Creosote Flower",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Yellow Powder",
                            "Ammount": 4
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Crushed Iron Lv2",
            "Untranslated": "Crushed Iron Lv2 Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 70,
            "BaseXPGain": 0.5,
            "CraftingTable": "Stamp Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Crushed Iron Lv2",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CrushedIronLv2",
                    "Name": "Crushed Iron Lv2",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Ore",
                            "Ammount": 20,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Crushed Iron Ore",
                            "Ammount": 4
                        },
                        {
                            "Name": "Crushed Sandstone",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Particle Boards",
            "Untranslated": "Particle Boards Recipe",
            "BaseCraftTime": 0.22399999,
            "BaseLaborCost": 20,
            "BaseXPGain": 0.1,
            "CraftingTable": "Sawmill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Particle Boards",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Logging",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "ParticleBoards",
                    "Name": "Particle Boards",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wood Pulp",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Board",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Campfire Animal Medium",
            "Untranslated": "Campfire Animal Medium Recipe",
            "BaseCraftTime": 3.5,
            "BaseLaborCost": 25,
            "BaseXPGain": 0.5,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Campfire Animal Medium",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 0
                }
            ],
            "Variants": [
                {
                    "Key": "CampfireAnimalMedium",
                    "Name": "Campfire Animal Medium",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Medium Carcass",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Charred Meat",
                            "Ammount": 4
                        },
                        {
                            "Name": "Tallow",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Crushed Iron Lv3",
            "Untranslated": "Crushed Iron Lv3 Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 90,
            "BaseXPGain": 0.5,
            "CraftingTable": "Jaw Crusher",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Crushed Iron Lv3",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "CrushedIronLv3",
                    "Name": "Crushed Iron Lv3",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Ore",
                            "Ammount": 20,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Crushed Iron Ore",
                            "Ammount": 5
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Crushed Mortared Stone Lv2",
            "Untranslated": "Crushed Mortared Stone Lv2 Recipe",
            "BaseCraftTime": 0.28,
            "BaseLaborCost": 120,
            "BaseXPGain": 0.5,
            "CraftingTable": "Jaw Crusher",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Crushed Mortared Stone Lv2",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "CrushedMortaredStoneLv2",
                    "Name": "Crushed Mortared Stone Lv2",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Mortared Stone",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Crushed Mixed Rock",
                            "Ammount": 5
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Butcher Medium Wooly Animal",
            "Untranslated": "Butcher Medium Wooly Animal Recipe",
            "BaseCraftTime": 2.1,
            "BaseLaborCost": 50,
            "BaseXPGain": 4,
            "CraftingTable": "Butchery Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Butcher Medium Wooly Animal",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Butchery",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "ButcherMediumWoolyAnimal",
                    "Name": "Butcher Medium Wooly Animal",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Medium Wooly Carcass",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Raw Meat",
                            "Ammount": 5
                        },
                        {
                            "Name": "Leather Hide",
                            "Ammount": 1
                        },
                        {
                            "Name": "Shorn Wool",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Weave Wool Fabric",
            "Untranslated": "Weave Wool Fabric Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 80,
            "BaseXPGain": 0.5,
            "CraftingTable": "Automatic Loom",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Weave Wool Fabric",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "WeaveWoolFabric",
                    "Name": "Weave Wool Fabric",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wool Yarn",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wool Fabric",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Waste Filter",
            "Untranslated": "Waste Filter Recipe",
            "BaseCraftTime": 28,
            "BaseLaborCost": 480,
            "BaseXPGain": 25,
            "CraftingTable": "Assembly Line",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Waste Filter",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "WasteFilter",
                    "Name": "Waste Filter",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cement",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gearbox",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Piston",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Waste Filter",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Rice Mortar",
            "Untranslated": "Rice Mortar Recipe",
            "BaseCraftTime": 0.14,
            "BaseLaborCost": 25,
            "BaseXPGain": 0.5,
            "CraftingTable": "Bakery Oven",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Rice Mortar",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Masonry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "RiceMortar",
                    "Name": "Rice Mortar",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Rice",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Mortar",
                            "Ammount": 3
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Crushed Limestone Lv2",
            "Untranslated": "Crushed Limestone Lv2 Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 70,
            "BaseXPGain": 0.5,
            "CraftingTable": "Stamp Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Crushed Limestone Lv2",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CrushedLimestoneLv2",
                    "Name": "Crushed Limestone Lv2",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Limestone",
                            "Ammount": 20,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Crushed Limestone",
                            "Ammount": 5
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Crushed Gold Lv3",
            "Untranslated": "Crushed Gold Lv3 Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 120,
            "BaseXPGain": 0.5,
            "CraftingTable": "Jaw Crusher",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Crushed Gold Lv3",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "CrushedGoldLv3",
                    "Name": "Crushed Gold Lv3",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Ore",
                            "Ammount": 20,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Crushed Gold Ore",
                            "Ammount": 5
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Arrow Bundle",
            "Untranslated": "Arrow Bundle Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 180,
            "BaseXPGain": 0.5,
            "CraftingTable": "Fletching Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Arrow Bundle",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Hunting",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "ArrowBundle",
                    "Name": "Arrow Bundle",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Arrow",
                            "Ammount": 20
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Processed Sun Cheese",
            "Untranslated": "Processed Sun Cheese Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 100,
            "BaseXPGain": 0.5,
            "CraftingTable": "Industrial Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Processed Sun Cheese",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "ProcessedSunCheese",
                    "Name": "Processed Sun Cheese",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Sunflower Seed",
                            "Ammount": 18,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Yeast",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Rice",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Oil",
                            "Name": "",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Sun Cheese",
                            "Ammount": 4
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Processed Huckleberry Extract",
            "Untranslated": "Processed Huckleberry Extract Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 100,
            "BaseXPGain": 1,
            "CraftingTable": "Industrial Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Processed Huckleberry Extract",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "ProcessedHuckleberryExtract",
                    "Name": "Processed Huckleberry Extract",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Huckleberries",
                            "Ammount": 50,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Huckleberry Extract",
                            "Ammount": 3
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Culinary Research Paper Basic Fish",
            "Untranslated": "Culinary Research Paper Basic Fish Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 30,
            "BaseXPGain": 0.5,
            "CraftingTable": "Research Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Culinary Research Paper Basic Fish",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Hunting",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CulinaryResearchPaperBasicFish",
                    "Name": "Culinary Research Paper Basic Fish",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Dried Fish",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Culinary Research Paper Basic",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Crushed Sandstone Lv2",
            "Untranslated": "Crushed Sandstone Lv2 Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 50,
            "BaseXPGain": 0.5,
            "CraftingTable": "Stamp Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Crushed Sandstone Lv2",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CrushedSandstoneLv2",
                    "Name": "Crushed Sandstone Lv2",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Sandstone",
                            "Ammount": 20,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Crushed Sandstone",
                            "Ammount": 5
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Processed Magenta Powder",
            "Untranslated": "Processed Magenta Powder Recipe",
            "BaseCraftTime": 0.28,
            "BaseLaborCost": 65,
            "BaseXPGain": 0.5,
            "CraftingTable": "Industrial Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Processed Magenta Powder",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "ProcessedMagentaPowder",
                    "Name": "Processed Magenta Powder",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Urchin",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Magenta Powder",
                            "Ammount": 14
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Stone Brazier",
            "Untranslated": "Stone Brazier Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 25,
            "BaseXPGain": 2,
            "CraftingTable": "Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Stone Brazier",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Masonry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "StoneBrazier",
                    "Name": "Stone Brazier",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Rock",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Stone Brazier",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Campfire Fish Medium",
            "Untranslated": "Campfire Fish Medium Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 25,
            "BaseXPGain": 0.5,
            "CraftingTable": "Campfire",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Campfire Fish Medium",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Campfire Cooking",
                    "Level": 0
                }
            ],
            "Variants": [
                {
                    "Key": "CampfireFishMedium",
                    "Name": "Campfire Fish Medium",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Medium Fish",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Charred Fish",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Homestead Claim Stake",
            "Untranslated": "Homestead Claim Stake Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 100,
            "BaseXPGain": 1,
            "CraftingTable": "Workbench",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Homestead Claim Stake",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "HomesteadClaimStake",
                    "Name": "Homestead Claim Stake",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Homestead Claim Stake",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Beet Sugar",
            "Untranslated": "Beet Sugar Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 50,
            "BaseXPGain": 0.5,
            "CraftingTable": "Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Beet Sugar",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "BeetSugar",
                    "Name": "Beet Sugar",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Beet",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Sugar",
                            "Ammount": 3
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Large Cotton Bunting Red",
            "Untranslated": "Large Cotton Bunting Red Recipe",
            "BaseCraftTime": 4.2,
            "BaseLaborCost": 240,
            "BaseXPGain": 2,
            "CraftingTable": "Loom",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Large Cotton Bunting Red",
            "NumberOfVariants": 4,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "LargeCottonBuntingRed",
                    "Name": "Large Cotton Bunting Red",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Cotton Bunting Red",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeCottonBuntingFestive",
                    "Name": "Large Cotton Bunting Festive",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Cotton Bunting Festive",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeCottonBuntingVibrant",
                    "Name": "Large Cotton Bunting Vibrant",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Cotton Bunting Vibrant",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeCottonBuntingRegal",
                    "Name": "Large Cotton Bunting Regal",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Cotton Bunting Regal",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Weave Nylon Fabric",
            "Untranslated": "Weave Nylon Fabric Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 80,
            "BaseXPGain": 0.5,
            "CraftingTable": "Automatic Loom",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Weave Nylon Fabric",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "WeaveNylonFabric",
                    "Name": "Weave Nylon Fabric",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nylon Thread",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Nylon Fabric",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Ceiling Candle",
            "Untranslated": "Ceiling Candle Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 60,
            "BaseXPGain": 3,
            "CraftingTable": "Blacksmith Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Ceiling Candle",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "CeilingCandle",
                    "Name": "Ceiling Candle",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 3,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Tallow Candle",
                            "Ammount": 2,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ceiling Candle",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Sand Concentrate",
            "Untranslated": "Sand Concentrate Recipe",
            "BaseCraftTime": 0.97999996,
            "BaseLaborCost": 50,
            "BaseXPGain": 0.5,
            "CraftingTable": "Rocker Box",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Sand Concentrate",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "SandConcentrate",
                    "Name": "Sand Concentrate",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Silica",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Sand",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Quicklime Glass",
            "Untranslated": "Quicklime Glass Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 45,
            "BaseXPGain": 1,
            "CraftingTable": "Glassworks",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Quicklime Glass",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Glassworking",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "QuicklimeGlass",
                    "Name": "Quicklime Glass",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Sand",
                            "Ammount": 3,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Quicklime",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Glass",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Processed Blue Powder",
            "Untranslated": "Processed Blue Powder Recipe",
            "BaseCraftTime": 0.28,
            "BaseLaborCost": 65,
            "BaseXPGain": 0.5,
            "CraftingTable": "Industrial Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Processed Blue Powder",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "ProcessedBluePowder",
                    "Name": "Processed Blue Powder",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Huckleberries",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Blue Powder",
                            "Ammount": 12
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Weave Cotton Fabric",
            "Untranslated": "Weave Cotton Fabric Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 80,
            "BaseXPGain": 0.5,
            "CraftingTable": "Automatic Loom",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Weave Cotton Fabric",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "WeaveCottonFabric",
                    "Name": "Weave Cotton Fabric",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Thread",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Cotton Fabric",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Fishery",
            "Untranslated": "Fishery Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 200,
            "BaseXPGain": 3,
            "CraftingTable": "Workbench",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Fishery",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Hunting",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Fishery",
                    "Name": "Fishery",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Fishery",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Crushed Basalt Lv2",
            "Untranslated": "Crushed Basalt Lv2 Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 150,
            "BaseXPGain": 0.5,
            "CraftingTable": "Stamp Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Crushed Basalt Lv2",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CrushedBasaltLv2",
                    "Name": "Crushed Basalt Lv2",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Basalt",
                            "Ammount": 20,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Crushed Basalt",
                            "Ammount": 5
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Settlement Crafting Table",
            "Untranslated": "Settlement Crafting Table Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 360,
            "BaseXPGain": 1,
            "CraftingTable": "Workbench",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Settlement Crafting Table",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "SettlementCraftingTable",
                    "Name": "Settlement Crafting Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Settlement Crafting Table",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Clean Crab",
            "Untranslated": "Clean Crab Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 20,
            "BaseXPGain": 1,
            "CraftingTable": "Fishery",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Clean Crab",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Hunting",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CleanCrab",
                    "Name": "Clean Crab",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Crab Carcass",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Raw Fish",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Steel Hanging Lamp",
            "Untranslated": "Steel Hanging Lamp Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 120,
            "BaseXPGain": 5,
            "CraftingTable": "Robotic Assembly Line",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Steel Hanging Lamp",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Electronics",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "SteelHangingLamp",
                    "Name": "Steel Hanging Lamp",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plastic",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Wiring",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Composite Lumber",
                            "Name": "",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Light Bulb",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Steel Hanging Lamp",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Industrial Generator",
            "Untranslated": "Industrial Generator Recipe",
            "BaseCraftTime": 56,
            "BaseLaborCost": 2400,
            "BaseXPGain": 60,
            "CraftingTable": "Robotic Assembly Line",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Industrial Generator",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Industry",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "IndustrialGenerator",
                    "Name": "Industrial Generator",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Plate",
                            "Ammount": 48,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Advanced Circuit",
                            "Ammount": 32,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Pipe",
                            "Ammount": 32,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Rivet",
                            "Ammount": 64,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Advanced Combustion Engine",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Radiator",
                            "Ammount": 24,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Axle",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Industrial Generator",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Primitive Backpack",
            "Untranslated": "Primitive Backpack Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 1,
            "BaseXPGain": 1,
            "CraftingTable": "Fletching Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Primitive Backpack",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Hunting",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "PrimitiveBackpack",
                    "Name": "Primitive Backpack",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plant Fibers",
                            "Ammount": 25,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Basic Backpack",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Processed Colored Powder",
            "Untranslated": "Processed Colored Powder Recipe",
            "BaseCraftTime": 0.28,
            "BaseLaborCost": 55,
            "BaseXPGain": 0.5,
            "CraftingTable": "Industrial Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Processed Colored Powder",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "ProcessedColoredPowder",
                    "Name": "Processed Colored Powder",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Petals",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Colored Powder",
                            "Ammount": 3
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Cast Iron Royal Bed",
            "Untranslated": "Cast Iron Royal Bed Recipe",
            "BaseCraftTime": 11.2,
            "BaseLaborCost": 300,
            "BaseXPGain": 20,
            "CraftingTable": "Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Cast Iron Royal Bed",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "CastIronRoyalBed",
                    "Name": "Cast Iron Royal Bed",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 80,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 24,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Bar",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Cast Iron Royal Bed",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Corn Ethanol",
            "Untranslated": "Corn Ethanol Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 60,
            "BaseXPGain": 0.5,
            "CraftingTable": "Laboratory",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Corn Ethanol",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Cutting Edge Cooking",
                    "Level": 0
                }
            ],
            "Variants": [
                {
                    "Key": "CornEthanol",
                    "Name": "Corn Ethanol",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Corn",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ethanol",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Elk Mount",
            "Untranslated": "Elk Mount Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 300,
            "BaseXPGain": 4,
            "CraftingTable": "Butchery Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Elk Mount",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Hunting",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "ElkMount",
                    "Name": "Elk Mount",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Composite Lumber",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fabric",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Elk Carcass",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Elk Mount",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Weave Small Cotton Bunting Red",
            "Untranslated": "Weave Small Cotton Bunting Red Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 120,
            "BaseXPGain": 1,
            "CraftingTable": "Automatic Loom",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Weave Small Cotton Bunting Red",
            "NumberOfVariants": 4,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "WeaveSmallCottonBuntingRed",
                    "Name": "Weave Small Cotton Bunting Red",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 3,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Cotton Bunting Red",
                            "Ammount": 2
                        }
                    ]
                },
                {
                    "Key": "WeaveSmallCottonBuntingRegal",
                    "Name": "Weave Small Cotton Bunting Regal",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 3,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Cotton Bunting Regal",
                            "Ammount": 2
                        }
                    ]
                },
                {
                    "Key": "WeaveSmallCottonBuntingFestive",
                    "Name": "Weave Small Cotton Bunting Festive",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 3,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Cotton Bunting Festive",
                            "Ammount": 2
                        }
                    ]
                },
                {
                    "Key": "WeaveSmallCottonBuntingVibrant",
                    "Name": "Weave Small Cotton Bunting Vibrant",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 3,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Cotton Bunting Vibrant",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Large Bath Mat",
            "Untranslated": "Large Bath Mat Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 120,
            "BaseXPGain": 3,
            "CraftingTable": "Loom",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Large Bath Mat",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "LargeBathMat",
                    "Name": "Large Bath Mat",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Linen Fabric",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cellulose Fiber",
                            "Ammount": 15,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 40,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Bath Mat",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Crushed Stone Road Lv2",
            "Untranslated": "Crushed Stone Road Lv2 Recipe",
            "BaseCraftTime": 0.28,
            "BaseLaborCost": 120,
            "BaseXPGain": 0.5,
            "CraftingTable": "Jaw Crusher",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Crushed Stone Road Lv2",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "CrushedStoneRoadLv2",
                    "Name": "Crushed Stone Road Lv2",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Stone Road",
                            "Ammount": 5,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Crushed Mixed Rock",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Crushed Coal Lv2",
            "Untranslated": "Crushed Coal Lv2 Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 70,
            "BaseXPGain": 0.5,
            "CraftingTable": "Stamp Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Crushed Coal Lv2",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "CrushedCoalLv2",
                    "Name": "Crushed Coal Lv2",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Coal",
                            "Ammount": 20,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Crushed Coal",
                            "Ammount": 4
                        },
                        {
                            "Name": "Crushed Mixed Rock",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Transmission Pole",
            "Untranslated": "Transmission Pole Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 480,
            "BaseXPGain": 2,
            "CraftingTable": "Machinist Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Transmission Pole",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "TransmissionPole",
                    "Name": "Transmission Pole",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Wiring",
                            "Ammount": 14,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Transmission Pole",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Crushed Gold Lv2",
            "Untranslated": "Crushed Gold Lv2 Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 90,
            "BaseXPGain": 0.5,
            "CraftingTable": "Stamp Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Crushed Gold Lv2",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CrushedGoldLv2",
                    "Name": "Crushed Gold Lv2",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Ore",
                            "Ammount": 20,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Crushed Gold Ore",
                            "Ammount": 4
                        },
                        {
                            "Name": "Crushed Granite",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Butcher Small Animal",
            "Untranslated": "Butcher Small Animal Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 40,
            "BaseXPGain": 3,
            "CraftingTable": "Butchery Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Butcher Small Animal",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Butchery",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "ButcherSmallAnimal",
                    "Name": "Butcher Small Animal",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Small Carcass",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Raw Meat",
                            "Ammount": 2
                        },
                        {
                            "Name": "Fur Pelt",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Limestone Owl Statue",
            "Untranslated": "Limestone Owl Statue Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 60,
            "BaseXPGain": 1,
            "CraftingTable": "Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Limestone Owl Statue",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Masonry",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "LimestoneOwlStatue",
                    "Name": "Limestone Owl Statue",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Limestone",
                            "Ammount": 40,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Limestone Owl Statue",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Loom",
            "Untranslated": "Loom Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 600,
            "BaseXPGain": 2,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Loom",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Loom",
                    "Name": "Loom",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Linen Yarn",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wooden Gear",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Loom",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Dense Charcoal",
            "Untranslated": "Dense Charcoal Recipe",
            "BaseCraftTime": 3.3600001,
            "BaseLaborCost": 50,
            "BaseXPGain": 2,
            "CraftingTable": "Kiln",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Dense Charcoal",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Logging",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "DenseCharcoal",
                    "Name": "Dense Charcoal",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 7,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Charcoal",
                            "Ammount": 4
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Laser",
            "Untranslated": "Laser Recipe",
            "BaseCraftTime": 140,
            "BaseLaborCost": 900,
            "BaseXPGain": 50,
            "CraftingTable": "Robotic Assembly Line",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Laser",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Electronics",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "Laser",
                    "Name": "Laser",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Bar",
                            "Ammount": 80,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 80,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Framed Glass",
                            "Ammount": 80,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Advanced Circuit",
                            "Ammount": 40,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Electric Motor",
                            "Ammount": 2,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Radiator",
                            "Ammount": 10,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Laser",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Weave Small Bath Mat",
            "Untranslated": "Weave Small Bath Mat Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 30,
            "BaseXPGain": 1,
            "CraftingTable": "Automatic Loom",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Weave Small Bath Mat",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "WeaveSmallBathMat",
                    "Name": "Weave Small Bath Mat",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 16,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Bath Mat",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Weave Large Bath Mat",
            "Untranslated": "Weave Large Bath Mat Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 120,
            "BaseXPGain": 3,
            "CraftingTable": "Automatic Loom",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Weave Large Bath Mat",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "WeaveLargeBathMat",
                    "Name": "Weave Large Bath Mat",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cellulose Fiber",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 35,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Bath Mat",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Clean Medium Fish",
            "Untranslated": "Clean Medium Fish Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 20,
            "BaseXPGain": 1,
            "CraftingTable": "Fishery",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Clean Medium Fish",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Hunting",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CleanMediumFish",
                    "Name": "Clean Medium Fish",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Medium Fish",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Raw Fish",
                            "Ammount": 2
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Crushed Slag Lv2",
            "Untranslated": "Crushed Slag Lv2 Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 120,
            "BaseXPGain": 0.5,
            "CraftingTable": "Stamp Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Crushed Slag Lv2",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CrushedSlagLv2",
                    "Name": "Crushed Slag Lv2",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Slag",
                            "Ammount": 20,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Crushed Slag",
                            "Ammount": 5
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Concentrate Gold Lv2",
            "Untranslated": "Concentrate Gold Lv2 Recipe",
            "BaseCraftTime": 1.12,
            "BaseLaborCost": 180,
            "BaseXPGain": 1,
            "CraftingTable": "Froth Floatation Cell",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Concentrate Gold Lv2",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "ConcentrateGoldLv2",
                    "Name": "Concentrate Gold Lv2",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Crushed Gold Ore",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Gold Concentrate",
                            "Ammount": 2
                        },
                        {
                            "Name": "Wet Tailings",
                            "Ammount": 3
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Fish Oil",
            "Untranslated": "Fish Oil Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 20,
            "BaseXPGain": 0.5,
            "CraftingTable": "Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Fish Oil",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "FishOil",
                    "Name": "Fish Oil",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Raw Fish",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Oil",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Stenographers Table",
            "Untranslated": "Stenographers Table Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 1200,
            "BaseXPGain": 1,
            "CraftingTable": "Settlement Crafting Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Stenographers Table",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "StenographersTable",
                    "Name": "Stenographers Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Paper",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Stenographers Table",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Gold Frame Wide",
            "Untranslated": "Gold Frame Wide Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 160,
            "BaseXPGain": 1,
            "CraftingTable": "Anvil",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Gold Frame Wide",
            "NumberOfVariants": 5,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "GoldFrameWide",
                    "Name": "Gold Frame Wide",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Bar",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Gold Frame Wide",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "GoldFramePortrait",
                    "Name": "Gold Frame Portrait",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Bar",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Gold Frame Portrait",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "GoldFrameLandscape",
                    "Name": "Gold Frame Landscape",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Bar",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Gold Frame Landscape",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "GoldFrameTall",
                    "Name": "Gold Frame Tall",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Bar",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Gold Frame Tall",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "GoldFrameSquare",
                    "Name": "Gold Frame Square",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Bar",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Gold Frame Square",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Scarecrow",
            "Untranslated": "Scarecrow Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 60,
            "BaseXPGain": 2,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Scarecrow",
            "NumberOfVariants": 2,
            "SkillNeeds": [
                {
                    "Skill": "Farming",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Scarecrow",
                    "Name": "Scarecrow",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plant Fibers",
                            "Ammount": 35,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fabric",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Scarecrow",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "FriendlyScarecrow",
                    "Name": "Friendly Scarecrow",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Scarecrow",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Friendly Scarecrow",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Weave Wool Curtains",
            "Untranslated": "Weave Wool Curtains Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 60,
            "BaseXPGain": 1.5,
            "CraftingTable": "Automatic Loom",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Weave Wool Curtains",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "WeaveWoolCurtains",
                    "Name": "Weave Wool Curtains",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wool Fabric",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Thread",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wool Curtains",
                            "Ammount": 4
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Kitchen",
            "Untranslated": "Kitchen Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 300,
            "BaseXPGain": 6,
            "CraftingTable": "Sawmill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Kitchen",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "Kitchen",
                    "Name": "Kitchen",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cooking Utensils",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fabric",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Kitchen",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Processed Simple Syrup",
            "Untranslated": "Processed Simple Syrup Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 100,
            "BaseXPGain": 1,
            "CraftingTable": "Industrial Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Processed Simple Syrup",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Milling",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "ProcessedSimpleSyrup",
                    "Name": "Processed Simple Syrup",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Sugar",
                            "Ammount": 25,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Simple Syrup",
                            "Ammount": 3
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Sawmill",
            "Untranslated": "Sawmill Recipe",
            "BaseCraftTime": 8.4,
            "BaseLaborCost": 600,
            "BaseXPGain": 20,
            "CraftingTable": "Blacksmith Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Sawmill",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "Sawmill",
                    "Name": "Sawmill",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 16,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Saw Blade",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Sawmill",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Sweet Jerky",
            "Untranslated": "Sweet Jerky Recipe",
            "BaseCraftTime": 4.2,
            "BaseLaborCost": 25,
            "BaseXPGain": 0.5,
            "CraftingTable": "Fish Rack",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Sweet Jerky",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Hunting",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "SweetJerky",
                    "Name": "Sweet Jerky",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Raw Meat",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Sugar",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Oil",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fruit",
                            "Name": "",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Sweet Deer Jerky",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Upholstered Chair",
            "Untranslated": "Upholstered Chair Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 100,
            "BaseXPGain": 1,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Upholstered Chair",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "UpholsteredChair",
                    "Name": "Upholstered Chair",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Spring",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Upholstered Chair",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wooden Frame Wide",
            "Untranslated": "Wooden Frame Wide Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 160,
            "BaseXPGain": 1,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Wooden Frame Wide",
            "NumberOfVariants": 5,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "WoodenFrameWide",
                    "Name": "Wooden Frame Wide",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wooden Frame Wide",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "WoodenFrameLandscape",
                    "Name": "Wooden Frame Landscape",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wooden Frame Landscape",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "WoodenFrameSquare",
                    "Name": "Wooden Frame Square",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wooden Frame Square",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "WoodenFrameTall",
                    "Name": "Wooden Frame Tall",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wooden Frame Tall",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "WoodenFramePortrait",
                    "Name": "Wooden Frame Portrait",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wooden Frame Portrait",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Powered Storage Silo",
            "Untranslated": "Powered Storage Silo Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 800,
            "BaseXPGain": 6,
            "CraftingTable": "Robotic Assembly Line",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Powered Storage Silo",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Industry",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "PoweredStorageSilo",
                    "Name": "Powered Storage Silo",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Plate",
                            "Ammount": 18,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Advanced Circuit",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Radiator",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Pipe",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Powered Storage Silo",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Crushed Limestone Lv3",
            "Untranslated": "Crushed Limestone Lv3 Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 90,
            "BaseXPGain": 0.5,
            "CraftingTable": "Jaw Crusher",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Crushed Limestone Lv3",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mining",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "CrushedLimestoneLv3",
                    "Name": "Crushed Limestone Lv3",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Limestone",
                            "Ammount": 20,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Crushed Limestone",
                            "Ammount": 5
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wheat Ethanol",
            "Untranslated": "Wheat Ethanol Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 60,
            "BaseXPGain": 0.5,
            "CraftingTable": "Laboratory",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Wheat Ethanol",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Cutting Edge Cooking",
                    "Level": 0
                }
            ],
            "Variants": [
                {
                    "Key": "WheatEthanol",
                    "Name": "Wheat Ethanol",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wheat",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ethanol",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Hewn Dresser",
            "Untranslated": "Hewn Dresser Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 60,
            "BaseXPGain": 2,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Hewn Dresser",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Logging",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "HewnDresser",
                    "Name": "Hewn Dresser",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 18,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Hewn Dresser",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Large Festive Paper Lantern",
            "Untranslated": "Large Festive Paper Lantern Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 60,
            "BaseXPGain": 1,
            "CraftingTable": "Small Paper Machine",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Large Festive Paper Lantern",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Paper Milling",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "LargeFestivePaperLantern",
                    "Name": "Large Festive Paper Lantern",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Paper",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Light Bulb",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Festive Paper Lantern",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Ornate Iron Frame Wide",
            "Untranslated": "Ornate Iron Frame Wide Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 160,
            "BaseXPGain": 1,
            "CraftingTable": "Anvil",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Ornate Iron Frame Wide",
            "NumberOfVariants": 5,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "OrnateIronFrameWide",
                    "Name": "Ornate Iron Frame Wide",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ornate Iron Frame Wide",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "OrnateIronFrameTall",
                    "Name": "Ornate Iron Frame Tall",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ornate Iron Frame Tall",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "OrnateIronFramePortrait",
                    "Name": "Ornate Iron Frame Portrait",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ornate Iron Frame Portrait",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "OrnateIronFrameSquare",
                    "Name": "Ornate Iron Frame Square",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ornate Iron Frame Square",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "OrnateIronFrameLandscape",
                    "Name": "Ornate Iron Frame Landscape",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ornate Iron Frame Landscape",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Mortared Stone Door",
            "Untranslated": "Mortared Stone Door Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 15,
            "BaseXPGain": 0.5,
            "CraftingTable": "Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Mortared Stone Door",
            "NumberOfVariants": 4,
            "SkillNeeds": [
                {
                    "Skill": "Masonry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "MortaredStoneDoor",
                    "Name": "Mortared Stone Door",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Mortared Stone",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Mortared Stone Door",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "MortaredGraniteDoor",
                    "Name": "Mortared Granite Door",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Mortared Granite",
                            "Ammount": 2,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Mortared Granite Door",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "MortaredLimestoneDoor",
                    "Name": "Mortared Limestone Door",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Mortared Limestone",
                            "Ammount": 2,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Mortared Limestone Door",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "MortaredSandstoneDoor",
                    "Name": "Mortared Sandstone Door",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Mortared Sandstone",
                            "Ammount": 2,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Mortared Sandstone Door",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Refine Heart Of Palm",
            "Untranslated": "Refine Heart Of Palm Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 30,
            "BaseXPGain": 0.5,
            "CraftingTable": "Fiber Scutching Station",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Refine Heart Of Palm",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Gathering",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "RefineHeartOfPalm",
                    "Name": "Refine Heart Of Palm",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Palm Log",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Heart Of Palm",
                            "Ammount": 2
                        },
                        {
                            "Name": "Wood Pulp",
                            "Ammount": 4
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Orange Vase",
            "Untranslated": "Orange Vase Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 90,
            "BaseXPGain": 1,
            "CraftingTable": "Glassworks",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Orange Vase",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Glassworking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "OrangeVase",
                    "Name": "Orange Vase",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Glass",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Yellow Powder",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Oxide",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Orange Vase",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Froth Floatation Cell",
            "Untranslated": "Froth Floatation Cell Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 120,
            "BaseXPGain": 5,
            "CraftingTable": "Electronics Assembly",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Froth Floatation Cell",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Electronics",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "FrothFloatationCell",
                    "Name": "Froth Floatation Cell",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Plate",
                            "Ammount": 15,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Pipe",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Advanced Circuit",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Electric Motor",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Froth Floatation Cell",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Automatic Loom",
            "Untranslated": "Automatic Loom Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 600,
            "BaseXPGain": 10,
            "CraftingTable": "Electric Machinist Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Automatic Loom",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Industry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "AutomaticLoom",
                    "Name": "Automatic Loom",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Plate",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Basic Circuit",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Automatic Loom",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Hewn Chair",
            "Untranslated": "Hewn Chair Recipe",
            "BaseCraftTime": 4.2,
            "BaseLaborCost": 60,
            "BaseXPGain": 2,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Hewn Chair",
            "NumberOfVariants": 3,
            "SkillNeeds": [
                {
                    "Skill": "Logging",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "HewnChair",
                    "Name": "Hewn Chair",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plant Fibers",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Hewn Chair",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "HewnSoftwoodChair",
                    "Name": "Hewn Softwood Chair",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Softwood Hewn Log",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Softwood Board",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plant Fibers",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Hewn Softwood Chair",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "HewnHardwoodChair",
                    "Name": "Hewn Hardwood Chair",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Hardwood Hewn Log",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Hardwood Board",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plant Fibers",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Hewn Hardwood Chair",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Large Corrugated Steel Door",
            "Untranslated": "Large Corrugated Steel Door Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 480,
            "BaseXPGain": 2.5,
            "CraftingTable": "Rolling Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Large Corrugated Steel Door",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Smelting",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "LargeCorrugatedSteelDoor",
                    "Name": "Large Corrugated Steel Door",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Corrugated Steel",
                            "Ammount": 20,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Corrugated Steel Door",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Torch Stand",
            "Untranslated": "Torch Stand Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 10,
            "BaseXPGain": 1,
            "CraftingTable": "Workbench",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Torch Stand",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "TorchStand",
                    "Name": "Torch Stand",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plant Fibers",
                            "Ammount": 15,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Rock",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Torch Stand",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Electric Machinist Table",
            "Untranslated": "Electric Machinist Table Recipe",
            "BaseCraftTime": 11.2,
            "BaseLaborCost": 360,
            "BaseXPGain": 20,
            "CraftingTable": "Assembly Line",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Electric Machinist Table",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "ElectricMachinistTable",
                    "Name": "Electric Machinist Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Plate",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Gear",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Electric Machinist Table",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Embassy Desk",
            "Untranslated": "Embassy Desk Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 1200,
            "BaseXPGain": 1,
            "CraftingTable": "Settlement Crafting Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Embassy Desk",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "EmbassyDesk",
                    "Name": "Embassy Desk",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Bar",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Bar",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Paper",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Embassy Desk",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Arrastra",
            "Untranslated": "Arrastra Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 300,
            "BaseXPGain": 5,
            "CraftingTable": "Workbench",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Arrastra",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Basic Engineering",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Arrastra",
                    "Name": "Arrastra",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Rock",
                            "Name": "",
                            "Ammount": 30,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Mill Stone",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Arrastra",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Printing Press",
            "Untranslated": "Printing Press Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 240,
            "BaseXPGain": 1,
            "CraftingTable": "Machinist Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Printing Press",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "PrintingPress",
                    "Name": "Printing Press",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gearbox",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Piston",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Plate",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Printing Press",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Washboard",
            "Untranslated": "Washboard Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 120,
            "BaseXPGain": 1,
            "CraftingTable": "Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Washboard",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "Washboard",
                    "Name": "Washboard",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Washboard",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wainwright Table",
            "Untranslated": "Wainwright Table Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 180,
            "BaseXPGain": 5,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Wainwright Table",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Logging",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "WainwrightTable",
                    "Name": "Wainwright Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wainwright Table",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Court Chair",
            "Untranslated": "Court Chair Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 240,
            "BaseXPGain": 2,
            "CraftingTable": "Sawmill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Court Chair",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "CourtChair",
                    "Name": "Court Chair",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Court Chair",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Steel Searchlight",
            "Untranslated": "Steel Searchlight Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 120,
            "BaseXPGain": 20,
            "CraftingTable": "Robotic Assembly Line",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Steel Searchlight",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Electronics",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "SteelSearchlight",
                    "Name": "Steel Searchlight",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Plate",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Rivet",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Wiring",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Light Bulb",
                            "Ammount": 4,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Steel Searchlight",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Standing Glass Sign",
            "Untranslated": "Standing Glass Sign Recipe",
            "BaseCraftTime": 4.2,
            "BaseLaborCost": 120,
            "BaseXPGain": 1,
            "CraftingTable": "Glassworks",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Standing Glass Sign",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Glassworking",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "StandingGlassSign",
                    "Name": "Standing Glass Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Glass",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Standing Glass Sign",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Screw Press",
            "Untranslated": "Screw Press Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 300,
            "BaseXPGain": 5,
            "CraftingTable": "Machinist Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Screw Press",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "ScrewPress",
                    "Name": "Screw Press",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 25,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Screw Press",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Limestone Bison Statue",
            "Untranslated": "Limestone Bison Statue Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 60,
            "BaseXPGain": 4,
            "CraftingTable": "Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Limestone Bison Statue",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Masonry",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "LimestoneBisonStatue",
                    "Name": "Limestone Bison Statue",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Limestone",
                            "Ammount": 200,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Limestone Bison Statue",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Ashlar Stone Chair",
            "Untranslated": "Ashlar Stone Chair Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 180,
            "BaseXPGain": 2,
            "CraftingTable": "Advanced Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Ashlar Stone Chair",
            "NumberOfVariants": 7,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Masonry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "AshlarStoneChair",
                    "Name": "Ashlar Stone Chair",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Ashlar Stone",
                            "Name": "",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Stone Chair",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AshlarShaleChair",
                    "Name": "Ashlar Shale Chair",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Shale",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Shale Chair",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AshlarGneissChair",
                    "Name": "Ashlar Gneiss Chair",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Gneiss",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Gneiss Chair",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AshlarGraniteChair",
                    "Name": "Ashlar Granite Chair",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Granite",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Granite Chair",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AshlarSandstoneChair",
                    "Name": "Ashlar Sandstone Chair",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Sandstone",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Sandstone Chair",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AshlarLimestoneChair",
                    "Name": "Ashlar Limestone Chair",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Limestone",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Limestone Chair",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AshlarBasaltChair",
                    "Name": "Ashlar Basalt Chair",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Basalt",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Basalt Chair",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Adorned Ashlar Stone Bench",
            "Untranslated": "Adorned Ashlar Stone Bench Recipe",
            "BaseCraftTime": 11.2,
            "BaseLaborCost": 240,
            "BaseXPGain": 3,
            "CraftingTable": "Advanced Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Adorned Ashlar Stone Bench",
            "NumberOfVariants": 7,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Masonry",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "AdornedAshlarStoneBench",
                    "Name": "Adorned Ashlar Stone Bench",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Bar",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Ashlar Stone",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Adorned Ashlar Stone Bench",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AdornedAshlarLimestoneBench",
                    "Name": "Adorned Ashlar Limestone Bench",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Limestone",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Bar",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Adorned Ashlar Limestone Bench",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AdornedAshlarGraniteBench",
                    "Name": "Adorned Ashlar Granite Bench",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Granite",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Bar",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Adorned Ashlar Granite Bench",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AdornedAshlarShaleBench",
                    "Name": "Adorned Ashlar Shale Bench",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Shale",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Bar",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Adorned Ashlar Shale Bench",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AdornedAshlarGneissBench",
                    "Name": "Adorned Ashlar Gneiss Bench",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Gneiss",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Bar",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Adorned Ashlar Gneiss Bench",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AdornedAshlarSandstoneBench",
                    "Name": "Adorned Ashlar Sandstone Bench",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Sandstone",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Bar",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Adorned Ashlar Sandstone Bench",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AdornedAshlarBasaltBench",
                    "Name": "Adorned Ashlar Basalt Bench",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Basalt",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Bar",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Adorned Ashlar Basalt Bench",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Butchery Table",
            "Untranslated": "Butchery Table Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 300,
            "BaseXPGain": 3,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Butchery Table",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Logging",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "ButcheryTable",
                    "Name": "Butchery Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Butchery Table",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Cast Iron Bench",
            "Untranslated": "Cast Iron Bench Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 60,
            "BaseXPGain": 3,
            "CraftingTable": "Bloomery",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Cast Iron Bench",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Smelting",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "CastIronBench",
                    "Name": "Cast Iron Bench",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Clay Mold",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Cast Iron Bench",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wood Plaque",
            "Untranslated": "Wood Plaque Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 80,
            "BaseXPGain": 1,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Wood Plaque",
            "NumberOfVariants": 2,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "WoodPlaque",
                    "Name": "Wood Plaque",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wood Plaque",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "OrnateWoodPlaque",
                    "Name": "Ornate Wood Plaque",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Plate",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wood Plaque",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ornate Wood Plaque",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Industrial Mill",
            "Untranslated": "Industrial Mill Recipe",
            "BaseCraftTime": 11.2,
            "BaseLaborCost": 360,
            "BaseXPGain": 5,
            "CraftingTable": "Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Industrial Mill",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Industry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "IndustrialMill",
                    "Name": "Industrial Mill",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Plate",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gearbox",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Combustion Engine",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Industrial Mill",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Electric Water Pump",
            "Untranslated": "Electric Water Pump Recipe",
            "BaseCraftTime": 11.2,
            "BaseLaborCost": 120,
            "BaseXPGain": 10,
            "CraftingTable": "Electric Machinist Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Electric Water Pump",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "ElectricWaterPump",
                    "Name": "Electric Water Pump",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Pipe",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Electric Water Pump",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Copper Frame Wide",
            "Untranslated": "Copper Frame Wide Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 160,
            "BaseXPGain": 1,
            "CraftingTable": "Anvil",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Copper Frame Wide",
            "NumberOfVariants": 5,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "CopperFrameWide",
                    "Name": "Copper Frame Wide",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Bar",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Copper Frame Wide",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CopperFramePortrait",
                    "Name": "Copper Frame Portrait",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Bar",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Copper Frame Portrait",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CopperFrameSquare",
                    "Name": "Copper Frame Square",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Bar",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Copper Frame Square",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CopperFrameLandscape",
                    "Name": "Copper Frame Landscape",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Bar",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Copper Frame Landscape",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CopperFrameTall",
                    "Name": "Copper Frame Tall",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Bar",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Copper Frame Tall",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Small Paper Lantern",
            "Untranslated": "Small Paper Lantern Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 60,
            "BaseXPGain": 1,
            "CraftingTable": "Small Paper Machine",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Small Paper Lantern",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Paper Milling",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "SmallPaperLantern",
                    "Name": "Small Paper Lantern",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Paper",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Light Bulb",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Paper Lantern",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Small Hanging Wood Sign",
            "Untranslated": "Small Hanging Wood Sign Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Small Hanging Wood Sign",
            "NumberOfVariants": 13,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "SmallHangingWoodSign",
                    "Name": "Small Hanging Wood Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Hanging Wood Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallHangingBirchSign",
                    "Name": "Small Hanging Birch Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Birch Log",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Hanging Birch Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallHangingCeibaSign",
                    "Name": "Small Hanging Ceiba Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ceiba Log",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Hanging Ceiba Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallHangingOakSign",
                    "Name": "Small Hanging Oak Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Oak Log",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Hanging Oak Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallHangingHardwoodSign",
                    "Name": "Small Hanging Hardwood Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Hardwood Board",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hardwood",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Hanging Hardwood Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallHangingPalmSign",
                    "Name": "Small Hanging Palm Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Palm Log",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Hanging Palm Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallHangingJoshuaSign",
                    "Name": "Small Hanging Joshua Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Joshua Log",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Hanging Joshua Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallHangingFirSign",
                    "Name": "Small Hanging Fir Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Fir Log",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Hanging Fir Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallHangingSoftwoodSign",
                    "Name": "Small Hanging Softwood Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Softwood Board",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Softwood",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Hanging Softwood Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallHangingSpruceSign",
                    "Name": "Small Hanging Spruce Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Spruce Log",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Hanging Spruce Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallHangingCedarSign",
                    "Name": "Small Hanging Cedar Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cedar Log",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Hanging Cedar Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallHangingRedwoodSign",
                    "Name": "Small Hanging Redwood Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Redwood Log",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Hanging Redwood Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallHangingSaguaroSign",
                    "Name": "Small Hanging Saguaro Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Saguaro Rib",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Hanging Saguaro Sign",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wooden Fabric Bed",
            "Untranslated": "Wooden Fabric Bed Recipe",
            "BaseCraftTime": 8.4,
            "BaseLaborCost": 180,
            "BaseXPGain": 4,
            "CraftingTable": "Sawmill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Wooden Fabric Bed",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "WoodenFabricBed",
                    "Name": "Wooden Fabric Bed",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nail",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fabric",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wooden Fabric Bed",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Ashlar Stone Door",
            "Untranslated": "Ashlar Stone Door Recipe",
            "BaseCraftTime": 4.2,
            "BaseLaborCost": 180,
            "BaseXPGain": 1.5,
            "CraftingTable": "Advanced Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Ashlar Stone Door",
            "NumberOfVariants": 7,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Masonry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "AshlarStoneDoor",
                    "Name": "Ashlar Stone Door",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Ashlar Stone",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Stone Door",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AshlarGneissDoor",
                    "Name": "Ashlar Gneiss Door",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Gneiss",
                            "Ammount": 2,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Gneiss Door",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AshlarBasaltDoor",
                    "Name": "Ashlar Basalt Door",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Basalt",
                            "Ammount": 2,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Basalt Door",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AshlarLimestoneDoor",
                    "Name": "Ashlar Limestone Door",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Limestone",
                            "Ammount": 2,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Limestone Door",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AshlarGraniteDoor",
                    "Name": "Ashlar Granite Door",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Granite",
                            "Ammount": 2,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Granite Door",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AshlarShaleDoor",
                    "Name": "Ashlar Shale Door",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Shale",
                            "Ammount": 2,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Shale Door",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AshlarSandstoneDoor",
                    "Name": "Ashlar Sandstone Door",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Sandstone",
                            "Ammount": 2,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Sandstone Door",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Jaw Crusher",
            "Untranslated": "Jaw Crusher Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 1200,
            "BaseXPGain": 5,
            "CraftingTable": "Robotic Assembly Line",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Jaw Crusher",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Electronics",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "JawCrusher",
                    "Name": "Jaw Crusher",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 25,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Synthetic Rubber",
                            "Ammount": 25,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Gear",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Electric Motor",
                            "Ammount": 2,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Jaw Crusher",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Steel Ceiling Light",
            "Untranslated": "Steel Ceiling Light Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 120,
            "BaseXPGain": 5,
            "CraftingTable": "Robotic Assembly Line",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Steel Ceiling Light",
            "NumberOfVariants": 2,
            "SkillNeeds": [
                {
                    "Skill": "Electronics",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "SteelCeilingLight",
                    "Name": "Steel Ceiling Light",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plastic",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Wiring",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Light Bulb",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Steel Ceiling Light",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "Chandelier",
                    "Name": "Chandelier",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plastic",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Wiring",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Light Bulb",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Chandelier",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Bathtub",
            "Untranslated": "Bathtub Recipe",
            "BaseCraftTime": 8.4,
            "BaseLaborCost": 240,
            "BaseXPGain": 2,
            "CraftingTable": "Kiln",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Bathtub",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Pottery",
                    "Level": 7
                }
            ],
            "Variants": [
                {
                    "Key": "Bathtub",
                    "Name": "Bathtub",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Clay",
                            "Ammount": 40,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "White Powder",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Bathtub",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Tallow Wall Lamp",
            "Untranslated": "Tallow Wall Lamp Recipe",
            "BaseCraftTime": 4.2,
            "BaseLaborCost": 50,
            "BaseXPGain": 3,
            "CraftingTable": "Blacksmith Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Tallow Wall Lamp",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "TallowWallLamp",
                    "Name": "Tallow Wall Lamp",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 3,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Tallow",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Thread",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Tallow Wall Lamp",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Stuffed Goat",
            "Untranslated": "Stuffed Goat Recipe",
            "BaseCraftTime": 16.8,
            "BaseLaborCost": 300,
            "BaseXPGain": 4,
            "CraftingTable": "Butchery Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Stuffed Goat",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Hunting",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "StuffedGoat",
                    "Name": "Stuffed Goat",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fabric",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Mountain Goat Carcass",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Stuffed Goat",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Cement Kiln",
            "Untranslated": "Cement Kiln Recipe",
            "BaseCraftTime": 140,
            "BaseLaborCost": 600,
            "BaseXPGain": 20,
            "CraftingTable": "Assembly Line",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Cement Kiln",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "CementKiln",
                    "Name": "Cement Kiln",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gearbox",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Piston",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Plate",
                            "Ammount": 16,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Cement Kiln",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Windmill",
            "Untranslated": "Windmill Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 180,
            "BaseXPGain": 8,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Windmill",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Basic Engineering",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Windmill",
                    "Name": "Windmill",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Linen Fabric",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Lubricant",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Windmill",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Rug Large",
            "Untranslated": "Rug Large Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 120,
            "BaseXPGain": 3,
            "CraftingTable": "Loom",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Rug Large",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "RugLarge",
                    "Name": "Rug Large",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cellulose Fiber",
                            "Ammount": 15,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wool Yarn",
                            "Ammount": 40,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fabric",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Rug Large",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Cotton Streamer Stripes",
            "Untranslated": "Cotton Streamer Stripes Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 120,
            "BaseXPGain": 0.5,
            "CraftingTable": "Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Cotton Streamer Stripes",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "CottonStreamerStripes",
                    "Name": "Cotton Streamer Stripes",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Cotton Streamer Stripes",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Rocker Box",
            "Untranslated": "Rocker Box Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 100,
            "BaseXPGain": 10,
            "CraftingTable": "Workbench",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Rocker Box",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Basic Engineering",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "RockerBox",
                    "Name": "Rocker Box",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Rocker Box",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Workbench",
            "Untranslated": "Workbench Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 30,
            "BaseXPGain": 1,
            "CraftingTable": "Campsite",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Workbench",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "Workbench",
                    "Name": "Workbench",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Workbench",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Tallow Lamp",
            "Untranslated": "Tallow Lamp Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 50,
            "BaseXPGain": 2,
            "CraftingTable": "Kiln",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Tallow Lamp",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Masonry",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "TallowLamp",
                    "Name": "Tallow Lamp",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Clay",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Tallow",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Thread",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Tallow Lamp",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Lumber Dresser",
            "Untranslated": "Lumber Dresser Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 180,
            "BaseXPGain": 2,
            "CraftingTable": "Sawmill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Lumber Dresser",
            "NumberOfVariants": 3,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "LumberDresser",
                    "Name": "Lumber Dresser",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nail",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Lumber Dresser",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "HardwoodLumberDresser",
                    "Name": "Hardwood Lumber Dresser",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Hardwood Lumber",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nail",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Hardwood Lumber Dresser",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SoftwoodLumberDresser",
                    "Name": "Softwood Lumber Dresser",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Softwood Lumber",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nail",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Softwood Lumber Dresser",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Limestone Otter Statue",
            "Untranslated": "Limestone Otter Statue Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 60,
            "BaseXPGain": 1,
            "CraftingTable": "Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Limestone Otter Statue",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Masonry",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "LimestoneOtterStatue",
                    "Name": "Limestone Otter Statue",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Limestone",
                            "Ammount": 40,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Limestone Otter Statue",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Large Hanging Ashlar Stone Sign",
            "Untranslated": "Large Hanging Ashlar Stone Sign Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 120,
            "BaseXPGain": 2,
            "CraftingTable": "Advanced Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Large Hanging Ashlar Stone Sign",
            "NumberOfVariants": 7,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Masonry",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "LargeHangingAshlarStoneSign",
                    "Name": "Large Hanging Ashlar Stone Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Ashlar Stone",
                            "Name": "",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Hanging Ashlar Stone Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeHangingAshlarSandstoneSign",
                    "Name": "Large Hanging Ashlar Sandstone Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Sandstone",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Hanging Ashlar Sandstone Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeHangingAshlarShaleSign",
                    "Name": "Large Hanging Ashlar Shale Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Shale",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Hanging Ashlar Shale Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeHangingAshlarBasaltSign",
                    "Name": "Large Hanging Ashlar Basalt Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Basalt",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Hanging Ashlar Basalt Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeHangingAshlarLimestoneSign",
                    "Name": "Large Hanging Ashlar Limestone Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Limestone",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Hanging Ashlar Limestone Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeHangingAshlarGraniteSign",
                    "Name": "Large Hanging Ashlar Granite Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Granite",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Hanging Ashlar Granite Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeHangingAshlarGneissSign",
                    "Name": "Large Hanging Ashlar Gneiss Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Gneiss",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Hanging Ashlar Gneiss Sign",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Hare Pelt",
            "Untranslated": "Hare Pelt Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 120,
            "BaseXPGain": 2,
            "CraftingTable": "Butchery Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Hare Pelt",
            "NumberOfVariants": 5,
            "SkillNeeds": [
                {
                    "Skill": "Hunting",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "HarePelt",
                    "Name": "Hare Pelt",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Hare Carcass",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Hare Pelt",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "BisonPelt",
                    "Name": "Bison Pelt",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Bison Carcass",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Bison Pelt",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "DeerPelt",
                    "Name": "Deer Pelt",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Deer Carcass",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Deer Pelt",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "FoxPelt",
                    "Name": "Fox Pelt",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Fox Carcass",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Fox Pelt",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "WolfPelt",
                    "Name": "Wolf Pelt",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wolf Carcass",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wolf Pelt",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Oil Refinery",
            "Untranslated": "Oil Refinery Recipe",
            "BaseCraftTime": 28,
            "BaseLaborCost": 420,
            "BaseXPGain": 40,
            "CraftingTable": "Assembly Line",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Oil Refinery",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "OilRefinery",
                    "Name": "Oil Refinery",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Reinforced Concrete",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Pipe",
                            "Ammount": 24,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Boiler",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Plate",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Oil Refinery",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Small Nylon Bunting Green",
            "Untranslated": "Small Nylon Bunting Green Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 60,
            "BaseXPGain": 1,
            "CraftingTable": "Automatic Loom",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Small Nylon Bunting Green",
            "NumberOfVariants": 3,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "SmallNylonBuntingGreen",
                    "Name": "Small Nylon Bunting Green",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nylon Fabric",
                            "Ammount": 3,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Nylon Bunting Green",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallNylonBuntingPurple",
                    "Name": "Small Nylon Bunting Purple",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nylon Fabric",
                            "Ammount": 3,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Nylon Bunting Purple",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallNylonBuntingYellow",
                    "Name": "Small Nylon Bunting Yellow",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nylon Fabric",
                            "Ammount": 3,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Nylon Bunting Yellow",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Tiny Stockpile",
            "Untranslated": "Tiny Stockpile Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 5,
            "BaseXPGain": 1,
            "CraftingTable": "Workbench",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Tiny Stockpile",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "TinyStockpile",
                    "Name": "Tiny Stockpile",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Tiny Stockpile",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Lumber Door",
            "Untranslated": "Lumber Door Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 60,
            "BaseXPGain": 1.5,
            "CraftingTable": "Sawmill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Lumber Door",
            "NumberOfVariants": 3,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "LumberDoor",
                    "Name": "Lumber Door",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Lumber Door",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "HardwoodLumberDoor",
                    "Name": "Hardwood Lumber Door",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Hardwood Lumber",
                            "Ammount": 2,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Hardwood Lumber Door",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SoftwoodLumberDoor",
                    "Name": "Softwood Lumber Door",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Softwood Lumber",
                            "Ammount": 2,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Softwood Lumber Door",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Adorned Ashlar Stone Table",
            "Untranslated": "Adorned Ashlar Stone Table Recipe",
            "BaseCraftTime": 11.2,
            "BaseLaborCost": 240,
            "BaseXPGain": 3,
            "CraftingTable": "Advanced Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Adorned Ashlar Stone Table",
            "NumberOfVariants": 7,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Masonry",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "AdornedAshlarStoneTable",
                    "Name": "Adorned Ashlar Stone Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Bar",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Ashlar Stone",
                            "Name": "",
                            "Ammount": 24,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Adorned Ashlar Stone Table",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AdornedAshlarSandstoneTable",
                    "Name": "Adorned Ashlar Sandstone Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Sandstone",
                            "Ammount": 24,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Bar",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Adorned Ashlar Sandstone Table",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AdornedAshlarShaleTable",
                    "Name": "Adorned Ashlar Shale Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Shale",
                            "Ammount": 24,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Bar",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Adorned Ashlar Shale Table",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AdornedAshlarGraniteTable",
                    "Name": "Adorned Ashlar Granite Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Granite",
                            "Ammount": 24,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Bar",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Adorned Ashlar Granite Table",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AdornedAshlarGneissTable",
                    "Name": "Adorned Ashlar Gneiss Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Gneiss",
                            "Ammount": 24,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Bar",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Adorned Ashlar Gneiss Table",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AdornedAshlarBasaltTable",
                    "Name": "Adorned Ashlar Basalt Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Basalt",
                            "Ammount": 24,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Bar",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Adorned Ashlar Basalt Table",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AdornedAshlarLimestoneTable",
                    "Name": "Adorned Ashlar Limestone Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Limestone",
                            "Ammount": 24,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Bar",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Adorned Ashlar Limestone Table",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Large Windowed Lumber Door",
            "Untranslated": "Large Windowed Lumber Door Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 240,
            "BaseXPGain": 2.5,
            "CraftingTable": "Sawmill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Large Windowed Lumber Door",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "LargeWindowedLumberDoor",
                    "Name": "Large Windowed Lumber Door",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Glass",
                            "Ammount": 5,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Windowed Lumber Door",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Fletching Table",
            "Untranslated": "Fletching Table Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 180,
            "BaseXPGain": 1,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Fletching Table",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Logging",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "FletchingTable",
                    "Name": "Fletching Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Fletching Table",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Large Court",
            "Untranslated": "Large Court Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 2000,
            "BaseXPGain": 1,
            "CraftingTable": "Settlement Crafting Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Large Court",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "LargeCourt",
                    "Name": "Large Court",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 30,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Mortared Stone",
                            "Name": "",
                            "Ammount": 30,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Court",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Padded Chair",
            "Untranslated": "Padded Chair Recipe",
            "BaseCraftTime": 11.2,
            "BaseLaborCost": 60,
            "BaseXPGain": 1,
            "CraftingTable": "Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Padded Chair",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "PaddedChair",
                    "Name": "Padded Chair",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fabric",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Padded Chair",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Huge Iron Banner Sign",
            "Untranslated": "Huge Iron Banner Sign Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 120,
            "BaseXPGain": 1,
            "CraftingTable": "Anvil",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Huge Iron Banner Sign",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "HugeIronBannerSign",
                    "Name": "Huge Iron Banner Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 16,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Plate",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Huge Iron Banner Sign",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Electric Lathe",
            "Untranslated": "Electric Lathe Recipe",
            "BaseCraftTime": 11.2,
            "BaseLaborCost": 240,
            "BaseXPGain": 20,
            "CraftingTable": "Electric Machinist Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Electric Lathe",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Industry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "ElectricLathe",
                    "Name": "Electric Lathe",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Wheel",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Plate",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Electric Lathe",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Large Hanging Wood Sign",
            "Untranslated": "Large Hanging Wood Sign Recipe",
            "BaseCraftTime": 4.2,
            "BaseLaborCost": 60,
            "BaseXPGain": 2,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Large Hanging Wood Sign",
            "NumberOfVariants": 13,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "LargeHangingWoodSign",
                    "Name": "Large Hanging Wood Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Hanging Wood Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeHangingRedwoodSign",
                    "Name": "Large Hanging Redwood Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Redwood Log",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Hanging Redwood Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeHangingOakSign",
                    "Name": "Large Hanging Oak Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Oak Log",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Hanging Oak Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeHangingFirSign",
                    "Name": "Large Hanging Fir Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Fir Log",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Hanging Fir Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeHangingCeibaSign",
                    "Name": "Large Hanging Ceiba Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ceiba Log",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Hanging Ceiba Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeHangingPalmSign",
                    "Name": "Large Hanging Palm Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Palm Log",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Hanging Palm Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeHangingHardwoodSign",
                    "Name": "Large Hanging Hardwood Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Hardwood Board",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hardwood",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Hanging Hardwood Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeHangingBirchSign",
                    "Name": "Large Hanging Birch Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Birch Log",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Hanging Birch Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeHangingJoshuaSign",
                    "Name": "Large Hanging Joshua Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Joshua Log",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Hanging Joshua Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeHangingCedarSign",
                    "Name": "Large Hanging Cedar Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cedar Log",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Hanging Cedar Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeHangingSaguaroSign",
                    "Name": "Large Hanging Saguaro Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Saguaro Rib",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Hanging Saguaro Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeHangingSoftwoodSign",
                    "Name": "Large Hanging Softwood Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Softwood Board",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Softwood",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Hanging Softwood Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeHangingSpruceSign",
                    "Name": "Large Hanging Spruce Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Spruce Log",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Hanging Spruce Sign",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Framed Glass Door",
            "Untranslated": "Framed Glass Door Recipe",
            "BaseCraftTime": 11.2,
            "BaseLaborCost": 115,
            "BaseXPGain": 1.5,
            "CraftingTable": "Glassworks",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Framed Glass Door",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Glassworking",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "FramedGlassDoor",
                    "Name": "Framed Glass Door",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Framed Glass",
                            "Ammount": 2,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Framed Glass Door",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Power Hammer",
            "Untranslated": "Power Hammer Recipe",
            "BaseCraftTime": 11.2,
            "BaseLaborCost": 180,
            "BaseXPGain": 10,
            "CraftingTable": "Assembly Line",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Power Hammer",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "PowerHammer",
                    "Name": "Power Hammer",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Plate",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gearbox",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Plate",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Power Hammer",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wooden Table Lamp",
            "Untranslated": "Wooden Table Lamp Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 120,
            "BaseXPGain": 3,
            "CraftingTable": "Sawmill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Wooden Table Lamp",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "WoodenTableLamp",
                    "Name": "Wooden Table Lamp",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Wiring",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fabric",
                            "Name": "",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Light Bulb",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wooden Table Lamp",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Small Shipyard",
            "Untranslated": "Small Shipyard Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 360,
            "BaseXPGain": 3,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Small Shipyard",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Logging",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "SmallShipyard",
                    "Name": "Small Shipyard",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Shipyard",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Streetlamp",
            "Untranslated": "Streetlamp Recipe",
            "BaseCraftTime": 8.4,
            "BaseLaborCost": 60,
            "BaseXPGain": 5,
            "CraftingTable": "Robotic Assembly Line",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Streetlamp",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Electronics",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "Streetlamp",
                    "Name": "Streetlamp",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Glass",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Wiring",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Light Bulb",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Streetlamp",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Steel Table Lamp",
            "Untranslated": "Steel Table Lamp Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 120,
            "BaseXPGain": 5,
            "CraftingTable": "Robotic Assembly Line",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Steel Table Lamp",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Electronics",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "SteelTableLamp",
                    "Name": "Steel Table Lamp",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plastic",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Wiring",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Light Bulb",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Steel Table Lamp",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Sensor Based Belt Sorter",
            "Untranslated": "Sensor Based Belt Sorter Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 600,
            "BaseXPGain": 6,
            "CraftingTable": "Electronics Assembly",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Sensor Based Belt Sorter",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Industry",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "SensorBasedBeltSorter",
                    "Name": "Sensor Based Belt Sorter",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Plate",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Gearbox",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Rivet",
                            "Ammount": 16,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Advanced Circuit",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Basic Circuit",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Electric Motor",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Sensor Based Belt Sorter",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Blacksmith Table",
            "Untranslated": "Blacksmith Table Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 180,
            "BaseXPGain": 5,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Blacksmith Table",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Logging",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "BlacksmithTable",
                    "Name": "Blacksmith Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Blacksmith Table",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Corrugated Steel Fence Door",
            "Untranslated": "Corrugated Steel Fence Door Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 300,
            "BaseXPGain": 0.5,
            "CraftingTable": "Rolling Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Corrugated Steel Fence Door",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Smelting",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "CorrugatedSteelFenceDoor",
                    "Name": "Corrugated Steel Fence Door",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Corrugated Steel",
                            "Ammount": 2,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Corrugated Steel Fence Door",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Large Lumber Stockpile",
            "Untranslated": "Large Lumber Stockpile Recipe",
            "BaseCraftTime": 16.8,
            "BaseLaborCost": 1200,
            "BaseXPGain": 3,
            "CraftingTable": "Sawmill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Large Lumber Stockpile",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "LargeLumberStockpile",
                    "Name": "Large Lumber Stockpile",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Lumber Stockpile",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Electric Wall Lamp",
            "Untranslated": "Electric Wall Lamp Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 120,
            "BaseXPGain": 5,
            "CraftingTable": "Electronics Assembly",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Electric Wall Lamp",
            "NumberOfVariants": 2,
            "SkillNeeds": [
                {
                    "Skill": "Electronics",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "ElectricWallLamp",
                    "Name": "Electric Wall Lamp",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Wiring",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Light Bulb",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Electric Wall Lamp",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "HangingElectricWallLamp",
                    "Name": "Hanging Electric Wall Lamp",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Wiring",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Light Bulb",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Hanging Electric Wall Lamp",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Country Foundation",
            "Untranslated": "Country Foundation Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 1200,
            "BaseXPGain": 1,
            "CraftingTable": "Settlement Crafting Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Country Foundation",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "CountryFoundation",
                    "Name": "Country Foundation",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Glass",
                            "Ammount": 30,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Bar",
                            "Ammount": 16,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Bar",
                            "Ammount": 16,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 16,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 30,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Mortared Stone",
                            "Name": "",
                            "Ammount": 30,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Country Foundation",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Flat Steel Door",
            "Untranslated": "Flat Steel Door Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 115,
            "BaseXPGain": 0.5,
            "CraftingTable": "Rolling Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Flat Steel Door",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Smelting",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "FlatSteelDoor",
                    "Name": "Flat Steel Door",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Flat Steel",
                            "Ammount": 2,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Glass",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Flat Steel Door",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Stuffed Wolf",
            "Untranslated": "Stuffed Wolf Recipe",
            "BaseCraftTime": 16.8,
            "BaseLaborCost": 300,
            "BaseXPGain": 4,
            "CraftingTable": "Butchery Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Stuffed Wolf",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Hunting",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "StuffedWolf",
                    "Name": "Stuffed Wolf",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fabric",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wolf Carcass",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Stuffed Wolf",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Small Stockpile",
            "Untranslated": "Small Stockpile Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 10,
            "BaseXPGain": 1,
            "CraftingTable": "Workbench",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Small Stockpile",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "SmallStockpile",
                    "Name": "Small Stockpile",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Stockpile",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Garden Pond",
            "Untranslated": "Garden Pond Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 60,
            "BaseXPGain": 0.1,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Garden Pond",
            "NumberOfVariants": 2,
            "SkillNeeds": [
                {
                    "Skill": "Farming",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "GardenPond",
                    "Name": "Garden Pond",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Crushed Rock",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Garden Pond",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "KoiPond",
                    "Name": "Koi Pond",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Garden Pond",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Koi Pond",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Decorative Life Preserver",
            "Untranslated": "Decorative Life Preserver Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 120,
            "BaseXPGain": 0.5,
            "CraftingTable": "Small Shipyard",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Decorative Life Preserver",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Shipwright",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "DecorativeLifePreserver",
                    "Name": "Decorative Life Preserver",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plastic",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Decorative Life Preserver",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Tiki Torch",
            "Untranslated": "Tiki Torch Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 360,
            "BaseXPGain": 0.5,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Tiki Torch",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Logging",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "TikiTorch",
                    "Name": "Tiki Torch",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Tiki Torch",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Large Hanging Mortared Stone Sign",
            "Untranslated": "Large Hanging Mortared Stone Sign Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 60,
            "BaseXPGain": 2,
            "CraftingTable": "Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Large Hanging Mortared Stone Sign",
            "NumberOfVariants": 4,
            "SkillNeeds": [
                {
                    "Skill": "Masonry",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "LargeHangingMortaredStoneSign",
                    "Name": "Large Hanging Mortared Stone Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Mortared Stone",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Hanging Mortared Stone Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeHangingMortaredGraniteSign",
                    "Name": "Large Hanging Mortared Granite Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Mortared Granite",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Hanging Mortared Granite Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeHangingMortaredLimestoneSign",
                    "Name": "Large Hanging Mortared Limestone Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Mortared Limestone",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Hanging Mortared Limestone Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeHangingMortaredSandstoneSign",
                    "Name": "Large Hanging Mortared Sandstone Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Mortared Sandstone",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Hanging Mortared Sandstone Sign",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Ashlar Small Stone Fountain",
            "Untranslated": "Ashlar Small Stone Fountain Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 180,
            "BaseXPGain": 2,
            "CraftingTable": "Advanced Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Ashlar Small Stone Fountain",
            "NumberOfVariants": 7,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Masonry",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "AshlarSmallStoneFountain",
                    "Name": "Ashlar Small Stone Fountain",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Ashlar Stone",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Small Stone Fountain",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AshlarSmallGraniteFountain",
                    "Name": "Ashlar Small Granite Fountain",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Granite",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Small Granite Fountain",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AshlarSmallBasaltFountain",
                    "Name": "Ashlar Small Basalt Fountain",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Basalt",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Small Basalt Fountain",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AshlarSmallShaleFountain",
                    "Name": "Ashlar Small Shale Fountain",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Shale",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Small Shale Fountain",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AshlarSmallSandstoneFountain",
                    "Name": "Ashlar Small Sandstone Fountain",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Sandstone",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Small Sandstone Fountain",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AshlarSmallGneissFountain",
                    "Name": "Ashlar Small Gneiss Fountain",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Gneiss",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Small Gneiss Fountain",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AshlarSmallLimestoneFountain",
                    "Name": "Ashlar Small Limestone Fountain",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Limestone",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Small Limestone Fountain",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Elevator Call Post",
            "Untranslated": "Elevator Call Post Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 120,
            "BaseXPGain": 2,
            "CraftingTable": "Sawmill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Elevator Call Post",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "ElevatorCallPost",
                    "Name": "Elevator Call Post",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Elevator Call Post",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Stop Sign",
            "Untranslated": "Stop Sign Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 60,
            "BaseXPGain": 1,
            "CraftingTable": "Wainwright Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Stop Sign",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Basic Engineering",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "StopSign",
                    "Name": "Stop Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Stop Sign",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Brazier",
            "Untranslated": "Brazier Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 120,
            "BaseXPGain": 3,
            "CraftingTable": "Blacksmith Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Brazier",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "Brazier",
                    "Name": "Brazier",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Tallow",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Brazier",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Registrar",
            "Untranslated": "Registrar Recipe",
            "BaseCraftTime": 35,
            "BaseLaborCost": 150,
            "BaseXPGain": 10,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Registrar",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Logging",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "Registrar",
                    "Name": "Registrar",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Ore",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 16,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Registrar",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Spin Melter",
            "Untranslated": "Spin Melter Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 300,
            "BaseXPGain": 10,
            "CraftingTable": "Electric Machinist Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Spin Melter",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Industry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "SpinMelter",
                    "Name": "Spin Melter",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Plate",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Basic Circuit",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Spin Melter",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Board Of Elections",
            "Untranslated": "Board Of Elections Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 1200,
            "BaseXPGain": 1,
            "CraftingTable": "Settlement Crafting Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Board Of Elections",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "BoardOfElections",
                    "Name": "Board Of Elections",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Mortared Stone",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Board Of Elections",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Galaxy Vase",
            "Untranslated": "Galaxy Vase Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 90,
            "BaseXPGain": 1,
            "CraftingTable": "Glassworks",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Galaxy Vase",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Glassworking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "GalaxyVase",
                    "Name": "Galaxy Vase",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Glass",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Blue Powder",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Galaxy Vase",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Capitol",
            "Untranslated": "Capitol Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 1200,
            "BaseXPGain": 1,
            "CraftingTable": "Settlement Crafting Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Capitol",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "Capitol",
                    "Name": "Capitol",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 30,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Capitol",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Redwoods Poster",
            "Untranslated": "Redwoods Poster Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 30,
            "BaseXPGain": 0.1,
            "CraftingTable": "Printing Press",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Redwoods Poster",
            "NumberOfVariants": 4,
            "SkillNeeds": [
                {
                    "Skill": "Paper Milling",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "RedwoodsPoster",
                    "Name": "Redwoods Poster",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Printing Supplies",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Paper",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Redwoods Poster",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CartBlueprintPoster",
                    "Name": "Cart Blueprint Poster",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Printing Supplies",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Paper",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Cart Blueprint Poster",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "PeriodicTablePoster",
                    "Name": "Periodic Table Poster",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Printing Supplies",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Paper",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Periodic Table Poster",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "ElectionPoster",
                    "Name": "Election Poster",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Printing Supplies",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Paper",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Election Poster",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Fish Trap",
            "Untranslated": "Fish Trap Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 120,
            "BaseXPGain": 3,
            "CraftingTable": "Fishery",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Fish Trap",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Hunting",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "FishTrap",
                    "Name": "Fish Trap",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plant Fibers",
                            "Ammount": 15,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Fish Trap",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Huge Steel Sign",
            "Untranslated": "Huge Steel Sign Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 60,
            "BaseXPGain": 1,
            "CraftingTable": "Rolling Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Huge Steel Sign",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Smelting",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "HugeSteelSign",
                    "Name": "Huge Steel Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Plate",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Light Bulb",
                            "Ammount": 3,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Huge Steel Sign",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Rug Medium",
            "Untranslated": "Rug Medium Recipe",
            "BaseCraftTime": 8.4,
            "BaseLaborCost": 60,
            "BaseXPGain": 2,
            "CraftingTable": "Loom",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Rug Medium",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "RugMedium",
                    "Name": "Rug Medium",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cellulose Fiber",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wool Yarn",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fabric",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Rug Medium",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Waterwheel",
            "Untranslated": "Waterwheel Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 180,
            "BaseXPGain": 8,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Waterwheel",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Basic Engineering",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Waterwheel",
                    "Name": "Waterwheel",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Lubricant",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Waterwheel",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Cast Iron Stove",
            "Untranslated": "Cast Iron Stove Recipe",
            "BaseCraftTime": 8.4,
            "BaseLaborCost": 300,
            "BaseXPGain": 15,
            "CraftingTable": "Bloomery",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Cast Iron Stove",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Smelting",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "CastIronStove",
                    "Name": "Cast Iron Stove",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cooking Utensils",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Cast Iron Stove",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Purple Vase",
            "Untranslated": "Purple Vase Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 90,
            "BaseXPGain": 1,
            "CraftingTable": "Glassworks",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Purple Vase",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Glassworking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "PurpleVase",
                    "Name": "Purple Vase",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Glass",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Blue Powder",
                            "Ammount": 1,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Oxide",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Purple Vase",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Lumber Chair",
            "Untranslated": "Lumber Chair Recipe",
            "BaseCraftTime": 4.2,
            "BaseLaborCost": 60,
            "BaseXPGain": 2,
            "CraftingTable": "Sawmill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Lumber Chair",
            "NumberOfVariants": 3,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "LumberChair",
                    "Name": "Lumber Chair",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nail",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Lumber Chair",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "HardwoodLumberChair",
                    "Name": "Hardwood Lumber Chair",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Hardwood Lumber",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nail",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Hardwood Lumber Chair",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SoftwoodLumberChair",
                    "Name": "Softwood Lumber Chair",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Softwood Lumber",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nail",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Softwood Lumber Chair",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Decorative Ship Wheel",
            "Untranslated": "Decorative Ship Wheel Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 240,
            "BaseXPGain": 0.5,
            "CraftingTable": "Sawmill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Decorative Ship Wheel",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "DecorativeShipWheel",
                    "Name": "Decorative Ship Wheel",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Decorative Ship Wheel",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Electronics Assembly",
            "Untranslated": "Electronics Assembly Recipe",
            "BaseCraftTime": 35,
            "BaseLaborCost": 300,
            "BaseXPGain": 20,
            "CraftingTable": "Electric Machinist Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Electronics Assembly",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Electronics",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "ElectronicsAssembly",
                    "Name": "Electronics Assembly",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Corrugated Steel",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Rivet",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Wiring",
                            "Ammount": 25,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plastic",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Electronics Assembly",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Nylon Futon Bed",
            "Untranslated": "Nylon Futon Bed Recipe",
            "BaseCraftTime": 8.4,
            "BaseLaborCost": 180,
            "BaseXPGain": 6,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Nylon Futon Bed",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "NylonFutonBed",
                    "Name": "Nylon Futon Bed",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nylon Fabric",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 15,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Nylon Futon Bed",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Store",
            "Untranslated": "Store Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 100,
            "BaseXPGain": 1,
            "CraftingTable": "Workbench",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Store",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "Store",
                    "Name": "Store",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Rock",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Store",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Small Cotton Bunting Red",
            "Untranslated": "Small Cotton Bunting Red Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 120,
            "BaseXPGain": 1,
            "CraftingTable": "Loom",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Small Cotton Bunting Red",
            "NumberOfVariants": 4,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "SmallCottonBuntingRed",
                    "Name": "Small Cotton Bunting Red",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 3,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Cotton Bunting Red",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallCottonBuntingFestive",
                    "Name": "Small Cotton Bunting Festive",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 3,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Cotton Bunting Festive",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallCottonBuntingVibrant",
                    "Name": "Small Cotton Bunting Vibrant",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 3,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Cotton Bunting Vibrant",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallCottonBuntingRegal",
                    "Name": "Small Cotton Bunting Regal",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 3,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Cotton Bunting Regal",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Kiln",
            "Untranslated": "Kiln Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 360,
            "BaseXPGain": 5,
            "CraftingTable": "Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Kiln",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Masonry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Kiln",
                    "Name": "Kiln",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Mortar",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Mortared Stone",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Kiln",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Outpost Table",
            "Untranslated": "Outpost Table Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 1200,
            "BaseXPGain": 1,
            "CraftingTable": "Settlement Crafting Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Outpost Table",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "OutpostTable",
                    "Name": "Outpost Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Glass Lens",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Paper",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Outpost Table",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Lighthouse Lamp",
            "Untranslated": "Lighthouse Lamp Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 480,
            "BaseXPGain": 4,
            "CraftingTable": "Machinist Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Lighthouse Lamp",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "LighthouseLamp",
                    "Name": "Lighthouse Lamp",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Glass",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Wiring",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Plate",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Electric Motor",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Lighthouse Lamp",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Screening Machine",
            "Untranslated": "Screening Machine Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 300,
            "BaseXPGain": 5,
            "CraftingTable": "Machinist Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Screening Machine",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "ScreeningMachine",
                    "Name": "Screening Machine",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 40,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 15,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Gear",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Screening Machine",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Carpentry Table",
            "Untranslated": "Carpentry Table Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 100,
            "BaseXPGain": 1,
            "CraftingTable": "Workbench",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Carpentry Table",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "CarpentryTable",
                    "Name": "Carpentry Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Rock",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Carpentry Table",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Executive Office",
            "Untranslated": "Executive Office Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 1200,
            "BaseXPGain": 1,
            "CraftingTable": "Settlement Crafting Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Executive Office",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "ExecutiveOffice",
                    "Name": "Executive Office",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Mortared Stone",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Executive Office",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Plastic Outdoor Bench",
            "Untranslated": "Plastic Outdoor Bench Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 120,
            "BaseXPGain": 0.5,
            "CraftingTable": "Injection Mold Machine",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Plastic Outdoor Bench",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Oil Drilling",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "PlasticOutdoorBench",
                    "Name": "Plastic Outdoor Bench",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plastic",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Plastic Outdoor Bench",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Granite Spiral Fountain",
            "Untranslated": "Granite Spiral Fountain Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 360,
            "BaseXPGain": 2,
            "CraftingTable": "Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Granite Spiral Fountain",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Masonry",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "GraniteSpiralFountain",
                    "Name": "Granite Spiral Fountain",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Granite",
                            "Ammount": 40,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Granite Spiral Fountain",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Mortared Stone Chair",
            "Untranslated": "Mortared Stone Chair Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 30,
            "BaseXPGain": 2,
            "CraftingTable": "Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Mortared Stone Chair",
            "NumberOfVariants": 4,
            "SkillNeeds": [
                {
                    "Skill": "Masonry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "MortaredStoneChair",
                    "Name": "Mortared Stone Chair",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Mortared Stone",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Mortared Stone Chair",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "MortaredLimestoneChair",
                    "Name": "Mortared Limestone Chair",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Mortared Limestone",
                            "Ammount": 15,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Mortared Limestone Chair",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "MortaredSandstoneChair",
                    "Name": "Mortared Sandstone Chair",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Mortared Sandstone",
                            "Ammount": 15,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Mortared Sandstone Chair",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "MortaredGraniteChair",
                    "Name": "Mortared Granite Chair",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Mortared Granite",
                            "Ammount": 15,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Mortared Granite Chair",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Upholstered Couch",
            "Untranslated": "Upholstered Couch Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 60,
            "BaseXPGain": 2,
            "CraftingTable": "Advanced Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Upholstered Couch",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "UpholsteredCouch",
                    "Name": "Upholstered Couch",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 15,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Spring",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Upholstered Couch",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Modern Street Light",
            "Untranslated": "Modern Street Light Recipe",
            "BaseCraftTime": 8.4,
            "BaseLaborCost": 120,
            "BaseXPGain": 5,
            "CraftingTable": "Robotic Assembly Line",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Modern Street Light",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Electronics",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "ModernStreetLight",
                    "Name": "Modern Street Light",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Plate",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plastic",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Wiring",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Light Bulb",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Modern Street Light",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Huge Wooden Banner Sign",
            "Untranslated": "Huge Wooden Banner Sign Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 120,
            "BaseXPGain": 1,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Huge Wooden Banner Sign",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Logging",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "HugeWoodenBannerSign",
                    "Name": "Huge Wooden Banner Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Linen Yarn",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 16,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Huge Wooden Banner Sign",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Window Shutters Center",
            "Untranslated": "Window Shutters Center Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 360,
            "BaseXPGain": 0.5,
            "CraftingTable": "Sawmill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Window Shutters Center",
            "NumberOfVariants": 3,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "WindowShuttersCenter",
                    "Name": "Window Shutters Center",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Window Shutters Center",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "WindowShuttersRight",
                    "Name": "Window Shutters Right",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Window Shutters Right",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "WindowShuttersLeft",
                    "Name": "Window Shutters Left",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Window Shutters Left",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Assembly Line",
            "Untranslated": "Assembly Line Recipe",
            "BaseCraftTime": 56,
            "BaseLaborCost": 240,
            "BaseXPGain": 40,
            "CraftingTable": "Machinist Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Assembly Line",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "AssemblyLine",
                    "Name": "Assembly Line",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Gear",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Portable Steam Engine",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Assembly Line",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Cast Iron Plaque",
            "Untranslated": "Cast Iron Plaque Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 80,
            "BaseXPGain": 1,
            "CraftingTable": "Bloomery",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Cast Iron Plaque",
            "NumberOfVariants": 2,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "CastIronPlaque",
                    "Name": "Cast Iron Plaque",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Concentrate",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Cast Iron Plaque",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "OrnateCastIronPlaque",
                    "Name": "Ornate Cast Iron Plaque",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Plate",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cast Iron Plaque",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ornate Cast Iron Plaque",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wooden Straw Bed",
            "Untranslated": "Wooden Straw Bed Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 60,
            "BaseXPGain": 2,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Wooden Straw Bed",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "WoodenStrawBed",
                    "Name": "Wooden Straw Bed",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plant Fibers",
                            "Ammount": 40,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wooden Straw Bed",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Toilet",
            "Untranslated": "Toilet Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 120,
            "BaseXPGain": 2,
            "CraftingTable": "Kiln",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Toilet",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Pottery",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "Toilet",
                    "Name": "Toilet",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Clay",
                            "Ammount": 30,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Pipe",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "White Powder",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Toilet",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Ornate Wooden Frame Wide",
            "Untranslated": "Ornate Wooden Frame Wide Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 160,
            "BaseXPGain": 1,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Ornate Wooden Frame Wide",
            "NumberOfVariants": 5,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "OrnateWoodenFrameWide",
                    "Name": "Ornate Wooden Frame Wide",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ornate Wooden Frame Wide",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "OrnateWoodenFrameSquare",
                    "Name": "Ornate Wooden Frame Square",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ornate Wooden Frame Square",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "OrnateWoodenFrameTall",
                    "Name": "Ornate Wooden Frame Tall",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ornate Wooden Frame Tall",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "OrnateWoodenFramePortrait",
                    "Name": "Ornate Wooden Frame Portrait",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ornate Wooden Frame Portrait",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "OrnateWoodenFrameLandscape",
                    "Name": "Ornate Wooden Frame Landscape",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ornate Wooden Frame Landscape",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Fish Rack",
            "Untranslated": "Fish Rack Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 240,
            "BaseXPGain": 0.5,
            "CraftingTable": "Fishery",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Fish Rack",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Hunting",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "FishRack",
                    "Name": "Fish Rack",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plant Fibers",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Fish Rack",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Sink",
            "Untranslated": "Sink Recipe",
            "BaseCraftTime": 11.2,
            "BaseLaborCost": 110,
            "BaseXPGain": 5,
            "CraftingTable": "Assembly Line",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Sink",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "Sink",
                    "Name": "Sink",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Plate",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Sink",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Distribution Station",
            "Untranslated": "Distribution Station Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 100,
            "BaseXPGain": 5,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Distribution Station",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "DistributionStation",
                    "Name": "Distribution Station",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Distribution Station",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Hanging Steel Framed Sign",
            "Untranslated": "Hanging Steel Framed Sign Recipe",
            "BaseCraftTime": 4.2,
            "BaseLaborCost": 120,
            "BaseXPGain": 1,
            "CraftingTable": "Rolling Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Hanging Steel Framed Sign",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Smelting",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "HangingSteelFramedSign",
                    "Name": "Hanging Steel Framed Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Rivet",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Hanging Steel Framed Sign",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Goat Mount",
            "Untranslated": "Goat Mount Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 300,
            "BaseXPGain": 4,
            "CraftingTable": "Butchery Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Goat Mount",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Hunting",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "GoatMount",
                    "Name": "Goat Mount",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fabric",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Mountain Goat Carcass",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Goat Mount",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Stuffed Bison",
            "Untranslated": "Stuffed Bison Recipe",
            "BaseCraftTime": 16.8,
            "BaseLaborCost": 300,
            "BaseXPGain": 4,
            "CraftingTable": "Butchery Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Stuffed Bison",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Hunting",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "StuffedBison",
                    "Name": "Stuffed Bison",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fabric",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Bison Carcass",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Stuffed Bison",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Salt Basket",
            "Untranslated": "Salt Basket Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 100,
            "BaseXPGain": 3,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Salt Basket",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Farming",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "SaltBasket",
                    "Name": "Salt Basket",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Salt Basket",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Mill",
            "Untranslated": "Mill Recipe",
            "BaseCraftTime": 11.2,
            "BaseLaborCost": 360,
            "BaseXPGain": 5,
            "CraftingTable": "Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Mill",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Masonry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Mill",
                    "Name": "Mill",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Rock",
                            "Name": "",
                            "Ammount": 30,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Mill Stone",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Mill",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Combustion Generator",
            "Untranslated": "Combustion Generator Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 600,
            "BaseXPGain": 25,
            "CraftingTable": "Assembly Line",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Combustion Generator",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "CombustionGenerator",
                    "Name": "Combustion Generator",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Piston",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Combustion Engine",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Combustion Generator",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Large Standing Lumber Sign",
            "Untranslated": "Large Standing Lumber Sign Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 120,
            "BaseXPGain": 3,
            "CraftingTable": "Sawmill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Large Standing Lumber Sign",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "LargeStandingLumberSign",
                    "Name": "Large Standing Lumber Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Standing Lumber Sign",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Tailoring Table",
            "Untranslated": "Tailoring Table Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 360,
            "BaseXPGain": 5,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Tailoring Table",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "TailoringTable",
                    "Name": "Tailoring Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plant Fibers",
                            "Ammount": 50,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Tailoring Table",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Grindstone",
            "Untranslated": "Grindstone Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 180,
            "BaseXPGain": 5,
            "CraftingTable": "Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Grindstone",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Masonry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Grindstone",
                    "Name": "Grindstone",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Rock",
                            "Name": "",
                            "Ammount": 40,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Grindstone",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Shelf Cabinet",
            "Untranslated": "Shelf Cabinet Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 60,
            "BaseXPGain": 5,
            "CraftingTable": "Sawmill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Shelf Cabinet",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "ShelfCabinet",
                    "Name": "Shelf Cabinet",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 14,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 16,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Shelf Cabinet",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Farmers Table",
            "Untranslated": "Farmers Table Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 200,
            "BaseXPGain": 5,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Farmers Table",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Logging",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "FarmersTable",
                    "Name": "Farmers Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Dirt",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Farmers Table",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Steel Moorage Post",
            "Untranslated": "Steel Moorage Post Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 240,
            "BaseXPGain": 1,
            "CraftingTable": "Medium Shipyard",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Steel Moorage Post",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Shipwright",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "SteelMooragePost",
                    "Name": "Steel Moorage Post",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Rivet",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nylon Mooring Rope",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Steel Moorage Post",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Bookshelf",
            "Untranslated": "Bookshelf Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 120,
            "BaseXPGain": 10,
            "CraftingTable": "Sawmill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Bookshelf",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "Bookshelf",
                    "Name": "Bookshelf",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Paper",
                            "Ammount": 50,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 14,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 16,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Bookshelf",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Hewn Nightstand",
            "Untranslated": "Hewn Nightstand Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 60,
            "BaseXPGain": 2,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Hewn Nightstand",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Logging",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "HewnNightstand",
                    "Name": "Hewn Nightstand",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 14,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Hewn Nightstand",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wood Window Planter",
            "Untranslated": "Wood Window Planter Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 360,
            "BaseXPGain": 0.5,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Wood Window Planter",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Fertilizers",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "WoodWindowPlanter",
                    "Name": "Wood Window Planter",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wood Window Planter",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Large Nylon Bunting Green",
            "Untranslated": "Large Nylon Bunting Green Recipe",
            "BaseCraftTime": 2.1,
            "BaseLaborCost": 120,
            "BaseXPGain": 2,
            "CraftingTable": "Automatic Loom",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Large Nylon Bunting Green",
            "NumberOfVariants": 3,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "LargeNylonBuntingGreen",
                    "Name": "Large Nylon Bunting Green",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nylon Fabric",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Nylon Bunting Green",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeNylonBuntingPurple",
                    "Name": "Large Nylon Bunting Purple",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nylon Fabric",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Nylon Bunting Purple",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeNylonBuntingYellow",
                    "Name": "Large Nylon Bunting Yellow",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nylon Fabric",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Nylon Bunting Yellow",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Shaper",
            "Untranslated": "Shaper Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 300,
            "BaseXPGain": 5,
            "CraftingTable": "Machinist Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Shaper",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Shaper",
                    "Name": "Shaper",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Plate",
                            "Ammount": 16,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Piston",
                            "Ammount": 16,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Shaper",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Tool Bench",
            "Untranslated": "Tool Bench Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 50,
            "BaseXPGain": 1,
            "CraftingTable": "Campsite",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Tool Bench",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "ToolBench",
                    "Name": "Tool Bench",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Rock",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Tool Bench",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Amendments",
            "Untranslated": "Amendments Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 1200,
            "BaseXPGain": 1,
            "CraftingTable": "Settlement Crafting Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Amendments",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "Amendments",
                    "Name": "Amendments",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Mortared Stone",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Amendments",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Hewn Door",
            "Untranslated": "Hewn Door Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 60,
            "BaseXPGain": 0.5,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Hewn Door",
            "NumberOfVariants": 4,
            "SkillNeeds": [
                {
                    "Skill": "Logging",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "HewnDoor",
                    "Name": "Hewn Door",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Hewn Door",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "HewnHardwoodDoor",
                    "Name": "Hewn Hardwood Door",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Hardwood Hewn Log",
                            "Ammount": 2,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Hewn Hardwood Door",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "FancyHewnDoor",
                    "Name": "Fancy Hewn Door",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Hewn Door",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Fancy Hewn Door",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "HewnSoftwoodDoor",
                    "Name": "Hewn Softwood Door",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Softwood Hewn Log",
                            "Ammount": 2,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Hewn Softwood Door",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Campsite",
            "Untranslated": "Campsite Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 15,
            "BaseXPGain": 1,
            "CraftingTable": "Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Campsite",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 0
                }
            ],
            "Variants": [
                {
                    "Key": "Campsite",
                    "Name": "Campsite",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fabric",
                            "Name": "",
                            "Ammount": 24,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Campsite",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Fiber Scutching Station",
            "Untranslated": "Fiber Scutching Station Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 300,
            "BaseXPGain": 2,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Fiber Scutching Station",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Logging",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "FiberScutchingStation",
                    "Name": "Fiber Scutching Station",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Fiber Scutching Station",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Rolling Mill",
            "Untranslated": "Rolling Mill Recipe",
            "BaseCraftTime": 28,
            "BaseLaborCost": 360,
            "BaseXPGain": 5,
            "CraftingTable": "Assembly Line",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Rolling Mill",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "RollingMill",
                    "Name": "Rolling Mill",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gearbox",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Rolling Mill",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Lumber Table",
            "Untranslated": "Lumber Table Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 60,
            "BaseXPGain": 3,
            "CraftingTable": "Sawmill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Lumber Table",
            "NumberOfVariants": 4,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "LumberTable",
                    "Name": "Lumber Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nail",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 18,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Lumber Table",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SoftwoodLumberTable",
                    "Name": "Softwood Lumber Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Softwood Lumber",
                            "Ammount": 18,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nail",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Softwood Lumber Table",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "HardwoodLumberTable",
                    "Name": "Hardwood Lumber Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Hardwood Lumber",
                            "Ammount": 18,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nail",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Hardwood Lumber Table",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "ZatakuTable",
                    "Name": "Zataku Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nail",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 18,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Zataku Table",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Census Bureau",
            "Untranslated": "Census Bureau Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 1200,
            "BaseXPGain": 1,
            "CraftingTable": "Settlement Crafting Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Census Bureau",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "CensusBureau",
                    "Name": "Census Bureau",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Mortared Stone",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Census Bureau",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Steam Engine",
            "Untranslated": "Steam Engine Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 450,
            "BaseXPGain": 25,
            "CraftingTable": "Machinist Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Steam Engine",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "SteamEngine",
                    "Name": "Steam Engine",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 24,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Pipe",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Lubricant",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Steam Engine",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Brick Fireplace",
            "Untranslated": "Brick Fireplace Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 300,
            "BaseXPGain": 3,
            "CraftingTable": "Kiln",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Brick Fireplace",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Pottery",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "BrickFireplace",
                    "Name": "Brick Fireplace",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Brick",
                            "Ammount": 50,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Brick Fireplace",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Small Standing Mortared Stone Sign",
            "Untranslated": "Small Standing Mortared Stone Sign Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Small Standing Mortared Stone Sign",
            "NumberOfVariants": 4,
            "SkillNeeds": [
                {
                    "Skill": "Masonry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "SmallStandingMortaredStoneSign",
                    "Name": "Small Standing Mortared Stone Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Mortared Stone",
                            "Name": "",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Standing Mortared Stone Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallStandingMortaredSandstoneSign",
                    "Name": "Small Standing Mortared Sandstone Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Mortared Sandstone",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Standing Mortared Sandstone Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallStandingMortaredGraniteSign",
                    "Name": "Small Standing Mortared Granite Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Mortared Granite",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Standing Mortared Granite Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallStandingMortaredLimestoneSign",
                    "Name": "Small Standing Mortared Limestone Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Mortared Limestone",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Standing Mortared Limestone Sign",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Small Hanging Mortared Stone Sign",
            "Untranslated": "Small Hanging Mortared Stone Sign Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Small Hanging Mortared Stone Sign",
            "NumberOfVariants": 4,
            "SkillNeeds": [
                {
                    "Skill": "Masonry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "SmallHangingMortaredStoneSign",
                    "Name": "Small Hanging Mortared Stone Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Mortared Stone",
                            "Name": "",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Hanging Mortared Stone Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallHangingMortaredGraniteSign",
                    "Name": "Small Hanging Mortared Granite Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Mortared Granite",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Hanging Mortared Granite Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallHangingMortaredLimestoneSign",
                    "Name": "Small Hanging Mortared Limestone Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Mortared Limestone",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Hanging Mortared Limestone Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallHangingMortaredSandstoneSign",
                    "Name": "Small Hanging Mortared Sandstone Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Mortared Sandstone",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Hanging Mortared Sandstone Sign",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wooden Latrine",
            "Untranslated": "Wooden Latrine Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 60,
            "BaseXPGain": 2,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Wooden Latrine",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "Latrine",
                    "Name": "Wooden Latrine",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wooden Latrine",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Ornate Gold Frame Wide",
            "Untranslated": "Ornate Gold Frame Wide Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 160,
            "BaseXPGain": 1,
            "CraftingTable": "Anvil",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Ornate Gold Frame Wide",
            "NumberOfVariants": 5,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "OrnateGoldFrameWide",
                    "Name": "Ornate Gold Frame Wide",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Bar",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ornate Gold Frame Wide",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "OrnateGoldFrameSquare",
                    "Name": "Ornate Gold Frame Square",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Bar",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ornate Gold Frame Square",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "OrnateGoldFrameTall",
                    "Name": "Ornate Gold Frame Tall",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Bar",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ornate Gold Frame Tall",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "OrnateGoldFramePortrait",
                    "Name": "Ornate Gold Frame Portrait",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Bar",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ornate Gold Frame Portrait",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "OrnateGoldFrameLandscape",
                    "Name": "Ornate Gold Frame Landscape",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Bar",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ornate Gold Frame Landscape",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Hanging Steel Plain Sign",
            "Untranslated": "Hanging Steel Plain Sign Recipe",
            "BaseCraftTime": 4.2,
            "BaseLaborCost": 120,
            "BaseXPGain": 1,
            "CraftingTable": "Rolling Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Hanging Steel Plain Sign",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Smelting",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "HangingSteelPlainSign",
                    "Name": "Hanging Steel Plain Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Plate",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Rivet",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Hanging Steel Plain Sign",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Solar Generator",
            "Untranslated": "Solar Generator Recipe",
            "BaseCraftTime": 28,
            "BaseLaborCost": 600,
            "BaseXPGain": 20,
            "CraftingTable": "Electronics Assembly",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Solar Generator",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Electronics",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "SolarGenerator",
                    "Name": "Solar Generator",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Servo",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Basic Circuit",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Solar Generator",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Cast Iron Table",
            "Untranslated": "Cast Iron Table Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 60,
            "BaseXPGain": 3,
            "CraftingTable": "Bloomery",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Cast Iron Table",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Smelting",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "CastIronTable",
                    "Name": "Cast Iron Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Clay Mold",
                            "Ammount": 3,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Cast Iron Table",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Blast Furnace",
            "Untranslated": "Blast Furnace Recipe",
            "BaseCraftTime": 35,
            "BaseLaborCost": 600,
            "BaseXPGain": 20,
            "CraftingTable": "Assembly Line",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Blast Furnace",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "BlastFurnace",
                    "Name": "Blast Furnace",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Brick",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Plate",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Pipe",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Blast Furnace",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Steel Floor Lamp",
            "Untranslated": "Steel Floor Lamp Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 120,
            "BaseXPGain": 5,
            "CraftingTable": "Robotic Assembly Line",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Steel Floor Lamp",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Electronics",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "SteelFloorLamp",
                    "Name": "Steel Floor Lamp",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plastic",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Wiring",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Light Bulb",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Steel Floor Lamp",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wooden Kitchen Lamp",
            "Untranslated": "Wooden Kitchen Lamp Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 120,
            "BaseXPGain": 3,
            "CraftingTable": "Sawmill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Wooden Kitchen Lamp",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "WoodenKitchenLamp",
                    "Name": "Wooden Kitchen Lamp",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Plate",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Wiring",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Light Bulb",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wooden Kitchen Lamp",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Hewn Table",
            "Untranslated": "Hewn Table Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 60,
            "BaseXPGain": 2,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Hewn Table",
            "NumberOfVariants": 3,
            "SkillNeeds": [
                {
                    "Skill": "Logging",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "HewnTable",
                    "Name": "Hewn Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Hewn Table",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "HewnHardwoodTable",
                    "Name": "Hewn Hardwood Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Hardwood Hewn Log",
                            "Ammount": 15,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Hardwood Board",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Hewn Hardwood Table",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "HewnSoftwoodTable",
                    "Name": "Hewn Softwood Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Softwood Hewn Log",
                            "Ammount": 15,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Softwood Board",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Hewn Softwood Table",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Huge Iron Sign",
            "Untranslated": "Huge Iron Sign Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 60,
            "BaseXPGain": 1,
            "CraftingTable": "Anvil",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Huge Iron Sign",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "HugeIronSign",
                    "Name": "Huge Iron Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Plate",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Huge Iron Sign",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Small Hanging Lumber Sign",
            "Untranslated": "Small Hanging Lumber Sign Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 60,
            "BaseXPGain": 2,
            "CraftingTable": "Sawmill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Small Hanging Lumber Sign",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "SmallHangingLumberSign",
                    "Name": "Small Hanging Lumber Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Hanging Lumber Sign",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Hanging Long Steel Sign",
            "Untranslated": "Hanging Long Steel Sign Recipe",
            "BaseCraftTime": 4.2,
            "BaseLaborCost": 120,
            "BaseXPGain": 1,
            "CraftingTable": "Rolling Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Hanging Long Steel Sign",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Smelting",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "HangingLongSteelSign",
                    "Name": "Hanging Long Steel Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Plate",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Rivet",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Hanging Long Steel Sign",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Hewn Bench",
            "Untranslated": "Hewn Bench Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 60,
            "BaseXPGain": 3,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Hewn Bench",
            "NumberOfVariants": 3,
            "SkillNeeds": [
                {
                    "Skill": "Logging",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "HewnBench",
                    "Name": "Hewn Bench",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 18,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Hewn Bench",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "HewnSoftwoodBench",
                    "Name": "Hewn Softwood Bench",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Softwood Hewn Log",
                            "Ammount": 18,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Softwood Board",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Hewn Softwood Bench",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "HewnHardwoodBench",
                    "Name": "Hewn Hardwood Bench",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Hardwood Hewn Log",
                            "Ammount": 18,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Hardwood Board",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Hewn Hardwood Bench",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Ashlar Stone Table",
            "Untranslated": "Ashlar Stone Table Recipe",
            "BaseCraftTime": 8.4,
            "BaseLaborCost": 180,
            "BaseXPGain": 3,
            "CraftingTable": "Advanced Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Ashlar Stone Table",
            "NumberOfVariants": 7,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Masonry",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "AshlarStoneTable",
                    "Name": "Ashlar Stone Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Ashlar Stone",
                            "Name": "",
                            "Ammount": 16,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Stone Table",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AshlarBasaltTable",
                    "Name": "Ashlar Basalt Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Basalt",
                            "Ammount": 16,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Basalt Table",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AshlarGneissTable",
                    "Name": "Ashlar Gneiss Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Gneiss",
                            "Ammount": 16,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Gneiss Table",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AshlarGraniteTable",
                    "Name": "Ashlar Granite Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Granite",
                            "Ammount": 16,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Granite Table",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AshlarLimestoneTable",
                    "Name": "Ashlar Limestone Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Limestone",
                            "Ammount": 16,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Limestone Table",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AshlarShaleTable",
                    "Name": "Ashlar Shale Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Shale",
                            "Ammount": 16,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Shale Table",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AshlarSandstoneTable",
                    "Name": "Ashlar Sandstone Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Sandstone",
                            "Ammount": 16,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Sandstone Table",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Electric Planer",
            "Untranslated": "Electric Planer Recipe",
            "BaseCraftTime": 11.2,
            "BaseLaborCost": 240,
            "BaseXPGain": 20,
            "CraftingTable": "Electric Machinist Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Electric Planer",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Industry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "ElectricPlaner",
                    "Name": "Electric Planer",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Plate",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Rivet",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gearbox",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Electric Planer",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Stockpile",
            "Untranslated": "Stockpile Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Workbench",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Stockpile",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "Stockpile",
                    "Name": "Stockpile",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Stockpile",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Masonry Table",
            "Untranslated": "Masonry Table Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 100,
            "BaseXPGain": 1,
            "CraftingTable": "Workbench",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Masonry Table",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "MasonryTable",
                    "Name": "Masonry Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Rock",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Masonry Table",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Composite Lumber Bench",
            "Untranslated": "Composite Lumber Bench Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 180,
            "BaseXPGain": 3,
            "CraftingTable": "Advanced Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Composite Lumber Bench",
            "NumberOfVariants": 13,
            "SkillNeeds": [
                {
                    "Skill": "Composites",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "CompositeLumberBench",
                    "Name": "Composite Lumber Bench",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Composite Lumber",
                            "Name": "",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Lumber Bench",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeSpruceBench",
                    "Name": "Composite Spruce Bench",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Composite Spruce Lumber",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Spruce Bench",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeBirchBench",
                    "Name": "Composite Birch Bench",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Composite Birch Lumber",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Birch Bench",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeFirBench",
                    "Name": "Composite Fir Bench",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Composite Fir Lumber",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Fir Bench",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeOakBench",
                    "Name": "Composite Oak Bench",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Composite Oak Lumber",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Oak Bench",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeSaguaroBench",
                    "Name": "Composite Saguaro Bench",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Composite Saguaro Lumber",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Saguaro Bench",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeCedarBench",
                    "Name": "Composite Cedar Bench",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Composite Cedar Lumber",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Cedar Bench",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeHardwoodBench",
                    "Name": "Composite Hardwood Bench",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hardwood Lumber",
                            "Name": "",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Hardwood Bench",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositePalmBench",
                    "Name": "Composite Palm Bench",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Composite Palm Lumber",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Palm Bench",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeJoshuaBench",
                    "Name": "Composite Joshua Bench",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Composite Joshua Lumber",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Joshua Bench",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeCeibaBench",
                    "Name": "Composite Ceiba Bench",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Composite Ceiba Lumber",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Ceiba Bench",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeRedwoodBench",
                    "Name": "Composite Redwood Bench",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Composite Redwood Lumber",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Redwood Bench",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeSoftwoodBench",
                    "Name": "Composite Softwood Bench",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Softwood Lumber",
                            "Name": "",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Softwood Bench",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Cotton Streamer Stars",
            "Untranslated": "Cotton Streamer Stars Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 120,
            "BaseXPGain": 0.5,
            "CraftingTable": "Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Cotton Streamer Stars",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "CottonStreamerStars",
                    "Name": "Cotton Streamer Stars",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Cotton Streamer Stars",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Large Paper Lantern",
            "Untranslated": "Large Paper Lantern Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 60,
            "BaseXPGain": 1,
            "CraftingTable": "Small Paper Machine",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Large Paper Lantern",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Paper Milling",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "LargePaperLantern",
                    "Name": "Large Paper Lantern",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Paper",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Light Bulb",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Paper Lantern",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Huge Steel Banner Sign",
            "Untranslated": "Huge Steel Banner Sign Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 120,
            "BaseXPGain": 1,
            "CraftingTable": "Rolling Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Huge Steel Banner Sign",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Smelting",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "HugeSteelBannerSign",
                    "Name": "Huge Steel Banner Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 16,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Plate",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Light Bulb",
                            "Ammount": 6,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Huge Steel Banner Sign",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Court Table",
            "Untranslated": "Court Table Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 1200,
            "BaseXPGain": 1,
            "CraftingTable": "Settlement Crafting Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Court Table",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "CourtTable",
                    "Name": "Court Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Court Table",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Anvil",
            "Untranslated": "Anvil Recipe",
            "BaseCraftTime": 11.2,
            "BaseLaborCost": 180,
            "BaseXPGain": 10,
            "CraftingTable": "Bloomery",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Anvil",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Smelting",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Anvil",
                    "Name": "Anvil",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Anvil",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Decorative Anchor",
            "Untranslated": "Decorative Anchor Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 240,
            "BaseXPGain": 0.5,
            "CraftingTable": "Blacksmith Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Decorative Anchor",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "DecorativeAnchor",
                    "Name": "Decorative Anchor",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Decorative Anchor",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Advanced Tailoring Table",
            "Untranslated": "Advanced Tailoring Table Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 600,
            "BaseXPGain": 5,
            "CraftingTable": "Electric Machinist Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Advanced Tailoring Table",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Industry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "AdvancedTailoringTable",
                    "Name": "Advanced Tailoring Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Plate",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Basic Circuit",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Advanced Tailoring Table",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Ashlar Large Stone Fountain",
            "Untranslated": "Ashlar Large Stone Fountain Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 180,
            "BaseXPGain": 2,
            "CraftingTable": "Advanced Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Ashlar Large Stone Fountain",
            "NumberOfVariants": 7,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Masonry",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "AshlarLargeStoneFountain",
                    "Name": "Ashlar Large Stone Fountain",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Ashlar Stone",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Large Stone Fountain",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AshlarLargeGraniteFountain",
                    "Name": "Ashlar Large Granite Fountain",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Granite",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Large Granite Fountain",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AshlarLargeGneissFountain",
                    "Name": "Ashlar Large Gneiss Fountain",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Gneiss",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Large Gneiss Fountain",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AshlarLargeLimestoneFountain",
                    "Name": "Ashlar Large Limestone Fountain",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Limestone",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Large Limestone Fountain",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AshlarLargeSandstoneFountain",
                    "Name": "Ashlar Large Sandstone Fountain",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Sandstone",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Large Sandstone Fountain",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AshlarLargeBasaltFountain",
                    "Name": "Ashlar Large Basalt Fountain",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Basalt",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Large Basalt Fountain",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AshlarLargeShaleFountain",
                    "Name": "Ashlar Large Shale Fountain",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Shale",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Large Shale Fountain",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Mortared Stone Fireplace",
            "Untranslated": "Mortared Stone Fireplace Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 30,
            "BaseXPGain": 4,
            "CraftingTable": "Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Mortared Stone Fireplace",
            "NumberOfVariants": 4,
            "SkillNeeds": [
                {
                    "Skill": "Masonry",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "MortaredStoneFireplace",
                    "Name": "Mortared Stone Fireplace",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Mortared Stone",
                            "Name": "",
                            "Ammount": 40,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Mortared Stone Fireplace",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "MortaredGraniteFireplace",
                    "Name": "Mortared Granite Fireplace",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Mortared Granite",
                            "Ammount": 40,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Mortared Granite Fireplace",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "MortaredSandstoneFireplace",
                    "Name": "Mortared Sandstone Fireplace",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Mortared Sandstone",
                            "Ammount": 40,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Mortared Sandstone Fireplace",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "MortaredLimestoneFireplace",
                    "Name": "Mortared Limestone Fireplace",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Mortared Limestone",
                            "Ammount": 40,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Mortared Limestone Fireplace",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Tripod Camera",
            "Untranslated": "Tripod Camera Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 250,
            "BaseXPGain": 5,
            "CraftingTable": "Machinist Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Tripod Camera",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "TripodCamera",
                    "Name": "Tripod Camera",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Bar",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 16,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Glass Lens",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Light Bulb",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Tripod Camera",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Hanging Steel Mine Sign",
            "Untranslated": "Hanging Steel Mine Sign Recipe",
            "BaseCraftTime": 4.2,
            "BaseLaborCost": 120,
            "BaseXPGain": 1,
            "CraftingTable": "Rolling Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Hanging Steel Mine Sign",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Smelting",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "HangingSteelMineSign",
                    "Name": "Hanging Steel Mine Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Plate",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Rivet",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 3,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Hanging Steel Mine Sign",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Electric Stamping Press",
            "Untranslated": "Electric Stamping Press Recipe",
            "BaseCraftTime": 11.2,
            "BaseLaborCost": 240,
            "BaseXPGain": 20,
            "CraftingTable": "Electric Machinist Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Electric Stamping Press",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Industry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "ElectricStampingPress",
                    "Name": "Electric Stamping Press",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 18,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Plate",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Piston",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Wiring",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gearbox",
                            "Ammount": 2,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Electric Stamping Press",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Pottery Table",
            "Untranslated": "Pottery Table Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 180,
            "BaseXPGain": 1,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Pottery Table",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Logging",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "PotteryTable",
                    "Name": "Pottery Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wooden Wheel",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Pottery Table",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Large Standing Ashlar Stone Sign",
            "Untranslated": "Large Standing Ashlar Stone Sign Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 120,
            "BaseXPGain": 2,
            "CraftingTable": "Advanced Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Large Standing Ashlar Stone Sign",
            "NumberOfVariants": 7,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Masonry",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "LargeStandingAshlarStoneSign",
                    "Name": "Large Standing Ashlar Stone Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Ashlar Stone",
                            "Name": "",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Standing Ashlar Stone Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeStandingAshlarShaleSign",
                    "Name": "Large Standing Ashlar Shale Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Shale",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Standing Ashlar Shale Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeStandingAshlarGraniteSign",
                    "Name": "Large Standing Ashlar Granite Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Granite",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Standing Ashlar Granite Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeStandingAshlarLimestoneSign",
                    "Name": "Large Standing Ashlar Limestone Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Limestone",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Standing Ashlar Limestone Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeStandingAshlarSandstoneSign",
                    "Name": "Large Standing Ashlar Sandstone Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Sandstone",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Standing Ashlar Sandstone Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeStandingAshlarBasaltSign",
                    "Name": "Large Standing Ashlar Basalt Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Basalt",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Standing Ashlar Basalt Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeStandingAshlarGneissSign",
                    "Name": "Large Standing Ashlar Gneiss Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Gneiss",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Standing Ashlar Gneiss Sign",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Machinist Table",
            "Untranslated": "Machinist Table Recipe",
            "BaseCraftTime": 28,
            "BaseLaborCost": 300,
            "BaseXPGain": 10,
            "CraftingTable": "Sawmill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Machinist Table",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "MachinistTable",
                    "Name": "Machinist Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wooden Gear",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Machinist Table",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Bakery Oven",
            "Untranslated": "Bakery Oven Recipe",
            "BaseCraftTime": 11.2,
            "BaseLaborCost": 180,
            "BaseXPGain": 5,
            "CraftingTable": "Kiln",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Bakery Oven",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Pottery",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "BakeryOven",
                    "Name": "Bakery Oven",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Brick",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cooking Utensils",
                            "Ammount": 1,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Bakery Oven",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Hanging Steel Shop Sign",
            "Untranslated": "Hanging Steel Shop Sign Recipe",
            "BaseCraftTime": 4.2,
            "BaseLaborCost": 120,
            "BaseXPGain": 1,
            "CraftingTable": "Rolling Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Hanging Steel Shop Sign",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Smelting",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "HangingSteelShopSign",
                    "Name": "Hanging Steel Shop Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Plate",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Rivet",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Hanging Steel Shop Sign",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Plastic Window Planter",
            "Untranslated": "Plastic Window Planter Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 360,
            "BaseXPGain": 0.5,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Plastic Window Planter",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Fertilizers",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "PlasticWindowPlanter",
                    "Name": "Plastic Window Planter",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plastic",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Plastic Window Planter",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Carved Pumpkin",
            "Untranslated": "Carved Pumpkin Recipe",
            "BaseCraftTime": 4.2,
            "BaseLaborCost": 30,
            "BaseXPGain": 1,
            "CraftingTable": "Farmers Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Carved Pumpkin",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Farming",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CarvedPumpkin",
                    "Name": "Carved Pumpkin",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Tallow Candle",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Pumpkin",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Carved Pumpkin",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Contract Board",
            "Untranslated": "Contract Board Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 100,
            "BaseXPGain": 1,
            "CraftingTable": "Workbench",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Contract Board",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "ContractBoard",
                    "Name": "Contract Board",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 30,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Contract Board",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Plastic Outdoor Chair",
            "Untranslated": "Plastic Outdoor Chair Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 120,
            "BaseXPGain": 0.5,
            "CraftingTable": "Injection Mold Machine",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Plastic Outdoor Chair",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Oil Drilling",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "PlasticOutdoorChair",
                    "Name": "Plastic Outdoor Chair",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plastic",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Plastic Outdoor Chair",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wall Candle",
            "Untranslated": "Wall Candle Recipe",
            "BaseCraftTime": 4.2,
            "BaseLaborCost": 50,
            "BaseXPGain": 3,
            "CraftingTable": "Blacksmith Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Wall Candle",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "WallCandle",
                    "Name": "Wall Candle",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Tallow Candle",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wall Candle",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Kite",
            "Untranslated": "Kite Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 120,
            "BaseXPGain": 1,
            "CraftingTable": "Tailoring Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Kite",
            "NumberOfVariants": 2,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "Kite",
                    "Name": "Kite",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fabric",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Kite",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "FishKites",
                    "Name": "Fish Kites",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fabric",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Fish Kites",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Federation Foundation",
            "Untranslated": "Federation Foundation Recipe",
            "BaseCraftTime": 42,
            "BaseLaborCost": 3600,
            "BaseXPGain": 1,
            "CraftingTable": "Settlement Crafting Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Federation Foundation",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "FederationFoundation",
                    "Name": "Federation Foundation",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Granite",
                            "Ammount": 30,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Limestone",
                            "Ammount": 30,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nylon Carpet",
                            "Ammount": 30,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Bar",
                            "Ammount": 60,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Plate",
                            "Ammount": 120,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Composite Lumber",
                            "Name": "",
                            "Ammount": 60,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Federation Foundation",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Campfire",
            "Untranslated": "Campfire Recipe",
            "BaseCraftTime": 0.7,
            "BaseLaborCost": 20,
            "BaseXPGain": 1,
            "CraftingTable": "Campsite",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Campfire",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "Campfire",
                    "Name": "Campfire",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 3,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Rock",
                            "Name": "",
                            "Ammount": 12,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Campfire",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Modern Double Street Light",
            "Untranslated": "Modern Double Street Light Recipe",
            "BaseCraftTime": 8.4,
            "BaseLaborCost": 140,
            "BaseXPGain": 5,
            "CraftingTable": "Robotic Assembly Line",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Modern Double Street Light",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Electronics",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "ModernDoubleStreetLight",
                    "Name": "Modern Double Street Light",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Plate",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plastic",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Wiring",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Light Bulb",
                            "Ammount": 2,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Modern Double Street Light",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Bloomery",
            "Untranslated": "Bloomery Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 120,
            "BaseXPGain": 20,
            "CraftingTable": "Kiln",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Bloomery",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Masonry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "Bloomery",
                    "Name": "Bloomery",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Mortar",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Crushed Rock",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 25,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Bloomery",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Standing Long Steel Sign",
            "Untranslated": "Standing Long Steel Sign Recipe",
            "BaseCraftTime": 4.2,
            "BaseLaborCost": 120,
            "BaseXPGain": 1,
            "CraftingTable": "Rolling Mill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Standing Long Steel Sign",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Smelting",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "StandingLongSteelSign",
                    "Name": "Standing Long Steel Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Plate",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Rivet",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Standing Long Steel Sign",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Storage Silo",
            "Untranslated": "Storage Silo Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 600,
            "BaseXPGain": 3,
            "CraftingTable": "Sawmill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Storage Silo",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "StorageSilo",
                    "Name": "Storage Silo",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Pipe",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Storage Silo",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Composite Lumber Chair",
            "Untranslated": "Composite Lumber Chair Recipe",
            "BaseCraftTime": 4.2,
            "BaseLaborCost": 180,
            "BaseXPGain": 2,
            "CraftingTable": "Advanced Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Composite Lumber Chair",
            "NumberOfVariants": 13,
            "SkillNeeds": [
                {
                    "Skill": "Composites",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "CompositeLumberChair",
                    "Name": "Composite Lumber Chair",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Composite Lumber",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Lumber Chair",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeSoftwoodChair",
                    "Name": "Composite Softwood Chair",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Softwood Lumber",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Softwood Chair",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeRedwoodChair",
                    "Name": "Composite Redwood Chair",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Composite Redwood Lumber",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Redwood Chair",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositePalmChair",
                    "Name": "Composite Palm Chair",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Composite Palm Lumber",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Palm Chair",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeJoshuaChair",
                    "Name": "Composite Joshua Chair",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Composite Joshua Lumber",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Joshua Chair",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeFirChair",
                    "Name": "Composite Fir Chair",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Composite Fir Lumber",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Fir Chair",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeOakChair",
                    "Name": "Composite Oak Chair",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Composite Oak Lumber",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Oak Chair",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeCeibaChair",
                    "Name": "Composite Ceiba Chair",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Composite Ceiba Lumber",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Ceiba Chair",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeSpruceChair",
                    "Name": "Composite Spruce Chair",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Composite Spruce Lumber",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Spruce Chair",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeSaguaroChair",
                    "Name": "Composite Saguaro Chair",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Composite Saguaro Lumber",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Saguaro Chair",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeHardwoodChair",
                    "Name": "Composite Hardwood Chair",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hardwood Lumber",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Hardwood Chair",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeCedarChair",
                    "Name": "Composite Cedar Chair",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Composite Cedar Lumber",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Cedar Chair",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeBirchChair",
                    "Name": "Composite Birch Chair",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Composite Birch Lumber",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Birch Chair",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Small Standing Wood Sign",
            "Untranslated": "Small Standing Wood Sign Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 25,
            "BaseXPGain": 1,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Small Standing Wood Sign",
            "NumberOfVariants": 13,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "SmallStandingWoodSign",
                    "Name": "Small Standing Wood Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Standing Wood Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallStandingSpruceSign",
                    "Name": "Small Standing Spruce Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Spruce Log",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Standing Spruce Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallStandingRedwoodSign",
                    "Name": "Small Standing Redwood Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Redwood Log",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Standing Redwood Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallStandingCedarSign",
                    "Name": "Small Standing Cedar Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cedar Log",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Standing Cedar Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallStandingJoshuaSign",
                    "Name": "Small Standing Joshua Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Joshua Log",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Standing Joshua Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallStandingCeibaSign",
                    "Name": "Small Standing Ceiba Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ceiba Log",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Standing Ceiba Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallStandingSoftwoodSign",
                    "Name": "Small Standing Softwood Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Softwood Board",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Softwood",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Standing Softwood Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallStandingOakSign",
                    "Name": "Small Standing Oak Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Oak Log",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Standing Oak Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallStandingFirSign",
                    "Name": "Small Standing Fir Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Fir Log",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Standing Fir Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallStandingPalmSign",
                    "Name": "Small Standing Palm Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Palm Log",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Standing Palm Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallStandingBirchSign",
                    "Name": "Small Standing Birch Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Birch Log",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Standing Birch Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallStandingSaguaroSign",
                    "Name": "Small Standing Saguaro Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Saguaro Rib",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Standing Saguaro Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallStandingHardwoodSign",
                    "Name": "Small Standing Hardwood Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Hardwood Board",
                            "Ammount": 2,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hardwood",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Standing Hardwood Sign",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Research Table",
            "Untranslated": "Research Table Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 250,
            "BaseXPGain": 1,
            "CraftingTable": "Workbench",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Research Table",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "ResearchTable",
                    "Name": "Research Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Plant Fibers",
                            "Ammount": 30,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 30,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Rock",
                            "Name": "",
                            "Ammount": 40,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Research Table",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Ballot Box",
            "Untranslated": "Ballot Box Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 1200,
            "BaseXPGain": 1,
            "CraftingTable": "Settlement Crafting Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Ballot Box",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "BallotBox",
                    "Name": "Ballot Box",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Mortared Stone",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ballot Box",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Candle Stand",
            "Untranslated": "Candle Stand Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 60,
            "BaseXPGain": 3,
            "CraftingTable": "Blacksmith Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Candle Stand",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "CandleStand",
                    "Name": "Candle Stand",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 3,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Tallow Candle",
                            "Ammount": 3,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Candle Stand",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Town Foundation",
            "Untranslated": "Town Foundation Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 1200,
            "BaseXPGain": 1,
            "CraftingTable": "Settlement Crafting Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Town Foundation",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "TownFoundation",
                    "Name": "Town Foundation",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 30,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Mortared Stone",
                            "Name": "",
                            "Ammount": 30,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Town Foundation",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Standing Steel Brick Sign",
            "Untranslated": "Standing Steel Brick Sign Recipe",
            "BaseCraftTime": 4.2,
            "BaseLaborCost": 120,
            "BaseXPGain": 1,
            "CraftingTable": "Kiln",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Standing Steel Brick Sign",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Pottery",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "StandingSteelBrickSign",
                    "Name": "Standing Steel Brick Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Brick",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Plate",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Mortar",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Standing Steel Brick Sign",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Government Office",
            "Untranslated": "Government Office Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 1200,
            "BaseXPGain": 1,
            "CraftingTable": "Settlement Crafting Table",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Government Office",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "GovernmentOffice",
                    "Name": "Government Office",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Mortared Stone",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Government Office",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Large Lumber Door",
            "Untranslated": "Large Lumber Door Recipe",
            "BaseCraftTime": 11.2,
            "BaseLaborCost": 60,
            "BaseXPGain": 1.5,
            "CraftingTable": "Sawmill",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Large Lumber Door",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "LargeLumberDoor",
                    "Name": "Large Lumber Door",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 30,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Lumber Door",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Red Vase",
            "Untranslated": "Red Vase Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 90,
            "BaseXPGain": 1,
            "CraftingTable": "Glassworks",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Red Vase",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Glassworking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "RedVase",
                    "Name": "Red Vase",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Glass",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Oxide",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Red Vase",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Small Hanging Ashlar Stone Sign",
            "Untranslated": "Small Hanging Ashlar Stone Sign Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 60,
            "BaseXPGain": 1,
            "CraftingTable": "Advanced Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Small Hanging Ashlar Stone Sign",
            "NumberOfVariants": 7,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Masonry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "SmallHangingAshlarStoneSign",
                    "Name": "Small Hanging Ashlar Stone Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Ashlar Stone",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Hanging Ashlar Stone Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallHangingAshlarLimestoneSign",
                    "Name": "Small Hanging Ashlar Limestone Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Limestone",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Hanging Ashlar Limestone Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallHangingAshlarBasaltSign",
                    "Name": "Small Hanging Ashlar Basalt Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Basalt",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Hanging Ashlar Basalt Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallHangingAshlarGneissSign",
                    "Name": "Small Hanging Ashlar Gneiss Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Gneiss",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Hanging Ashlar Gneiss Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallHangingAshlarGraniteSign",
                    "Name": "Small Hanging Ashlar Granite Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Granite",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Hanging Ashlar Granite Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallHangingAshlarSandstoneSign",
                    "Name": "Small Hanging Ashlar Sandstone Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Sandstone",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Hanging Ashlar Sandstone Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallHangingAshlarShaleSign",
                    "Name": "Small Hanging Ashlar Shale Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Shale",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Hanging Ashlar Shale Sign",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Washing Machine",
            "Untranslated": "Washing Machine Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 240,
            "BaseXPGain": 2,
            "CraftingTable": "Robotic Assembly Line",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Washing Machine",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Industry",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "WashingMachine",
                    "Name": "Washing Machine",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Basic Circuit",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Washing Machine",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Advanced Masonry Table",
            "Untranslated": "Advanced Masonry Table Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 600,
            "BaseXPGain": 10,
            "CraftingTable": "Electric Machinist Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Advanced Masonry Table",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Industry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "AdvancedMasonryTable",
                    "Name": "Advanced Masonry Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Plate",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Saw Blade",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Advanced Masonry Table",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Iron Frame Wide",
            "Untranslated": "Iron Frame Wide Recipe",
            "BaseCraftTime": 1.4,
            "BaseLaborCost": 160,
            "BaseXPGain": 1,
            "CraftingTable": "Anvil",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Iron Frame Wide",
            "NumberOfVariants": 5,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "IronFrameWide",
                    "Name": "Iron Frame Wide",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Iron Frame Wide",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "IronFramePortrait",
                    "Name": "Iron Frame Portrait",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Iron Frame Portrait",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "IronFrameTall",
                    "Name": "Iron Frame Tall",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Iron Frame Tall",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "IronFrameSquare",
                    "Name": "Iron Frame Square",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Iron Frame Square",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "IronFrameLandscape",
                    "Name": "Iron Frame Landscape",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Iron Frame Landscape",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Outpost Netting",
            "Untranslated": "Outpost Netting Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 180,
            "BaseXPGain": 2,
            "CraftingTable": "Small Shipyard",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Outpost Netting",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Shipwright",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "OutpostNetting",
                    "Name": "Outpost Netting",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Hemp Mooring Rope",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Outpost Netting",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wind Turbine",
            "Untranslated": "Wind Turbine Recipe",
            "BaseCraftTime": 28,
            "BaseLaborCost": 1200,
            "BaseXPGain": 15,
            "CraftingTable": "Robotic Assembly Line",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Wind Turbine",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Electronics",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "WindTurbine",
                    "Name": "Wind Turbine",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Bar",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gearbox",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Advanced Circuit",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Servo",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wind Turbine",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Large Standing Wood Sign",
            "Untranslated": "Large Standing Wood Sign Recipe",
            "BaseCraftTime": 4.2,
            "BaseLaborCost": 60,
            "BaseXPGain": 2,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Large Standing Wood Sign",
            "NumberOfVariants": 13,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "LargeStandingWoodSign",
                    "Name": "Large Standing Wood Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Standing Wood Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeStandingSpruceSign",
                    "Name": "Large Standing Spruce Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Spruce Log",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Standing Spruce Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeStandingOakSign",
                    "Name": "Large Standing Oak Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Oak Log",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Standing Oak Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeStandingBirchSign",
                    "Name": "Large Standing Birch Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Birch Log",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Standing Birch Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeStandingRedwoodSign",
                    "Name": "Large Standing Redwood Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Redwood Log",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Standing Redwood Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeStandingJoshuaSign",
                    "Name": "Large Standing Joshua Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Joshua Log",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Standing Joshua Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeStandingSaguaroSign",
                    "Name": "Large Standing Saguaro Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Saguaro Rib",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Standing Saguaro Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeStandingPalmSign",
                    "Name": "Large Standing Palm Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Palm Log",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Standing Palm Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeStandingCeibaSign",
                    "Name": "Large Standing Ceiba Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ceiba Log",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Standing Ceiba Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeStandingCedarSign",
                    "Name": "Large Standing Cedar Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cedar Log",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Standing Cedar Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeStandingFirSign",
                    "Name": "Large Standing Fir Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Fir Log",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Standing Fir Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeStandingHardwoodSign",
                    "Name": "Large Standing Hardwood Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Hardwood Board",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hardwood",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Standing Hardwood Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "LargeStandingSoftwoodSign",
                    "Name": "Large Standing Softwood Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Softwood Board",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Softwood",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Standing Softwood Sign",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Mint",
            "Untranslated": "Mint Recipe",
            "BaseCraftTime": 35,
            "BaseLaborCost": 900,
            "BaseXPGain": 20,
            "CraftingTable": "Blacksmith Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Mint",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "Mint",
                    "Name": "Mint",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Brick",
                            "Ammount": 16,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Bar",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 30,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Mint",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wood Cart",
            "Untranslated": "Wood Cart Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 150,
            "BaseXPGain": 10,
            "CraftingTable": "Wainwright Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Wood Cart",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Basic Engineering",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "WoodCart",
                    "Name": "Wood Cart",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wooden Wheel",
                            "Ammount": 2,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Lubricant",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wood Cart",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Skid Steer",
            "Untranslated": "Skid Steer Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 2500,
            "BaseXPGain": 24,
            "CraftingTable": "Robotic Assembly Line",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Skid Steer",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Industry",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "SkidSteer",
                    "Name": "Skid Steer",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gearbox",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Plate",
                            "Ammount": 16,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nylon Fabric",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Spring",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Advanced Combustion Engine",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Rubber Wheel",
                            "Ammount": 4,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Radiator",
                            "Ammount": 2,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Axle",
                            "Ammount": 2,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Light Bulb",
                            "Ammount": 4,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Lubricant",
                            "Ammount": 2,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Skid Steer",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Rug Small",
            "Untranslated": "Rug Small Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 30,
            "BaseXPGain": 1,
            "CraftingTable": "Loom",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Rug Small",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "RugSmall",
                    "Name": "Rug Small",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wool Yarn",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Rug Small",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Industrial Barge",
            "Untranslated": "Industrial Barge Recipe",
            "BaseCraftTime": 42,
            "BaseLaborCost": 2500,
            "BaseXPGain": 24,
            "CraftingTable": "Medium Shipyard",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Industrial Barge",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Shipwright",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "IndustrialBarge",
                    "Name": "Industrial Barge",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Hull Sheet",
                            "Ammount": 18,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nylon Mooring Rope",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Metal Rudder",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Axle",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Anchor",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Combustion Engine",
                            "Ammount": 2,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Large Metal Ship Frame",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Lubricant",
                            "Ammount": 2,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Industrial Barge",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Small Standing Ashlar Stone Sign",
            "Untranslated": "Small Standing Ashlar Stone Sign Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 60,
            "BaseXPGain": 1,
            "CraftingTable": "Advanced Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Small Standing Ashlar Stone Sign",
            "NumberOfVariants": 7,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Masonry",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "SmallStandingAshlarStoneSign",
                    "Name": "Small Standing Ashlar Stone Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Ashlar Stone",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Standing Ashlar Stone Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallStandingAshlarShaleSign",
                    "Name": "Small Standing Ashlar Shale Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Shale",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Standing Ashlar Shale Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallStandingAshlarGraniteSign",
                    "Name": "Small Standing Ashlar Granite Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Granite",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Standing Ashlar Granite Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallStandingAshlarLimestoneSign",
                    "Name": "Small Standing Ashlar Limestone Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Limestone",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Standing Ashlar Limestone Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallStandingAshlarBasaltSign",
                    "Name": "Small Standing Ashlar Basalt Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Basalt",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Standing Ashlar Basalt Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallStandingAshlarSandstoneSign",
                    "Name": "Small Standing Ashlar Sandstone Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Sandstone",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Standing Ashlar Sandstone Sign",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "SmallStandingAshlarGneissSign",
                    "Name": "Small Standing Ashlar Gneiss Sign",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Gneiss",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Standing Ashlar Gneiss Sign",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Bank",
            "Untranslated": "Bank Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 1500,
            "BaseXPGain": 30,
            "CraftingTable": "Blacksmith Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Bank",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Blacksmith",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "Bank",
                    "Name": "Bank",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gold Bar",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 16,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Brick",
                            "Ammount": 16,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 16,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Bank",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Truck",
            "Untranslated": "Truck Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 2000,
            "BaseXPGain": 18,
            "CraftingTable": "Robotic Assembly Line",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Truck",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Industry",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "Truck",
                    "Name": "Truck",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gearbox",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Plate",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nylon Fabric",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Spring",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Combustion Engine",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Rubber Wheel",
                            "Ammount": 6,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Radiator",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Axle",
                            "Ammount": 2,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Light Bulb",
                            "Ammount": 4,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Lubricant",
                            "Ammount": 2,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Truck",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Small Canoe",
            "Untranslated": "Small Canoe Recipe",
            "BaseCraftTime": 7,
            "BaseLaborCost": 120,
            "BaseXPGain": 1,
            "CraftingTable": "Workbench",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Small Canoe",
            "NumberOfVariants": 2,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "SmallCanoe",
                    "Name": "Small Canoe",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 14,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Canoe",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "EgyptianCanoe",
                    "Name": "Egyptian Canoe",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 14,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Egyptian Canoe",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Powered Cart",
            "Untranslated": "Powered Cart Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 200,
            "BaseXPGain": 20,
            "CraftingTable": "Wainwright Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Powered Cart",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Basic Engineering",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "PoweredCart",
                    "Name": "Powered Cart",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 30,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Fabric",
                            "Name": "",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cast Iron Stove",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Wheel",
                            "Ammount": 3,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Lubricant",
                            "Ammount": 2,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Powered Cart",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Real Estate Desk",
            "Untranslated": "Real Estate Desk Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 60,
            "BaseXPGain": 5,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Real Estate Desk",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Carpentry",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "RealEstateDesk",
                    "Name": "Real Estate Desk",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Linen Fabric",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Real Estate Desk",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wood Shop Cart",
            "Untranslated": "Wood Shop Cart Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 225,
            "BaseXPGain": 10,
            "CraftingTable": "Wainwright Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Wood Shop Cart",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Basic Engineering",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "WoodShopCart",
                    "Name": "Wood Shop Cart",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wood Cart",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Store",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Lubricant",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wood Shop Cart",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Granite Terrace Fountain",
            "Untranslated": "Granite Terrace Fountain Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 360,
            "BaseXPGain": 2,
            "CraftingTable": "Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Granite Terrace Fountain",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Masonry",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "GraniteTerraceFountain",
                    "Name": "Granite Terrace Fountain",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Granite",
                            "Ammount": 40,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Granite Terrace Fountain",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Hand Plow",
            "Untranslated": "Hand Plow Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 100,
            "BaseXPGain": 10,
            "CraftingTable": "Wainwright Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Hand Plow",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Basic Engineering",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "HandPlow",
                    "Name": "Hand Plow",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 5,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wooden Wheel",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Lubricant",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Hand Plow",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wooden Elevator",
            "Untranslated": "Wooden Elevator Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 500,
            "BaseXPGain": 15,
            "CraftingTable": "Assembly Line",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Wooden Elevator",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "WoodenElevator",
                    "Name": "Wooden Elevator",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gearbox",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cellulose Fiber",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 30,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Portable Steam Engine",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Lubricant",
                            "Ammount": 2,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wooden Elevator",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Steam Truck",
            "Untranslated": "Steam Truck Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 1000,
            "BaseXPGain": 25,
            "CraftingTable": "Assembly Line",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Steam Truck",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "SteamTruck",
                    "Name": "Steam Truck",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Plate",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Pipe",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 24,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 30,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Portable Steam Engine",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Wheel",
                            "Ammount": 4,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Axle",
                            "Ammount": 2,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Light Bulb",
                            "Ammount": 2,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Lubricant",
                            "Ammount": 2,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Steam Truck",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Small Wood Cart",
            "Untranslated": "Small Wood Cart Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 50,
            "BaseXPGain": 3,
            "CraftingTable": "Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Small Wood Cart",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Logging",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "SmallWoodCart",
                    "Name": "Small Wood Cart",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 15,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Wood Cart",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wheelbarrow",
            "Untranslated": "Wheelbarrow Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 75,
            "BaseXPGain": 1,
            "CraftingTable": "Workbench",
            "CraftingTableCanUseModules": false,
            "DefaultVariant": "Wheelbarrow",
            "NumberOfVariants": 1,
            "SkillNeeds": [],
            "Variants": [
                {
                    "Key": "Wheelbarrow",
                    "Name": "Wheelbarrow",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wheelbarrow",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Large Canoe",
            "Untranslated": "Large Canoe Recipe",
            "BaseCraftTime": 11.2,
            "BaseLaborCost": 360,
            "BaseXPGain": 10,
            "CraftingTable": "Small Shipyard",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Large Canoe",
            "NumberOfVariants": 2,
            "SkillNeeds": [
                {
                    "Skill": "Shipwright",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "LargeCanoe",
                    "Name": "Large Canoe",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wooden Hull Planks",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wooden Oar",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Small Wooden Ship Frame",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Large Canoe",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "NorseCanoe",
                    "Name": "Norse Canoe",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wooden Hull Planks",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wooden Oar",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Small Wooden Ship Frame",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Norse Canoe",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Mortared Stone Bench",
            "Untranslated": "Mortared Stone Bench Recipe",
            "BaseCraftTime": 4.2,
            "BaseLaborCost": 30,
            "BaseXPGain": 3,
            "CraftingTable": "Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Mortared Stone Bench",
            "NumberOfVariants": 4,
            "SkillNeeds": [
                {
                    "Skill": "Masonry",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "MortaredStoneBench",
                    "Name": "Mortared Stone Bench",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Mortared Stone",
                            "Name": "",
                            "Ammount": 24,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Mortared Stone Bench",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "MortaredLimestoneBench",
                    "Name": "Mortared Limestone Bench",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Mortared Limestone",
                            "Ammount": 24,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Mortared Limestone Bench",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "MortaredSandstoneBench",
                    "Name": "Mortared Sandstone Bench",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Mortared Sandstone",
                            "Ammount": 24,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Mortared Sandstone Bench",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "MortaredGraniteBench",
                    "Name": "Mortared Granite Bench",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Mortared Granite",
                            "Ammount": 24,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Mortared Granite Bench",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wooden Barge",
            "Untranslated": "Wooden Barge Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 800,
            "BaseXPGain": 16,
            "CraftingTable": "Small Shipyard",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Wooden Barge",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Shipwright",
                    "Level": 4
                }
            ],
            "Variants": [
                {
                    "Key": "WoodenBarge",
                    "Name": "Wooden Barge",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wooden Hull Planks",
                            "Ammount": 18,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Hemp Mooring Rope",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wooden Wheel",
                            "Ammount": 2,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gearbox",
                            "Ammount": 4,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Large Wooden Ship Frame",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Lubricant",
                            "Ammount": 2,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wooden Barge",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Crane",
            "Untranslated": "Crane Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 3000,
            "BaseXPGain": 24,
            "CraftingTable": "Assembly Line",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Crane",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "Crane",
                    "Name": "Crane",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gearbox",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Plate",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Portable Steam Engine",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Wheel",
                            "Ammount": 4,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Heat Sink",
                            "Ammount": 2,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Axle",
                            "Ammount": 2,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Lubricant",
                            "Ammount": 2,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Crane",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Excavator",
            "Untranslated": "Excavator Recipe",
            "BaseCraftTime": 28,
            "BaseLaborCost": 3000,
            "BaseXPGain": 24,
            "CraftingTable": "Robotic Assembly Line",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Excavator",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Industry",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "Excavator",
                    "Name": "Excavator",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gearbox",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Plate",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Nylon Fabric",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Spring",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Advanced Combustion Engine",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Rubber Wheel",
                            "Ammount": 4,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Radiator",
                            "Ammount": 2,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Axle",
                            "Ammount": 2,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Light Bulb",
                            "Ammount": 4,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Lubricant",
                            "Ammount": 2,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Excavator",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Small Wooden Boat",
            "Untranslated": "Small Wooden Boat Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 680,
            "BaseXPGain": 16,
            "CraftingTable": "Small Shipyard",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Small Wooden Boat",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Shipwright",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "SmallWoodenBoat",
                    "Name": "Small Wooden Boat",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wooden Hull Planks",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wooden Wheel",
                            "Ammount": 2,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gearbox",
                            "Ammount": 2,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Medium Wooden Ship Frame",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Lubricant",
                            "Ammount": 1,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Wooden Boat",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wooden Transport Ship",
            "Untranslated": "Wooden Transport Ship Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 1000,
            "BaseXPGain": 10,
            "CraftingTable": "Medium Shipyard",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Wooden Transport Ship",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Shipwright",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "WoodenTransportShip",
                    "Name": "Wooden Transport Ship",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wooden Hull Planks",
                            "Ammount": 18,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Bar",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Hemp Mooring Rope",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wooden Rudder",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Axle",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Portable Steam Engine",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Large Wooden Ship Frame",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Lubricant",
                            "Ammount": 2,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wooden Transport Ship",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Medium Fishing Trawler",
            "Untranslated": "Medium Fishing Trawler Recipe",
            "BaseCraftTime": 21,
            "BaseLaborCost": 1200,
            "BaseXPGain": 24,
            "CraftingTable": "Medium Shipyard",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Medium Fishing Trawler",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Shipwright",
                    "Level": 5
                }
            ],
            "Variants": [
                {
                    "Key": "MediumFishingTrawler",
                    "Name": "Medium Fishing Trawler",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Hull Sheet",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Wooden Hull Planks",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Hemp Mooring Rope",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Metal Rudder",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Axle",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Anchor",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Portable Steam Engine",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Medium Metal Ship Frame",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Lubricant",
                            "Ammount": 2,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Medium Fishing Trawler",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Steam Tractor",
            "Untranslated": "Steam Tractor Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 1000,
            "BaseXPGain": 25,
            "CraftingTable": "Assembly Line",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Steam Tractor",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "SteamTractor",
                    "Name": "Steam Tractor",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Plate",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Pipe",
                            "Ammount": 8,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 24,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Leather Hide",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Lumber",
                            "Name": "",
                            "Ammount": 30,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Portable Steam Engine",
                            "Ammount": 1,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Wheel",
                            "Ammount": 4,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Iron Axle",
                            "Ammount": 2,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Light Bulb",
                            "Ammount": 2,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Lubricant",
                            "Ammount": 2,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Steam Tractor",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Industrial Elevator",
            "Untranslated": "Industrial Elevator Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 2500,
            "BaseXPGain": 20,
            "CraftingTable": "Robotic Assembly Line",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Industrial Elevator",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Industry",
                    "Level": 2
                }
            ],
            "Variants": [
                {
                    "Key": "IndustrialElevator",
                    "Name": "Industrial Elevator",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Flat Steel",
                            "Ammount": 30,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Steel Gearbox",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Copper Wiring",
                            "Ammount": 20,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Electric Motor",
                            "Ammount": 2,
                            "IsStatic": true
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Lubricant",
                            "Ammount": 2,
                            "IsStatic": true
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Industrial Elevator",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Small Bath Mat",
            "Untranslated": "Small Bath Mat Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 30,
            "BaseXPGain": 1,
            "CraftingTable": "Loom",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Small Bath Mat",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Tailoring",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "SmallBathMat",
                    "Name": "Small Bath Mat",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cotton Fabric",
                            "Ammount": 20,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Small Bath Mat",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Composite Lumber Table",
            "Untranslated": "Composite Lumber Table Recipe",
            "BaseCraftTime": 5.6,
            "BaseLaborCost": 180,
            "BaseXPGain": 3,
            "CraftingTable": "Advanced Carpentry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Composite Lumber Table",
            "NumberOfVariants": 13,
            "SkillNeeds": [
                {
                    "Skill": "Composites",
                    "Level": 3
                }
            ],
            "Variants": [
                {
                    "Key": "CompositeLumberTable",
                    "Name": "Composite Lumber Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Composite Lumber",
                            "Name": "",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Lumber Table",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeJoshuaTable",
                    "Name": "Composite Joshua Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Composite Joshua Lumber",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Joshua Table",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeBirchTable",
                    "Name": "Composite Birch Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Composite Birch Lumber",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Birch Table",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeOakTable",
                    "Name": "Composite Oak Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Composite Oak Lumber",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Oak Table",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeCeibaTable",
                    "Name": "Composite Ceiba Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Composite Ceiba Lumber",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Ceiba Table",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeCedarTable",
                    "Name": "Composite Cedar Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Composite Cedar Lumber",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Cedar Table",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeSaguaroTable",
                    "Name": "Composite Saguaro Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Composite Saguaro Lumber",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Saguaro Table",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeFirTable",
                    "Name": "Composite Fir Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Composite Fir Lumber",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Fir Table",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeSoftwoodTable",
                    "Name": "Composite Softwood Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Softwood Lumber",
                            "Name": "",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Softwood Table",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeHardwoodTable",
                    "Name": "Composite Hardwood Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 10,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hardwood Lumber",
                            "Name": "",
                            "Ammount": 6,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Hardwood Table",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeSpruceTable",
                    "Name": "Composite Spruce Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Composite Spruce Lumber",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Spruce Table",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositeRedwoodTable",
                    "Name": "Composite Redwood Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Composite Redwood Lumber",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Redwood Table",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "CompositePalmTable",
                    "Name": "Composite Palm Table",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Composite Palm Lumber",
                            "Ammount": 6,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Screws",
                            "Ammount": 10,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Composite Palm Table",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Pump Jack",
            "Untranslated": "Pump Jack Recipe",
            "BaseCraftTime": 28,
            "BaseLaborCost": 72,
            "BaseXPGain": 25,
            "CraftingTable": "Assembly Line",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Pump Jack",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Mechanics",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "PumpJack",
                    "Name": "Pump Jack",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Reinforced Concrete",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Gearbox",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Piston",
                            "Ammount": 8,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Pump Jack",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Swirl Vase",
            "Untranslated": "Swirl Vase Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 90,
            "BaseXPGain": 1,
            "CraftingTable": "Glassworks",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Swirl Vase",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Glassworking",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "SwirlVase",
                    "Name": "Swirl Vase",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Glass",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Cyan Powder",
                            "Ammount": 2,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Swirl Vase",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Wooden Moorage Post",
            "Untranslated": "Wooden Moorage Post Recipe",
            "BaseCraftTime": 2.8,
            "BaseLaborCost": 120,
            "BaseXPGain": 1,
            "CraftingTable": "Small Shipyard",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Wooden Moorage Post",
            "NumberOfVariants": 1,
            "SkillNeeds": [
                {
                    "Skill": "Shipwright",
                    "Level": 1
                }
            ],
            "Variants": [
                {
                    "Key": "WoodenMooragePost",
                    "Name": "Wooden Moorage Post",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Hemp Mooring Rope",
                            "Ammount": 4,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Hewn Log",
                            "Name": "",
                            "Ammount": 12,
                            "IsStatic": false
                        },
                        {
                            "IsSpecificItem": false,
                            "Tag": "Wood Board",
                            "Name": "",
                            "Ammount": 4,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Wooden Moorage Post",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        },
        {
            "Key": "Ashlar Stone Fireplace",
            "Untranslated": "Ashlar Stone Fireplace Recipe",
            "BaseCraftTime": 14,
            "BaseLaborCost": 180,
            "BaseXPGain": 3,
            "CraftingTable": "Advanced Masonry Table",
            "CraftingTableCanUseModules": true,
            "DefaultVariant": "Ashlar Stone Fireplace",
            "NumberOfVariants": 7,
            "SkillNeeds": [
                {
                    "Skill": "Advanced Masonry",
                    "Level": 6
                }
            ],
            "Variants": [
                {
                    "Key": "AshlarStoneFireplace",
                    "Name": "Ashlar Stone Fireplace",
                    "Ingredients": [
                        {
                            "IsSpecificItem": false,
                            "Tag": "Ashlar Stone",
                            "Name": "",
                            "Ammount": 24,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Stone Fireplace",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AshlarLimestoneFireplace",
                    "Name": "Ashlar Limestone Fireplace",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Limestone",
                            "Ammount": 24,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Limestone Fireplace",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AshlarGneissFireplace",
                    "Name": "Ashlar Gneiss Fireplace",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Gneiss",
                            "Ammount": 24,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Gneiss Fireplace",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AshlarSandstoneFireplace",
                    "Name": "Ashlar Sandstone Fireplace",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Sandstone",
                            "Ammount": 24,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Sandstone Fireplace",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AshlarBasaltFireplace",
                    "Name": "Ashlar Basalt Fireplace",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Basalt",
                            "Ammount": 24,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Basalt Fireplace",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AshlarShaleFireplace",
                    "Name": "Ashlar Shale Fireplace",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Shale",
                            "Ammount": 24,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Shale Fireplace",
                            "Ammount": 1
                        }
                    ]
                },
                {
                    "Key": "AshlarGraniteFireplace",
                    "Name": "Ashlar Granite Fireplace",
                    "Ingredients": [
                        {
                            "IsSpecificItem": true,
                            "Tag": null,
                            "Name": "Ashlar Granite",
                            "Ammount": 24,
                            "IsStatic": false
                        }
                    ],
                    "Products": [
                        {
                            "Name": "Ashlar Granite Fireplace",
                            "Ammount": 1
                        }
                    ]
                }
            ]
        }
    ]
}