export class Item {

    name: string;
    isSpecificItem: boolean;

    constructor(name, isSpecificItem) {
        this.name = name;
        this.isSpecificItem = isSpecificItem;
    }

}
