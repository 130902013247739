import {Component, Input, OnInit} from '@angular/core';
import {EarthService} from '../../../service/earth.service';
import {Price} from '../../../models/Price';
import {Recipe} from "../../../models/Recipe";
import {Item} from "../../../models/Item";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.scss']
})
export class PriceComponent implements OnInit {

  @Input('price') price: Price;
  recipeForItem: Recipe;
  alternateRecipes: Recipe[];

  constructor(
      public earthService: EarthService,
  ) {

  }

  ngOnInit(): void {
    this.recipeForItem = this.earthService.findRecipeForItem(this.price.baseItem);
    this.alternateRecipes = this.earthService.getRecipesWithMatchingProduct(this.price.baseItem);
  }

  addAlternateRecipe(item: Item): void {
    const recipeToAdd = this.earthService.findRecipeForItem(item);
    this.earthService.addWatchedRecipe(recipeToAdd);
  }

  addRecipe(): void {
    this.earthService.findRecipeForItem(this.price.baseItem);
    this.earthService.addWatchedRecipe(this.recipeForItem);
  }

  showAddRecipeButton(): boolean {
    return this.recipeForItem && !this.earthService.isRecipeAdded(this.recipeForItem);
  }

  showAddRecipeButtonAlternate(item: Item): boolean {
    const recipeToAdd = this.earthService.findRecipeForItem(item);
    return recipeToAdd && !this.earthService.isRecipeAdded(recipeToAdd);
  }

  showAddRecipeChooseButton(): boolean {
    const filteredRecipes = this.alternateRecipes.filter(recipeToAdd => !this.earthService.isRecipeAdded(recipeToAdd));
    return filteredRecipes.length > 1;
  }

  showAddAlternateRecipeChooseButton(item: Item): boolean {
    const alternateRecipes = this.earthService.getRecipesWithMatchingProduct(item);
    const filteredRecipes = alternateRecipes.filter(recipeToAdd => !this.earthService.isRecipeAdded(recipeToAdd));
    return filteredRecipes.length > 1;
  }

  showExpandRecipeButton(): boolean {
    return !this.price.baseItem.isSpecificItem;
  }

  toggleExpandPrice(event: MatAutocompleteSelectedEvent): void {
    this.price.expanded = !this.price.expanded;
  }

  expandIcon(): string {
    return this.price.expanded ? 'highlight_off' : 'swap_horiz';
  }

  toggleCraft(): void {
    this.price.mode = this.price.nextMode();
    this.updatePrice();
  }

  swapRecipe(event: MatAutocompleteSelectedEvent, item: Item): void {
    if (this.price.alternateIngredient === item) {
      this.price.alternateIngredient = null;
    } else {
      this.price.alternateIngredient = item;
    }
    this.updatePrice();
  }

  swapRecipeIcon(item: Item): string {
    return this.price.alternateIngredient === item ? 'check_box' : 'check_box_outline_blank';
  }

  updatePrice(): void {
    this.earthService.refreshPrices();
  }

}
