export class CraftingTable {

    tableName: string;
    resourceEfficiencyModule: string;
    ownerName: string;
    allowedUpgrades: string[];
    moduleSkillType: string;
    moduleLevel: number;
    genericMultiplier: number;
    skillMultiplier: number;


    constructor(tableName: string, resourceEfficiencyModule: string, ownerName: string,
                allowedUpgrades: string[], moduleSkillType: string, moduleLevel: number,
                genericMultiplier: number, skillMultiplier: number) {
        this.tableName = tableName;
        this.resourceEfficiencyModule = resourceEfficiencyModule;
        this.ownerName = ownerName;
        this.allowedUpgrades = allowedUpgrades;
        this.moduleSkillType = moduleSkillType;
        this.moduleLevel = moduleLevel;
        this.genericMultiplier = genericMultiplier;
        this.skillMultiplier = skillMultiplier;
    }

}
