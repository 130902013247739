import {Item} from './Item';

export class ItemsRequired {

    amount: number;
    item: Item;

    constructor(item, amount) {
        this.item = item;
        this.amount = amount;
    }

}
