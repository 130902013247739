<h1 mat-dialog-title>Data Export</h1>


<div fxLayout="column" fxLayoutGap="20px">
    <div fxLayout="column" fxLayoutGap="10px">
        <h2>Full Data Export</h2>
        <div fxFill fxLayout="row">
            <mat-form-field fxFlex fxFill fxLayout="column" fxLayoutAlign="start center" >
                <label>
                    <input name="allData" #allData matInput type="text" [value]="getAllData()" (click)="clickInput($event)" readonly>
                </label>
            </mat-form-field>
            <button matSuffix mat-raised-button color="primary" aria-label="Copy" [cdkCopyToClipboard]="getAllData()">
                <mat-icon>content_copy</mat-icon> Copy
            </button>
        </div>
    </div>
    <mat-divider></mat-divider>
    <div fxLayout="column" fxLayoutGap="10px">
        <h2>Custom Recipes Export</h2>
        <div fxLayout="column">
            <div *ngFor="let customRecipe of  exportCustomRecipeForm.controls" [formGroup]="getRecipeControl(customRecipe)">
                <mat-checkbox class="example-margin" formControlName="enabled">{{getRecipeName(customRecipe).value}}</mat-checkbox>
            </div>
        </div>
        <div fxFill fxLayout="row">
            <mat-form-field fxFlex fxFill fxLayout="column" fxLayoutAlign="start center">
                <label>
                    <input [formControl]="customRecipeExportControl" matInput type="text" (click)="clickInput($event)" readonly>
                </label>
            </mat-form-field>
            <button matSuffix mat-raised-button color="primary" aria-label="Copy" [cdkCopyToClipboard]="getCustomRecipeData()">
                <mat-icon>content_copy</mat-icon> Copy
            </button>
        </div>
    </div>
    <mat-divider></mat-divider>
    <div fxLayout="column" fxLayoutGap="10px">
        <h2>Debug All Recipes Export</h2>
        <div fxFill fxLayout="row">
            <mat-form-field fxFlex fxFill fxLayout="column" fxLayoutAlign="start center" >
                <label>
                    <input matInput type="text" [value]="getAllRecipeData()" (click)="clickInput($event)" readonly>
                </label>
            </mat-form-field>
<!--            <button matSuffix mat-raised-button color="primary" aria-label="Copy" [cdkCopyToClipboard]="getAllRecipeData()">-->
<!--                <mat-icon>content_copy</mat-icon> Copy-->
<!--            </button>-->
        </div>
    </div>
</div>


<mat-dialog-actions align="end">
    <button mat-raised-button (click)="onNoClick()">Close</button>
</mat-dialog-actions>