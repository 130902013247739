<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="14px">
    <div fxFlex fxLayoutAlign="start center" fxLayoutGap="14px">
        <span (click)="earthService.scrollToRecipe(recipeForItem.id)">{{price.item.name}}</span> <mat-icon *ngIf="!price.baseItem.isSpecificItem" class="pointer">bookmark</mat-icon>
    </div>
    <div (click)="addRecipe()" *ngIf="showAddRecipeButton() && !showAddRecipeChooseButton()" fxLayoutAlign="center center" matTooltip="Add {{price.baseItem.name}} to Recipe List">
        <!--<item-picker *ngIf="item.hasMultipleSources(recipes) && prc" matTooltip="Choose a Different Recipe" [ingredient]="ingredient" [recipes]="recipes" [prices]="prices" (updated)="onUpdate()"></item-picker>-->
        <mat-icon class="pointer">playlist_add</mat-icon>
    </div>
    <div *ngIf="showAddRecipeChooseButton()" fxLayoutAlign="center center">
        <item-picker [ingredient]="price.item" matTooltip="Choose a Different Recipe"></item-picker>
    </div>

    <div (click)="toggleExpandPrice(null)" *ngIf="showExpandRecipeButton()" fxLayoutAlign="center center" matTooltip="Choose a specific version of {{price.baseItem.name}}">
        <mat-icon class="pointer">{{expandIcon()}}</mat-icon>
    </div>
    <div [class.price-highlight]="price.craftPrice" class='craft-price' fxFlex="14" fxLayout="column" fxLayoutAlign="center center">
        {{earthService.findCheapestCraftingCost(price.item) | number : '1.2-2'}}
    </div>
    <div *ngIf="price.personalPrice" [class.price-highlight]="price.personalPrice" class='market-price' fxFlex="18" fxLayout="column">
        <mat-form-field appearance="fill">
            <input (ngModelChange)="updatePrice()" [(ngModel)]="price.value" [name]="price.item.name" id="id-{{price.item.name}}" matInput type="number">
        </mat-form-field>
    </div>
    <div *ngIf="price.serverPrice || price.craftPrice" [class.price-highlight]="price.serverPrice" class='craft-price' fxFlex="18" fxLayout="column" fxLayoutAlign="center center">
        {{earthService.findCheapestServerPrice(price.item) ? (earthService.findCheapestServerPrice(price.item) | number : '1.2-2') : '-'}}
    </div>

    <div fxFlex="5" fxLayout="column">
        <button (click)="toggleCraft()" class="tiny-button" color="primary" mat-icon-button>
            <mat-icon matTooltip="Set {{price.item.name}} to {{price.modeLabel(price.mode)}} Mode">{{price.modeIcon()}}</mat-icon>
        </button>
    </div>
</div>
<div *ngIf="price.expanded" fxLayout="column" fxLayoutGap="10px">
    <div *ngFor="let item of earthService.findProductsForTag(price.baseItem);" fxFlex>
        <div *ngIf="item" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="14px">
            <div fxFlex="5" fxLayoutAlign="center center">
                <mat-icon (click)="swapRecipe(null, item)" class="pointer" matTooltip="Use {{item.name}} for {{price.item.name}}">
                    {{swapRecipeIcon(item)}}
                </mat-icon>
            </div>
            <div fxFlex fxLayoutAlign="start center">{{item.name}}</div>
            <div (click)="addAlternateRecipe(item)" *ngIf="showAddRecipeButtonAlternate(item) && !showAddAlternateRecipeChooseButton(item)" fxLayoutAlign="center center" matTooltip="Add {{item.name}} to Recipe List">
                <mat-icon class="pointer">playlist_add</mat-icon>
            </div>
            <div *ngIf="showAddAlternateRecipeChooseButton(item)" fxLayoutAlign="center center">
                <item-picker [ingredient]="item" matTooltip="Choose a Different Recipe"></item-picker>
            </div>
        </div>
    </div>
</div>