import {Injectable} from '@angular/core';
import {RecipeGroup} from '../models/RecipeGroup';
import {Recipe} from '../models/Recipe';
import {RecipeIngredient} from '../models/RecipeIngredient';
import {BehaviorSubject, combineLatest, interval, Observable} from 'rxjs';
import {Price} from '../models/Price';
import {map, mergeMap} from 'rxjs/operators';
import {sampleRecipes} from '../data/RecipesSample';
import {Item} from '../models/Item';
import {SkillNeed} from '../models/SkillNeed';
import {sampleTags} from '../data/TagsSample';
import {Tag} from '../models/Tag';
import {sampleStores} from '../data/StoreSamples';
import {StoreOffer} from '../models/StoreOffer';
import {StoreOfferSummary} from '../models/StoreOfferSummary';
import axios from 'axios';
import {CraftingTable} from "../models/CraftingTable";

@Injectable({
    providedIn: 'root',
})
export class EarthService {

    recipeGroups: RecipeGroup[];
    allRecipes: RecipeGroup;
    activeRecipeGroup: RecipeGroup | null;

    recipes: BehaviorSubject<Recipe[]>;
    // customRecipes: BehaviorSubject<Recipe[]>;
    watchedRecipes: BehaviorSubject<Recipe[]>;
    // customItems: BehaviorSubject<Item[]>;
    prices: BehaviorSubject<Price[]>;
    tags: BehaviorSubject<Tag[]>;
    ingredients: RecipeIngredient[] = [];
    items: Item[] = [];
    savedPrices: BehaviorSubject<Price[]>;
    storeOffers: BehaviorSubject<StoreOffer[]>;
    craftingTables: BehaviorSubject<CraftingTable[]>;
    playerCraftingTables: BehaviorSubject<CraftingTable[]>;
    filteredStoreOffers: BehaviorSubject<StoreOffer[]>;
    storeOfferSummaries: BehaviorSubject<StoreOfferSummary[]>;

    autoCompleteValue: BehaviorSubject<string>;
    selectedCurrency: BehaviorSubject<string>;
    selectedPlayer: BehaviorSubject<string>;
    calorieCost: BehaviorSubject<number>;
    profitMargin: BehaviorSubject<number>;


    rawRecipes = sampleRecipes;
    // sampleRecipes = sampleRecipes;

    lastServerStoreUpdate: BehaviorSubject<Date>;
    lastServerCraftingTableUpdate: BehaviorSubject<Date>;


    constructor() {
        this.recipeGroups = [];
        this.activeRecipeGroup = null;
        this.recipes = new BehaviorSubject<Recipe[]>([]);

        this.storeOffers = new BehaviorSubject<StoreOffer[]>([]);
        this.craftingTables = new BehaviorSubject<CraftingTable[]>([]);
        this.playerCraftingTables = new BehaviorSubject<CraftingTable[]>([]);
        this.filteredStoreOffers = new BehaviorSubject<StoreOffer[]>([]);
        this.storeOfferSummaries = new BehaviorSubject<StoreOfferSummary[]>([]);

        this.watchedRecipes = new BehaviorSubject<Recipe[]>([]);
        this.prices = new BehaviorSubject<Price[]>([]);

        this.savedPrices = new BehaviorSubject<Price[]>([]);

        this.tags = new BehaviorSubject<Tag[]>([]);
        this.autoCompleteValue = new BehaviorSubject<string>('');
        this.lastServerStoreUpdate = new BehaviorSubject<Date>(new Date());
        this.lastServerCraftingTableUpdate = new BehaviorSubject<Date>(new Date());
        this.selectedCurrency = new BehaviorSubject<string>('Dollars');
        this.selectedPlayer = new BehaviorSubject<string>('');
        this.calorieCost = new BehaviorSubject<number>(1000);
        this.profitMargin = new BehaviorSubject<number>(15);


        combineLatest([
            this.pullServerPrices(),
            this.pullServerCraftingTables()
        ]).subscribe(([priceData, craftingTableData]) => {
            this.updateRecipes(this.buildRecipes(sampleRecipes));
            this.updateTags(this.buildTags(sampleTags));
            this.updateStores(this.buildStoreOffers(priceData));
            this.updateCraftingTables(this.buildCraftingTables(craftingTableData));
            this.loadLocalStorageData();
            this.registerSubscribers();
        });

    }

    registerSubscribers(): void {
        this.prices.subscribe((prices) => {
            console.log('Prices Updated');
            this.updateLocalStorage();
        });

        this.recipes.subscribe((recipes) => {
            console.log('Recipes Updated');
        });

        this.watchedRecipes.subscribe((recipes) => {
            console.log('Watched Recipes Updated');
        });

        this.storeOffers.subscribe((storeOffers) => {
            console.log('Store Offers Updated');
            this.updateStoreOfferSummaries(storeOffers);
        });

        this.craftingTables.subscribe((craftingTables) => {
            console.log('Crafting Tables Updated');
        });

        this.filteredStoreOffers.subscribe((storeOffers) => {
            console.log('Filtered Store Offers Updated');
        });

        this.selectedCurrency.subscribe(selectedCurrency => {
            console.log('Selected Currency Updated');
            this.updateStoreOfferSummaries(this.storeOffers.getValue());
            this.updateLocalStorage();
        });

        this.selectedPlayer.subscribe(selectedCurrency => {
            console.log('Selected Player Updated');
            this.updateLocalStorage();
        });

        this.calorieCost.subscribe(calorieCost => {
            console.log('Calorie Cost Updated');
            this.updateLocalStorage();
        });

        this.profitMargin.subscribe(profitMargin => {
            console.log('Profit Margin Updated');
            this.updateLocalStorage();
        });

        combineLatest([
            this.selectedPlayer,
            this.craftingTables
        ]).subscribe(([selectedPlayer, craftingTables]) => {
            const playerCraftingTables = craftingTables.filter(table => table.ownerName === selectedPlayer);
            this.playerCraftingTables.next(playerCraftingTables);
            console.log(playerCraftingTables);
        });

        combineLatest([
            // this.savedPrices,
            this.watchedRecipes,
            this.storeOffers
        ]).subscribe(([watchedRecipes, storeOffers]) => {
            console.log('combineLatest Prices Updated');
            this.updateLocalStorage();
            this.updatePrices(watchedRecipes);
        });

        combineLatest([
            this.prices,
            this.storeOffers,
        ]).subscribe(([prices, storeOffers]) => {
            this.updateFilteredStoreOffers(storeOffers, prices);
        });

        interval(5 * 60 * 1000)
            .pipe(
                mergeMap(() => this.pullServerPrices())
            )
            .subscribe((priceData) => {
                this.updateStores(this.buildStoreOffers(priceData));
            });

    }

    async pullServerPrices(): Promise<any> {
        const serverPrices = 'https://dead.earth/Stores.json';
        const cachedData = this.loadCachedServerStores();
        try {
            if (cachedData) {
                const lastUpdated = cachedData.lastUpdated;
                const lastUpdatedDate = new Date(lastUpdated);
                const currentDate = new Date();
                const elapsed = currentDate.getTime() - lastUpdatedDate.getTime();
                console.log('Time Since Cached ' + elapsed);
                if (elapsed > 300000) {
                    const response = await axios.get(serverPrices);
                    this.saveCachedServerStores(response.data);
                    this.lastServerStoreUpdate.next(lastUpdatedDate);
                    return response.data;
                }
            } else {
                const response = await axios.get(serverPrices);
                this.saveCachedServerStores(response.data);
                this.lastServerStoreUpdate.next(new Date());
                return response.data;
            }

            console.log('Server Stores Cache Still Good');
            return cachedData;
        } catch (error) {
            console.log('Could not get pricing from server: ' + error);
            return cachedData;
        }

    }

    async pullServerCraftingTables(): Promise<any> {
        const serverPrices = 'https://dead.earth/CraftingTables.json';
        const cachedData = this.loadCachedCraftingTables();
        try {
            if (cachedData) {
                const lastUpdated = cachedData.lastUpdated;
                const lastUpdatedDate = new Date(lastUpdated);
                const currentDate = new Date();
                const elapsed = currentDate.getTime() - lastUpdatedDate.getTime();
                console.log('Time Since Cached ' + elapsed);
                if (elapsed > 300000) {
                    const response = await axios.get(serverPrices);
                    this.saveCachedServerCraftingTables(response.data);
                    this.lastServerCraftingTableUpdate.next(lastUpdatedDate);
                    return response.data;
                }
            } else {
                const response = await axios.get(serverPrices);
                this.saveCachedServerCraftingTables(response.data);
                this.lastServerCraftingTableUpdate.next(new Date());
                return response.data;
            }

            console.log('Server Stores Cache Still Good');
            return cachedData;
        } catch (error) {
            console.log('Could not get pricing from server: ' + error);
            return cachedData;
        }

    }

    buildRecipes(recipeData: any): Recipe[] {
        return recipeData.Recipes.reduce((recipes, raw) => {
                return recipes.concat(this.buildRecipeFromRaw(raw));
            }, [] as Recipe[]
        ).sort((a: Recipe, b: Recipe) => a.name.localeCompare(b.name));
    }

    buildTags(tagData: any): Tag[] {
        const tags = Object.entries(tagData.Tags).map(
            ([key, raw]) => (
                this.buildTagFromRaw(key, raw as string[])
            )
        );
        return tags;
    }

    buildStoreOffers(storeData: any): StoreOffer[] {
        return storeData.Stores.reduce((stores, raw) => {
                return stores.concat(this.buildStoreOfferFromRaw(raw));
            }, [] as StoreOffer[]
        ).sort((a: StoreOffer, b: StoreOffer) => a.itemName.localeCompare(b.itemName));
    }

    buildCraftingTables(craftingTableData: any): CraftingTable[] {
        return craftingTableData.CraftingTables.reduce((craftingTables, raw) => {
                return craftingTables.concat(this.buildCraftingTableFromRaw(raw));
            }, [] as CraftingTable[]
        ).sort((a: CraftingTable, b: CraftingTable) => a.tableName.localeCompare(b.tableName));
    }

    buildRecipeFromRaw(rawRecipe): Recipe[] {

        if (!rawRecipe.Key || rawRecipe.name === '') {
            throw new Error('Recipe Key/Label Required');
        }

        const recipes: Recipe[] = rawRecipe.Variants.map(variant => {

            const ingredients = variant.Ingredients.map(ingredient => {
                const i = new RecipeIngredient(
                    ingredient.IsSpecificItem,
                    ingredient.Tag ? ingredient.Tag : ingredient.Name,
                    ingredient.Ammount,
                    ingredient.IsStatic
                );
                // if (i.name === 'Hardwood') {
                //     console.log('RIGHT FUCKING HEREEER');
                //     console.log(i);
                // }
                this.addIngredient(i);
                return i;
            });

            const products = variant.Products.map(product => {
                const p = new RecipeIngredient(
                    true,
                    product.Name,
                    product.Ammount,
                    true
                );
                this.addProduct(p);
                return p;
            });

            const skillNeeds: SkillNeed[] = rawRecipe.SkillNeeds.map(skillNeed => {
                return new SkillNeed(
                    skillNeed.Skill,
                    skillNeed.Level
                );
            });

            return new Recipe(
                rawRecipe.Key,
                variant.Key,
                rawRecipe.Untranslated,
                variant.Name,
                rawRecipe.BaseCraftTime,
                rawRecipe.BaseLaborCost,
                rawRecipe.BaseXPGain,
                rawRecipe.CraftingTable,
                rawRecipe.CraftingTableCanUseModules,
                rawRecipe.DefaultVariant,
                rawRecipe.NumberOfVariants,
                skillNeeds,
                ingredients,
                products
            );

        });

        return recipes;

    }

    buildStoreOfferFromRaw(rawStore): StoreOffer[] {
        const storeOffers: StoreOffer[] = rawStore.AllOffers.map(offer => {
            return new StoreOffer(
                rawStore.Name,
                rawStore.Owner,
                rawStore.Balance,
                rawStore.CurrencyName,
                rawStore.Enabled,
                offer.ItemName,
                offer.Buying,
                offer.Price,
                offer.Quantity,
                offer.Limit,
                offer.MaxNumWanted,
                offer.MinDurability,
            );
        });
        return storeOffers;
    }

    buildCraftingTableFromRaw(rawCraftingTable): CraftingTable[] {
        return [new CraftingTable(
            rawCraftingTable.TableName,
            rawCraftingTable.ResourceEfficiencyModule,
            rawCraftingTable.OwnerName,
            rawCraftingTable.AllowedUpgrades,
            rawCraftingTable.ModuleSkillType,
            rawCraftingTable.ModuleLevel,
            rawCraftingTable.GenericMultiplier,
            rawCraftingTable.SkillMultiplier
        )];
    }

    buildTagFromRaw(tag: string, rawProductNames: string[]): Tag {

        const p = new Item(
            tag,
            false
        );
        this.addItem(p);

        return new Tag(
            tag,
            rawProductNames
        );

    }

    isRecipeAdded(recipe: Recipe): boolean {
        return this.watchedRecipes.getValue().includes(recipe);
    }

    updateFilteredStoreOffers(storeOffers: StoreOffer[], prices: Price[]): void {
        const filteredStoreOffers = storeOffers.filter(offer => {
            return prices.some(price => price.item.name === offer.itemName);
        });
        this.filteredStoreOffers.next(filteredStoreOffers);
    }

    updateStoreOfferSummaries(storeOffers: StoreOffer[]): void {
        const updatedStoreOfferSummaries = storeOffers.reduce((updatedSummaries, storeOffer) => {
            if (!updatedSummaries.some(summary => summary.itemName === storeOffer.itemName)) {
                updatedSummaries.push(new StoreOfferSummary(
                    storeOffer.itemName,
                    storeOffers.filter(s => storeOffer.itemName === s.itemName &&
                        storeOffer.currencyName === this.selectedCurrency.getValue())
                ));
            }
            return updatedSummaries;
        }, [] as StoreOfferSummary[]);
        this.storeOfferSummaries.next(updatedStoreOfferSummaries);
    }


    findIngredientPriceObject(ingredient: RecipeIngredient, debug = false): Price {
        //console.log('findIngredientPriceObject');
        if (debug) {
            //console.log(ingredient);
        }
        const foundPrice = this.prices.getValue().find(r => r.baseItem.name === ingredient.name);
        if (debug) {

            //console.log(foundPrice);
        }
        return foundPrice;
    }


    findItemPriceValue(ingredient: RecipeIngredient): number {
        const price = this.findIngredientPriceObject(ingredient);
        const recipe = this.findWatchedRecipeForItem(price.item);
        // console.log(ingredient);
        // console.log(price);
        // console.log(recipe);
        if (price && price.personalPrice) {
            return price.value;
        } else if (price && price.serverPrice) {
            const serverPrice = this.findCheapestServerPrice(price.item);
            return serverPrice ? serverPrice : 0;
        } else if (recipe) {
            const priceFromRecipe = this.findRecipePriceValue(recipe);
            // console.log("priceFromRecipe");
            // console.log(priceFromRecipe);
            return priceFromRecipe;
        }
        return 0;
    }

    getRecipesWithMatchingProduct(item: Item): Recipe[] {
        return this.recipes.getValue().filter(recipe =>
            recipe.products.find(i => i.name === item.name)
        );
    }

    getWatchedRecipesWithMatchingProduct(item: Item): Recipe[] {
        return this.watchedRecipes.getValue().filter(recipe =>
            recipe.products.find(i => i.name === item.name)
        );
    }

    findCheapestCraftingCost(item: Item): number {
        const lowest = this.getWatchedRecipesWithMatchingProduct(item).reduce((lowestPrice, recipe) => {
            const recipePrice = this.findRecipePriceValue(recipe);
            if (lowestPrice === null || recipePrice < lowestPrice) {
                return recipePrice;
            } else {
                return lowestPrice;
            }
        }, null as number);
        return lowest ? lowest : 0;
    }

    // findCheapestCraftingCostPriceObject(item: Item): Price {
    //     const lowest = this.getWatchedRecipesWithMatchingProduct(item).reduce((previousValue, recipe) => {
    //         const recipePrice = this.findRecipePriceValue(recipe);
    //         if (lowestPrice === null || recipePrice < lowestPrice) {
    //             return {recipePrice;
    //         } else {
    //             return lowestPrice;
    //         }
    //     }, {null, null});
    //     return lowest ? lowest : null;
    // }

    findCheapestServerPrice(item: Item): number {
        const sellOffers = this.storeOfferSummaries.getValue().find(s => s.itemName === item.name);
        if (sellOffers && sellOffers.elligibleSellOffers()) {
            const lowest = sellOffers.elligibleSellOffers().reduce((lowestPrice, offer) => {
                if (lowestPrice === null || offer.price < lowestPrice) {
                    return offer.price;
                } else {
                    return lowestPrice;
                }
            }, null as number);
            return lowest;
        } else {
            return null;
        }
    }

    getCostPerLabor(): number {
        return 1 / this.calorieCost.getValue() ;
    }

    getProfitMarginMultiplier(): number {
        return (this.profitMargin.getValue() / 100) + 1;
    }

    findMyStorePrice(item: Item): number {
        return this.findMyStoreOffer(item)?.price;
    }

    findMyStoreOffer(item: Item): StoreOffer {
        const activePlayer = this.selectedPlayer.getValue();
        if (this.selectedPlayer.getValue() !== '') {
            const myStoreOffers = this.storeOffers.getValue().find(s =>
                s.itemName === item.name && s.owner === activePlayer && !s.buying && s.currencyName === this.selectedCurrency.getValue()
            );
            if (myStoreOffers) {
                return myStoreOffers;
            }
        }
        return null;
    }

    findRecipePriceValue(recipe: Recipe): number {
        const total = recipe.ingredients.reduce((sum, ingredient) => {
            const ingredientPrice = this.findItemPriceValue(ingredient);
            const ingredientAmount = ingredient.modifiedAmount(recipe.efficiency, recipe.lavish);
            return sum + (ingredientPrice * ingredientAmount);
        }, 0) + (recipe.modifiedLaborCost(recipe.efficiency) * this.getCostPerLabor());
        // TODO: Breakdown of cost by product
        return (total * this.getProfitMarginMultiplier()) / recipe.products[0].amount;
    }

    findWatchedRecipeForItem(item: Item): Recipe {
        const recipesWithMatchingProduct = this.getWatchedRecipesWithMatchingProduct(item);
        const sorted = recipesWithMatchingProduct.sort((a, b) => a.products.length - b.products.length);
        const result = sorted.shift();
        return result;
    }

    findRecipeForItem(item: Item): Recipe {
        const recipesWithMatchingProduct = this.getRecipesWithMatchingProduct(item);
        const sorted = recipesWithMatchingProduct.sort((a, b) => a.products.length - b.products.length);
        const result = sorted.shift();
        return result;
    }

    findProductsForTag(item: Item): Item[] {
        // console.log('findProductsForTag ' + item);
        return this.tags.getValue().find(tag =>
            tag.name === item.name
        ).products.map(productName => {
            // console.log('finding ' + productName);
            return this.items.find(i => i.name === productName) || this.ingredients.find(i => i.name === productName);
        });
    }

    ingredientHasTag(ingredient: RecipeIngredient): boolean {
        return this.tags.getValue().some(tag => tag.products.some(p => p === ingredient.name));
    }

    setActiveRecipeGroup(recipeGroup: RecipeGroup): void {
        this.activeRecipeGroup = recipeGroup;
    }

    updateRecipes(recipes: Recipe[]): void {
        this.recipes.next(recipes);
    }

    updateTags(tags: Tag[]): void {
        tags.forEach(tag => {
            tag.products.forEach(p => {
                const itemFromTag = new Item(
                    p,
                    false
                );
                this.addItem(itemFromTag);
            });
        });
        this.tags.next(tags);
    }

    updateStores(storeOffers: StoreOffer[]): void {
        this.storeOffers.next(storeOffers);
    }

    updateCraftingTables(craftingTables: CraftingTable[]): void {
        this.craftingTables.next(craftingTables);
    }

    updateWatchedRecipes(recipes: Recipe[]): void {
        const sorted = recipes.sort((a: Recipe, b: Recipe) => {
            if (a.position < b.position) {
                return -1;
            } else if (b.position < a.position) {
                return 1;
            } else {
                return 0;
            }
        });
        this.watchedRecipes.next(sorted);
    }

    refreshWatchedRecipes(): void {
        this.updateWatchedRecipes(this.watchedRecipes.getValue());
    }

    refreshPrices(): void {
        this.prices.next(this.prices.getValue());
    }

    updatePrices(watchedRecipes: Recipe[]): void {
        const existingPrices = this.prices.getValue();
        const updatedIngredients = watchedRecipes.reduce((collected, recipeIterator) => {
            recipeIterator.ingredients.forEach(
                item => collected.push(item)
            );
            return collected;
        }, [] as Item[]);

        const updatedPrices = updatedIngredients.reduce((collectedPrices, ingredient) => {
            const existingPrice = existingPrices.find(price =>
                price.baseItem.name === ingredient.name
            );
            if (!existingPrice) {
                const price = new Price(
                    ingredient,
                    0,
                    'server',
                    false
                );
                collectedPrices.push(price);
            } else {
                const collectedPrice = collectedPrices.find(price =>
                    price.baseItem.name === ingredient.name
                );
                if (!collectedPrice) {
                    collectedPrices.push(existingPrice);
                }
            }
            return collectedPrices;
        }, []).sort((a: Price, b: Price) => a.baseItem.name.localeCompare(b.baseItem.name));

        this.prices.next(updatedPrices);
    }

    addIngredient(ingredient: RecipeIngredient): void {
        if (this.ingredients.some((i) => i.name === ingredient.name)){
            // update logic
        } else {
            this.ingredients.push(ingredient);
            this.addItem(ingredient as Item);
        }
    }

    addProduct(ingredient: RecipeIngredient): void {
        this.addItem(ingredient as Item);
    }

    addItem(product: Item): void {
        if (this.items.some((i) => i.name === product.name)){
            // update logic
        } else {
            this.items.push(product);
        }
    }

    addWatchedRecipe(recipe: Recipe): void {
        if (this.watchedRecipes.getValue().some((i) => i.id === recipe.id)){
            // update logic
        } else {
            this.updateWatchedRecipes(this.watchedRecipes.getValue().concat(recipe));
        }
    }

    removeWatchedRecipe(recipe: Recipe): void {
        this.updateWatchedRecipes((this.watchedRecipes.getValue().filter(r => r !== recipe)));
    }

    getAllIngredients(): Item[] {
        return this.items;
    }

    updateLocalStorage(): void {
        console.log('Updating Local Storage');
        this.setLocalStorage(this.getJsonData(), this.getSettingsJsonData());
    }

    setLocalStorage(data: string, settingsData: string): void {
        localStorage.setItem('priceData', data);
        localStorage.setItem('settingsData', settingsData);
    }

    resetLocalStorage(): void {
        localStorage.setItem('priceData', '{"prices":[],"recipes":[],"customRecipes":[],"customItems":[]}');
    }

    saveCachedServerStores(data: any): void {
        data.lastUpdated = new Date().getTime();
        localStorage.setItem('serverStores',  JSON.stringify(data));
    }

    saveCachedServerCraftingTables(data: any): void {
        data.lastUpdated = new Date().getTime();
        localStorage.setItem('serverCraftingTables',  JSON.stringify(data));
    }

    loadCachedServerStores(): any {
        const serverStores = localStorage.getItem('serverStores');
        try {
            if (serverStores) {
                return JSON.parse(serverStores);
            }
        } catch (error) {
            console.log('Could not parse Saved Stores ' + error);
            return null;
        }
    }

    loadCachedCraftingTables(): any {
        const serverCraftingTables = localStorage.getItem('serverCraftingTables');
        try {
            if (serverCraftingTables) {
                return JSON.parse(serverCraftingTables);
            }
        } catch (error) {
            console.log('Could not parse Crafting Tables ' + error);
            return null;
        }
    }

    loadLocalStorageData(): void {
        console.log('Loading From Local Storage');
        const storage = localStorage.getItem('priceData');
        const settingsJsonData = localStorage.getItem('settingsData');
        try {
            if (storage) {
                const storedData = JSON.parse(storage);
                const savedRecipeGroup = new RecipeGroup('default');

                const savedRecipes = storedData.recipes.map((sr) => {
                    const recipe = this.recipes.getValue().find(r => r.id === sr.id);
                    recipe.lavish = sr.lavish;
                    recipe.efficiency = sr.efficiency;
                    recipe.position = sr.position;
                    return recipe;
                });

                const savedPrices = storedData.prices.map( (storedPrices) => {
                    const price = new Price(
                        this.findIngredientByName(storedPrices.baseItem),
                        storedPrices.value,
                        storedPrices.mode,
                        storedPrices.expanded
                    );

                    if (storedPrices.alternateIngredient?.baseItem) {
                        price.alternateIngredient = this.findIngredientByName((storedPrices.alternateIngredient.baseItem));
                    }
                    return price;
                });

                this.prices.next(savedPrices);

                this.updateWatchedRecipes(savedRecipes);

            }

            if (settingsJsonData) {
                const settingsData = JSON.parse(settingsJsonData);
                if (settingsData.currency) {
                    this.selectedCurrency.next(settingsData.currency);
                }
                if (settingsData.player) {
                    this.selectedPlayer.next(settingsData.player);
                }
                if (settingsData.calorieCost) {
                    this.calorieCost.next(settingsData.calorieCost);
                }
                if (settingsData.profitMargin) {
                    this.profitMargin.next(settingsData.profitMargin);
                }
            }


        } catch (error) {
            console.log('Could not parse Saved Recipe JSON: ' + error);
        }
    }

    getJsonData(): string {
        const priceData = {
            prices: this.prices.getValue(),
            recipes: this.watchedRecipes.getValue(),
            // customRecipes: this.customRecipes.getValue(),
            // customItems: this.customItems.getValue()
        };
        return JSON.stringify(priceData);
    }
    getSettingsJsonData(): string {
        const settingsData = {
            player: this.selectedPlayer.getValue(),
            currency: this.selectedCurrency.getValue(),
            calorieCost: this.calorieCost.getValue(),
            profitMargin: this.profitMargin.getValue(),
            // customRecipes: this.customRecipes.getValue(),
            // customItems: this.customItems.getValue()
        };
        return JSON.stringify(settingsData);
    }

    private findIngredientByName(ingredient: any): Item {
        return this.getAllIngredients().find(item => item.name === ingredient.name);

    }

    filteredRecipes(): Observable<Recipe[]> {
        return combineLatest([this.recipes, /*, this.customRecipes */ this.autoCompleteValue]).pipe(
            // map(([recipes, customRecipes, autoCompleteValue]) => {
            map(([recipes, autoCompleteValue]) => {
                // return recipes.concat(customRecipes).filter(r =>
                return recipes.filter(r =>
                    r.name.toLocaleLowerCase().includes(autoCompleteValue.toLocaleLowerCase()));
            })
        );
    }

    storeUpdatedString(): Observable<string> {
        return this.lastServerStoreUpdate.pipe(map((date) => {
            return date.toLocaleString();
        }));
    }

    scrollToRecipe(recipeId): void {
        console.log(`scrolling to ${recipeId}`);
        const el = document.getElementById(recipeId);
        el.scrollIntoView();
    }

    craftingTablesUpdatedString(): Observable<string> {
        return this.lastServerCraftingTableUpdate.pipe(map((date) => {
            return date.toLocaleString();
        }));
    }

    filteredStoreOfferSummaries(): Observable<StoreOfferSummary[]> {
        return combineLatest([this.storeOfferSummaries]).pipe(
            map(([storeOfferSummaries]) => {
                return storeOfferSummaries.reduce((updatedSummaries, storeOffer) => {
                    if (!updatedSummaries.some(summary => summary.itemName === storeOffer.itemName)) {
                        if (this.prices.getValue().some(price => price.baseItem.name === storeOffer.itemName)) {
                            updatedSummaries.push(storeOffer);
                        }
                    }
                    return updatedSummaries;
                }, [] as StoreOfferSummary[]);
            })
        );
    }

    getCurrencies(): Observable<string[]> {
        return combineLatest([this.storeOffers]).pipe(
            map(([storeOffers]) => {
                return storeOffers.reduce((currencies, storeOffer) => {
                    const currencyName = storeOffer.currencyName;
                    if (!currencies.some(c => c === currencyName)) {
                        currencies.push(currencyName);
                    }
                    return currencies;
                }, [] as string[]);
            })
        );
    }

    getPlayers(): Observable<string[]> {
        return combineLatest([this.storeOffers]).pipe(
            map(([storeOffers]) => {
                return storeOffers.reduce((players, storeOffer) => {
                    const ownerName = storeOffer.owner;
                    if (ownerName && ownerName !== '' && !players.some(c => c === ownerName)) {
                        players.push(ownerName);
                    }
                    return players;
                }, [] as string[]).sort((a, b) => {
                    return a.toLowerCase().localeCompare(b.toLowerCase());
                });
            })
        );
    }


    predatoryPricing(): Observable<StoreOfferSummary[]> {
        return combineLatest([this.storeOfferSummaries]).pipe(
            map(([storeOfferSummaries]) => {
                return storeOfferSummaries.reduce((offers, summary) => {
                    if (
                        summary.minSellOffer() && summary.maxBuyOffer() &&
                        summary.minSellOffer().price < summary.maxBuyOffer().price &&
                        summary.minSellOffer().currencyName === summary.maxBuyOffer().currencyName &&
                        summary.maxBuyOffer().price / summary.minSellOffer().price  > 1.20
                    ) {
                        offers.push(summary);
                    }
                    return offers;
                }, [] as StoreOfferSummary[]);
            })
        );
    }

    syncPlayer(): void {
        if (this.selectedPlayer.getValue() !== ''){
            this.importStoreRecipes();
            this.importCraftingTableSetup();
            this.optimizePrices();
        }
    }

    importStoreRecipes(): void {
        this.storeOffers.getValue().filter(offer => {
            return offer.owner === this.selectedPlayer.getValue();
        }).forEach(offer => {
            const foundRecipe = this.findRecipeForItem(new Item(offer.itemName, true));
            if (foundRecipe) {
                this.addWatchedRecipe(foundRecipe);
            }
        });
    }

    importCraftingTableSetup(): void {
        const watchedRecipes = this.watchedRecipes.getValue();
        const playerTables  = this.playerCraftingTables.getValue();
        const updatedWatchedRecipes = watchedRecipes.map(recipe => {
            const bestTable = playerTables.filter(table => table.tableName === recipe.craftingTable)
                .reduce((p, c) => c.moduleLevel > p ? c.moduleLevel : p , 0);
            console.log(bestTable);
            recipe.efficiency = bestTable;
            switch (bestTable) {
                case 0: recipe.efficiency = 1.00; break;
                case 1: recipe.efficiency = 0.90; break;
                case 2: recipe.efficiency = 0.75; break;
                case 3: recipe.efficiency = 0.65; break;
                case 4: recipe.efficiency = 0.55; break;
                case 5: recipe.efficiency = 0.50; break;
            }
            return recipe;
        });
        this.updateWatchedRecipes(updatedWatchedRecipes);
    }

    optimizePrices(): void {
        const updatedPrices = this.prices.getValue().map(price => {
            const serverPrice = this.findCheapestServerPrice(price.item);
            if (!serverPrice) {
                const craftPrice = this.findCheapestCraftingCost(price.item);
                const personalPrice = price.value;
                if (personalPrice === 0) {
                    price.mode = 'craft';
                } else {
                    price.mode = 'personal';
                }
            } else {
                price.mode = 'server';
            }
            return price;
        });
        this.prices.next(updatedPrices);
    }
}
