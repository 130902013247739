import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {EarthService} from '../../../service/earth.service';
import {Recipe} from '../../../models/Recipe';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-import-data',
  templateUrl: './import-data.component.html',
  styleUrls: ['./import-data.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ImportDataComponent implements OnInit {

  fullImportControl: FormControl;
  recipeImportControl: FormControl;

  importedCustomRecipes: BehaviorSubject<Recipe[]>;

  constructor(
      private fb: FormBuilder,
      public earthService: EarthService,
      public dialogRef: MatDialogRef<ImportDataComponent>,
      @Inject(MAT_DIALOG_DATA) public data: string
  ) {
    this.fullImportControl = this.fb.control('');
    this.recipeImportControl = this.fb.control('', Validators.required);

    this.importedCustomRecipes = new BehaviorSubject<Recipe[]>([]);
  }

  ngOnInit(): void {
    this.recipeImportControl.valueChanges.subscribe((value => {
      try {
        if (value && value !== '') {
          const importedRecipes = this.buildImportedRecipes(value);
          if (importedRecipes) {
            this.importedCustomRecipes.next(importedRecipes);
          }
        }
      } catch (exception) {
        this.recipeImportControl.setErrors({message: exception});
        console.log(exception);
        this.importedCustomRecipes.next(null);
      }
    }));
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

  importData(): void {
    const data = this.fullImportControl.value;
    if (data !== '') {
      this.earthService.setLocalStorage(data, '');
      this.earthService.loadLocalStorageData();
      this.fullImportControl.setValue('');
    }
  }

  canSubmit(): Observable<boolean> {
    return this.importedCustomRecipes.pipe(map((customRecipes) => {
      return customRecipes && customRecipes.length > 0;
    }));
  }

  importCustomRecipeData(): void {
    // this.earthService.updateCustomRecipes(this.importedCustomRecipes.getValue());
    this.recipeImportControl.setValue(null);
    this.recipeImportControl.setErrors(null);
    this.recipeImportControl.reset();
  }

  buildImportedRecipes(data: string): Recipe[] {
    const rawRecipes: [] = JSON.parse(data);
    return [];
    // return rawRecipes.map(rawRecipe => this.earthService.buildCustomRecipe(rawRecipe));
  }
}
