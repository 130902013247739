<div fxFlex fxLayout="column">
    <mat-toolbar fxFlex fxLayout="row" fxLayoutGap="20px">
        <span>Eco Price Calculator</span>
        <div class="toolbar-padding">
            <mat-form-field>
                <mat-label>Currency</mat-label>
                <mat-select [formControl]="currencySelect">
                    <mat-option *ngFor="let currency of earthService.getCurrencies() | async" [value]="currency">{{currency}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="toolbar-padding">
            <mat-form-field>
                <mat-label>Player</mat-label>
                <mat-select [formControl]="playerSelect">
                    <mat-option *ngFor="let player of earthService.getPlayers() | async" [value]="player">{{player}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="toolbar-padding">
            <mat-form-field>
                <mat-label>Calories Per {{earthService.selectedCurrency | async}}</mat-label>
                <input [formControl]="calorieCost" matInput type="number">
            </mat-form-field>
        </div>
        <div class="toolbar-padding">
            <mat-form-field>
                <mat-label>Profit Margin %</mat-label>
                <input [formControl]="profitMargin" matInput type="number">
            </mat-form-field>
        </div>
        <div class="toolbar-padding">
            <button (click)="earthService.syncPlayer()" mat-raised-button>Sync Player Recipes & Crafting Tables</button>
        </div>


      <span class="spacer"></span>
      <button [matMenuTriggerFor]="headerMenu" aria-label="Example icon-button with share icon" class="example-icon" mat-icon-button>
        <mat-icon>menu</mat-icon>
      </button>
      <mat-menu #headerMenu="matMenu">
        <button (click)="exportData()" mat-menu-item>
          <mat-icon>get_app</mat-icon>
          <span>Export</span>
        </button>
        <button (click)="importData()" mat-menu-item>
          <mat-icon>publish</mat-icon>
          <span>Import</span>
        </button>
        <button (click)="resetData()" mat-menu-item>
          <mat-icon>delete_forever</mat-icon>
          <span>Reset All Recipes</span>
        </button>
      </mat-menu>
    </mat-toolbar>
    <div fxFlex fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="30px" ngStyle.gt-sm="padding:20px;">
        <div fxFlex="none" fxFlex.gt-sm="22" fxLayout="column" fxLayoutGap="10px">

            <!-- Price Panel -->
            <mat-accordion>
                <mat-expansion-panel [expanded]="true" class="price-expansion-panel">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span class="primary-fg-header" fxLayoutAlign="start center" fxLayoutGap="10px">
                                <mat-icon>local_atm</mat-icon>
                                <span>Prices</span>
                            </span>
                        </mat-panel-title>
                        <mat-panel-description>

                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div *ngIf="((earthService.prices | async).length)" class="price-list" fxLayout="column" fxLayoutGap="10px">
                        <div class="price-list-header" fxLayoutGap="14px">
                            <div fxFlex>Item</div>
                            <div fxFlex="14" fxLayoutAlign="center">Craft</div>
                            <div fxFlex="18" fxLayoutAlign="center">Buy</div>
                            <div fxFlex="5"></div>
                        </div>
                        <app-price *ngFor="let price of earthService.prices | async;" fxLayout="column" fxLayoutGap="10px" [price]="price"></app-price>
                        <mat-divider></mat-divider>
                        <div class="prices-help" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">
                            Set your prices for recipe ingredients here, you can switch between the 3 pricing modes (Crafted, Server and Personal) by using the toggle button on the left hand side of the price.
                        </div>
                        <div class="prices-help" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px">
                            <div fxLayout="column wrap" fxLayoutAlign="center start"  fxLayoutGap="5px">
                                <div fxLayoutAlign="start center" fxLayoutGap="10px"><mat-icon>construction</mat-icon><span>Craft Price</span></div>
                                <div fxLayoutAlign="start center" fxLayoutGap="10px"><mat-icon>shopping_cart</mat-icon><span>Server Price</span></div>
                                <div fxLayoutAlign="start center" fxLayoutGap="10px"><mat-icon>personal</mat-icon><span>Personal Price</span></div>
                            </div>
                            <div fxLayout="column wrap" fxLayoutAlign="center start" fxLayoutGap="5px">
                                <div fxLayoutAlign="start center" fxLayoutGap="10px"><mat-icon>playlist_add</mat-icon><span>Add Recipe to Recipe List</span></div>
                                <div fxLayoutAlign="start center" fxLayoutGap="10px"><mat-icon>swap_horiz</mat-icon><span>Choose Specific Tag Item</span></div>
                                <div fxLayoutAlign="start center" fxLayoutGap="10px"><mat-icon>bookmark</mat-icon><span>Generic/Tag Item</span></div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!((earthService.prices | async).length)" fxLayout="column" fxLayoutAlign="start center">
                        <span class="mat-small add-recipe-label">Add a recipe to start calculating prices</span>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>


            <!-- Server Prices -->
            <mat-accordion>
                <mat-expansion-panel [expanded]="true" class="price-expansion-panel">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span class="primary-fg-header" fxLayoutAlign="start center" fxLayoutGap="10px">
                                <mat-icon>local_atm</mat-icon>
                                <span>Server Prices ({{(earthService.storeUpdatedString() | async)}})</span>
                            </span>
                        </mat-panel-title>
                        <mat-panel-description>

                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div fxLayout="column">
                        <div fxFlex="10"></div>
                        <div *ngIf="((earthService.filteredStoreOfferSummaries() | async).length)" class="price-list" fxLayout="column" fxLayoutGap="10px">
                            <div class="price-list-header" fxLayoutGap="14px">
                                <div fxFlex>Item</div>
                                <div fxFlex="25" fxLayoutAlign="end center">Max Buy</div>
                                <div fxFlex="25" fxLayoutAlign="end center">Min Sell</div>
                            </div>
                            <div (click)="offerSummary.expanded = !offerSummary.expanded" *ngFor="let offerSummary of earthService.filteredStoreOfferSummaries() | async;" fxLayout="column" fxLayoutGap="6px">
                                <div fxLayout="row" fxLayoutAlign="start center" >
                                    <div fxFlex>{{offerSummary.itemName}}</div>
                                    <div fxFlex="25" fxLayout="column" fxLayoutAlign="center end">
                                        {{offerSummary.maxBuyOffer() ? (offerSummary.maxBuyOffer().price | number : '1.2-2') : '-'}}  {{offerSummary.maxBuyOffer()?.currencyName}}
                                    </div>
                                    <div fxFlex="25" fxLayout="column" fxLayoutAlign="center end">
                                        {{offerSummary.minSellOffer() ? (offerSummary.minSellOffer().price | number : '1.2-2') : '-'}} {{offerSummary.minSellOffer()?.currencyName}}
                                    </div>
                                </div>
                                <div *ngIf="offerSummary.expanded" fxLayout="column" fxLayoutGap="10px">
                                    <div *ngFor="let offer of offerSummary.elligibleBuyOffers()" class="price-faded" fxLayout="row" fxLayoutAlign="start center">
                                        <div fxFlex>{{offer.storeName}}</div>
                                        <div fxFlex="25" fxLayoutAlign="end center">Buying</div>
                                        <div fxFlex="25" fxLayoutAlign="end center" fxLayoutGap="5px">
                                            <div fxFlex fxLayoutAlign="end center">{{offer.quantity}}</div>
                                            <div fxFlex="0 0 auto" fxLayoutAlign="center end">@</div>
                                            <div fxFlex="0 0 auto"  fxLayoutAlign="end center">{{offer.price | number : '1.2-2'}}</div>
                                        </div>

                                    </div>
                                    <mat-divider></mat-divider>
                                    <div *ngFor="let offer of offerSummary.elligibleSellOffers()" class="price-faded" fxLayout="row" fxLayoutAlign="start center">
                                        <div fxFlex>{{offer.storeName}}</div>
                                        <div fxFlex="25" fxLayoutAlign="end center">Selling</div>
                                        <div fxFlex="25" fxLayoutAlign="end center" fxLayoutGap="5px">
                                            <div fxFlex fxLayoutAlign="end center">{{offer.quantity}}</div>
                                            <div fxFlex="0 0 auto" fxLayoutAlign="center end">@</div>
                                            <div fxFlex="0 0 auto" fxLayoutAlign="end center">{{offer.price | number : '1.2-2'}}</div>
                                        </div>
                                    </div>
                                    <mat-divider></mat-divider>
                                </div>

                            </div>
                        </div>
<!--                        <div class="price-list" fxLayout="column" fxLayoutGap="10px" *ngIf="((earthService.predatoryPricing() | async).length)">-->
<!--                            <div class="price-list-header" fxLayoutGap="14px">-->
<!--                                <div fxFlex>Item</div>-->
<!--                                <div fxFlex="25" fxLayoutAlign="end center">Max Buy</div>-->
<!--                                <div fxFlex="25" fxLayoutAlign="end center">Min Sell</div>-->
<!--                            </div>-->
<!--                            <div *ngFor="let offerSummary of earthService.predatoryPricing() | async;" fxLayout="column" fxLayoutGap="6px" (click)="offerSummary.expanded = !offerSummary.expanded">-->
<!--                                <div fxLayoutAlign="start center" fxLayout="row" >-->
<!--                                    <div fxFlex>{{offerSummary.itemName}}</div>-->
<!--                                    <div fxFlex="25" fxLayout="column" fxLayoutAlign="center end">-->
<!--                                        {{offerSummary.maxBuyOffer()?.price | number : '1.2-2'}}  {{offerSummary.maxBuyOffer()?.currencyName}}-->
<!--                                    </div>-->
<!--                                    <div fxFlex="25" fxLayout="column" fxLayoutAlign="center end">-->
<!--                                        {{offerSummary.minSellOffer()?.price | number : '1.2-2'}} {{offerSummary.minSellOffer()?.currencyName}}-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <div *ngIf="offerSummary.expanded" fxLayout="column" fxLayoutGap="10px">-->
<!--                                    <div *ngFor="let offer of offerSummary.elligibleBuyOffers()" fxLayout="row" fxLayoutAlign="start center" class="price-faded">-->
<!--                                        <div fxFlex>{{offer.storeName}}</div>-->
<!--                                        <div fxFlex="25" fxLayoutAlign="end center">Buying</div>-->
<!--                                        <div fxFlex="25" fxLayoutAlign="end center" fxLayoutGap="5px">-->
<!--                                            <div fxFlex fxLayoutAlign="end center">{{offer.quantity}}</div>-->
<!--                                            <div fxFlex="0 0 auto" fxLayoutAlign="center end">@</div>-->
<!--                                            <div fxFlex="0 0 auto"  fxLayoutAlign="end center">{{offer.price | number : '1.2-2'}}</div>-->
<!--                                        </div>-->

<!--                                    </div>-->
<!--                                    <mat-divider></mat-divider>-->
<!--                                    <div *ngFor="let offer of offerSummary.elligibleSellOffers()" fxLayout="row" fxLayoutAlign="start center" class="price-faded">-->
<!--                                        <div fxFlex>{{offer.storeName}}</div>-->
<!--                                        <div fxFlex="25" fxLayoutAlign="end center">Selling</div>-->
<!--                                        <div fxFlex="25" fxLayoutAlign="end center" fxLayoutGap="5px">-->
<!--                                            <div fxFlex fxLayoutAlign="end center">{{offer.quantity}}</div>-->
<!--                                            <div fxFlex="0 0 auto" fxLayoutAlign="center end">@</div>-->
<!--                                            <div fxFlex="0 0 auto" fxLayoutAlign="end center">{{offer.price | number : '1.2-2'}}</div>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                    <mat-divider></mat-divider>-->
<!--                                </div>-->

<!--                            </div>-->
<!--                        </div>-->
                    </div>
                    <div *ngIf="!((earthService.prices | async).length)" fxLayout="column" fxLayoutAlign="start center">
                        <span class="mat-small add-recipe-label">Add a recipe to view server prices.</span>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <div fxFlex fxLayout="column" fxLayoutGap="10px">
            <form class="example-form find-recipes-input" fxLayoutGap="10px">
                <mat-form-field appearance="fill" class="example-full-width" fxFlex>
                    <input [(ngModel)]="autoCompleteValue"
                           [formControl]="myControl"
                           [matAutocomplete]="auto"
                           aria-label="Number"
                           matInput
                           placeholder="Find A Recipe"
                           type="text">
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addRecipe($event, autoCompleteValue)">
                        <mat-option *ngFor="let recipe of earthService.filteredRecipes() | async" [value]="recipe.id">
                            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
                                <span fxFlex="">{{recipe.name}}</span>
                                <span class="mat-small">{{recipe.craftingTable}}</span>
                                <span class="mat-small">{{recipe.baseXPGain}} XP </span>
                                <span class="mat-small">{{recipe.baseLaborCost}} Calories</span>
                                <span class="mat-small">{{recipe.baseCraftTime}} Seconds</span>
                            </div>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </form>

            <mat-accordion (cdkDropListDropped)="drop($event)" cdkDropList class="example-headers-align" multi>
                <div *ngFor="let recipe of earthService.watchedRecipes | async" cdkDrag class="panel-container" [id]="recipe.id">
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title cdkDragHandle>
                                <recipe-header  [recipe]="recipe" fxFlex></recipe-header>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <recipe-detail [recipe]="recipe" fxFlex></recipe-detail>
                    </mat-expansion-panel>
                </div>
                <div *ngIf="!((earthService.watchedRecipes | async).length)" fxLayout="column" fxLayoutAlign="start center">
                    <mat-card fxLayout="column" fxLayoutAlign="center center">
                        <div fxFlex>Your Recipe Watchlist is empty, select your player name in the header and use the player sync button to automatically watch recipes for items that are available in your Stores.</div>
                    </mat-card>
                </div>
            </mat-accordion>

        </div>
    </div>
</div>
