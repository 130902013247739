import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Recipe} from '../../models/Recipe';
import {RecipeIngredient} from '../../models/RecipeIngredient';
import {EarthService} from '../../service/earth.service';
import {ItemsRequired} from "../../models/ItemsRequired";
import {Item} from "../../models/Item";
import {BillOfMaterial} from "../../models/BillOfMaterial";
import {StoreOffer} from "../../models/StoreOffer";

@Component({
  selector: 'recipe-detail',
  templateUrl: './recipe-detail.component.html',
  styleUrls: ['./recipe-detail.component.scss']
})
export class RecipeDetailComponent implements OnInit {

  // @Input("prices") prices: Price[];
  @Input('recipe') recipe: Recipe;

  @Output() updated = new EventEmitter();
  @Output() editRecipe = new EventEmitter();
  @Output() exportRecipe = new EventEmitter();

  craftQuantity: number;
  storeOffer: StoreOffer;
  expandedList: RecipeIngredient[];
  itemsRequired: ItemsRequired[];
  billOfMaterials: BillOfMaterial[];

  constructor(
      public earthService: EarthService,
  ) {
    this.expandedList = [];
    this.itemsRequired = [];
    this.billOfMaterials = [];
  }

  ngOnInit(): void {
    this.craftQuantity = 1;
    this.recipe.ingredients.forEach(i => {
      this.itemsRequired.push(new ItemsRequired(i, i.modifiedAmount(this.recipe.efficiency, this.recipe.lavish)));
    });
    this.storeOffer = this.earthService.findMyStoreOffer(this.recipe.products[0]);
  }

  recipeIngredientPrice(ingredient: RecipeIngredient): number {
    return ingredient.modifiedAmount(this.recipe.efficiency, this.recipe.lavish)
        * this.earthService.findItemPriceValue(ingredient);
  }

  recipeLaborPrice(): number {
    return this.recipe.modifiedLaborCost(this.recipe.efficiency) *
        this.earthService.getCostPerLabor();
  }

  deleteRecipe(): void {
    this.earthService.removeWatchedRecipe(this.recipe);
  }

  editCustomRecipe(): void {
    this.editRecipe.emit();
  }

  exportCustomRecipe(): void {
    this.exportRecipe.emit();
  }

  getTotalOfIngredient(ingredient: Item): number {
    const i = this.itemsRequired.find(item => item.item.name === ingredient.name);
    if (i) {
     return i.amount;
    }
    return 0;
  }

  onUpdate(): void {
    this.updated.emit();
    this.earthService.refreshWatchedRecipes();
  }

  expand(ingredient: RecipeIngredient): void {
    this.expandedList.push(ingredient);
  }

}
