import {RecipeIngredient} from './RecipeIngredient';
import {Item} from './Item';

export class Price {

    baseItem: Item;
    value: number;
    mode: string;
    expanded = false;
    alternateIngredient: Item = null;

    constructor(item: Item, value: number, mode: string, expanded: boolean) {
       this.baseItem = item;
       this.value = value;
       this.mode = mode;
       this.expanded = expanded;
    }

    get item(): Item {
        return this.alternateIngredient ? this.alternateIngredient : this.baseItem;
    }

    modeIcon(): string {
        switch (this.mode) {
            case 'craft': return 'construction';
            case 'server': return 'shopping_cart';
            default: return 'personal';
        }
    }

    nextMode(): string {
        switch (this.mode) {
            case 'craft': return 'server';
            case 'server': return 'personal';
            default: return 'craft';
        }
    }

    modeLabel(mode: string): string {
        switch (mode) {
            case 'craft': return 'Craft';
            case 'server': return 'Server Price';
            default: return 'Personal Price';
        }
    }

    get craftPrice(): boolean {
        return this.mode === 'craft';
    }

    get serverPrice(): boolean {
        return this.mode === 'server';
    }

    get personalPrice(): boolean {
        return this.mode === 'personal';
    }

}
