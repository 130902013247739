import {Item} from "./Item";


export class Tag {

    name: string;
    products: string[];

    constructor(name, products: string[]) {
        this.name = name;
        this.products = products;
    }

}
