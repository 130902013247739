import {Component, Inject, OnInit} from '@angular/core';
import {AbstractControl, Form, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {EarthService} from '../../../service/earth.service';
import {Recipe} from '../../../models/Recipe';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject, Observable} from 'rxjs';

@Component({
  selector: 'app-create-recipe',
  templateUrl: './create-recipe.component.html',
  styleUrls: ['./create-recipe.component.scss']
})
export class CreateRecipeComponent implements OnInit {

  // public filteredItems: BehaviorSubject<Item[]>;

  createRecipeForm = this.fb.group({
    id: [''],
    custom: [true],
    label: ['', Validators.required],
    ingredients: this.fb.array([
    ]),
    products: this.fb.array([
    ])
  });


  constructor(
      private fb: FormBuilder,
      private dialogRef: MatDialogRef<CreateRecipeComponent>,
      public earthService: EarthService,
      @Inject(MAT_DIALOG_DATA) public data: Recipe
  ) {
    // this.filteredItems = new BehaviorSubject<Item[]>(this.earthService.getAllIngredients());
  }

  filter = (v) => {
    // const filteredItems = this._filter(v);
    // this.filteredItems.next(filteredItems);
  }

  ngOnInit(): void {
    // console.log(this.data);

    // if (this.data) {
    //   this.data.products.forEach(p => this.addProduct());
    //   this.data.ingredients.forEach(p => this.addIngredient());
    //   this.createRecipeForm.setValue(this.data);
    // } else {
    //   this.addProduct();
    //   this.addIngredient();
    // }

    this.ingredients.controls.forEach((control: FormGroup) => {
      control.get('item').get('name').valueChanges.subscribe(this.filter);
    });

    this.products.controls.forEach((control: FormGroup) => {
      control.get('item').get('name').valueChanges.subscribe(this.filter);
    });

    // this.ingredients.valueChanges.subscribe((v) => {
    //   const filteredItems = this._filter(v);
    //   if (typeof filteredItems !== 'boolean') {
    //     this.filteredItems.next(filteredItems);
    //   }
    // });

    // this.filteredItems = this.ingredients.valueChanges.pipe(
    //   startWith(''),
    //   tap(v => console.log(v)),
    //   tap(v => console.log('xc')),
    //   map(values => values.map(value => typeof value === 'string' ? value : value.product)),
    //   // tap(v => console.log(v)),
    //   map(values => values.map(searchString => searchString ? this._filter(searchString) : this.earthService.items)),
    //   // tap(v => console.log(v)),
    // );

  }

  get ingredients(): FormArray {
    return this.createRecipeForm.get('ingredients') as FormArray;
  }

  get label(): FormControl {
    return this.createRecipeForm.get('label') as FormControl;
  }

  get products(): FormArray {
    return this.createRecipeForm.get('products') as FormArray;
  }

  getIngredientQuantity(control: AbstractControl): FormControl {
    return control.get('quantity') as FormControl;
  }

  getIngredientName(control: AbstractControl): FormControl {
    const item = control.get('item') as FormGroup;
    return item.get('name') as FormControl;
  }

  // getIngredientNameAutoComplete(control: AbstractControl): Observable<NWItem[]> {
  //   const itemFormControl = control.get('item').get('name') as FormControl;
  //   return itemFormControl.valueChanges.pipe(
  //       startWith('d'),
  //       map(value => {
  //         console.log(value);
  //         console.log(this._filter(value));
  //         return this._filter(value);
  //       })
  //     );
  // }

  // private _filter(value: string): Item[] {
  //   if (value) {
  //     const filterValue = value.toLowerCase();
  //     return this.earthService.getAllIngredients().filter(option => option.name.toLowerCase().includes(filterValue));
  //   }
  //   return  this.earthService.getAllIngredients();
  // }

  addIngredient(): void {
    this.ingredients.push(this.itemFormTemplate);
  }

  addItem(): void {
    this.products.push(this.itemFormTemplate);
  }

  buttonLabel(): string {
    return !this.data ? 'Add Custom Recipe' : 'Update Custom Recipe';
  }


  save(): void {
    // const formData = this.createRecipeForm.value;
    // const newRecipe =  this.earthService.buildCustomRecipe(formData);
    //
    // if (!this.data) {
    //   this.dialogRef.close(newRecipe);
    // } else {
    //   this.data.ingredients = newRecipe.ingredients;
    //   this.data.products = newRecipe.products;
    //   this.data.label = newRecipe.label;
    //   this.data.id = newRecipe.id;
    //   this.dialogRef.close();
    // }


  }

  close(): void {
    this.dialogRef.close();
  }


  get itemFormTemplate(): FormGroup {
    const nameControl = this.fb.control('', Validators.required);
    nameControl.valueChanges.subscribe(this.filter);

    return this.fb.group({
      item: this.fb.group({
        name: nameControl
      }),
      quantity: this.fb.control(1)
    });
  }

  resetAutocomplete(): void {
    // this.filteredItems.next(this.earthService.getAllIngredients());
  }
}
