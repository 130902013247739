export const sampleTags = {
    "PluginVersion": "3.4.1",
    "Version": 2,
    "Tags": {
    "Raw Food": [
        "Rice",
        "Beans",
        "Camas Bulb",
        "Pumpkin",
        "Tomato",
        "Crimini Mushrooms",
        "Agave Leaves",
        "Giant Cactus Fruit",
        "Bolete Mushrooms",
        "Pineapple",
        "Taro Root",
        "Beet Greens",
        "Prickly Pear Fruit",
        "Beet",
        "Wheat",
        "Cookeina Mushrooms",
        "Heart Of Palm",
        "Huckleberries",
        "Fireweed Shoots",
        "Papaya",
        "Fiddleheads",
        "Bean Sprout",
        "Corn"
    ],
        "Wood": [
        "Ceiba Log",
        "Fir Log",
        "Birch Log",
        "Palm Log",
        "Saguaro Rib",
        "Cedar Log",
        "Spruce Log",
        "Joshua Log",
        "Oak Log",
        "Redwood Log"
    ],
        "Natural Fiber": [
        "Wood Pulp",
        "Kelp",
        "Plant Fibers"
    ],
        "Lumber": [
        "Softwood Lumber",
        "Hardwood Lumber",
        "Lumber"
    ],
        "Wood Board": [
        "Softwood Board",
        "Board",
        "Hardwood Board"
    ],
        "Fabric": [
        "Linen Fabric",
        "Wool Fabric",
        "Cotton Fabric",
        "Nylon Fabric",
        "Cloth"
    ],
        "Basic Research": [
        "Dendrology Research Paper Basic",
        "Geology Research Paper Basic",
        "Metallurgy Research Paper Basic",
        "Gathering Research Paper Basic",
        "Culinary Research Paper Basic"
    ],
        "Hewn Log": [
        "Hardwood Hewn Log",
        "Hewn Log",
        "Softwood Hewn Log"
    ],
        "Softwood": [
        "Fir Log",
        "Cedar Log",
        "Spruce Log",
        "Redwood Log"
    ],
        "Hardwood": [
        "Ceiba Log",
        "Birch Log",
        "Oak Log"
    ],
        "Rock": [
        "Sandstone",
        "Gneiss",
        "Shale",
        "Basalt",
        "Granite",
        "Stone",
        "Limestone"
    ],
        "Crop Seed": [
        "Sunflower Seed",
        "Flax Seed",
        "Amanita Mushroom Spores",
        "Agave Seed",
        "Bolete Mushroom Spores",
        "Taro Seed",
        "Huckleberry Seed",
        "Wheat Seed",
        "Prickly Pear Seed",
        "Rice",
        "Cookeina Mushroom Spores",
        "Pumpkin Seed",
        "Beans",
        "Crimini Mushroom Spores",
        "Papaya Seed",
        "Fireweed Seed",
        "Pineapple Seed",
        "Fern Spore",
        "Corn Seed",
        "Tomato Seed",
        "Beet Seed",
        "Camas Bulb"
    ],
        "Mortared Stone": [
        "Mortared Granite",
        "Mortared Limestone",
        "Mortared Stone",
        "Mortared Sandstone"
    ],
        "Ore": [
        "Coal",
        "Iron Ore",
        "Gold Ore",
        "Copper Ore"
    ],
        "Composite Lumber": [
        "Composite Birch Lumber",
        "Composite Oak Lumber",
        "Composite Palm Lumber",
        "Composite Joshua Lumber",
        "Composite Cedar Lumber",
        "Composite Fir Lumber",
        "Composite Ceiba Lumber",
        "Composite Redwood Lumber",
        "Composite Saguaro Lumber",
        "Composite Spruce Lumber",
        "Composite Lumber"
    ],
        "Fried Vegetable": [
        "Fried Hearts Of Palm",
        "Fried Fiddleheads",
        "Fried Camas",
        "Fried Tomatoes",
        "Fried Taro"
    ],
        "Campfire Salad": [
        "Fern Campfire Salad",
        "Root Campfire Salad",
        "Jungle Campfire Salad",
        "Beet Campfire Salad"
    ],
        "Crushed Rock": [
        "Crushed Sandstone",
        "Crushed Basalt",
        "Crushed Slag",
        "Crushed Shale",
        "Crushed Granite",
        "Crushed Gneiss",
        "Crushed Mixed Rock"
    ],
        "Small Fish": [
        "Moon Jellyfish",
        "Crab Carcass"
    ],
        "Silica": [
        "Crushed Sandstone",
        "Crushed Granite",
        "Crushed Mixed Rock"
    ],
        "Medium Fish": [
        "Salmon",
        "Bass",
        "Cod",
        "Trout"
    ],
        "Large Fish": [
        "Blue Shark",
        "Tuna"
    ],
        "Oil": [
        "Oil",
        "Flaxseed Oil"
    ],
        "Fruit": [
        "Pumpkin",
        "Giant Cactus Fruit",
        "Pineapple",
        "Prickly Pear Fruit",
        "Huckleberries",
        "Papaya"
    ],
        "Tiny Leather Carcass": [
        "Prairie Dog Carcass",
        "Turkey Carcass",
        "Snapping Turtle Carcass"
    ],
        "Petals": [
        "Orchid",
        "Creosote Flower",
        "Trillium Flower"
    ],
        "Medium Wooly Carcass": [
        "Bighorn Carcass",
        "Mountain Goat Carcass"
    ],
        "Tiny Carcass": [
        "Prairie Dog Carcass",
        "Hare Carcass",
        "Turkey Carcass",
        "Snapping Turtle Carcass",
        "Agouti Carcass",
        "Otter Carcass"
    ],
        "Small Carcass": [
        "Coyote Carcass",
        "Fox Carcass",
        "Wolf Carcass"
    ],
        "Baked Vegetable": [
        "Baked Tomato",
        "Baked Heart Of Palm",
        "Baked Beet",
        "Baked Taro",
        "Baked Agave",
        "Baked Corn",
        "Camas Bulb Bake"
    ],
        "Salad": [
        "Fruit Salad",
        "Basic Salad"
    ],
        "Fat": [
        "Oil",
        "Flaxseed Oil",
        "Tallow"
    ],
        "Tiny Fur Carcass": [
        "Agouti Carcass",
        "Otter Carcass"
    ],
        "Advanced Research": [
        "Agriculture Research Paper Advanced",
        "Dendrology Research Paper Advanced",
        "Engineering Research Paper Advanced",
        "Geology Research Paper Advanced",
        "Metallurgy Research Paper Advanced",
        "Culinary Research Paper Advanced"
    ],
        "Medium Leather Carcass": [
        "Alligator Carcass",
        "Elk Carcass",
        "Deer Carcass",
        "Jaguar Carcass"
    ],
        "Modern Research": [
        "Metallurgy Research Paper Modern",
        "Agriculture Research Paper Modern",
        "Dendrology Research Paper Modern",
        "Engineering Research Paper Modern",
        "Culinary Research Paper Modern",
        "Geology Research Paper Modern",
        "Cooking Research Paper Modern",
        "Campfire Cooking Research Paper Modern",
        "Masonry Research Paper Modern",
        "Tailoring Research Paper Modern",
        "Mechanics Research Paper Modern",
        "Baking Research Paper Modern",
        "Oil Drilling Research Paper Modern",
        "Industry Research Paper Modern",
        "Electronics Research Paper Modern",
        "Advanced Baking Research Paper Modern",
        "Hunting Research Paper Modern",
        "Glassworking Research Paper Modern",
        "Cutting Edge Cooking Research Paper Modern",
        "Advanced Masonry Research Paper Modern",
        "Composites Research Paper Modern",
        "Advanced Cooking Research Paper Modern"
    ],
        "Medium Carcass": [
        "Bighorn Carcass",
        "Alligator Carcass",
        "Elk Carcass",
        "Deer Carcass",
        "Jaguar Carcass",
        "Mountain Goat Carcass"
    ],
        "Ashlar Stone": [
        "Ashlar Granite",
        "Ashlar Gneiss",
        "Ashlar Shale",
        "Ashlar Basalt",
        "Ashlar Limestone",
        "Ashlar Sandstone"
    ],
        "Hardwood Lumber": [
        "Composite Birch Lumber",
        "Composite Oak Lumber",
        "Composite Ceiba Lumber"
    ],
        "Softwood Lumber": [
        "Composite Cedar Lumber",
        "Composite Fir Lumber",
        "Composite Redwood Lumber"
    ],
        "Fertilizer Filler": [
        "Pulp Filler",
        "Fiber Filler",
        "Composite Filler"
    ],
        "Fungus": [
        "Crimini Mushrooms",
        "Amanita Mushrooms",
        "Bolete Mushrooms",
        "Cookeina Mushrooms"
    ],
        "Greens": [
        "Agave Leaves",
        "Beet Greens",
        "Fireweed Shoots",
        "Fiddleheads"
    ],
        "Vegetable": [
        "Beans",
        "Camas Bulb",
        "Tomato",
        "Taro Root",
        "Beet",
        "Heart Of Palm",
        "Corn"
    ],
        "Grain": [
        "Rice",
        "Wheat"
    ]
},
    "ExportedAt": {
    "Ticks": 638410465452794100,
        "Year": 2024,
        "Month": 1,
        "Day": 17,
        "Hour": 0,
        "Min": 2,
        "Sec": 25,
        "StringRepresentation": "2024-01-17, 0:02:25"
}
}