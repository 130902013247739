<div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
    <div fxFlex="1 0 12" class="price" fxLayoutAlign="star center" fxLayout="row" fxLayoutGap="20px">
        <div fxLayoutAlign="center center" fxLayout="column">
            <div class="price-value" fxLayoutAlign="center center">
                ${{earthService.findRecipePriceValue(recipe) | number : '1.2-2'}}
            </div>
            <div class="price-type-label mat-small" fxLayoutAlign="center center">
                Craft Price
            </div>
        </div>
        <div *ngIf="earthService.findMyStorePrice(recipe.products[0])" fxLayoutAlign="center center" fxLayout="column" class="store-price-faded">
            <div class="price-value" fxLayoutAlign="center center" >
                ${{earthService.findMyStorePrice(recipe.products[0]) | number : '1.2-2'}}
            </div>
            <div class="price-type-label mat-small" fxLayoutAlign="center center">
                Store Price
            </div>
        </div>
        <div *ngIf="earthService.findCheapestServerPrice(recipe.products[0])" fxLayoutAlign="center center" fxLayout="column" class="server-price-faded">
            <div class="price-value" fxLayoutAlign="center center">
                ${{earthService.findCheapestServerPrice(recipe.products[0]) | number : '1.2-2'}}
            </div>
            <div class="price-type-label mat-small" fxLayoutAlign="center center">
                Server Price
            </div>
        </div>
    </div>
    <div fxFlex="1 1 100" class="recipe-name" fxLayoutAlign="start center" fxLayoutGap="30px">
        <div>{{recipe.name}}</div>
        <div fxLayoutGap="10px">
            <span class="mat-small">{{recipe.craftingTable}}</span>
            <span class="mat-small">{{recipe.modifiedLaborCost(1.0) | number : '1.0'}} Labor</span>
            <span class="mat-small">{{recipe.modifiedCraftTime(recipe.efficiency, recipe.lavish) | number : '1.0'}} Seconds</span>
        </div>
    </div>
</div>

