import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './home/home.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FlexLayoutModule} from '@angular/flex-layout';
import {RecipeDetailComponent} from './home/recipe-detail/recipe-detail.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatCheckbox, MatCheckboxModule} from '@angular/material/checkbox';
import {MatSelect, MatSelectModule} from '@angular/material/select';
import {MatOptionModule} from '@angular/material/core';
import {MatDivider, MatDividerModule} from '@angular/material/divider';
import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButton, MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatExpansionModule} from '@angular/material/expansion';
import {RecipeHeaderComponent} from './home/recipe-header/recipe-header.component';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDialogModule} from '@angular/material/dialog';
import {MatMenuModule} from '@angular/material/menu';
import {ItemPickerComponent} from './home/widgets/item-picker.component';
import { CreateRecipeComponent } from './home/dialogs/create-recipe/create-recipe.component';
import { ExpandableIngredientComponent } from './home/recipe-detail/expandable-ingredient/expandable-ingredient.component';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions} from '@angular/material/form-field';
import { CreateItemComponent } from './home/dialogs/create-item/create-item.component';
import { ExportDataComponent } from './home/dialogs/export-data/export-data.component';
import { ImportDataComponent } from './home/dialogs/import-data/import-data.component';
import { ResetDataComponent } from './home/dialogs/reset-data/reset-data.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { RecipeUpgradeComponent } from './home/recipe-header/recipe-upgade.component';
import { MatCardModule } from '@angular/material/card';
import { PriceComponent } from './home/prices/price/price.component';
import { GroupsComponent } from './home/recipes/groups/groups.component';
import {MatTabsModule} from "@angular/material/tabs";

const appearance: MatFormFieldDefaultOptions = {
    appearance: 'fill'
};

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        RecipeHeaderComponent,
        RecipeDetailComponent,
        RecipeUpgradeComponent,
        ItemPickerComponent,
        CreateRecipeComponent,
        ExpandableIngredientComponent,
        CreateItemComponent,
        ExportDataComponent,
        ImportDataComponent,
        ResetDataComponent,
        PriceComponent,
        GroupsComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        MatCheckboxModule,
        MatSelectModule,
        MatInputModule,
        MatMenuModule,
        MatDividerModule,
        MatDialogModule,
        MatOptionModule,
        MatButtonModule,
        MatTooltipModule,
        MatButtonModule,
        MatAutocompleteModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatIconModule,
        MatToolbarModule,
        DragDropModule,
        MatExpansionModule,
        MatSliderModule,
        MatSlideToggleModule,
        ClipboardModule,
        MatCardModule,
        MatTabsModule
    ],
    providers: [{
        provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
        useValue: appearance
    }],
    bootstrap: [AppComponent]
})
export class AppModule {
}
