export class StoreOffer {

    storeName: string;
    owner: string;
    balance: number;
    currencyName: string;
    enabled: boolean;
    itemName: string;
    buying: boolean;
    price: number;
    quantity: number;
    limit: number;
    maxNumWanted: number;
    minDurability: number;

    constructor(storeName: string, owner: string, balance: number, currencyName: string, enabled: boolean, itemName: string,
                buying: boolean, price: number, quantity: number, limit: number, maxNumWanted: number, minDurability: number) {
        this.storeName = storeName;
        this.owner = owner;
        this.balance = balance;
        this.currencyName = currencyName;
        this.enabled = enabled;
        this.itemName = itemName;
        this.buying = buying;
        this.price = price;
        this.quantity = quantity;
        this.limit = limit;
        this.maxNumWanted = maxNumWanted;
        this.minDurability = minDurability;
    }

}
