import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Recipe} from '../../../models/Recipe';
import {RecipeIngredient} from '../../../models/RecipeIngredient';
import {EarthService} from '../../../service/earth.service';
import {BillOfMaterial} from "../../../models/BillOfMaterial";

@Component({
  selector: 'app-expandable-ingredient',
  templateUrl: './expandable-ingredient.component.html',
  styleUrls: ['./expandable-ingredient.component.scss']
})
export class ExpandableIngredientComponent implements OnInit {

  @Input('ingredient') ingredient: RecipeIngredient;
  @Input('craftQuantity') craftQuantity = 0;
  @Input('recipe') recipe: Recipe;
  @Output() updated = new EventEmitter<BillOfMaterial[]>();

  expanded: boolean;
  childRecipe: Recipe;
  billOfMaterials: BillOfMaterial[];

  constructor(
      private earthService: EarthService
  ) {
    this.expanded = false;
    this.billOfMaterials = [];
  }

  ngOnInit(): void {
    this.childRecipe = this.earthService.findWatchedRecipeForItem(this.ingredient);
    if (!this.expanded || this.childRecipe.ingredients.length === 0) {
      this.billOfMaterials.push(
          new BillOfMaterial(this.ingredient.name, this.ingredient.modifiedAmount(this.recipe.efficiency, this.recipe.lavish), false, [])
      );
    }

    // this.childRecipe.ingredients.forEach(i => {
    //   this.billOfMaterials.push(new BillOfMaterial(i.name, i.modifiedAmount(this.recipe.efficiency, this.recipe.lavish), false, []));
    // });
  }

  modifiedAmount(): number {
   return this.ingredient.modifiedAmount(this.recipe.efficiency, this.recipe.lavish);
  }

  toggleExpand(ingredient: RecipeIngredient): void {
    this.expanded = !this.expanded;
  }

  expandIcon(): string {
    return this.expanded ? 'expand_less' : 'expand_more';
  }

  getRecipeIngredients(): RecipeIngredient[] {
    if (this.ingredient) {
      if (this.childRecipe) {
        return this.childRecipe.ingredients;
      }
    }
    return [];
  }

}
