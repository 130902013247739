export class SkillNeed {

    skill: string;
    level: number;

    constructor(skill, level) {
        this.skill = skill;
        this.level = level;
    }

}
