import {Item} from './Item';

export class BillOfMaterial {

    item: string;
    amount: number;
    expanded: boolean;
    children: BillOfMaterial[];

    constructor(item, amount, expanded, children) {
        this.item = item;
        this.amount = amount;
        this.expanded = expanded;
        this.children = children;
    }

}
