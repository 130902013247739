import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-reset-data',
  templateUrl: './reset-data.component.html',
  styleUrls: ['./reset-data.component.scss']
})
export class ResetDataComponent implements OnInit {

  constructor(
      public dialogRef: MatDialogRef<ResetDataComponent>,
      @Inject(MAT_DIALOG_DATA) public data: string
  ) {

  }

  ngOnInit(): void {}


  confirmDelete(): void {
    this.dialogRef.close(true);
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

}
