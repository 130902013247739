import {Price} from './Price';
import {RecipeIngredient} from './RecipeIngredient';
import {Tag} from './Tag';
import {SkillNeed} from './SkillNeed';
import {Item} from './Item';
import {BillOfMaterial} from './BillOfMaterial';

export class Recipe {

    parentKey: string;
    key: string;
    parentName: string;
    name: string;
    baseCraftTime: number;
    baseLaborCost: number;
    baseXPGain: number;
    craftingTable: string;
    craftingTableCanUseModules: string;
    defaultVariant: string;
    numberOfVariants: string;
    skillNeeds: SkillNeed[];
    ingredients: RecipeIngredient[];
    products: RecipeIngredient[];

    // Custom Saved Data
    position = 0;
    lavish = false;
    efficiency = 1.0;
    id = '';

    constructor(parentKey, key, parentName, name, baseCraftTime, baseLaborCost,
                baseXPGain, craftingTable, craftingTableCanUseModules,
                defaultVariant, numberOfVariants, skillNeeds, ingredients, products
    ) {
       this.parentKey = parentKey;
       this.key = key;
       this.name = name;
       this.parentName = parentName;
       this.baseCraftTime = baseCraftTime;
       this.baseLaborCost = baseLaborCost;
       this.baseXPGain = baseXPGain;
       this.craftingTable = craftingTable;
       this.craftingTableCanUseModules = craftingTableCanUseModules;
       this.defaultVariant = defaultVariant;
       this.numberOfVariants = numberOfVariants;
       this.skillNeeds = skillNeeds;
       this.ingredients = ingredients;
       this.products = products;

       this.id =  this.key + this.craftingTable;
    }

    modifiedCraftTime(efficiency: number, lavish: boolean): number {
        return this.baseCraftTime * efficiency * (lavish ? 0.95 : 1.00);
    }

    modifiedLaborCost(efficiency: number): number {
        return this.baseLaborCost * efficiency;
    }

}
