import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Price} from '../../models/Price';
import {Recipe} from '../../models/Recipe';
import {RecipeIngredient} from '../../models/RecipeIngredient';
import {EarthService} from "../../service/earth.service";
import {Item} from "../../models/Item";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";


@Component({
    selector: 'item-picker',
    templateUrl: './item-picker.component.html',
    styleUrls: ['./item-picker.component.scss']
})
export class ItemPickerComponent implements OnInit {

    @Input('ingredient') ingredient: Item;

    @Output() updated = new EventEmitter();

    constructor(
        public earthService: EarthService,
    ) {
    }


    ngOnInit(): void {

    }

    alternateRecipes(): Recipe[] {
        const matchingRecipes = this.earthService.getRecipesWithMatchingProduct(this.ingredient);
        return matchingRecipes.filter(recipeToAdd => !this.earthService.isRecipeAdded(recipeToAdd));
    }

    choseAlternateRecipe(recipe: Recipe): void {
        this.earthService.addWatchedRecipe(recipe);
    }

    onUpdate(): void {
        this.updated.emit();
    }

    protected readonly Recipe = Recipe;
}
