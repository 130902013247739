import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Recipe} from '../../models/Recipe';

@Component({
  selector: 'recipe-upgrade',
  template: `
    <button mat-icon-button class="small-button" [color]="isSelected(1.0)" (click)="setUpgrade(1.0)">
      <mat-icon>indeterminate_check_box</mat-icon>
    </button>
    <button mat-icon-button class="small-button" [color]="isSelected(0.9)" (click)="setUpgrade(0.9)">
      <mat-icon>looks_one</mat-icon>
    </button>
    <button mat-icon-button class="small-button" [color]="isSelected(0.75)" (click)="setUpgrade(0.75)">
      <mat-icon>looks_two</mat-icon>
    </button>
    <button mat-icon-button class="small-button" [color]="isSelected(0.65)" (click)="setUpgrade(0.65)">
      <mat-icon>looks_3</mat-icon>
    </button>
    <button mat-icon-button class="small-button" [color]="isSelected(0.55)" (click)="setUpgrade(0.55)">
      <mat-icon>looks_4</mat-icon>
    </button>
    <button mat-icon-button class="small-button" [color]="isSelected(0.50)" (click)="setUpgrade(0.50)">
      <mat-icon>looks_5</mat-icon>
    </button>
  `
})
export class RecipeUpgradeComponent implements OnInit {

  @Input('recipe') recipe: Recipe;

  @Output() updated = new EventEmitter();

  constructor() {

  }

  ngOnInit(): void {

  }

  getUpgradeIcon(): string {
    switch (this.recipe.efficiency) {
      case 1.0: return 'indeterminate_check_box';
      case 0.90: return 'looks_one';
      case 0.75: return 'looks_two';
      case 0.60: return 'looks_3';
      case 0.55: return 'looks_4';
      case 0.50: return 'looks_5';
      default: return '';
    }
  }

  cycleUpgrade(event: Event): void {
    event.stopPropagation();
    switch (this.recipe.efficiency) {
      case 1.0: this.recipe.efficiency = 0.90; return;
      case 0.90: this.recipe.efficiency = 0.75; return;
      case 0.75: this.recipe.efficiency = 0.60; return;
      case 0.60: this.recipe.efficiency = 0.55; return;
      case 0.55: this.recipe.efficiency = 0.50; return;
      case 0.50: this.recipe.efficiency = 1.0; return;
    }
  }

  isSelected(value: number): string {
    if (this.recipe.efficiency === value) {
      return 'primary';
    }
    return 'inactive';
  }

  setUpgrade(value: number): void {
    event.stopPropagation();
    this.recipe.efficiency = value;
    this.updated.emit();
  }

}
