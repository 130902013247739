import {Component, Inject, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {EarthService} from '../../../service/earth.service';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

import {MatInput} from '@angular/material/input';

@Component({
  selector: 'app-export-data',
  templateUrl: './export-data.component.html',
  styleUrls: ['./export-data.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExportDataComponent implements OnInit {

  exportCustomRecipeForm = this.fb.array([]);

  @ViewChild('customData') customData: MatInput;
  customRecipeExportControl: FormControl = this.fb.control('');

  constructor(
      private fb: FormBuilder,
      public earthService: EarthService,
      public dialogRef: MatDialogRef<ExportDataComponent>,
      @Inject(MAT_DIALOG_DATA) public data: string
  ) {

  }

  ngOnInit(): void {
    // const customRecipes = this.earthService.customRecipes.getValue();
    // this.exportCustomRecipeForm.valueChanges.subscribe((value: [{label: string, enabled: boolean}]) => {
    //   console.log(value);
    //   const recipes = value.filter(recipe => recipe.enabled).map((recipe) => {
    //     console.log(recipe.label);
    //     const foundRecipe = customRecipes.find( lookup => lookup.label === recipe.label);
    //     console.log(foundRecipe);
    //     return foundRecipe;
    //   });
    //   console.log(this.customRecipeExportControl);
    //   this.customRecipeExportControl.setValue(JSON.stringify(recipes));
    //   return JSON.stringify(recipes);
    // });
    //
    // this.earthService.customRecipes.getValue().forEach((customRecipe) => {
    //   this.exportCustomRecipeForm.push(this.fb.group({
    //     label: customRecipe.label,
    //     enabled: true
    //   }));
    // });

  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  getAllData(): string {
    return this.earthService.getJsonData();
  }

  getAllRecipeData(): void {
    // return JSON.stringify(this.earthService.customRecipes.getValue().concat(this.earthService.recipes.getValue()));
  }

  getCustomRecipeData(): string {
    return this.customRecipeExportControl.value;
  }

  getRecipeName(control: AbstractControl): FormControl {
    return control.get('label') as FormControl;
  }

  getRecipeEnabled(control: AbstractControl): FormControl {
    return control.get('enabled') as FormControl;
  }

  clickInput($event: MouseEvent): void {
    const eventTarget = $event.target as HTMLInputElement;
    eventTarget.select();
  }

  getRecipeControl(customRecipe: AbstractControl): FormGroup {
    return customRecipe as FormGroup;
  }
}
