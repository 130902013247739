<div class="recipe-detail" fxLayout="row" fxLayoutGap="20px">
    <div fxLayout="column" fxLayoutGap="8px" *ngIf="storeOffer" fxFlex="10" class="store-details">
        <div class="section-title">Store Details</div>
        <div><span fxLayoutAlign="start center">Store:</span> <span fxFlex fxLayoutAlign="end center">{{storeOffer.storeName}}</span></div>
        <div><span fxLayoutAlign="start center">Price:</span> <span fxFlex fxLayoutAlign="end center">{{storeOffer.price}} {{storeOffer.currencyName}}</span></div>
        <div><span fxLayoutAlign="start center">Quantity:</span> <span fxFlex fxLayoutAlign="end center">{{storeOffer.quantity}}</span></div>
        <div><span fxLayoutAlign="start center">Limit:</span> <span fxFlex fxLayoutAlign="end center">{{storeOffer.limit}}</span></div>
        <div><span fxLayoutAlign="start center">Durability:</span> <span fxFlex fxLayoutAlign="end center">{{storeOffer.minDurability}}</span></div>
    </div>

    <div fxLayout="column" fxLayoutGap="12px" fxFlex>
        <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="10px" fxLayoutAlign.lt-sm="start stretch" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="20px">
            <div fxFlex="50" fxFlex.lt-sm="100" fxLayout="column" fxLayoutGap="10px">
                <div class="section-title">Produces</div>
                <div *ngFor="let ingredient of recipe.products" fxLayout="row" fxLayoutGap="5px">
                    <span fxFlex>{{ingredient.name}}</span>
                    <span fxFlex="0 0 auto" class="light-text symbol">x</span>
                    <span fxFlex="10">{{ingredient.amount | number : '1.0-2'}} </span>
                </div>
            </div>
            <div fxFlex="50" fxLayout="column" fxLayoutGap="10px">
                <div class="section-title">Ingredients</div>
                <div *ngFor="let ingredient of recipe.ingredients" fxLayout="row" fxLayoutGap="5px">
                    <div fxFlex fxLayout="column">
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px">
                            <mat-icon>
                                {{earthService.findIngredientPriceObject(ingredient, true).modeIcon()}}
                            </mat-icon>
                            <span>{{earthService.findIngredientPriceObject(ingredient, true).item.name}}</span>
    <!--                        <item-picker *ngIf="ingredient.item.hasMultipleSources(recipes)  && ingredient.isCrafted(recipes, prices)" matTooltip="Choose a Different Recipe" [ingredient]="ingredient" [recipes]="recipes" [prices]="prices" (updated)="onUpdate()"></item-picker>-->
                        </div>
    <!--                    <span class="light-text mat-small" *ngIf="ingredient.recipeOverride && ingredient.isCrafted(recipes, prices)" fxLayoutAlign="start center">-->
    <!--                        <mat-icon class="tiny pointer" (click)="ingredient.removeRecipeOverride()">clear</mat-icon>-->
    <!--                        <span>{{ingredient.recipeOverride.label}}</span>-->
    <!--                    </span>-->
                    </div>
                    <span fxFlex="14" class="ingredient-price">${{earthService.findItemPriceValue(ingredient) | number : '1.2'}}</span>
                    <span fxFlex="0 0 auto" class="light-text symbol">x</span>
                    <span fxFlex="12">{{ingredient.modifiedAmount(recipe.efficiency, recipe.lavish) | number : '1.0-2'}} </span>
                    <span fxFlex="0 0 auto" class="light-text symbol">=</span>
                    <span fxFlex="10">${{recipeIngredientPrice(ingredient) | number : '1.0-2'}}</span>
                </div>
                <!-- Labor -->
                <div fxLayout="row" fxLayoutGap="5px">
                    <div fxFlex fxLayout="column">
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="6px">
                            <mat-icon>bolt</mat-icon>
                            <span>Labor</span>
                        </div>
                    </div>
                    <span fxFlex="14" class="ingredient-price">${{earthService.getCostPerLabor() | number : '1.2'}}</span>
                    <span fxFlex="0 0 auto" class="light-text symbol">x</span>
                    <span fxFlex="12">{{recipe.modifiedLaborCost(recipe.efficiency) | number : '1.0-2'}} </span>
                    <span fxFlex="0 0 auto" class="light-text symbol">=</span>
                    <span fxFlex="10">${{recipeLaborPrice() | number : '1.0-2'}}</span>
                </div>
            </div>

        </div>
    <!--    <mat-divider></mat-divider>-->
    <!--    <div  fxLayout="row"  fxLayoutGap="10px">-->
    <!--        <div  fxLayout="column"  fxLayoutGap="10px">-->
    <!--            <div fxFlex fxLayoutAlign="start center" fxLayout="row" fxLayoutGap="10px">-->
    <!--                <span>Craft x </span>-->
    <!--                <mat-form-field appearance="fill">-->
    <!--                    <input matInput type="number" [(ngModel)]="craftQuantity">-->
    <!--                </mat-form-field>-->
    <!--            </div>-->
    <!--            <div fxLayoutGap="00px">-->
    <!--                <app-expandable-ingredient *ngFor="let ingredient of recipe.ingredients" [recipe]="recipe" [craftQuantity]="craftQuantity" [ingredient]="ingredient"></app-expandable-ingredient>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--        <div fxLayout="column" fxLayoutGap="10px">-->
    <!--            <div>Totals</div>-->
    <!--            <div *ngFor="let ingredient of recipe.ingredients" fxLayout="row" fxLayoutGap="5px">-->
    <!--                <div>{{ingredient.name}} x {{getTotalOfIngredient(ingredient)}}</div>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--    </div>-->
        <mat-divider></mat-divider>
        <div fxLayout="row wrap" fxLayoutAlign="space-between center">
            <div fxFlex.lt-sm="30">Labor: {{recipe.baseLaborCost}}</div>
            <div fxFlex.lt-sm="30" fxLayoutAlign="center center">Craft Time: {{recipe.modifiedCraftTime(recipe.efficiency, recipe.lavish) | number : '1.0-2'}}</div>
            <div fxFlex.lt-sm="30" fxLayoutAlign="end center">
                <mat-slide-toggle matTooltip="Lavish Workspace Talent" color="primary" [(ngModel)]="recipe.lavish" [checked]="recipe.lavish" (change)="onUpdate()">
                    Lavish
                </mat-slide-toggle>
            </div>
            <div fxFlex.lt-sm="1 0 auto" class="button-padding" fxLayout="row" fxLayoutAlign="start center">
                <recipe-upgrade matTooltip="Upgrade Level" [recipe]="recipe" (updated)="onUpdate()"></recipe-upgrade>
            </div>
            <div fxLayoutAlign="end center">
                <button mat-icon-button color="primary" matTooltip="Delete Recipe" aria-label="Delete" (click)="deleteRecipe()">
                    <mat-icon>delete</mat-icon>
                </button>
    <!--            <button mat-icon-button color="primary" matTooltip="Edit Custom Recipe" aria-label="Delete" (click)="editCustomRecipe()" *ngIf="Recipe.custom">-->
    <!--                <mat-icon>edit</mat-icon>-->
    <!--            </button>-->
    <!--            <button mat-icon-button color="primary" matTooltip="Export Custom Recipe" aria-label="Delete" (click)="exportCustomRecipe()" *ngIf="Recipe.custom">-->
    <!--                <mat-icon>export</mat-icon>-->
    <!--            </button>-->
            </div>
        </div>
    </div>
</div>


