<h1 mat-dialog-title> Data Import</h1>


<div fxLayout="column" fxLayoutGap="20px">
    <div fxLayout="column" fxLayoutGap="10px">
        <h2>Full Data Import</h2>
        <div fxFill fxLayout="row">
            <mat-form-field fxFlex fxFill fxLayout="column" fxLayoutAlign="start center" >
                <label>
                    <input matInput type="text" [formControl]="fullImportControl">
                </label>
            </mat-form-field>
            <button matSuffix mat-raised-button color="primary" aria-label="Copy" (click)="importData()">
                <mat-icon>content_paste</mat-icon> Import
            </button>
        </div>
        <mat-error *ngIf="fullImportControl.invalid">{{fullImportControl.errors.message}}</mat-error>
    </div>
    <mat-divider></mat-divider>
    <div fxLayout="column" fxLayoutGap="10px">
        <h2>Custom Recipe Import</h2>
        <div fxFill fxLayout="row">
            <mat-form-field fxFlex fxFill fxLayout="column" fxLayoutAlign="start center" >
                <label>
                    <input matInput type="text" [formControl]="recipeImportControl">
                </label>
            </mat-form-field>
            <button matSuffix mat-raised-button color="primary" aria-label="Copy" (click)="importCustomRecipeData()" [disabled]="recipeImportControl.invalid">
                <mat-icon>content_paste</mat-icon> Import
            </button>
        </div>
        <mat-error *ngIf="recipeImportControl.invalid">{{recipeImportControl.errors.message}}</mat-error>
    </div>
</div>

<mat-dialog-actions align="end">
    <button mat-raised-button (click)="onNoClick()">Close</button>
</mat-dialog-actions>