import {Item} from './Item';

export class RecipeIngredient extends Item {

    isStatic: boolean;
    amount: number;

    constructor(isSpecificItem, name, amount, isStatic) {
        super(name, isSpecificItem);
        this.amount = amount;
        this.isStatic = isStatic;
    }

    modifiedAmount(efficiency: number, lavish: boolean): number {
        return this.amount * (this.isStatic ? 1.0 : efficiency * (lavish ? 0.95 : 1.00));
    }
}
