export const recipeData = [{
    "Name": "Ashlar Basalt Recipe",
    "LaborInCalories": {"Value": 300.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "AshlarBasalt",
        "Ingredients": [{"Name": "BasaltItem", "Value": {"Value": 20.0, "IsDynamic": true}}, {
            "Name": "MortarItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }, {"Name": "SteelBarItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{
            "Name": "AshlarBasaltItem",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }, {"Name": "CrushedBasaltItem", "Value": {"Value": 2.0, "IsDynamic": true}}]
    }]
}, {
    "Name": "Ashlar Gneiss Recipe",
    "LaborInCalories": {"Value": 300.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "AshlarGneiss",
        "Ingredients": [{"Name": "GneissItem", "Value": {"Value": 20.0, "IsDynamic": true}}, {
            "Name": "MortarItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }, {"Name": "SteelBarItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{
            "Name": "AshlarGneissItem",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }, {"Name": "CrushedGneissItem", "Value": {"Value": 2.0, "IsDynamic": true}}]
    }]
}, {
    "Name": "Ashlar Granite Recipe",
    "LaborInCalories": {"Value": 300.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "AshlarGranite",
        "Ingredients": [{"Name": "GraniteItem", "Value": {"Value": 20.0, "IsDynamic": true}}, {
            "Name": "MortarItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }, {"Name": "SteelBarItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{
            "Name": "AshlarGraniteItem",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }, {"Name": "CrushedGraniteItem", "Value": {"Value": 2.0, "IsDynamic": true}}]
    }]
}, {
    "Name": "Ashlar Limestone Recipe",
    "LaborInCalories": {"Value": 300.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "AshlarLimestone",
        "Ingredients": [{"Name": "LimestoneItem", "Value": {"Value": 20.0, "IsDynamic": true}}, {
            "Name": "MortarItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }, {"Name": "SteelBarItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{
            "Name": "AshlarLimestoneItem",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }, {"Name": "CrushedLimestoneItem", "Value": {"Value": 2.0, "IsDynamic": true}}]
    }]
}, {
    "Name": "Ashlar Sandstone Recipe",
    "LaborInCalories": {"Value": 300.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "AshlarSandstone",
        "Ingredients": [{"Name": "SandstoneItem", "Value": {"Value": 20.0, "IsDynamic": true}}, {
            "Name": "MortarItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }, {"Name": "SteelBarItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{
            "Name": "AshlarSandstoneItem",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }, {"Name": "CrushedSandstoneItem", "Value": {"Value": 2.0, "IsDynamic": true}}]
    }]
}, {
    "Name": "Ashlar Shale Recipe",
    "LaborInCalories": {"Value": 300.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "AshlarShale",
        "Ingredients": [{"Name": "ShaleItem", "Value": {"Value": 20.0, "IsDynamic": true}}, {
            "Name": "MortarItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }, {"Name": "SteelBarItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{
            "Name": "AshlarShaleItem",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }, {"Name": "CrushedShaleItem", "Value": {"Value": 2.0, "IsDynamic": true}}]
    }]
}, {
    "Name": "Asphalt Concrete Recipe",
    "LaborInCalories": {"Value": 300.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "AsphaltConcrete",
        "Ingredients": [{"Name": "CementItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "SandItem",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "CrushedRock", "Value": {"Value": 5.0, "IsDynamic": true}}],
        "Products": [{"Name": "AsphaltConcreteItem", "Value": {"Value": 2.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Barrel Recipe",
    "LaborInCalories": {"Value": 180.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "Barrel",
        "Ingredients": [{"Name": "IronBarItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "BarrelItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Biodiesel Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.8, "IsDynamic": true},
    "Recipes": [{
        "Name": "Biodiesel",
        "Ingredients": [{"Name": "Fat", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "BarrelItem",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "BiodieselItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Brick Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "Brick",
        "Ingredients": [{"Name": "ClayItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "MortarItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "BrickItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Composite Lumber Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CompositeLumber",
        "Ingredients": [{"Name": "PlasticItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "EpoxyItem",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }, {"Name": "Wood", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "CompositeLumberItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeBirchLumber",
        "Ingredients": [{"Name": "BirchLogItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "PlasticItem",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }, {"Name": "EpoxyItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "CompositeBirchLumberItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeCedarLumber",
        "Ingredients": [{"Name": "CedarLogItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "PlasticItem",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }, {"Name": "EpoxyItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "CompositeCedarLumberItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeCeibaLumber",
        "Ingredients": [{"Name": "CeibaLogItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "PlasticItem",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }, {"Name": "EpoxyItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "CompositeCeibaLumberItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeFirLumber",
        "Ingredients": [{"Name": "FirLogItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "PlasticItem",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }, {"Name": "EpoxyItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "CompositeFirLumberItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeJoshuaLumber",
        "Ingredients": [{"Name": "JoshuaLogItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "PlasticItem",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }, {"Name": "EpoxyItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "CompositeJoshuaLumberItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeOakLumber",
        "Ingredients": [{"Name": "OakLogItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "PlasticItem",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }, {"Name": "EpoxyItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "CompositeOakLumberItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositePalmLumber",
        "Ingredients": [{"Name": "PalmLogItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "PlasticItem",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }, {"Name": "EpoxyItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "CompositePalmLumberItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeRedwoodLumber",
        "Ingredients": [{"Name": "RedwoodLogItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "PlasticItem",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }, {"Name": "EpoxyItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "CompositeRedwoodLumberItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeSaguaroLumber",
        "Ingredients": [{"Name": "SaguaroRibItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "PlasticItem",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }, {"Name": "EpoxyItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "CompositeSaguaroLumberItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeSpruceLumber",
        "Ingredients": [{"Name": "SpruceLogItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "PlasticItem",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }, {"Name": "EpoxyItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "CompositeSpruceLumberItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Copper Bar Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.25, "IsDynamic": true},
    "Recipes": [{
        "Name": "CopperBar",
        "Ingredients": [{"Name": "CopperConcentrateItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "CopperBarItem", "Value": {"Value": 2.0, "IsDynamic": false}}, {
            "Name": "SlagItem",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }]
    }]
}, {
    "Name": "Copper Concentrate Recipe",
    "LaborInCalories": {"Value": 40.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "CopperConcentrate",
        "Ingredients": [{"Name": "CrushedCopperOreItem", "Value": {"Value": 7.0, "IsDynamic": true}}],
        "Products": [{
            "Name": "CopperConcentrateItem",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }, {"Name": "WetTailingsItem", "Value": {"Value": 3.0, "IsDynamic": true}}]
    }]
}, {
    "Name": "Copper Pipe Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.8, "IsDynamic": true},
    "Recipes": [{
        "Name": "CopperPipe",
        "Ingredients": [{"Name": "CopperBarItem", "Value": {"Value": 2.0, "IsDynamic": true}}],
        "Products": [{"Name": "CopperPipeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Corrugated Steel Recipe",
    "LaborInCalories": {"Value": 40.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CorrugatedSteel",
        "Ingredients": [{"Name": "SteelBarItem", "Value": {"Value": 2.0, "IsDynamic": true}}],
        "Products": [{"Name": "CorrugatedSteelItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Crushed Copper Ore Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "CrushedCopperOre",
        "Ingredients": [{"Name": "CopperOreItem", "Value": {"Value": 12.0, "IsDynamic": false}}],
        "Products": [{
            "Name": "CrushedCopperOreItem",
            "Value": {"Value": 2.0, "IsDynamic": false}
        }, {"Name": "CrushedGraniteItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Crushed Gold Ore Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "CrushedGoldOre",
        "Ingredients": [{"Name": "GoldOreItem", "Value": {"Value": 12.0, "IsDynamic": false}}],
        "Products": [{
            "Name": "CrushedGoldOreItem",
            "Value": {"Value": 2.0, "IsDynamic": false}
        }, {"Name": "CrushedGraniteItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Crushed Granite Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "CrushedGranite",
        "Ingredients": [{"Name": "GraniteItem", "Value": {"Value": 12.0, "IsDynamic": false}}],
        "Products": [{"Name": "CrushedGraniteItem", "Value": {"Value": 3.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Crushed Iron Ore Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "CrushedIronOre",
        "Ingredients": [{"Name": "IronOreItem", "Value": {"Value": 12.0, "IsDynamic": false}}],
        "Products": [{
            "Name": "CrushedIronOreItem",
            "Value": {"Value": 2.0, "IsDynamic": false}
        }, {"Name": "CrushedSandstoneItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Crushed Limestone Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "CrushedLimestone",
        "Ingredients": [{"Name": "LimestoneItem", "Value": {"Value": 12.0, "IsDynamic": false}}],
        "Products": [{"Name": "CrushedLimestoneItem", "Value": {"Value": 3.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Crushed Sandstone Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "CrushedSandstone",
        "Ingredients": [{"Name": "SandstoneItem", "Value": {"Value": 12.0, "IsDynamic": false}}],
        "Products": [{"Name": "CrushedSandstoneItem", "Value": {"Value": 3.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Crushed Shale Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "CrushedShale",
        "Ingredients": [{"Name": "ShaleItem", "Value": {"Value": 12.0, "IsDynamic": false}}],
        "Products": [{"Name": "CrushedShaleItem", "Value": {"Value": 3.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Dirt Ramp Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": false},
    "Recipes": [{
        "Name": "DirtRamp",
        "Ingredients": [{"Name": "DirtItem", "Value": {"Value": 6.0, "IsDynamic": false}}],
        "Products": [{"Name": "DirtRampItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Flat Steel Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "FlatSteel",
        "Ingredients": [{"Name": "SteelBarItem", "Value": {"Value": 3.0, "IsDynamic": true}}, {
            "Name": "EpoxyItem",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "FlatSteelItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Framed Glass Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "FramedGlass",
        "Ingredients": [{"Name": "GlassItem", "Value": {"Value": 6.0, "IsDynamic": true}}, {
            "Name": "SteelBarItem",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "FramedGlassItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Gasoline Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.8, "IsDynamic": true},
    "Recipes": [{
        "Name": "Gasoline",
        "Ingredients": [{"Name": "PetroleumItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "GasolineItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Glass Recipe",
    "LaborInCalories": {"Value": 50.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "Glass",
        "Ingredients": [{"Name": "SandItem", "Value": {"Value": 3.0, "IsDynamic": true}}],
        "Products": [{"Name": "GlassItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Gold Bar Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.25, "IsDynamic": true},
    "Recipes": [{
        "Name": "GoldBar",
        "Ingredients": [{"Name": "GoldConcentrateItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "GoldBarItem", "Value": {"Value": 2.0, "IsDynamic": false}}, {
            "Name": "SlagItem",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }]
    }]
}, {
    "Name": "Gold Concentrate Recipe",
    "LaborInCalories": {"Value": 40.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "GoldConcentrate",
        "Ingredients": [{"Name": "CrushedGoldOreItem", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{
            "Name": "GoldConcentrateItem",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }, {"Name": "WetTailingsItem", "Value": {"Value": 4.0, "IsDynamic": true}}]
    }]
}, {
    "Name": "Hardwood Hewn Log Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 0.25, "IsDynamic": false},
    "Recipes": [{
        "Name": "HardwoodHewnLog",
        "Ingredients": [{"Name": "Hardwood", "Value": {"Value": 2.0, "IsDynamic": false}}],
        "Products": [{"Name": "HardwoodHewnLogItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Hardwood Lumber Recipe",
    "LaborInCalories": {"Value": 60.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "HardwoodLumber",
        "Ingredients": [{
            "Name": "HardwoodHewnLogItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "NailItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "HardwoodLumberItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Hewn Log Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 0.25, "IsDynamic": false},
    "Recipes": [{
        "Name": "HewnLog",
        "Ingredients": [{"Name": "Wood", "Value": {"Value": 2.0, "IsDynamic": false}}],
        "Products": [{"Name": "HewnLogItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Iron Bar Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.25, "IsDynamic": true},
    "Recipes": [{
        "Name": "IronBar",
        "Ingredients": [{"Name": "IronConcentrateItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "IronBarItem", "Value": {"Value": 2.0, "IsDynamic": false}}, {
            "Name": "SlagItem",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }]
    }]
}, {
    "Name": "Iron Concentrate Recipe",
    "LaborInCalories": {"Value": 40.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "IronConcentrate",
        "Ingredients": [{"Name": "CrushedIronOreItem", "Value": {"Value": 5.0, "IsDynamic": true}}],
        "Products": [{
            "Name": "IronConcentrateItem",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }, {"Name": "WetTailingsItem", "Value": {"Value": 2.0, "IsDynamic": true}}]
    }]
}, {
    "Name": "Iron Pipe Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.8, "IsDynamic": true},
    "Recipes": [{
        "Name": "IronPipe",
        "Ingredients": [{"Name": "IronBarItem", "Value": {"Value": 2.0, "IsDynamic": true}}],
        "Products": [{"Name": "IronPipeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Lumber Recipe",
    "LaborInCalories": {"Value": 60.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "Lumber",
        "Ingredients": [{"Name": "NailItem", "Value": {"Value": 4.0, "IsDynamic": true}}, {
            "Name": "HewnLog",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "LumberItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Mortared Stone Recipe",
    "LaborInCalories": {"Value": 30.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.15, "IsDynamic": true},
    "Recipes": [{
        "Name": "MortaredStone",
        "Ingredients": [{"Name": "MortarItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "Rock",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "MortaredStoneItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "MortaredGranite",
        "Ingredients": [{"Name": "GraniteItem", "Value": {"Value": 4.0, "IsDynamic": true}}, {
            "Name": "MortarItem",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "MortaredGraniteItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "MortaredLimestone",
        "Ingredients": [{"Name": "LimestoneItem", "Value": {"Value": 4.0, "IsDynamic": true}}, {
            "Name": "MortarItem",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "MortaredLimestoneItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "MortaredSandstone",
        "Ingredients": [{"Name": "SandstoneItem", "Value": {"Value": 4.0, "IsDynamic": true}}, {
            "Name": "MortarItem",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "MortaredSandstoneItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Petroleum Recipe",
    "LaborInCalories": {"Value": 60.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Petroleum",
        "Ingredients": [{"Name": "BarrelItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "PetroleumItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Reinforced Concrete Recipe",
    "LaborInCalories": {"Value": 40.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "ReinforcedConcrete",
        "Ingredients": [{"Name": "CementItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "RebarItem",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "SandItem", "Value": {"Value": 2.0, "IsDynamic": true}}, {
            "Name": "CrushedRock",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "ReinforcedConcreteItem", "Value": {"Value": 4.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Softwood Hewn Log Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 0.25, "IsDynamic": false},
    "Recipes": [{
        "Name": "SoftwoodHewnLog",
        "Ingredients": [{"Name": "Softwood", "Value": {"Value": 2.0, "IsDynamic": false}}],
        "Products": [{"Name": "SoftwoodHewnLogItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Softwood Lumber Recipe",
    "LaborInCalories": {"Value": 60.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "SoftwoodLumber",
        "Ingredients": [{
            "Name": "SoftwoodHewnLogItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "NailItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "SoftwoodLumberItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Steel Bar Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "SteelBar",
        "Ingredients": [{"Name": "IronBarItem", "Value": {"Value": 2.0, "IsDynamic": true}}, {
            "Name": "QuicklimeItem",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }, {"Name": "Coal", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "SteelBarItem", "Value": {"Value": 1.0, "IsDynamic": false}}, {
            "Name": "SlagItem",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }]
    }]
}, {
    "Name": "Steel Pipe Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "SteelPipe",
        "Ingredients": [{"Name": "SteelBarItem", "Value": {"Value": 2.0, "IsDynamic": true}}],
        "Products": [{"Name": "SteelPipeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Stone Ramp Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "StoneRamp",
        "Ingredients": [{"Name": "MortarItem", "Value": {"Value": 6.0, "IsDynamic": true}}, {
            "Name": "CrushedRock",
            "Value": {"Value": 3.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "StoneRampItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Stone Road Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "StoneRoad",
        "Ingredients": [{"Name": "MortarItem", "Value": {"Value": 3.0, "IsDynamic": true}}, {
            "Name": "CrushedRock",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "StoneRoadItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Bandana Recipe",
    "LaborInCalories": {"Value": 60.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Bandana",
        "Ingredients": [{"Name": "ClothItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "LeatherHideItem",
            "Value": {"Value": 15.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "BandanaItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Basic Backpack Recipe",
    "LaborInCalories": {"Value": 60.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "BasicBackpack",
        "Ingredients": [{"Name": "LeatherHideItem", "Value": {"Value": 4.0, "IsDynamic": true}}, {
            "Name": "ClothItem",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "BasicBackpackItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Bearpack Recipe",
    "LaborInCalories": {"Value": 60.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Bearpack",
        "Ingredients": [{
            "Name": "CelluloseFiberItem",
            "Value": {"Value": 20.0, "IsDynamic": true}
        }, {"Name": "ClothItem", "Value": {"Value": 30.0, "IsDynamic": true}}, {
            "Name": "FurPeltItem",
            "Value": {"Value": 25.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "BearpackItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Big Backpack Recipe",
    "LaborInCalories": {"Value": 60.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "BigBackpack",
        "Ingredients": [{"Name": "LeatherHideItem", "Value": {"Value": 4.0, "IsDynamic": true}}, {
            "Name": "ClothItem",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "BigBackpackItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Camo Pants Recipe",
    "LaborInCalories": {"Value": 60.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CamoPants",
        "Ingredients": [{
            "Name": "ClothItem",
            "Value": {"Value": 20.0, "IsDynamic": true}
        }, {"Name": "CelluloseFiberItem", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "CamoPantsItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Camo Shirt Recipe",
    "LaborInCalories": {"Value": 60.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CamoShirt",
        "Ingredients": [{
            "Name": "ClothItem",
            "Value": {"Value": 20.0, "IsDynamic": true}
        }, {"Name": "CelluloseFiberItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "LeatherHideItem",
            "Value": {"Value": 20.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "CamoShirtItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Capris Recipe",
    "LaborInCalories": {"Value": 60.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Capris",
        "Ingredients": [{
            "Name": "LeatherHideItem",
            "Value": {"Value": 3.0, "IsDynamic": true}
        }, {"Name": "PlantFibersItem", "Value": {"Value": 20.0, "IsDynamic": true}}],
        "Products": [{"Name": "CaprisItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Cloth Belt Recipe",
    "LaborInCalories": {"Value": 60.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "ClothBelt",
        "Ingredients": [{"Name": "LeatherHideItem", "Value": {"Value": 2.0, "IsDynamic": true}}],
        "Products": [{"Name": "ClothBeltItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Cloth Cape Recipe",
    "LaborInCalories": {"Value": 60.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "ClothCape",
        "Ingredients": [{
            "Name": "ClothItem",
            "Value": {"Value": 20.0, "IsDynamic": true}
        }, {"Name": "CelluloseFiberItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "LeatherHideItem",
            "Value": {"Value": 30.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "ClothCapeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Derpy Hat Recipe",
    "LaborInCalories": {"Value": 60.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "DerpyHat",
        "Ingredients": [{
            "Name": "LeatherHideItem",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "PlantFibersItem", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "DerpyHatItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Garden Boots Recipe",
    "LaborInCalories": {"Value": 60.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "GardenBoots",
        "Ingredients": [{"Name": "LeatherHideItem", "Value": {"Value": 2.0, "IsDynamic": true}}, {
            "Name": "FurPeltItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "GardenBootsItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Gigot Sleeve Shirt Recipe",
    "LaborInCalories": {"Value": 60.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "GigotSleeveShirt",
        "Ingredients": [{"Name": "PlantFibersItem", "Value": {"Value": 30.0, "IsDynamic": true}}],
        "Products": [{"Name": "GigotSleeveShirtItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Henley Recipe",
    "LaborInCalories": {"Value": 60.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Henley",
        "Ingredients": [{
            "Name": "LeatherHideItem",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }, {"Name": "PlantFibersItem", "Value": {"Value": 25.0, "IsDynamic": true}}],
        "Products": [{"Name": "HenleyItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Light Backpack Recipe",
    "LaborInCalories": {"Value": 60.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "LightBackpack",
        "Ingredients": [{
            "Name": "ClothItem",
            "Value": {"Value": 20.0, "IsDynamic": true}
        }, {"Name": "CelluloseFiberItem", "Value": {"Value": 20.0, "IsDynamic": true}}],
        "Products": [{"Name": "LightBackpackItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Low Top Shoes Recipe",
    "LaborInCalories": {"Value": 60.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "LowTopShoes",
        "Ingredients": [{"Name": "LeatherHideItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "LowTopShoesItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Running Shoes Recipe",
    "LaborInCalories": {"Value": 60.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "RunningShoes",
        "Ingredients": [{"Name": "LeatherHideItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "ClothItem",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "RunningShoesItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Shorts Recipe",
    "LaborInCalories": {"Value": 60.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Shorts",
        "Ingredients": [{
            "Name": "LeatherHideItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "PlantFibersItem", "Value": {"Value": 20.0, "IsDynamic": true}}],
        "Products": [{"Name": "ShortsItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Square Belt Recipe",
    "LaborInCalories": {"Value": 60.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "SquareBelt",
        "Ingredients": [{"Name": "LeatherHideItem", "Value": {"Value": 2.0, "IsDynamic": true}}],
        "Products": [{"Name": "SquareBeltItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Tall Boots Recipe",
    "LaborInCalories": {"Value": 60.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "TallBoots",
        "Ingredients": [{"Name": "LeatherHideItem", "Value": {"Value": 2.0, "IsDynamic": true}}, {
            "Name": "FurPeltItem",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "TallBootsItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Trousers Recipe",
    "LaborInCalories": {"Value": 60.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Trousers",
        "Ingredients": [{"Name": "LeatherHideItem", "Value": {"Value": 2.0, "IsDynamic": true}}, {
            "Name": "FurPeltItem",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }, {"Name": "PlantFibersItem", "Value": {"Value": 20.0, "IsDynamic": true}}],
        "Products": [{"Name": "TrousersItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Work Backpack Recipe",
    "LaborInCalories": {"Value": 60.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "WorkBackpack",
        "Ingredients": [{
            "Name": "ClothItem",
            "Value": {"Value": 20.0, "IsDynamic": true}
        }, {"Name": "CelluloseFiberItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "LeatherHideItem",
            "Value": {"Value": 20.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "WorkBackpackItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Work Boots Recipe",
    "LaborInCalories": {"Value": 60.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "WorkBoots",
        "Ingredients": [{
            "Name": "ClothItem",
            "Value": {"Value": 20.0, "IsDynamic": true}
        }, {"Name": "CelluloseFiberItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "LeatherHideItem",
            "Value": {"Value": 20.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "WorkBootsItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Berry Extract Fertilizer Recipe",
    "LaborInCalories": {"Value": 25.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.3, "IsDynamic": true},
    "Recipes": [{
        "Name": "BerryExtractFertilizer",
        "Ingredients": [{
            "Name": "CompositeFillerItem",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }, {"Name": "HuckleberryExtractItem", "Value": {"Value": 2.0, "IsDynamic": true}}],
        "Products": [{"Name": "BerryExtractFertilizerItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Blood Meal Fertilizer Recipe",
    "LaborInCalories": {"Value": 25.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.3, "IsDynamic": true},
    "Recipes": [{
        "Name": "BloodMealFertilizer",
        "Ingredients": [{
            "Name": "PulpFillerItem",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }, {"Name": "ScrapMeatItem", "Value": {"Value": 15.0, "IsDynamic": true}}],
        "Products": [{"Name": "BloodMealFertilizerItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Camas Ash Fertilizer Recipe",
    "LaborInCalories": {"Value": 25.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.3, "IsDynamic": true},
    "Recipes": [{
        "Name": "CamasAshFertilizer",
        "Ingredients": [{
            "Name": "FiberFillerItem",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }, {"Name": "CharredCamasBulbItem", "Value": {"Value": 2.0, "IsDynamic": true}}],
        "Products": [{"Name": "CamasAshFertilizerItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Composite Filler Recipe",
    "LaborInCalories": {"Value": 25.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.3, "IsDynamic": true},
    "Recipes": [{
        "Name": "CompositeFiller",
        "Ingredients": [{
            "Name": "PulpFillerItem",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }, {"Name": "FiberFillerItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "CompositeFillerItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Compost Fertilizer Recipe",
    "LaborInCalories": {"Value": 25.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.3, "IsDynamic": true},
    "Recipes": [{
        "Name": "CompostFertilizer",
        "Ingredients": [{"Name": "CompostItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "FiberFillerItem",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "CompostFertilizerItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Fiber Filler Recipe",
    "LaborInCalories": {"Value": 25.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.3, "IsDynamic": true},
    "Recipes": [{
        "Name": "FiberFiller",
        "Ingredients": [{"Name": "PlantFibersItem", "Value": {"Value": 15.0, "IsDynamic": true}}, {
            "Name": "DirtItem",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "FiberFillerItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Hide Ash Fertilizer Recipe",
    "LaborInCalories": {"Value": 25.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.3, "IsDynamic": true},
    "Recipes": [{
        "Name": "HideAshFertilizer",
        "Ingredients": [{
            "Name": "PulpFillerItem",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }, {"Name": "LeatherHideItem", "Value": {"Value": 2.0, "IsDynamic": true}}],
        "Products": [{"Name": "HideAshFertilizerItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Pelt Fertilizer Recipe",
    "LaborInCalories": {"Value": 25.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.3, "IsDynamic": true},
    "Recipes": [{
        "Name": "PeltFertilizer",
        "Ingredients": [{"Name": "FiberFillerItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "FurPeltItem",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "PeltFertilizerItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Phosphate Fertilizer Recipe",
    "LaborInCalories": {"Value": 25.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.3, "IsDynamic": true},
    "Recipes": [{
        "Name": "PhosphateFertilizer",
        "Ingredients": [{
            "Name": "CompositeFillerItem",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "LimestoneItem", "Value": {"Value": 20.0, "IsDynamic": true}}],
        "Products": [{"Name": "PhosphateFertilizerItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Pulp Filler Recipe",
    "LaborInCalories": {"Value": 25.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.3, "IsDynamic": true},
    "Recipes": [{
        "Name": "PulpFiller",
        "Ingredients": [{"Name": "WoodPulpItem", "Value": {"Value": 15.0, "IsDynamic": true}}, {
            "Name": "DirtItem",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "PulpFillerItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Acorn Powder Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "AcornPowder",
        "Ingredients": [{"Name": "AcornItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "AcornPowderItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Baked Agave Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "BakedAgave",
        "Ingredients": [{"Name": "AgaveLeavesItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "BakedAgaveItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Baked Beet Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "BakedBeet",
        "Ingredients": [{"Name": "BeetItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "BakedBeetItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Baked Corn Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "BakedCorn",
        "Ingredients": [{"Name": "CornItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "BakedCornItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Baked Heart Of Palm Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "BakedHeartOfPalm",
        "Ingredients": [{"Name": "HeartOfPalmItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "BakedHeartOfPalmItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Baked Meat Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "BakedMeat",
        "Ingredients": [{"Name": "RawMeatItem", "Value": {"Value": 2.0, "IsDynamic": true}}],
        "Products": [{"Name": "BakedMeatItem", "Value": {"Value": 1.0, "IsDynamic": false}}, {
            "Name": "TallowItem",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }]
    }]
}, {
    "Name": "Baked Roast Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "BakedRoast",
        "Ingredients": [{"Name": "RawRoastItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "BakedRoastItem", "Value": {"Value": 1.0, "IsDynamic": false}}, {
            "Name": "TallowItem",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }]
    }]
}, {
    "Name": "Baked Taro Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "BakedTaro",
        "Ingredients": [{"Name": "TaroRootItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "BakedTaroItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Baked Tomato Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "BakedTomato",
        "Ingredients": [{"Name": "TomatoItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "BakedTomatoItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Bannock Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "Bannock",
        "Ingredients": [{"Name": "WheatItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "Fat",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "BannockItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Bean Paste Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "BeanPaste",
        "Ingredients": [{"Name": "BeansItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "BeanPasteItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Bearclaw Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 3.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Bearclaw",
        "Ingredients": [{"Name": "PastryDoughItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "SugarItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "BearclawItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Bear S U P R E M E Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 8.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "BearSUPREME",
        "Ingredients": [{
            "Name": "PrimeCutItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "VegetableMedleyItem", "Value": {"Value": 2.0, "IsDynamic": true}}, {
            "Name": "MeatStockItem",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "InfusedOilItem", "Value": {"Value": 2.0, "IsDynamic": true}}],
        "Products": [{"Name": "BearSUPREMEItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Beet Campfire Salad Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "BeetCampfireSalad",
        "Ingredients": [{"Name": "BeetGreensItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "BeetItem",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }, {"Name": "Fruit", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "BeetCampfireSaladItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Boiled Grains Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "BoiledGrains",
        "Ingredients": [{"Name": "Grain", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "BoiledGrainsItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Boiled Rice Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "BoiledRice",
        "Ingredients": [{"Name": "RiceItem", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "BoiledRiceItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Boiled Sausage Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "BoiledSausage",
        "Ingredients": [{
            "Name": "RawSausageItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "MeatStockItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "BoiledSausageItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Bread Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 3.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Bread",
        "Ingredients": [{"Name": "LeavenedDoughItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "BreadItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Camas Bread Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 3.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CamasBread",
        "Ingredients": [{
            "Name": "CamasPasteItem",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }, {"Name": "LeavenedDoughItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "CamasBreadItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Camas Bulb Bake Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CamasBulbBake",
        "Ingredients": [{"Name": "CamasBulbItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "CamasBulbBakeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Camas Paste Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CamasPaste",
        "Ingredients": [{"Name": "CamasBulbItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "CamasPasteItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Campfire Roast Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CampfireRoast",
        "Ingredients": [{"Name": "RawRoastItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "CampfireRoastItem", "Value": {"Value": 1.0, "IsDynamic": false}}, {
            "Name": "TallowItem",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }]
    }]
}, {
    "Name": "Charred Agave Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "CharredAgave",
        "Ingredients": [{"Name": "AgaveLeavesItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "CharredAgaveItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Charred Beans Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "CharredBeans",
        "Ingredients": [{"Name": "BeansItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "CharredBeansItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Charred Beet Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "CharredBeet",
        "Ingredients": [{"Name": "BeetItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "CharredBeetItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Charred Cactus Fruit Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "CharredCactusFruit",
        "Ingredients": [{"Name": "GiantCactusFruitItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "CharredCactusFruitItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Charred Camas Bulb Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "CharredCamasBulb",
        "Ingredients": [{"Name": "CamasBulbItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "CharredCamasBulbItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Charred Corn Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "CharredCorn",
        "Ingredients": [{"Name": "CornItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "CharredCornItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Charred Fireweed Shoots Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "CharredFireweedShoots",
        "Ingredients": [{"Name": "FireweedShootsItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "CharredFireweedShootsItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Charred Fish Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "CharredFish",
        "Ingredients": [{"Name": "RawFishItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "CharredFishItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Charred Heart Of Palm Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "CharredHeartOfPalm",
        "Ingredients": [{"Name": "HeartOfPalmItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "CharredHeartOfPalmItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Charred Meat Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CharredMeat",
        "Ingredients": [{"Name": "RawMeatItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "CharredMeatItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Charred Mushrooms Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "CharredMushrooms",
        "Ingredients": [{"Name": "Fungus", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "CharredMushroomsItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Charred Papaya Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "CharredPapaya",
        "Ingredients": [{"Name": "PapayaItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "CharredPapayaItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Charred Pineapple Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "CharredPineapple",
        "Ingredients": [{"Name": "PineappleItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "CharredPineappleItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Charred Sausage Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "CharredSausage",
        "Ingredients": [{"Name": "RawSausageItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "CharredSausageItem", "Value": {"Value": 1.0, "IsDynamic": false}}, {
            "Name": "TallowItem",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }]
    }]
}, {
    "Name": "Charred Taro Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "CharredTaro",
        "Ingredients": [{"Name": "TaroRootItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "CharredTaroItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Charred Tomato Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "CharredTomato",
        "Ingredients": [{"Name": "TomatoItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "CharredTomatoItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "C O2 Canister Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 8.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CO2Canister",
        "Ingredients": [{"Name": "SteelBarItem", "Value": {"Value": 2.0, "IsDynamic": true}}],
        "Products": [{"Name": "CO2CanisterItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Corn Fritters Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CornFritters",
        "Ingredients": [{"Name": "CornmealItem", "Value": {"Value": 6.0, "IsDynamic": true}}, {
            "Name": "CornItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "Fat", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "CornFrittersItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Cornmeal Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Cornmeal",
        "Ingredients": [{"Name": "CornItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "CornmealItem", "Value": {"Value": 1.0, "IsDynamic": false}}, {
            "Name": "CerealGermItem",
            "Value": {"Value": 2.0, "IsDynamic": false}
        }]
    }]
}, {
    "Name": "Corn Starch Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 8.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CornStarch",
        "Ingredients": [{"Name": "CornItem", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "CornStarchItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Crimson Salad Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 6.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CrimsonSalad",
        "Ingredients": [{
            "Name": "BasicSaladItem",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "InfusedOilItem", "Value": {"Value": 2.0, "IsDynamic": true}}, {
            "Name": "CharredBeetItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "TomatoItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "CrimsonSaladItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Crispy Bacon Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CrispyBacon",
        "Ingredients": [{"Name": "RawBaconItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "CrispyBaconItem", "Value": {"Value": 1.0, "IsDynamic": false}}, {
            "Name": "TallowItem",
            "Value": {"Value": 4.0, "IsDynamic": false}
        }]
    }]
}, {
    "Name": "Elk Taco Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 6.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "ElkTaco",
        "Ingredients": [{"Name": "ScrapMeatItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "TortillaItem",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "WildMixItem", "Value": {"Value": 2.0, "IsDynamic": true}}],
        "Products": [{"Name": "ElkTacoItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Elk Wellington Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 3.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "ElkWellington",
        "Ingredients": [{
            "Name": "LeavenedDoughItem",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "PrimeCutItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "ElkWellingtonItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Fern Campfire Salad Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "FernCampfireSalad",
        "Ingredients": [{
            "Name": "FiddleheadsItem",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }, {"Name": "HuckleberriesItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "Vegetable",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "FernCampfireSaladItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Field Campfire Stew Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "FieldCampfireStew",
        "Ingredients": [{"Name": "CornItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "TomatoItem",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }, {"Name": "Fat", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "FieldCampfireStewItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Flatbread Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Flatbread",
        "Ingredients": [{"Name": "FlourItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "FlatbreadItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Flour Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Flour",
        "Ingredients": [{"Name": "WheatItem", "Value": {"Value": 2.0, "IsDynamic": true}}],
        "Products": [{"Name": "FlourItem", "Value": {"Value": 1.0, "IsDynamic": false}}, {
            "Name": "CerealGermItem",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }]
    }]
}, {
    "Name": "Fried Camas Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "FriedCamas",
        "Ingredients": [{"Name": "CamasBulbItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "Fat",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "FriedCamasItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Fried Fiddleheads Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "FriedFiddleheads",
        "Ingredients": [{"Name": "FiddleheadsItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "Fat",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "FriedFiddleheadsItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Fried Hare Haunches Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "FriedHareHaunches",
        "Ingredients": [{"Name": "PreparedMeatItem", "Value": {"Value": 4.0, "IsDynamic": true}}, {
            "Name": "FlourItem",
            "Value": {"Value": 8.0, "IsDynamic": true}
        }, {"Name": "OilItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "FriedHareHaunchesItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Fried Hearts Of Palm Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "FriedHeartsOfPalm",
        "Ingredients": [{"Name": "HeartOfPalmItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "Fat",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "FriedHeartsOfPalmItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Fried Taro Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "FriedTaro",
        "Ingredients": [{"Name": "TaroRootItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "Fat",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "FriedTaroItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Fried Tomatoes Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "FriedTomatoes",
        "Ingredients": [{"Name": "TomatoItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "Fat",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "FriedTomatoesItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Fruit Muffin Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "FruitMuffin",
        "Ingredients": [{"Name": "FlourItem", "Value": {"Value": 4.0, "IsDynamic": true}}, {
            "Name": "Fruit",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "FruitMuffinItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Fruit Tart Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "FruitTart",
        "Ingredients": [{"Name": "PastryDoughItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "Fruit",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "FruitTartItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Huckleberry Extract Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "HuckleberryExtract",
        "Ingredients": [{"Name": "HuckleberriesItem", "Value": {"Value": 20.0, "IsDynamic": true}}],
        "Products": [{"Name": "HuckleberryExtractItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Huckleberry Pie Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "HuckleberryPie",
        "Ingredients": [{"Name": "FlourItem", "Value": {"Value": 4.0, "IsDynamic": true}}, {
            "Name": "HuckleberriesItem",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }, {"Name": "Fat", "Value": {"Value": 2.0, "IsDynamic": true}}],
        "Products": [{"Name": "HuckleberryPieItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Hydrocolloids Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 8.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Hydrocolloids",
        "Ingredients": [{"Name": "CornStarchItem", "Value": {"Value": 20.0, "IsDynamic": true}}],
        "Products": [{"Name": "HydrocolloidsItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Infused Oil Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "InfusedOil",
        "Ingredients": [{
            "Name": "OilItem",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "HuckleberryExtractItem", "Value": {"Value": 2.0, "IsDynamic": true}}],
        "Products": [{"Name": "InfusedOilItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Jungle Campfire Salad Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "JungleCampfireSalad",
        "Ingredients": [{"Name": "PapayaItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "PineappleItem",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }, {"Name": "Greens", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "JungleCampfireSaladItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Jungle Campfire Stew Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "JungleCampfireStew",
        "Ingredients": [{"Name": "PapayaItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "Fat",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }, {"Name": "Fungus", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "JungleCampfireStewItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Leavened Dough Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.4, "IsDynamic": true},
    "Recipes": [{
        "Name": "LeavenedDough",
        "Ingredients": [{"Name": "FlourItem", "Value": {"Value": 4.0, "IsDynamic": true}}, {
            "Name": "YeastItem",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "LeavenedDoughItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Liquid Nitrogen Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 8.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "LiquidNitrogen",
        "Ingredients": [{"Name": "SteelBarItem", "Value": {"Value": 2.0, "IsDynamic": true}}],
        "Products": [{"Name": "LiquidNitrogenItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Macaroons Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 3.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Macaroons",
        "Ingredients": [{"Name": "PastryDoughItem", "Value": {"Value": 2.0, "IsDynamic": true}}, {
            "Name": "SugarItem",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }, {"Name": "HuckleberryExtractItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "MacaroonsItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Maltodextrin Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 8.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Maltodextrin",
        "Ingredients": [{"Name": "CornStarchItem", "Value": {"Value": 20.0, "IsDynamic": true}}],
        "Products": [{"Name": "MaltodextrinItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Meat Pie Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "MeatPie",
        "Ingredients": [{"Name": "FlourItem", "Value": {"Value": 4.0, "IsDynamic": true}}, {
            "Name": "ScrapMeatItem",
            "Value": {"Value": 12.0, "IsDynamic": true}
        }, {"Name": "Fat", "Value": {"Value": 2.0, "IsDynamic": true}}],
        "Products": [{"Name": "MeatPieItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Meat Stock Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 8.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "MeatStock",
        "Ingredients": [{"Name": "ScrapMeatItem", "Value": {"Value": 8.0, "IsDynamic": true}}],
        "Products": [{"Name": "MeatStockItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Meaty Stew Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "MeatyStew",
        "Ingredients": [{"Name": "CharredMeatItem", "Value": {"Value": 2.0, "IsDynamic": true}}, {
            "Name": "Fat",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "MeatyStewItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Oil Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Oil",
        "Ingredients": [{"Name": "CerealGermItem", "Value": {"Value": 12.0, "IsDynamic": true}}],
        "Products": [{"Name": "OilItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Pastry Dough Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.4, "IsDynamic": true},
    "Recipes": [{
        "Name": "PastryDough",
        "Ingredients": [{"Name": "FlourItem", "Value": {"Value": 2.0, "IsDynamic": true}}, {
            "Name": "YeastItem",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }, {"Name": "Fat", "Value": {"Value": 2.0, "IsDynamic": true}}],
        "Products": [{"Name": "PastryDoughItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Pineapple Friend Rice Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "PineappleFriendRice",
        "Ingredients": [{
            "Name": "CharredPineappleItem",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }, {"Name": "BoiledRiceItem", "Value": {"Value": 5.0, "IsDynamic": true}}, {
            "Name": "ScrapMeatItem",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "PineappleFriendRiceItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Pirozhok Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 3.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Pirozhok",
        "Ingredients": [{
            "Name": "PastryDoughItem",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }, {"Name": "PreparedMeatItem", "Value": {"Value": 2.0, "IsDynamic": true}}, {
            "Name": "Fungus",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "PirozhokItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Prepared Meat Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.8, "IsDynamic": true},
    "Recipes": [{
        "Name": "PreparedMeat",
        "Ingredients": [{"Name": "RawMeatItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{
            "Name": "PreparedMeatItem",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }, {"Name": "ScrapMeatItem", "Value": {"Value": 2.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Prime Cut Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.8, "IsDynamic": true},
    "Recipes": [{
        "Name": "PrimeCut",
        "Ingredients": [{"Name": "RawMeatItem", "Value": {"Value": 16.0, "IsDynamic": true}}],
        "Products": [{"Name": "PrimeCutItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Raw Bacon Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.8, "IsDynamic": true},
    "Recipes": [{
        "Name": "RawBacon",
        "Ingredients": [{"Name": "RawMeatItem", "Value": {"Value": 3.0, "IsDynamic": true}}],
        "Products": [{"Name": "RawBaconItem", "Value": {"Value": 1.0, "IsDynamic": false}}, {
            "Name": "ScrapMeatItem",
            "Value": {"Value": 4.0, "IsDynamic": false}
        }]
    }]
}, {
    "Name": "Raw Roast Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.8, "IsDynamic": true},
    "Recipes": [{
        "Name": "RawRoast",
        "Ingredients": [{"Name": "RawMeatItem", "Value": {"Value": 2.0, "IsDynamic": true}}],
        "Products": [{"Name": "RawRoastItem", "Value": {"Value": 1.0, "IsDynamic": false}}, {
            "Name": "ScrapMeatItem",
            "Value": {"Value": 3.0, "IsDynamic": false}
        }]
    }]
}, {
    "Name": "Raw Sausage Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.8, "IsDynamic": true},
    "Recipes": [{
        "Name": "RawSausage",
        "Ingredients": [{"Name": "ScrapMeatItem", "Value": {"Value": 3.0, "IsDynamic": true}}],
        "Products": [{"Name": "RawSausageItem", "Value": {"Value": 1.0, "IsDynamic": false}}, {
            "Name": "TallowItem",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }]
    }]
}, {
    "Name": "Root Campfire Salad Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "RootCampfireSalad",
        "Ingredients": [{"Name": "CamasBulbItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "TaroRootItem",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }, {"Name": "Greens", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "RootCampfireSaladItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Root Campfire Stew Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "RootCampfireStew",
        "Ingredients": [{"Name": "BeetItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "CamasBulbItem",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }, {"Name": "Fat", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "RootCampfireStewItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Scrap Meat Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "ScrapMeat",
        "Ingredients": [{"Name": "RawMeatItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "ScrapMeatItem", "Value": {"Value": 3.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Seared Meat Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 3.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "SearedMeat",
        "Ingredients": [{"Name": "PrimeCutItem", "Value": {"Value": 2.0, "IsDynamic": true}}, {
            "Name": "InfusedOilItem",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "SearedMeatItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Simmered Meat Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "SimmeredMeat",
        "Ingredients": [{
            "Name": "PreparedMeatItem",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "MeatStockItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "SimmeredMeatItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Simple Syrup Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "SimpleSyrup",
        "Ingredients": [{"Name": "SugarItem", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "SimpleSyrupItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Stuffed Turkey Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "StuffedTurkey",
        "Ingredients": [{"Name": "PrimeCutItem", "Value": {"Value": 4.0, "IsDynamic": true}}, {
            "Name": "BreadItem",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }, {"Name": "VegetableMedleyItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "StuffedTurkeyItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Sugar Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Sugar",
        "Ingredients": [{"Name": "HuckleberriesItem", "Value": {"Value": 8.0, "IsDynamic": true}}],
        "Products": [{"Name": "SugarItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Sweet Salad Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 6.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "SweetSalad",
        "Ingredients": [{
            "Name": "BasicSaladItem",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "FruitSaladItem", "Value": {"Value": 2.0, "IsDynamic": true}}, {
            "Name": "SimpleSyrupItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "SweetSaladItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Topped Porridge Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "ToppedPorridge",
        "Ingredients": [{"Name": "BoiledGrainsItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "Fruit",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "ToppedPorridgeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Tortilla Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Tortilla",
        "Ingredients": [{"Name": "CornmealItem", "Value": {"Value": 2.0, "IsDynamic": true}}],
        "Products": [{"Name": "TortillaItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Transglutaminase Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 8.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Transglutaminase",
        "Ingredients": [{"Name": "ScrapMeatItem", "Value": {"Value": 30.0, "IsDynamic": true}}],
        "Products": [{"Name": "TransglutaminaseItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Vegetable Soup Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "VegetableSoup",
        "Ingredients": [{
            "Name": "VegetableStockItem",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }, {"Name": "VegetableMedleyItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "VegetableSoupItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Vegetable Stock Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 8.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "VegetableStock",
        "Ingredients": [{"Name": "VegetableMedleyItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "VegetableStockItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Wild Mix Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "WildMix",
        "Ingredients": [{
            "Name": "BasicSaladItem",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "HuckleberryExtractItem", "Value": {"Value": 2.0, "IsDynamic": true}}],
        "Products": [{"Name": "WildMixItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Wild Stew Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "WildStew",
        "Ingredients": [{"Name": "HuckleberriesItem", "Value": {"Value": 2.0, "IsDynamic": true}}, {
            "Name": "BeansItem",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }, {"Name": "BeetItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "WildStewItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Wilted Fiddleheads Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "WiltedFiddleheads",
        "Ingredients": [{"Name": "FiddleheadsItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "WiltedFiddleheadsItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Yeast Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Yeast",
        "Ingredients": [{"Name": "SugarItem", "Value": {"Value": 2.0, "IsDynamic": true}}],
        "Products": [{"Name": "YeastItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Advanced Circuit Recipe",
    "LaborInCalories": {"Value": 140.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.8, "IsDynamic": true},
    "Recipes": [{
        "Name": "AdvancedCircuit",
        "Ingredients": [{
            "Name": "CopperWiringItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "GoldWiringItem", "Value": {"Value": 4.0, "IsDynamic": true}}, {
            "Name": "GoldFlakesItem",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }, {"Name": "SubstrateItem", "Value": {"Value": 2.0, "IsDynamic": true}}],
        "Products": [{"Name": "AdvancedCircuitItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Advanced Combustion Engine Recipe",
    "LaborInCalories": {"Value": 1000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "AdvancedCombustionEngine",
        "Ingredients": [{"Name": "SteelPlateItem", "Value": {"Value": 16.0, "IsDynamic": true}}, {
            "Name": "RivetItem",
            "Value": {"Value": 12.0, "IsDynamic": true}
        }, {"Name": "PistonItem", "Value": {"Value": 6.0, "IsDynamic": true}}, {
            "Name": "ValveItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }, {"Name": "ServoItem", "Value": {"Value": 6.0, "IsDynamic": true}}, {
            "Name": "AdvancedCircuitItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }, {"Name": "RadiatorItem", "Value": {"Value": 3.0, "IsDynamic": true}}],
        "Products": [{"Name": "AdvancedCombustionEngineItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Agriculture Research Paper Advanced Recipe",
    "LaborInCalories": {"Value": 400.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "AgricultureResearchPaperAdvanced",
        "Ingredients": [{"Name": "DirtItem", "Value": {"Value": 5.0, "IsDynamic": true}}, {
            "Name": "Raw Food",
            "Value": {"Value": 40.0, "IsDynamic": true}
        }, {"Name": "Crop Seed", "Value": {"Value": 20.0, "IsDynamic": true}}],
        "Products": [{"Name": "AgricultureResearchPaperAdvancedItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Agriculture Research Paper Modern Recipe",
    "LaborInCalories": {"Value": 1000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "AgricultureResearchPaperModern",
        "Ingredients": [{
            "Name": "HideAshFertilizerItem",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }, {"Name": "BloodMealFertilizerItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "Raw Food",
            "Value": {"Value": 200.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "AgricultureResearchPaperModernItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Arrow Recipe",
    "LaborInCalories": {"Value": 30.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 0.1, "IsDynamic": false},
    "Recipes": [{
        "Name": "Arrow",
        "Ingredients": [{"Name": "Wood", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "ArrowItem", "Value": {"Value": 4.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Basic Circuit Recipe",
    "LaborInCalories": {"Value": 70.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.8, "IsDynamic": true},
    "Recipes": [{
        "Name": "BasicCircuit",
        "Ingredients": [{
            "Name": "CopperWiringItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }, {"Name": "GoldFlakesItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "SubstrateItem",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "BasicCircuitItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Board Recipe",
    "LaborInCalories": {"Value": 60.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.2, "IsDynamic": true},
    "Recipes": [{
        "Name": "Board",
        "Ingredients": [{"Name": "HewnLog", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "BoardItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "HardwoodBoard",
        "Ingredients": [{"Name": "HardwoodHewnLogItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "HardwoodBoardItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SoftwoodBoard",
        "Ingredients": [{"Name": "SoftwoodHewnLogItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "SoftwoodBoardItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Boiler Recipe",
    "LaborInCalories": {"Value": 300.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Boiler",
        "Ingredients": [{"Name": "IronPlateItem", "Value": {"Value": 15.0, "IsDynamic": true}}, {
            "Name": "ScrewsItem",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "BoilerItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Cellulose Fiber Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.8, "IsDynamic": true},
    "Recipes": [{
        "Name": "CelluloseFiber",
        "Ingredients": [{"Name": "NaturalFiber", "Value": {"Value": 20.0, "IsDynamic": true}}],
        "Products": [{"Name": "CelluloseFiberItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Cement Recipe",
    "LaborInCalories": {"Value": 120.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.6, "IsDynamic": true},
    "Recipes": [{
        "Name": "Cement",
        "Ingredients": [{
            "Name": "CrushedLimestoneItem",
            "Value": {"Value": 3.0, "IsDynamic": true}
        }, {"Name": "ClayItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "CementItem", "Value": {"Value": 2.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Charcoal Recipe",
    "LaborInCalories": {"Value": 80.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Charcoal",
        "Ingredients": [{"Name": "Wood", "Value": {"Value": 7.0, "IsDynamic": true}}],
        "Products": [{"Name": "CharcoalItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Cloth Recipe",
    "LaborInCalories": {"Value": 50.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.8, "IsDynamic": true},
    "Recipes": [{
        "Name": "Cloth",
        "Ingredients": [{"Name": "PlantFibersItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "ClothItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Combustion Engine Recipe",
    "LaborInCalories": {"Value": 500.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 8.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CombustionEngine",
        "Ingredients": [{"Name": "PistonItem", "Value": {"Value": 6.0, "IsDynamic": true}}, {
            "Name": "GearboxItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "IronPlateItem", "Value": {"Value": 12.0, "IsDynamic": true}}, {
            "Name": "RivetItem",
            "Value": {"Value": 8.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "CombustionEngineItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Copper Wiring Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.4, "IsDynamic": true},
    "Recipes": [{
        "Name": "CopperWiring",
        "Ingredients": [{"Name": "CopperBarItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "CopperWiringItem", "Value": {"Value": 2.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Culinary Research Paper Advanced Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "CulinaryResearchPaperAdvanced",
        "Ingredients": [{"Name": "BakedVegetable", "Value": {"Value": 16.0, "IsDynamic": true}}, {
            "Name": "Salad",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "CulinaryResearchPaperAdvancedItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Culinary Research Paper Basic Recipe",
    "LaborInCalories": {"Value": 50.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "CulinaryResearchPaperBasic",
        "Ingredients": [{
            "Name": "FriedVegetable",
            "Value": {"Value": 3.0, "IsDynamic": true}
        }, {"Name": "CampfireSalad", "Value": {"Value": 5.0, "IsDynamic": true}}],
        "Products": [{"Name": "CulinaryResearchPaperBasicItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Culinary Research Paper Modern Recipe",
    "LaborInCalories": {"Value": 1000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "CulinaryResearchPaperModern",
        "Ingredients": [{
            "Name": "BoiledSausageItem",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }, {"Name": "BreadItem", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "CulinaryResearchPaperModernItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Dendrology Research Paper Advanced Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "DendrologyResearchPaperAdvanced",
        "Ingredients": [{"Name": "HewnLog", "Value": {"Value": 20.0, "IsDynamic": true}}],
        "Products": [{"Name": "DendrologyResearchPaperAdvancedItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Dendrology Research Paper Basic Recipe",
    "LaborInCalories": {"Value": 50.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "DendrologyResearchPaperBasic",
        "Ingredients": [{"Name": "Wood", "Value": {"Value": 20.0, "IsDynamic": true}}],
        "Products": [{"Name": "DendrologyResearchPaperBasicItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Dendrology Research Paper Modern Recipe",
    "LaborInCalories": {"Value": 1000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "DendrologyResearchPaperModern",
        "Ingredients": [{"Name": "Lumber", "Value": {"Value": 30.0, "IsDynamic": true}}],
        "Products": [{"Name": "DendrologyResearchPaperModernItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Electric Motor Recipe",
    "LaborInCalories": {"Value": 600.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "ElectricMotor",
        "Ingredients": [{
            "Name": "BasicCircuitItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "CopperWiringItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "SteelBarItem",
            "Value": {"Value": 8.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "ElectricMotorItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Engineering Research Paper Advanced Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "EngineeringResearchPaperAdvanced",
        "Ingredients": [{"Name": "WaterwheelItem", "Value": {"Value": 2.0, "IsDynamic": true}}, {
            "Name": "WindmillItem",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "EngineeringResearchPaperAdvancedItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Engineering Research Paper Modern Recipe",
    "LaborInCalories": {"Value": 1000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "EngineeringResearchPaperModern",
        "Ingredients": [{"Name": "IronAxleItem", "Value": {"Value": 8.0, "IsDynamic": true}}, {
            "Name": "IronPlateItem",
            "Value": {"Value": 8.0, "IsDynamic": true}
        }, {"Name": "IronGearItem", "Value": {"Value": 20.0, "IsDynamic": true}}],
        "Products": [{"Name": "EngineeringResearchPaperModernItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Epoxy Recipe",
    "LaborInCalories": {"Value": 300.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.6, "IsDynamic": true},
    "Recipes": [{
        "Name": "Epoxy",
        "Ingredients": [{"Name": "PetroleumItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "EpoxyItem", "Value": {"Value": 2.0, "IsDynamic": false}}, {
            "Name": "BarrelItem",
            "Value": {"Value": 3.0, "IsDynamic": true}
        }]
    }]
}, {
    "Name": "Fiberglass Recipe",
    "LaborInCalories": {"Value": 75.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Fiberglass",
        "Ingredients": [{"Name": "PlasticItem", "Value": {"Value": 4.0, "IsDynamic": true}}, {
            "Name": "GlassItem",
            "Value": {"Value": 3.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "FiberglassItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Fishing Pole Recipe",
    "LaborInCalories": {"Value": 40.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 5.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "FishingPole",
        "Ingredients": [{"Name": "Wood", "Value": {"Value": 10.0, "IsDynamic": false}}],
        "Products": [{"Name": "FishingPoleItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Gathering Research Paper Basic Recipe",
    "LaborInCalories": {"Value": 50.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "GatheringResearchPaperBasic",
        "Ingredients": [{"Name": "PlantFibersItem", "Value": {"Value": 20.0, "IsDynamic": true}}, {
            "Name": "Raw Food",
            "Value": {"Value": 30.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "GatheringResearchPaperBasicItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Gearbox Recipe",
    "LaborInCalories": {"Value": 75.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "Gearbox",
        "Ingredients": [{"Name": "IronBarItem", "Value": {"Value": 2.0, "IsDynamic": true}}, {
            "Name": "IronGearItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "GearboxItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Geology Research Paper Advanced Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "GeologyResearchPaperAdvanced",
        "Ingredients": [{"Name": "MortaredStone", "Value": {"Value": 20.0, "IsDynamic": true}}],
        "Products": [{"Name": "GeologyResearchPaperAdvancedItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Geology Research Paper Basic Recipe",
    "LaborInCalories": {"Value": 50.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "GeologyResearchPaperBasic",
        "Ingredients": [{"Name": "Rock", "Value": {"Value": 30.0, "IsDynamic": true}}],
        "Products": [{"Name": "GeologyResearchPaperBasicItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Geology Research Paper Modern Recipe",
    "LaborInCalories": {"Value": 1000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "GeologyResearchPaperModern",
        "Ingredients": [{"Name": "BrickItem", "Value": {"Value": 30.0, "IsDynamic": true}}],
        "Products": [{"Name": "GeologyResearchPaperModernItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Gold Flakes Recipe",
    "LaborInCalories": {"Value": 120.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.8, "IsDynamic": true},
    "Recipes": [{
        "Name": "GoldFlakes",
        "Ingredients": [{"Name": "GoldBarItem", "Value": {"Value": 2.0, "IsDynamic": true}}],
        "Products": [{"Name": "GoldFlakesItem", "Value": {"Value": 4.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Gold Wiring Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.4, "IsDynamic": true},
    "Recipes": [{
        "Name": "GoldWiring",
        "Ingredients": [{"Name": "GoldBarItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "GoldWiringItem", "Value": {"Value": 2.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Heat Sink Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "HeatSink",
        "Ingredients": [{"Name": "CopperBarItem", "Value": {"Value": 8.0, "IsDynamic": true}}],
        "Products": [{"Name": "HeatSinkItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Iron Axle Recipe",
    "LaborInCalories": {"Value": 120.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "IronAxle",
        "Ingredients": [{"Name": "IronBarItem", "Value": {"Value": 2.0, "IsDynamic": true}}],
        "Products": [{"Name": "IronAxleItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Iron Gear Recipe",
    "LaborInCalories": {"Value": 120.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.4, "IsDynamic": true},
    "Recipes": [{
        "Name": "IronGear",
        "Ingredients": [{"Name": "IronBarItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "IronGearItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Iron Plate Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "IronPlate",
        "Ingredients": [{"Name": "IronBarItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "IronPlateItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Iron Saw Blade Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.2, "IsDynamic": true},
    "Recipes": [{
        "Name": "IronSawBlade",
        "Ingredients": [{"Name": "IronBarItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "IronSawBladeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Iron Wheel Recipe",
    "LaborInCalories": {"Value": 160.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "IronWheel",
        "Ingredients": [{"Name": "IronBarItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "IronWheelItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Large Hanging Wood Sign Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 3.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "LargeHangingWoodSign",
        "Ingredients": [{"Name": "WoodBoard", "Value": {"Value": 5.0, "IsDynamic": true}}, {
            "Name": "HewnLog",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "Wood", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeHangingWoodSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "LargeHangingBirchSign",
        "Ingredients": [{"Name": "BirchLogItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }, {"Name": "HewnLog", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeHangingBirchSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "LargeHangingCedarSign",
        "Ingredients": [{"Name": "CedarLogItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }, {"Name": "HewnLog", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeHangingCedarSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "LargeHangingCeibaSign",
        "Ingredients": [{"Name": "CeibaLogItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }, {"Name": "HewnLog", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeHangingCeibaSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "LargeHangingFirSign",
        "Ingredients": [{"Name": "FirLogItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }, {"Name": "HewnLog", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeHangingFirSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "LargeHangingHardwoodSign",
        "Ingredients": [{"Name": "HardwoodBoardItem", "Value": {"Value": 5.0, "IsDynamic": true}}, {
            "Name": "HewnLog",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "Hardwood", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeHangingHardwoodSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "LargeHangingJoshuaSign",
        "Ingredients": [{"Name": "JoshuaLogItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }, {"Name": "HewnLog", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeHangingJoshuaSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "LargeHangingOakSign",
        "Ingredients": [{"Name": "OakLogItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }, {"Name": "HewnLog", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeHangingOakSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "LargeHangingPalmSign",
        "Ingredients": [{"Name": "PalmLogItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }, {"Name": "HewnLog", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeHangingPalmSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "LargeHangingRedwoodSign",
        "Ingredients": [{"Name": "RedwoodLogItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }, {"Name": "HewnLog", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeHangingRedwoodSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "LargeHangingSaguaroSign",
        "Ingredients": [{"Name": "SaguaroRibItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }, {"Name": "HewnLog", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeHangingSaguaroSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "LargeHangingSoftwoodSign",
        "Ingredients": [{"Name": "SoftwoodBoardItem", "Value": {"Value": 5.0, "IsDynamic": true}}, {
            "Name": "HewnLog",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "Softwood", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeHangingSoftwoodSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "LargeHangingSpruceSign",
        "Ingredients": [{"Name": "SpruceLogItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }, {"Name": "HewnLog", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeHangingSpruceSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Large Standing Wood Sign Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 3.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "LargeStandingWoodSign",
        "Ingredients": [{"Name": "WoodBoard", "Value": {"Value": 5.0, "IsDynamic": true}}, {
            "Name": "HewnLog",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "Wood", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeStandingWoodSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "LargeStandingBirchSign",
        "Ingredients": [{"Name": "BirchLogItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }, {"Name": "HewnLog", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeStandingBirchSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "LargeStandingCedarSign",
        "Ingredients": [{"Name": "CedarLogItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }, {"Name": "HewnLog", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeStandingCedarSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "LargeStandingCeibaSign",
        "Ingredients": [{"Name": "CeibaLogItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }, {"Name": "HewnLog", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeStandingCeibaSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "LargeStandingFirSign",
        "Ingredients": [{"Name": "FirLogItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }, {"Name": "HewnLog", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeStandingFirSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "LargeStandingHardwoodSign",
        "Ingredients": [{"Name": "HardwoodBoardItem", "Value": {"Value": 5.0, "IsDynamic": true}}, {
            "Name": "HewnLog",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "Hardwood", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeStandingHardwoodSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "LargeStandingJoshuaSign",
        "Ingredients": [{"Name": "JoshuaLogItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }, {"Name": "HewnLog", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeStandingJoshuaSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "LargeStandingOakSign",
        "Ingredients": [{"Name": "OakLogItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }, {"Name": "HewnLog", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeStandingOakSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "LargeStandingPalmSign",
        "Ingredients": [{"Name": "PalmLogItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }, {"Name": "HewnLog", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeStandingPalmSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "LargeStandingRedwoodSign",
        "Ingredients": [{"Name": "RedwoodLogItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }, {"Name": "HewnLog", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeStandingRedwoodSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "LargeStandingSaguaroSign",
        "Ingredients": [{"Name": "SaguaroRibItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }, {"Name": "HewnLog", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeStandingSaguaroSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "LargeStandingSoftwoodSign",
        "Ingredients": [{"Name": "SoftwoodBoardItem", "Value": {"Value": 5.0, "IsDynamic": true}}, {
            "Name": "HewnLog",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "Softwood", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeStandingSoftwoodSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "LargeStandingSpruceSign",
        "Ingredients": [{"Name": "SpruceLogItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }, {"Name": "HewnLog", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeStandingSpruceSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Light Bulb Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "LightBulb",
        "Ingredients": [{"Name": "GlassItem", "Value": {"Value": 2.0, "IsDynamic": true}}, {
            "Name": "CopperWiringItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "LightBulbItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Metallurgy Research Paper Advanced Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "MetallurgyResearchPaperAdvanced",
        "Ingredients": [{"Name": "IronBarItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "CopperBarItem",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "MetallurgyResearchPaperAdvancedItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Metallurgy Research Paper Basic Recipe",
    "LaborInCalories": {"Value": 50.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "MetallurgyResearchPaperBasic",
        "Ingredients": [{"Name": "Ore", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "MetallurgyResearchPaperBasicItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Metallurgy Research Paper Modern Recipe",
    "LaborInCalories": {"Value": 1000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "MetallurgyResearchPaperModern",
        "Ingredients": [{"Name": "RebarItem", "Value": {"Value": 6.0, "IsDynamic": true}}, {
            "Name": "SteelBarItem",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "MetallurgyResearchPaperModernItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Nail Recipe",
    "LaborInCalories": {"Value": 80.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.8, "IsDynamic": true},
    "Recipes": [{
        "Name": "Nail",
        "Ingredients": [{"Name": "IronBarItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "NailItem", "Value": {"Value": 16.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Paper Recipe",
    "LaborInCalories": {"Value": 35.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.1, "IsDynamic": true},
    "Recipes": [{
        "Name": "Paper",
        "Ingredients": [{"Name": "CelluloseFiberItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "PaperItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "IronPiston Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "Piston",
        "Ingredients": [{"Name": "IronPipeItem", "Value": {"Value": 2.0, "IsDynamic": true}}, {
            "Name": "IronBarItem",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "PistonItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Plastic Recipe",
    "LaborInCalories": {"Value": 300.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.6, "IsDynamic": true},
    "Recipes": [{
        "Name": "Plastic",
        "Ingredients": [{"Name": "PetroleumItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "PlasticItem", "Value": {"Value": 2.0, "IsDynamic": false}}, {
            "Name": "BarrelItem",
            "Value": {"Value": 3.0, "IsDynamic": true}
        }]
    }]
}, {
    "Name": "Portable Steam Engine Recipe",
    "LaborInCalories": {"Value": 2000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 8.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "PortableSteamEngine",
        "Ingredients": [{"Name": "PistonItem", "Value": {"Value": 8.0, "IsDynamic": true}}, {
            "Name": "ScrewsItem",
            "Value": {"Value": 18.0, "IsDynamic": true}
        }, {"Name": "IronPlateItem", "Value": {"Value": 12.0, "IsDynamic": true}}, {
            "Name": "BoilerItem",
            "Value": {"Value": 3.0, "IsDynamic": true}
        }, {"Name": "IronGearItem", "Value": {"Value": 12.0, "IsDynamic": true}}],
        "Products": [{"Name": "PortableSteamEngineItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Land Claim Stake Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": false},
    "Recipes": [{
        "Name": "PropertyTool",
        "Ingredients": [{"Name": "Wood", "Value": {"Value": 10.0, "IsDynamic": false}}],
        "Products": [{"Name": "PropertyToolItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Quicklime Recipe",
    "LaborInCalories": {"Value": 80.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.2, "IsDynamic": true},
    "Recipes": [{
        "Name": "Quicklime",
        "Ingredients": [{"Name": "CrushedLimestoneItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "QuicklimeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Radiator Recipe",
    "LaborInCalories": {"Value": 60.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "Radiator",
        "Ingredients": [{
            "Name": "HeatSinkItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "CopperWiringItem", "Value": {"Value": 8.0, "IsDynamic": true}}],
        "Products": [{"Name": "RadiatorItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Rebar Recipe",
    "LaborInCalories": {"Value": 80.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.2, "IsDynamic": true},
    "Recipes": [{
        "Name": "Rebar",
        "Ingredients": [{"Name": "SteelBarItem", "Value": {"Value": 2.0, "IsDynamic": true}}],
        "Products": [{"Name": "RebarItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Rivet Recipe",
    "LaborInCalories": {"Value": 140.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.8, "IsDynamic": true},
    "Recipes": [{
        "Name": "Rivet",
        "Ingredients": [{"Name": "SteelBarItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "RivetItem", "Value": {"Value": 3.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Rubber Wheel Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "RubberWheel",
        "Ingredients": [{
            "Name": "SyntheticRubberItem",
            "Value": {"Value": 8.0, "IsDynamic": true}
        }, {"Name": "SteelBarItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "RubberWheelItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Screws Recipe",
    "LaborInCalories": {"Value": 35.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Screws",
        "Ingredients": [{"Name": "IronBarItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "ScrewsItem", "Value": {"Value": 4.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Servo Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 3.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Servo",
        "Ingredients": [{
            "Name": "BasicCircuitItem",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "FiberglassItem", "Value": {"Value": 5.0, "IsDynamic": true}}],
        "Products": [{"Name": "ServoItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Small Hanging Wood Sign Recipe",
    "LaborInCalories": {"Value": 40.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "SmallHangingWoodSign",
        "Ingredients": [{"Name": "WoodBoard", "Value": {"Value": 2.0, "IsDynamic": true}}, {
            "Name": "HewnLog",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "Wood", "Value": {"Value": 5.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallHangingWoodSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SmallHangingBirchSign",
        "Ingredients": [{"Name": "BirchLogItem", "Value": {"Value": 5.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "HewnLog", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallHangingBirchSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SmallHangingCedarSign",
        "Ingredients": [{"Name": "CedarLogItem", "Value": {"Value": 5.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "HewnLog", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallHangingCedarSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SmallHangingCeibaSign",
        "Ingredients": [{"Name": "CeibaLogItem", "Value": {"Value": 5.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "HewnLog", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallHangingCeibaSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SmallHangingFirSign",
        "Ingredients": [{"Name": "FirLogItem", "Value": {"Value": 5.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "HewnLog", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallHangingFirSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SmallHangingHardwoodSign",
        "Ingredients": [{"Name": "HardwoodBoardItem", "Value": {"Value": 2.0, "IsDynamic": true}}, {
            "Name": "HewnLog",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "Hardwood", "Value": {"Value": 5.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallHangingHardwoodSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SmallHangingJoshuaSign",
        "Ingredients": [{"Name": "JoshuaLogItem", "Value": {"Value": 5.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "HewnLog", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallHangingJoshuaSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SmallHangingOakSign",
        "Ingredients": [{"Name": "OakLogItem", "Value": {"Value": 5.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "HewnLog", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallHangingOakSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SmallHangingPalmSign",
        "Ingredients": [{"Name": "PalmLogItem", "Value": {"Value": 5.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "HewnLog", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallHangingPalmSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SmallHangingRedwoodSign",
        "Ingredients": [{"Name": "RedwoodLogItem", "Value": {"Value": 5.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "HewnLog", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallHangingRedwoodSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SmallHangingSaguaroSign",
        "Ingredients": [{"Name": "SaguaroRibItem", "Value": {"Value": 5.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "HewnLog", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallHangingSaguaroSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SmallHangingSoftwoodSign",
        "Ingredients": [{"Name": "SoftwoodBoardItem", "Value": {"Value": 2.0, "IsDynamic": true}}, {
            "Name": "HewnLog",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "Softwood", "Value": {"Value": 5.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallHangingSoftwoodSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SmallHangingSpruceSign",
        "Ingredients": [{"Name": "SpruceLogItem", "Value": {"Value": 5.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "HewnLog", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallHangingSpruceSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Small Standing Wood Sign Recipe",
    "LaborInCalories": {"Value": 40.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "SmallStandingWoodSign",
        "Ingredients": [{"Name": "WoodBoard", "Value": {"Value": 2.0, "IsDynamic": true}}, {
            "Name": "HewnLog",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "Wood", "Value": {"Value": 5.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallStandingWoodSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SmallStandingBirchSign",
        "Ingredients": [{"Name": "BirchLogItem", "Value": {"Value": 5.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "HewnLog", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallStandingBirchSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SmallStandingCedarSign",
        "Ingredients": [{"Name": "CedarLogItem", "Value": {"Value": 5.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "HewnLog", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallStandingCedarSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SmallStandingCeibaSign",
        "Ingredients": [{"Name": "CeibaLogItem", "Value": {"Value": 5.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "HewnLog", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallStandingCeibaSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SmallStandingFirSign",
        "Ingredients": [{"Name": "FirLogItem", "Value": {"Value": 5.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "HewnLog", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallStandingFirSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SmallStandingHardwoodSign",
        "Ingredients": [{"Name": "HardwoodBoardItem", "Value": {"Value": 2.0, "IsDynamic": true}}, {
            "Name": "HewnLog",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "Hardwood", "Value": {"Value": 5.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallStandingHardwoodSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SmallStandingJoshuaSign",
        "Ingredients": [{"Name": "JoshuaLogItem", "Value": {"Value": 5.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "HewnLog", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallStandingJoshuaSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SmallStandingOakSign",
        "Ingredients": [{"Name": "OakLogItem", "Value": {"Value": 5.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "HewnLog", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallStandingOakSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SmallStandingPalmSign",
        "Ingredients": [{"Name": "PalmLogItem", "Value": {"Value": 5.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "HewnLog", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallStandingPalmSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SmallStandingRedwoodSign",
        "Ingredients": [{"Name": "RedwoodLogItem", "Value": {"Value": 5.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "HewnLog", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallStandingRedwoodSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SmallStandingSaguaroSign",
        "Ingredients": [{"Name": "SaguaroRibItem", "Value": {"Value": 5.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "HewnLog", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallStandingSaguaroSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SmallStandingSoftwoodSign",
        "Ingredients": [{"Name": "SoftwoodBoardItem", "Value": {"Value": 2.0, "IsDynamic": true}}, {
            "Name": "HewnLog",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "Softwood", "Value": {"Value": 5.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallStandingSoftwoodSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SmallStandingSpruceSign",
        "Ingredients": [{"Name": "SpruceLogItem", "Value": {"Value": 5.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "HewnLog", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallStandingSpruceSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Soil Sampler Recipe",
    "LaborInCalories": {"Value": 50.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "SoilSampler",
        "Ingredients": [{"Name": "Wood", "Value": {"Value": 2.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "SoilSamplerItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Steam Tractor Harvester Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 6.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "SteamTractorHarvester",
        "Ingredients": [{"Name": "IronPlateItem", "Value": {"Value": 12.0, "IsDynamic": true}}, {
            "Name": "ScrewsItem",
            "Value": {"Value": 12.0, "IsDynamic": true}
        }, {"Name": "IronPipeItem", "Value": {"Value": 8.0, "IsDynamic": true}}],
        "Products": [{"Name": "SteamTractorHarvesterItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Steam Tractor Plow Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 6.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "SteamTractorPlow",
        "Ingredients": [{"Name": "IronPlateItem", "Value": {"Value": 8.0, "IsDynamic": true}}, {
            "Name": "ScrewsItem",
            "Value": {"Value": 18.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "SteamTractorPlowItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Steam Tractor Sower Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 6.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "SteamTractorSower",
        "Ingredients": [{"Name": "IronPlateItem", "Value": {"Value": 8.0, "IsDynamic": true}}, {
            "Name": "ScrewsItem",
            "Value": {"Value": 18.0, "IsDynamic": true}
        }, {"Name": "IronPipeItem", "Value": {"Value": 8.0, "IsDynamic": true}}],
        "Products": [{"Name": "SteamTractorSowerItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Steel Axle Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "SteelAxle",
        "Ingredients": [{"Name": "SteelBarItem", "Value": {"Value": 4.0, "IsDynamic": true}}, {
            "Name": "EpoxyItem",
            "Value": {"Value": 3.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "SteelAxleItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Steel Gear Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.4, "IsDynamic": true},
    "Recipes": [{
        "Name": "SteelGear",
        "Ingredients": [{"Name": "SteelBarItem", "Value": {"Value": 2.0, "IsDynamic": true}}, {
            "Name": "EpoxyItem",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "SteelGearItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Steel Gearbox Recipe",
    "LaborInCalories": {"Value": 160.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "SteelGearbox",
        "Ingredients": [{"Name": "SteelBarItem", "Value": {"Value": 8.0, "IsDynamic": true}}, {
            "Name": "SteelGearItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "SteelGearboxItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Steel Plate Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "SteelPlate",
        "Ingredients": [{"Name": "SteelBarItem", "Value": {"Value": 3.0, "IsDynamic": true}}],
        "Products": [{"Name": "SteelPlateItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Steel Saw Blade Recipe",
    "LaborInCalories": {"Value": 300.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.2, "IsDynamic": true},
    "Recipes": [{
        "Name": "SteelSawBlade",
        "Ingredients": [{"Name": "SteelBarItem", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "SteelSawBladeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Substrate Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Substrate",
        "Ingredients": [{"Name": "FiberglassItem", "Value": {"Value": 4.0, "IsDynamic": true}}, {
            "Name": "EpoxyItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "SubstrateItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Synthetic Rubber Recipe",
    "LaborInCalories": {"Value": 300.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.6, "IsDynamic": true},
    "Recipes": [{
        "Name": "SyntheticRubber",
        "Ingredients": [{"Name": "PetroleumItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{
            "Name": "SyntheticRubberItem",
            "Value": {"Value": 2.0, "IsDynamic": false}
        }, {"Name": "BarrelItem", "Value": {"Value": 3.0, "IsDynamic": true}}]
    }]
}, {
    "Name": "Torch Recipe",
    "LaborInCalories": {"Value": 50.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": false},
    "Recipes": [{
        "Name": "Torch",
        "Ingredients": [{"Name": "Wood", "Value": {"Value": 2.0, "IsDynamic": false}}],
        "Products": [{"Name": "TorchItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Valve Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 3.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Valve",
        "Ingredients": [{
            "Name": "SteelPipeItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "SteelPlateItem", "Value": {"Value": 4.0, "IsDynamic": true}}, {
            "Name": "SteelGearboxItem",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "ValveItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Wooden Wheel Recipe",
    "LaborInCalories": {"Value": 60.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "WoodenWheel",
        "Ingredients": [{"Name": "HewnLog", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "WoodenWheelItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Advanced Baking Advanced Upgrade Recipe",
    "LaborInCalories": {"Value": 10000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 15.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "AdvancedBakingUpgrade",
        "Ingredients": [{"Name": "AdvancedUpgradeLvl4Item", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "AdvancedBakingUpgradeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Advanced Cooking Advanced Upgrade Recipe",
    "LaborInCalories": {"Value": 10000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 15.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "AdvancedCookingUpgrade",
        "Ingredients": [{"Name": "AdvancedUpgradeLvl4Item", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "AdvancedCookingUpgradeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Advanced Masonry Modern Upgrade Recipe",
    "LaborInCalories": {"Value": 15000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 18.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "AdvancedMasonryUpgrade",
        "Ingredients": [{"Name": "ModernUpgradeLvl4Item", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "AdvancedMasonryUpgradeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Advanced Smelting Advanced Upgrade Recipe",
    "LaborInCalories": {"Value": 10000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 15.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "AdvancedSmeltingUpgrade",
        "Ingredients": [{"Name": "AdvancedUpgradeLvl4Item", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "AdvancedSmeltingUpgradeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Advanced Upgrade 1 Recipe",
    "LaborInCalories": {"Value": 150.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 3.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "AdvancedUpgradeLvl1",
        "Ingredients": [{"Name": "ClayItem", "Value": {"Value": 12.0, "IsDynamic": true}}, {
            "Name": "BrickItem",
            "Value": {"Value": 20.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "AdvancedUpgradeLvl1Item", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Advanced Upgrade 2 Recipe",
    "LaborInCalories": {"Value": 150.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 6.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "AdvancedUpgradeLvl2",
        "Ingredients": [{"Name": "GlassItem", "Value": {"Value": 20.0, "IsDynamic": true}}, {
            "Name": "SandItem",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }, {"Name": "AdvancedUpgradeLvl1Item", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "AdvancedUpgradeLvl2Item", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Advanced Upgrade 3 Recipe",
    "LaborInCalories": {"Value": 150.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 9.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "AdvancedUpgradeLvl3",
        "Ingredients": [{"Name": "NailItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "IronBarItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }, {"Name": "AdvancedUpgradeLvl2Item", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "AdvancedUpgradeLvl3Item", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Advanced Upgrade 4 Recipe",
    "LaborInCalories": {"Value": 150.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 12.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "AdvancedUpgradeLvl4",
        "Ingredients": [{"Name": "IronPlateItem", "Value": {"Value": 6.0, "IsDynamic": true}}, {
            "Name": "PistonItem",
            "Value": {"Value": 8.0, "IsDynamic": true}
        }, {"Name": "Lumber", "Value": {"Value": 12.0, "IsDynamic": true}}, {
            "Name": "AdvancedUpgradeLvl3Item",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "AdvancedUpgradeLvl4Item", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Baking Advanced Upgrade Recipe",
    "LaborInCalories": {"Value": 10000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 15.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "BakingUpgrade",
        "Ingredients": [{"Name": "AdvancedUpgradeLvl4Item", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "BakingUpgradeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Basic Engineering Basic Upgrade Recipe",
    "LaborInCalories": {"Value": 5000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "BasicEngineeringUpgrade",
        "Ingredients": [{"Name": "BasicUpgradeLvl4Item", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "BasicEngineeringUpgradeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Basic Upgrade 1 Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "BasicUpgradeLvl1",
        "Ingredients": [{"Name": "HewnLog", "Value": {"Value": 40.0, "IsDynamic": true}}],
        "Products": [{"Name": "BasicUpgradeLvl1Item", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Basic Upgrade 2 Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "BasicUpgradeLvl2",
        "Ingredients": [{"Name": "MortarItem", "Value": {"Value": 20.0, "IsDynamic": true}}, {
            "Name": "MortaredStone",
            "Value": {"Value": 20.0, "IsDynamic": true}
        }, {"Name": "BasicUpgradeLvl1Item", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "BasicUpgradeLvl2Item", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Basic Upgrade 3 Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 6.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "BasicUpgradeLvl3",
        "Ingredients": [{"Name": "PlantFibersItem", "Value": {"Value": 80.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 20.0, "IsDynamic": true}
        }, {"Name": "BasicUpgradeLvl2Item", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "BasicUpgradeLvl3Item", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Basic Upgrade 4 Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 8.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "BasicUpgradeLvl4",
        "Ingredients": [{
            "Name": "WoodenWheelItem",
            "Value": {"Value": 20.0, "IsDynamic": true}
        }, {"Name": "PlantFibersItem", "Value": {"Value": 120.0, "IsDynamic": true}}, {
            "Name": "BasicUpgradeLvl3Item",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "BasicUpgradeLvl4Item", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Butchery Basic Upgrade Recipe",
    "LaborInCalories": {"Value": 5000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "ButcheryUpgrade",
        "Ingredients": [{"Name": "BasicUpgradeLvl4Item", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "ButcheryUpgradeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Campfire Cooking Basic Upgrade Recipe",
    "LaborInCalories": {"Value": 5000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CampfireCookingUpgrade",
        "Ingredients": [{"Name": "BasicUpgradeLvl4Item", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "CampfireCookingUpgradeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Carpentry Advanced Upgrade Recipe",
    "LaborInCalories": {"Value": 10000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 15.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CarpentryAdvancedUpgrade",
        "Ingredients": [{"Name": "AdvancedUpgradeLvl4Item", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "CarpentryAdvancedUpgradeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Carpentry Basic Upgrade Recipe",
    "LaborInCalories": {"Value": 5000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CarpentryBasicUpgrade",
        "Ingredients": [{"Name": "BasicUpgradeLvl4Item", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "CarpentryBasicUpgradeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Composites Modern Upgrade Recipe",
    "LaborInCalories": {"Value": 15000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 18.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CompositesUpgrade",
        "Ingredients": [{"Name": "ModernUpgradeLvl4Item", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "CompositesUpgradeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Cooking Advanced Upgrade Recipe",
    "LaborInCalories": {"Value": 10000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 15.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CookingUpgrade",
        "Ingredients": [{"Name": "AdvancedUpgradeLvl4Item", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "CookingUpgradeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Cutting Edge Cooking Modern Upgrade Recipe",
    "LaborInCalories": {"Value": 15000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 18.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CuttingEdgeCookingUpgrade",
        "Ingredients": [{"Name": "ModernUpgradeLvl4Item", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "CuttingEdgeCookingUpgradeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Electronics Modern Upgrade Recipe",
    "LaborInCalories": {"Value": 15000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 18.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "ElectronicsUpgrade",
        "Ingredients": [{"Name": "ModernUpgradeLvl4Item", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "ElectronicsUpgradeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Farming Basic Upgrade Recipe",
    "LaborInCalories": {"Value": 5000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "FarmingUpgrade",
        "Ingredients": [{"Name": "BasicUpgradeLvl4Item", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "FarmingUpgradeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Fertilizers Basic Upgrade Recipe",
    "LaborInCalories": {"Value": 10000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 15.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "FertilizersUpgrade",
        "Ingredients": [{"Name": "BasicUpgradeLvl4Item", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "FertilizersUpgradeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Glassworking Advanced Upgrade Recipe",
    "LaborInCalories": {"Value": 10000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 15.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "GlassworkingAdvancedUpgrade",
        "Ingredients": [{"Name": "AdvancedUpgradeLvl4Item", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "GlassworkingAdvancedUpgradeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Glassworking Modern Upgrade Recipe",
    "LaborInCalories": {"Value": 10000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 15.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "GlassworkingModernUpgrade",
        "Ingredients": [{"Name": "ModernUpgradeLvl4Item", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "GlassworkingModernUpgradeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Industry Modern Upgrade Recipe",
    "LaborInCalories": {"Value": 15000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 18.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "IndustryUpgrade",
        "Ingredients": [{"Name": "ModernUpgradeLvl4Item", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "IndustryUpgradeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Logging Advanced Upgrade Recipe",
    "LaborInCalories": {"Value": 5000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "LoggingAdvancedUpgrade",
        "Ingredients": [{"Name": "AdvancedUpgradeLvl4Item", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "LoggingAdvancedUpgradeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Logging Basic Upgrade Recipe",
    "LaborInCalories": {"Value": 5000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "LoggingBasicUpgrade",
        "Ingredients": [{"Name": "BasicUpgradeLvl4Item", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "LoggingBasicUpgradeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Masonry Advanced Upgrade Recipe",
    "LaborInCalories": {"Value": 10000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 15.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "MasonryAdvancedUpgrade",
        "Ingredients": [{"Name": "AdvancedUpgradeLvl4Item", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "MasonryAdvancedUpgradeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Masonry Basic Upgrade Recipe",
    "LaborInCalories": {"Value": 5000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "MasonryBasicUpgrade",
        "Ingredients": [{"Name": "BasicUpgradeLvl4Item", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "MasonryBasicUpgradeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Mechanics Advanced Upgrade Recipe",
    "LaborInCalories": {"Value": 10000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 15.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "MechanicsAdvancedUpgrade",
        "Ingredients": [{"Name": "AdvancedUpgradeLvl4Item", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "MechanicsAdvancedUpgradeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Mechanics Modern Upgrade Recipe",
    "LaborInCalories": {"Value": 10000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 15.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "MechanicsModernUpgrade",
        "Ingredients": [{"Name": "ModernUpgradeLvl4Item", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "MechanicsModernUpgradeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Milling Advanced Upgrade Recipe",
    "LaborInCalories": {"Value": 10000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 15.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "MillingUpgrade",
        "Ingredients": [{"Name": "AdvancedUpgradeLvl4Item", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "MillingUpgradeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Mining Advanced Upgrade Recipe",
    "LaborInCalories": {"Value": 10000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 15.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "MiningAdvancedUpgrade",
        "Ingredients": [{"Name": "AdvancedUpgradeLvl4Item", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "MiningAdvancedUpgradeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Mining Basic Upgrade Recipe",
    "LaborInCalories": {"Value": 5000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "MiningBasicUpgrade",
        "Ingredients": [{"Name": "BasicUpgradeLvl4Item", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "MiningBasicUpgradeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Mining Modern Upgrade Recipe",
    "LaborInCalories": {"Value": 15000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 18.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "MiningModernUpgrade",
        "Ingredients": [{"Name": "ModernUpgradeLvl4Item", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "MiningModernUpgradeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Modern Upgrade 1 Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "ModernUpgradeLvl1",
        "Ingredients": [{"Name": "SteelBarItem", "Value": {"Value": 6.0, "IsDynamic": true}}, {
            "Name": "RivetItem",
            "Value": {"Value": 20.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "ModernUpgradeLvl1Item", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Modern Upgrade 2 Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 6.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "ModernUpgradeLvl2",
        "Ingredients": [{
            "Name": "SteelPlateItem",
            "Value": {"Value": 16.0, "IsDynamic": true}
        }, {"Name": "SteelGearItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "ModernUpgradeLvl1Item",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "ModernUpgradeLvl2Item", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Modern Upgrade 3 Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "ModernUpgradeLvl3",
        "Ingredients": [{
            "Name": "BasicCircuitItem",
            "Value": {"Value": 8.0, "IsDynamic": true}
        }, {"Name": "PlasticItem", "Value": {"Value": 15.0, "IsDynamic": true}}, {
            "Name": "EpoxyItem",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }, {"Name": "ModernUpgradeLvl2Item", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "ModernUpgradeLvl3Item", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Modern Upgrade 4 Recipe",
    "LaborInCalories": {"Value": 2000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 14.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "ModernUpgradeLvl4",
        "Ingredients": [{
            "Name": "CompositeLumber",
            "Value": {"Value": 12.0, "IsDynamic": true}
        }, {"Name": "ModernUpgradeLvl3Item", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "ModernUpgradeLvl4Item", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Oil Drilling Modern Upgrade Recipe",
    "LaborInCalories": {"Value": 10000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 15.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "OilDrillingUpgrade",
        "Ingredients": [{"Name": "ModernUpgradeLvl4Item", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "OilDrillingUpgradeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Paper Milling Basic Upgrade Recipe",
    "LaborInCalories": {"Value": 10000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 15.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "PaperMillingUpgrade",
        "Ingredients": [{"Name": "BasicUpgradeLvl4Item", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "PaperMillingUpgradeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Pottery Advanced Upgrade Recipe",
    "LaborInCalories": {"Value": 10000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 15.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "PotteryUpgrade",
        "Ingredients": [{"Name": "AdvancedUpgradeLvl4Item", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "PotteryUpgradeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Smelting Advanced Upgrade Recipe",
    "LaborInCalories": {"Value": 10000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 15.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "SmeltingUpgrade",
        "Ingredients": [{"Name": "AdvancedUpgradeLvl4Item", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "SmeltingUpgradeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Tailoring Basic Upgrade Recipe",
    "LaborInCalories": {"Value": 5000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "TailoringUpgrade",
        "Ingredients": [{"Name": "BasicUpgradeLvl4Item", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "TailoringUpgradeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Baked Mortar Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.1, "IsDynamic": true},
    "Recipes": [{
        "Name": "BakedMortar",
        "Ingredients": [{"Name": "WoodPulpItem", "Value": {"Value": 5.0, "IsDynamic": true}}],
        "Products": [{"Name": "MortarItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Beet Sugar Recipe",
    "LaborInCalories": {"Value": 80.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "BeetSugar",
        "Ingredients": [{"Name": "BeetItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "SugarItem", "Value": {"Value": 3.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Boards Recipe",
    "LaborInCalories": {"Value": 60.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 0.2, "IsDynamic": false},
    "Recipes": [{
        "Name": "Boards",
        "Ingredients": [{"Name": "HewnLog", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "BoardItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "HardwoodBoards",
        "Ingredients": [{"Name": "HardwoodHewnLogItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "HardwoodBoardItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SoftwoodBoards",
        "Ingredients": [{"Name": "SoftwoodHewnLogItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "SoftwoodBoardItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Butcher Bison Recipe",
    "LaborInCalories": {"Value": 160.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "ButcherBison",
        "Ingredients": [{"Name": "BisonCarcassItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "RawMeatItem", "Value": {"Value": 10.0, "IsDynamic": false}}, {
            "Name": "LeatherHideItem",
            "Value": {"Value": 2.0, "IsDynamic": false}
        }, {"Name": "TallowItem", "Value": {"Value": 2.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Butcher Medium Animal Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "ButcherMediumAnimal",
        "Ingredients": [{"Name": "MediumCarcass", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "RawMeatItem", "Value": {"Value": 5.0, "IsDynamic": false}}, {
            "Name": "LeatherHideItem",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }, {"Name": "TallowItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Butcher Small Animal Recipe",
    "LaborInCalories": {"Value": 80.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.2, "IsDynamic": true},
    "Recipes": [{
        "Name": "ButcherSmallAnimal",
        "Ingredients": [{"Name": "SmallCarcass", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "RawMeatItem", "Value": {"Value": 2.0, "IsDynamic": false}}, {
            "Name": "FurPeltItem",
            "Value": {"Value": 2.0, "IsDynamic": false}
        }]
    }]
}, {
    "Name": "Butcher Tiny Fur Animal Recipe",
    "LaborInCalories": {"Value": 60.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.2, "IsDynamic": true},
    "Recipes": [{
        "Name": "ButcherTinyFurAnimal",
        "Ingredients": [{"Name": "TinyFurCarcass", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "RawMeatItem", "Value": {"Value": 1.0, "IsDynamic": false}}, {
            "Name": "FurPeltItem",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }]
    }]
}, {
    "Name": "Butcher Tiny Leather Animal Recipe",
    "LaborInCalories": {"Value": 60.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.2, "IsDynamic": true},
    "Recipes": [{
        "Name": "ButcherTinyLeatherAnimal",
        "Ingredients": [{"Name": "TinyLeatherCarcass", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "RawMeatItem", "Value": {"Value": 1.0, "IsDynamic": false}}, {
            "Name": "LeatherHideItem",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }]
    }]
}, {
    "Name": "Campfire Animal Medium Recipe",
    "LaborInCalories": {"Value": 40.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "CampfireAnimalMedium",
        "Ingredients": [{"Name": "MediumCarcass", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "CharredMeatItem", "Value": {"Value": 4.0, "IsDynamic": false}}, {
            "Name": "TallowItem",
            "Value": {"Value": 2.0, "IsDynamic": false}
        }]
    }]
}, {
    "Name": "Campfire Animal Small Recipe",
    "LaborInCalories": {"Value": 40.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CampfireAnimalSmall",
        "Ingredients": [{"Name": "SmallCarcass", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "CharredMeatItem", "Value": {"Value": 2.0, "IsDynamic": false}}, {
            "Name": "TallowItem",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }]
    }]
}, {
    "Name": "Campfire Animal Tiny Recipe",
    "LaborInCalories": {"Value": 40.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.8, "IsDynamic": true},
    "Recipes": [{
        "Name": "CampfireAnimalTiny",
        "Ingredients": [{"Name": "TinyCarcass", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "CharredMeatItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Campfire Bass Recipe",
    "LaborInCalories": {"Value": 40.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.8, "IsDynamic": true},
    "Recipes": [{
        "Name": "CampfireBass",
        "Ingredients": [{"Name": "BassItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "CharredFishItem", "Value": {"Value": 2.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Campfire Bison Recipe",
    "LaborInCalories": {"Value": 40.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CampfireBison",
        "Ingredients": [{"Name": "BisonCarcassItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "CharredMeatItem", "Value": {"Value": 8.0, "IsDynamic": false}}, {
            "Name": "TallowItem",
            "Value": {"Value": 4.0, "IsDynamic": false}
        }]
    }]
}, {
    "Name": "Campfire Blue Shark Recipe",
    "LaborInCalories": {"Value": 40.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CampfireBlueShark",
        "Ingredients": [{"Name": "BlueSharkItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "CharredFishItem", "Value": {"Value": 6.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Campfire Cod Recipe",
    "LaborInCalories": {"Value": 40.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.8, "IsDynamic": true},
    "Recipes": [{
        "Name": "CampfireCod",
        "Ingredients": [{"Name": "CodItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "CharredFishItem", "Value": {"Value": 2.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Campfire Crab Recipe",
    "LaborInCalories": {"Value": 40.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.4, "IsDynamic": true},
    "Recipes": [{
        "Name": "CampfireCrab",
        "Ingredients": [{"Name": "CrabCarcassItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "CharredMeatItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Campfire Moon Jellyfish Recipe",
    "LaborInCalories": {"Value": 40.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.4, "IsDynamic": true},
    "Recipes": [{
        "Name": "CampfireMoonJellyfish",
        "Ingredients": [{"Name": "MoonJellyfishItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "CharredFishItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Campfire Pacific Sardine Recipe",
    "LaborInCalories": {"Value": 40.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.8, "IsDynamic": true},
    "Recipes": [{
        "Name": "CampfirePacificSardine",
        "Ingredients": [{"Name": "PacificSardineItem", "Value": {"Value": 3.0, "IsDynamic": false}}],
        "Products": [{"Name": "CharredFishItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Campfire Salmon Recipe",
    "LaborInCalories": {"Value": 40.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CampfireSalmon",
        "Ingredients": [{"Name": "SalmonItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "CharredFishItem", "Value": {"Value": 2.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Campfire Trout Recipe",
    "LaborInCalories": {"Value": 40.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.2, "IsDynamic": true},
    "Recipes": [{
        "Name": "CampfireTrout",
        "Ingredients": [{"Name": "TroutItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "CharredFishItem", "Value": {"Value": 2.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Campfire Tuna Recipe",
    "LaborInCalories": {"Value": 40.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CampfireTuna",
        "Ingredients": [{"Name": "TunaItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "CharredFishItem", "Value": {"Value": 4.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Charred Mortar Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 0.16, "IsDynamic": false},
    "Recipes": [{
        "Name": "CharredMortar",
        "Ingredients": [{"Name": "NaturalFiber", "Value": {"Value": 5.0, "IsDynamic": false}}],
        "Products": [{"Name": "MortarItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Clean Bass Recipe",
    "LaborInCalories": {"Value": 30.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "CleanBass",
        "Ingredients": [{"Name": "BassItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "RawFishItem", "Value": {"Value": 3.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Clean Blue Shark Recipe",
    "LaborInCalories": {"Value": 30.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "CleanBlueShark",
        "Ingredients": [{"Name": "BlueSharkItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "RawFishItem", "Value": {"Value": 8.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Clean Cod Recipe",
    "LaborInCalories": {"Value": 30.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "CleanCod",
        "Ingredients": [{"Name": "CodItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "RawFishItem", "Value": {"Value": 4.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Clean Pacific Sardine Recipe",
    "LaborInCalories": {"Value": 30.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "CleanPacificSardine",
        "Ingredients": [{"Name": "PacificSardineItem", "Value": {"Value": 2.0, "IsDynamic": false}}],
        "Products": [{"Name": "RawFishItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Clean Salmon Recipe",
    "LaborInCalories": {"Value": 30.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "CleanSalmon",
        "Ingredients": [{"Name": "SalmonItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "RawFishItem", "Value": {"Value": 4.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Clean Trout Recipe",
    "LaborInCalories": {"Value": 30.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 1.5, "IsDynamic": false},
    "Recipes": [{
        "Name": "CleanTrout",
        "Ingredients": [{"Name": "TroutItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "RawFishItem", "Value": {"Value": 3.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Clean Tuna Recipe",
    "LaborInCalories": {"Value": 30.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "CleanTuna",
        "Ingredients": [{"Name": "TunaItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "RawFishItem", "Value": {"Value": 5.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Clean Urchins Recipe",
    "LaborInCalories": {"Value": 30.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 0.2, "IsDynamic": false},
    "Recipes": [{
        "Name": "CleanUrchins",
        "Ingredients": [{"Name": "UrchinItem", "Value": {"Value": 4.0, "IsDynamic": false}}],
        "Products": [{"Name": "RawFishItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Concentrate Copper Lv2 Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.8, "IsDynamic": true},
    "Recipes": [{
        "Name": "ConcentrateCopperLv2",
        "Ingredients": [{"Name": "CrushedCopperOreItem", "Value": {"Value": 7.0, "IsDynamic": true}}],
        "Products": [{
            "Name": "CopperConcentrateItem",
            "Value": {"Value": 2.0, "IsDynamic": false}
        }, {"Name": "WetTailingsItem", "Value": {"Value": 2.0, "IsDynamic": true}}]
    }]
}, {
    "Name": "Concentrate Dry Iron Recipe",
    "LaborInCalories": {"Value": 150.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.2, "IsDynamic": true},
    "Recipes": [{
        "Name": "ConcentrateDryIron",
        "Ingredients": [{"Name": "CrushedIronOreItem", "Value": {"Value": 3.0, "IsDynamic": true}}],
        "Products": [{
            "Name": "IronConcentrateItem",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }, {"Name": "TailingsItem", "Value": {"Value": 1.0, "IsDynamic": true}}]
    }]
}, {
    "Name": "Concentrate Dry Iron Lv2 Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.4, "IsDynamic": true},
    "Recipes": [{
        "Name": "ConcentrateDryIronLv2",
        "Ingredients": [{"Name": "CrushedIronOreItem", "Value": {"Value": 5.0, "IsDynamic": true}}],
        "Products": [{
            "Name": "IronConcentrateItem",
            "Value": {"Value": 2.0, "IsDynamic": false}
        }, {"Name": "TailingsItem", "Value": {"Value": 1.0, "IsDynamic": true}}]
    }]
}, {
    "Name": "Concentrate Gold Lv2 Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.8, "IsDynamic": true},
    "Recipes": [{
        "Name": "ConcentrateGoldLv2",
        "Ingredients": [{"Name": "CrushedGoldOreItem", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{
            "Name": "GoldConcentrateItem",
            "Value": {"Value": 2.0, "IsDynamic": false}
        }, {"Name": "WetTailingsItem", "Value": {"Value": 3.0, "IsDynamic": true}}]
    }]
}, {
    "Name": "Concentrate Iron Lv2 Recipe",
    "LaborInCalories": {"Value": 300.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.8, "IsDynamic": true},
    "Recipes": [{
        "Name": "ConcentrateIronLv2",
        "Ingredients": [{"Name": "CrushedIronOreItem", "Value": {"Value": 5.0, "IsDynamic": true}}],
        "Products": [{
            "Name": "IronConcentrateItem",
            "Value": {"Value": 2.0, "IsDynamic": false}
        }, {"Name": "WetTailingsItem", "Value": {"Value": 1.0, "IsDynamic": true}}]
    }]
}, {
    "Name": "Crushed Basalt Lv2 Recipe",
    "LaborInCalories": {"Value": 500.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 5.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CrushedBasaltLv2",
        "Ingredients": [{"Name": "BasaltItem", "Value": {"Value": 20.0, "IsDynamic": false}}],
        "Products": [{"Name": "CrushedBasaltItem", "Value": {"Value": 5.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Crushed Basalt Lv3 Recipe",
    "LaborInCalories": {"Value": 50.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "CrushedBasaltLv3",
        "Ingredients": [{"Name": "BasaltItem", "Value": {"Value": 20.0, "IsDynamic": false}}],
        "Products": [{"Name": "CrushedBasaltItem", "Value": {"Value": 5.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Crushed Copper Lv2 Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CrushedCopperLv2",
        "Ingredients": [{"Name": "CopperOreItem", "Value": {"Value": 20.0, "IsDynamic": false}}],
        "Products": [{
            "Name": "CrushedCopperOreItem",
            "Value": {"Value": 4.0, "IsDynamic": false}
        }, {"Name": "CrushedGraniteItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Crushed Copper Lv3 Recipe",
    "LaborInCalories": {"Value": 50.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "CrushedCopperLv3",
        "Ingredients": [{"Name": "CopperOreItem", "Value": {"Value": 20.0, "IsDynamic": false}}],
        "Products": [{"Name": "CrushedCopperOreItem", "Value": {"Value": 5.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Crushed Gneiss Lv2 Recipe",
    "LaborInCalories": {"Value": 250.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 3.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CrushedGneissLv2",
        "Ingredients": [{"Name": "GneissItem", "Value": {"Value": 20.0, "IsDynamic": false}}],
        "Products": [{"Name": "CrushedGneissItem", "Value": {"Value": 5.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Crushed Gneiss Lv3 Recipe",
    "LaborInCalories": {"Value": 50.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "CrushedGneissLv3",
        "Ingredients": [{"Name": "GneissItem", "Value": {"Value": 20.0, "IsDynamic": false}}],
        "Products": [{"Name": "CrushedGneissItem", "Value": {"Value": 5.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Crushed Gold Lv2 Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CrushedGoldLv2",
        "Ingredients": [{"Name": "GoldOreItem", "Value": {"Value": 20.0, "IsDynamic": false}}],
        "Products": [{
            "Name": "CrushedGoldOreItem",
            "Value": {"Value": 4.0, "IsDynamic": false}
        }, {"Name": "CrushedGraniteItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Crushed Gold Lv3 Recipe",
    "LaborInCalories": {"Value": 50.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "CrushedGoldLv3",
        "Ingredients": [{"Name": "GoldOreItem", "Value": {"Value": 20.0, "IsDynamic": false}}],
        "Products": [{"Name": "CrushedGoldOreItem", "Value": {"Value": 5.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Crushed Granite Lv2 Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CrushedGraniteLv2",
        "Ingredients": [{"Name": "GraniteItem", "Value": {"Value": 20.0, "IsDynamic": false}}],
        "Products": [{"Name": "CrushedGraniteItem", "Value": {"Value": 5.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Crushed Granite Lv3 Recipe",
    "LaborInCalories": {"Value": 50.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "CrushedGraniteLv3",
        "Ingredients": [{"Name": "GraniteItem", "Value": {"Value": 20.0, "IsDynamic": false}}],
        "Products": [{"Name": "CrushedGraniteItem", "Value": {"Value": 5.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Crushed Iron Lv2 Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CrushedIronLv2",
        "Ingredients": [{"Name": "IronOreItem", "Value": {"Value": 20.0, "IsDynamic": false}}],
        "Products": [{
            "Name": "CrushedIronOreItem",
            "Value": {"Value": 4.0, "IsDynamic": false}
        }, {"Name": "CrushedSandstoneItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Crushed Iron Lv3 Recipe",
    "LaborInCalories": {"Value": 50.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "CrushedIronLv3",
        "Ingredients": [{"Name": "IronOreItem", "Value": {"Value": 20.0, "IsDynamic": false}}],
        "Products": [{"Name": "CrushedIronOreItem", "Value": {"Value": 5.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Crushed Limestone Lv2 Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CrushedLimestoneLv2",
        "Ingredients": [{"Name": "LimestoneItem", "Value": {"Value": 20.0, "IsDynamic": false}}],
        "Products": [{"Name": "CrushedLimestoneItem", "Value": {"Value": 5.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Crushed Limestone Lv3 Recipe",
    "LaborInCalories": {"Value": 50.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "CrushedLimestoneLv3",
        "Ingredients": [{"Name": "LimestoneItem", "Value": {"Value": 20.0, "IsDynamic": false}}],
        "Products": [{"Name": "CrushedLimestoneItem", "Value": {"Value": 5.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Crushed Sandstone Lv2 Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CrushedSandstoneLv2",
        "Ingredients": [{"Name": "SandstoneItem", "Value": {"Value": 20.0, "IsDynamic": false}}],
        "Products": [{"Name": "CrushedSandstoneItem", "Value": {"Value": 5.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Crushed Sandstone Lv3 Recipe",
    "LaborInCalories": {"Value": 50.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "CrushedSandstoneLv3",
        "Ingredients": [{"Name": "SandstoneItem", "Value": {"Value": 20.0, "IsDynamic": false}}],
        "Products": [{"Name": "CrushedSandstoneItem", "Value": {"Value": 5.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Crushed Shale Lv2 Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CrushedShaleLv2",
        "Ingredients": [{"Name": "ShaleItem", "Value": {"Value": 20.0, "IsDynamic": false}}],
        "Products": [{"Name": "CrushedShaleItem", "Value": {"Value": 5.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Crushed Shale Lv3 Recipe",
    "LaborInCalories": {"Value": 50.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "CrushedShaleLv3",
        "Ingredients": [{"Name": "ShaleItem", "Value": {"Value": 20.0, "IsDynamic": false}}],
        "Products": [{"Name": "CrushedShaleItem", "Value": {"Value": 5.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Crushed Slag Lv2 Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CrushedSlagLv2",
        "Ingredients": [{"Name": "SlagItem", "Value": {"Value": 20.0, "IsDynamic": false}}],
        "Products": [{"Name": "CrushedSlagItem", "Value": {"Value": 5.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Crushed Slag Lv3 Recipe",
    "LaborInCalories": {"Value": 50.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "CrushedSlagLv3",
        "Ingredients": [{"Name": "SlagItem", "Value": {"Value": 20.0, "IsDynamic": false}}],
        "Products": [{"Name": "CrushedSlagItem", "Value": {"Value": 5.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Culinary Research Paper Advanced Meat Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CulinaryResearchPaperAdvancedMeat",
        "Ingredients": [{
            "Name": "BakedMeatItem",
            "Value": {"Value": 20.0, "IsDynamic": true}
        }, {"Name": "SimmeredMeatItem", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "CulinaryResearchPaperAdvancedItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Culinary Research Paper Basic Meat Recipe",
    "LaborInCalories": {"Value": 50.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CulinaryResearchPaperBasicMeat",
        "Ingredients": [{"Name": "CharredMeatItem", "Value": {"Value": 20.0, "IsDynamic": true}}],
        "Products": [{"Name": "CulinaryResearchPaperBasicItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Dirt Ramp Basic Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": false},
    "Recipes": [{
        "Name": "DirtRampBasic",
        "Ingredients": [{"Name": "DirtItem", "Value": {"Value": 6.0, "IsDynamic": false}}],
        "Products": [{"Name": "DirtRampItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Exotic Fruit Salad Recipe",
    "LaborInCalories": {"Value": 40.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "ExoticFruitSalad",
        "Ingredients": [{
            "Name": "PricklyPearFruitItem",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }, {"Name": "PumpkinItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "FruitSaladItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Exotic Salad Recipe",
    "LaborInCalories": {"Value": 40.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "ExoticSalad",
        "Ingredients": [{
            "Name": "PricklyPearFruitItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "CriminiMushroomsItem", "Value": {"Value": 4.0, "IsDynamic": true}}, {
            "Name": "RiceItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "BasicSaladItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Exotic Vegetable Medley Recipe",
    "LaborInCalories": {"Value": 40.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.8, "IsDynamic": true},
    "Recipes": [{
        "Name": "ExoticVegetableMedley",
        "Ingredients": [{"Name": "BeansItem", "Value": {"Value": 8.0, "IsDynamic": true}}, {
            "Name": "TomatoItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }, {"Name": "BeetItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "VegetableMedleyItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Forest Salad Recipe",
    "LaborInCalories": {"Value": 40.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "ForestSalad",
        "Ingredients": [{
            "Name": "FiddleheadsItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }, {"Name": "HuckleberriesItem", "Value": {"Value": 8.0, "IsDynamic": true}}, {
            "Name": "BeansItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "BasicSaladItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Grassland Salad Recipe",
    "LaborInCalories": {"Value": 40.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "GrasslandSalad",
        "Ingredients": [{"Name": "CornItem", "Value": {"Value": 5.0, "IsDynamic": true}}, {
            "Name": "TomatoItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }, {"Name": "BeetItem", "Value": {"Value": 5.0, "IsDynamic": true}}],
        "Products": [{"Name": "BasicSaladItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Hewn Hardwood Recipe",
    "LaborInCalories": {"Value": 30.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.15, "IsDynamic": true},
    "Recipes": [{
        "Name": "HewnHardwood",
        "Ingredients": [{"Name": "Hardwood", "Value": {"Value": 2.0, "IsDynamic": true}}],
        "Products": [{"Name": "HardwoodHewnLogItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Hewn Log Door Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "HewnLogDoor",
        "Ingredients": [{"Name": "HewnLog", "Value": {"Value": 5.0, "IsDynamic": false}}],
        "Products": [{"Name": "HewnDoorItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Hewn Logs Recipe",
    "LaborInCalories": {"Value": 30.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.15, "IsDynamic": true},
    "Recipes": [{
        "Name": "HewnLogs",
        "Ingredients": [{"Name": "Wood", "Value": {"Value": 2.0, "IsDynamic": true}}],
        "Products": [{"Name": "HewnLogItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Hewn Softwood Recipe",
    "LaborInCalories": {"Value": 30.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.15, "IsDynamic": true},
    "Recipes": [{
        "Name": "HewnSoftwood",
        "Ingredients": [{"Name": "Softwood", "Value": {"Value": 2.0, "IsDynamic": true}}],
        "Products": [{"Name": "SoftwoodHewnLogItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Masonry Mortar Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.2, "IsDynamic": true},
    "Recipes": [{
        "Name": "MasonryMortar",
        "Ingredients": [{"Name": "SandItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "MortarItem", "Value": {"Value": 3.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Mixed Fruit Salad Recipe",
    "LaborInCalories": {"Value": 40.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "MixedFruitSalad",
        "Ingredients": [{"Name": "HuckleberriesItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "BeetItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "FruitSaladItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Mixed Salad Recipe",
    "LaborInCalories": {"Value": 40.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "MixedSalad",
        "Ingredients": [{"Name": "FiddleheadsItem", "Value": {"Value": 6.0, "IsDynamic": true}}, {
            "Name": "TomatoItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }, {"Name": "FireweedShootsItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "BasicSaladItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Mixed Vegetable Medley Recipe",
    "LaborInCalories": {"Value": 40.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.8, "IsDynamic": true},
    "Recipes": [{
        "Name": "MixedVegetableMedley",
        "Ingredients": [{"Name": "CornItem", "Value": {"Value": 6.0, "IsDynamic": true}}, {
            "Name": "CamasBulbItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "VegetableMedleyItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Mortared Stones Recipe",
    "LaborInCalories": {"Value": 30.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 0.25, "IsDynamic": false},
    "Recipes": [{
        "Name": "MortaredStones",
        "Ingredients": [{"Name": "MortarItem", "Value": {"Value": 1.0, "IsDynamic": false}}, {
            "Name": "Rock",
            "Value": {"Value": 6.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "MortaredStoneItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Mushroom Medley Recipe",
    "LaborInCalories": {"Value": 40.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.8, "IsDynamic": true},
    "Recipes": [{
        "Name": "MushroomMedley",
        "Ingredients": [{
            "Name": "CriminiMushroomsItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {
            "Name": "CookeinaMushroomsItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "BoleteMushroomsItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "VegetableMedleyItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Rainforest Fruit Salad Recipe",
    "LaborInCalories": {"Value": 40.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "RainforestFruitSalad",
        "Ingredients": [{"Name": "PapayaItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "PineappleItem",
            "Value": {"Value": 8.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "FruitSaladItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Rainforest Salad Recipe",
    "LaborInCalories": {"Value": 40.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "RainforestSalad",
        "Ingredients": [{
            "Name": "TaroRootItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "BoleteMushroomsItem", "Value": {"Value": 8.0, "IsDynamic": true}}, {
            "Name": "PapayaItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "BasicSaladItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Refine Fish Recipe",
    "LaborInCalories": {"Value": 40.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "RefineFish",
        "Ingredients": [{"Name": "RawFishItem", "Value": {"Value": 16.0, "IsDynamic": true}}],
        "Products": [{"Name": "HydrocolloidsItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Refine Kelp Recipe",
    "LaborInCalories": {"Value": 40.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "RefineKelp",
        "Ingredients": [{"Name": "KelpItem", "Value": {"Value": 8.0, "IsDynamic": true}}],
        "Products": [{"Name": "HydrocolloidsItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Refine Meat Recipe",
    "LaborInCalories": {"Value": 40.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "RefineMeat",
        "Ingredients": [{"Name": "RawMeatItem", "Value": {"Value": 16.0, "IsDynamic": true}}],
        "Products": [{"Name": "HydrocolloidsItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Refine Tallow Recipe",
    "LaborInCalories": {"Value": 30.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.8, "IsDynamic": true},
    "Recipes": [{
        "Name": "RefineTallow",
        "Ingredients": [{"Name": "TallowItem", "Value": {"Value": 18.0, "IsDynamic": true}}],
        "Products": [{"Name": "OilItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Render Fat Recipe",
    "LaborInCalories": {"Value": 30.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.8, "IsDynamic": true},
    "Recipes": [{
        "Name": "RenderFat",
        "Ingredients": [{"Name": "RawMeatItem", "Value": {"Value": 3.0, "IsDynamic": true}}],
        "Products": [{"Name": "TallowItem", "Value": {"Value": 2.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Sand Concentrate Recipe",
    "LaborInCalories": {"Value": 40.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "SandConcentrate",
        "Ingredients": [{"Name": "Silica", "Value": {"Value": 2.0, "IsDynamic": true}}],
        "Products": [{"Name": "SandItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Saw Boards Recipe",
    "LaborInCalories": {"Value": 30.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.2, "IsDynamic": true},
    "Recipes": [{
        "Name": "SawBoards",
        "Ingredients": [{"Name": "HewnLog", "Value": {"Value": 2.0, "IsDynamic": true}}],
        "Products": [{"Name": "BoardItem", "Value": {"Value": 3.0, "IsDynamic": false}}]
    }, {
        "Name": "SawHardwoodBoards",
        "Ingredients": [{"Name": "HardwoodHewnLogItem", "Value": {"Value": 2.0, "IsDynamic": true}}],
        "Products": [{"Name": "HardwoodBoardItem", "Value": {"Value": 3.0, "IsDynamic": false}}]
    }, {
        "Name": "SawSoftwoodBoards",
        "Ingredients": [{"Name": "SoftwoodHewnLogItem", "Value": {"Value": 2.0, "IsDynamic": true}}],
        "Products": [{"Name": "SoftwoodBoardItem", "Value": {"Value": 3.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Shale Brick Recipe",
    "LaborInCalories": {"Value": 40.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "ShaleBrick",
        "Ingredients": [{"Name": "CrushedShaleItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "ClayItem",
            "Value": {"Value": 1.0, "IsDynamic": true}
        }, {"Name": "MortarItem", "Value": {"Value": 8.0, "IsDynamic": true}}],
        "Products": [{"Name": "BrickItem", "Value": {"Value": 2.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Shred Kelp Recipe",
    "LaborInCalories": {"Value": 40.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "ShredKelp",
        "Ingredients": [{"Name": "KelpItem", "Value": {"Value": 8.0, "IsDynamic": false}}],
        "Products": [{"Name": "PlantFibersItem", "Value": {"Value": 7.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Shuck Clams Recipe",
    "LaborInCalories": {"Value": 40.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 0.2, "IsDynamic": false},
    "Recipes": [{
        "Name": "ShuckClams",
        "Ingredients": [{"Name": "ClamItem", "Value": {"Value": 5.0, "IsDynamic": false}}],
        "Products": [{"Name": "RawFishItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Smelt Copper Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "SmeltCopper",
        "Ingredients": [{"Name": "CopperConcentrateItem", "Value": {"Value": 2.0, "IsDynamic": true}}],
        "Products": [{"Name": "CopperBarItem", "Value": {"Value": 3.0, "IsDynamic": false}}, {
            "Name": "SlagItem",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }]
    }]
}, {
    "Name": "Smelt Gold Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "SmeltGold",
        "Ingredients": [{"Name": "GoldConcentrateItem", "Value": {"Value": 2.0, "IsDynamic": true}}],
        "Products": [{"Name": "GoldBarItem", "Value": {"Value": 3.0, "IsDynamic": false}}, {
            "Name": "SlagItem",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }]
    }]
}, {
    "Name": "Smelt Iron Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "SmeltIron",
        "Ingredients": [{"Name": "IronConcentrateItem", "Value": {"Value": 2.0, "IsDynamic": true}}],
        "Products": [{"Name": "IronBarItem", "Value": {"Value": 3.0, "IsDynamic": false}}, {
            "Name": "SlagItem",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }]
    }]
}, {
    "Name": "Wheat Mortar Recipe",
    "LaborInCalories": {"Value": 40.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.1, "IsDynamic": true},
    "Recipes": [{
        "Name": "WheatMortar",
        "Ingredients": [{"Name": "WheatItem", "Value": {"Value": 8.0, "IsDynamic": true}}],
        "Products": [{"Name": "MortarItem", "Value": {"Value": 3.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Agave Seed Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "AgaveSeed",
        "Ingredients": [{"Name": "AgaveLeavesItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "AgaveSeedItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Amanita Mushroom Spores Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "AmanitaMushroomSpores",
        "Ingredients": [{"Name": "AmanitaMushroomsItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "AmanitaMushroomSporesItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Beet Seed Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "BeetSeed",
        "Ingredients": [{"Name": "BeetItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "BeetSeedItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Bolete Mushroom Spores Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "BoleteMushroomSpores",
        "Ingredients": [{"Name": "BoleteMushroomsItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "BoleteMushroomSporesItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Cookeina Mushroom Spores Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CookeinaMushroomSpores",
        "Ingredients": [{"Name": "CookeinaMushroomsItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "CookeinaMushroomSporesItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Corn Seed Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CornSeed",
        "Ingredients": [{"Name": "CornItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "CornSeedItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Crimini Mushroom Spores Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CriminiMushroomSpores",
        "Ingredients": [{"Name": "CriminiMushroomsItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "CriminiMushroomSporesItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Fern Spore Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "FernSpore",
        "Ingredients": [{"Name": "FiddleheadsItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "FernSporeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Fireweed Seed Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "FireweedSeed",
        "Ingredients": [{"Name": "FireweedShootsItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "FireweedSeedItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Huckleberry Seed Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "HuckleberrySeed",
        "Ingredients": [{"Name": "HuckleberriesItem", "Value": {"Value": 2.0, "IsDynamic": true}}],
        "Products": [{"Name": "HuckleberrySeedItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Papaya Seed Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "PapayaSeed",
        "Ingredients": [{"Name": "PapayaItem", "Value": {"Value": 2.0, "IsDynamic": true}}],
        "Products": [{"Name": "PapayaSeedItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Pineapple Seed Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "PineappleSeed",
        "Ingredients": [{"Name": "PineappleItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "PineappleSeedItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Prickly Pear Seed Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "PricklyPearSeed",
        "Ingredients": [{"Name": "PricklyPearFruitItem", "Value": {"Value": 2.0, "IsDynamic": true}}],
        "Products": [{"Name": "PricklyPearSeedItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Pumpkin Seed Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "PumpkinSeed",
        "Ingredients": [{"Name": "PumpkinItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "PumpkinSeedItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Taro Seed Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "TaroSeed",
        "Ingredients": [{"Name": "TaroRootItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "TaroSeedItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Tomato Seed Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "TomatoSeed",
        "Ingredients": [{"Name": "TomatoItem", "Value": {"Value": 2.0, "IsDynamic": true}}],
        "Products": [{"Name": "TomatoSeedItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Wheat Seed Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "WheatSeed",
        "Ingredients": [{"Name": "WheatItem", "Value": {"Value": 1.0, "IsDynamic": true}}],
        "Products": [{"Name": "WheatSeedItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Advanced Baking Skill Book Recipe",
    "LaborInCalories": {"Value": 10000.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 30.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "AdvancedBaking",
        "Ingredients": [{
            "Name": "CulinaryResearchPaperAdvancedItem",
            "Value": {"Value": 20.0, "IsDynamic": false}
        }, {
            "Name": "DendrologyResearchPaperModernItem",
            "Value": {"Value": 10.0, "IsDynamic": false}
        }, {
            "Name": "GeologyResearchPaperModernItem",
            "Value": {"Value": 10.0, "IsDynamic": false}
        }, {"Name": "Basic Research", "Value": {"Value": 30.0, "IsDynamic": false}}, {
            "Name": "Advanced Research",
            "Value": {"Value": 10.0, "IsDynamic": false}
        }, {"Name": "Modern Research", "Value": {"Value": 10.0, "IsDynamic": false}}],
        "Products": [{"Name": "AdvancedBakingSkillBook", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Advanced Cooking Skill Book Recipe",
    "LaborInCalories": {"Value": 10000.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 30.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "AdvancedCooking",
        "Ingredients": [{
            "Name": "CulinaryResearchPaperAdvancedItem",
            "Value": {"Value": 20.0, "IsDynamic": false}
        }, {
            "Name": "DendrologyResearchPaperModernItem",
            "Value": {"Value": 10.0, "IsDynamic": false}
        }, {
            "Name": "GeologyResearchPaperModernItem",
            "Value": {"Value": 10.0, "IsDynamic": false}
        }, {"Name": "Basic Research", "Value": {"Value": 30.0, "IsDynamic": false}}, {
            "Name": "Advanced Research",
            "Value": {"Value": 10.0, "IsDynamic": false}
        }, {"Name": "Modern Research", "Value": {"Value": 10.0, "IsDynamic": false}}],
        "Products": [{"Name": "AdvancedCookingSkillBook", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Advanced Masonry Skill Book Recipe",
    "LaborInCalories": {"Value": 10000.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 30.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "AdvancedMasonry",
        "Ingredients": [{
            "Name": "GeologyResearchPaperAdvancedItem",
            "Value": {"Value": 20.0, "IsDynamic": false}
        }, {
            "Name": "GeologyResearchPaperModernItem",
            "Value": {"Value": 10.0, "IsDynamic": false}
        }, {
            "Name": "MetallurgyResearchPaperModernItem",
            "Value": {"Value": 10.0, "IsDynamic": false}
        }, {
            "Name": "EngineeringResearchPaperModernItem",
            "Value": {"Value": 10.0, "IsDynamic": false}
        }, {"Name": "Basic Research", "Value": {"Value": 30.0, "IsDynamic": false}}, {
            "Name": "Advanced Research",
            "Value": {"Value": 10.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "AdvancedMasonrySkillBook", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Advanced Smelting Skill Book Recipe",
    "LaborInCalories": {"Value": 10000.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 30.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "AdvancedSmelting",
        "Ingredients": [{
            "Name": "MetallurgyResearchPaperBasicItem",
            "Value": {"Value": 20.0, "IsDynamic": false}
        }, {
            "Name": "MetallurgyResearchPaperAdvancedItem",
            "Value": {"Value": 20.0, "IsDynamic": false}
        }, {"Name": "Basic Research", "Value": {"Value": 10.0, "IsDynamic": false}}],
        "Products": [{"Name": "AdvancedSmeltingSkillBook", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Baking Skill Book Recipe",
    "LaborInCalories": {"Value": 4000.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 15.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "Baking",
        "Ingredients": [{
            "Name": "CulinaryResearchPaperBasicItem",
            "Value": {"Value": 10.0, "IsDynamic": false}
        }, {
            "Name": "MetallurgyResearchPaperBasicItem",
            "Value": {"Value": 5.0, "IsDynamic": false}
        }, {"Name": "Basic Research", "Value": {"Value": 10.0, "IsDynamic": false}}],
        "Products": [{"Name": "BakingSkillBook", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Basic Engineering Skill Book Recipe",
    "LaborInCalories": {"Value": 1000.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 5.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "BasicEngineering",
        "Ingredients": [{
            "Name": "DendrologyResearchPaperAdvancedItem",
            "Value": {"Value": 3.0, "IsDynamic": false}
        }, {"Name": "Basic Research", "Value": {"Value": 5.0, "IsDynamic": false}}],
        "Products": [{"Name": "BasicEngineeringSkillBook", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Butchery Skill Book Recipe",
    "LaborInCalories": {"Value": 1000.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 5.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "Butchery",
        "Ingredients": [{"Name": "CulinaryResearchPaperBasicItem", "Value": {"Value": 3.0, "IsDynamic": false}}],
        "Products": [{"Name": "ButcherySkillBook", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Carpentry Skill Book Recipe",
    "LaborInCalories": {"Value": 500.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 5.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "Carpentry",
        "Ingredients": [{"Name": "DendrologyResearchPaperBasicItem", "Value": {"Value": 3.0, "IsDynamic": false}}],
        "Products": [{"Name": "CarpentrySkillBook", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Composites Skill Book Recipe",
    "LaborInCalories": {"Value": 10000.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 30.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "Composites",
        "Ingredients": [{
            "Name": "DendrologyResearchPaperAdvancedItem",
            "Value": {"Value": 20.0, "IsDynamic": false}
        }, {
            "Name": "DendrologyResearchPaperModernItem",
            "Value": {"Value": 10.0, "IsDynamic": false}
        }, {
            "Name": "MetallurgyResearchPaperModernItem",
            "Value": {"Value": 10.0, "IsDynamic": false}
        }, {
            "Name": "EngineeringResearchPaperModernItem",
            "Value": {"Value": 10.0, "IsDynamic": false}
        }, {"Name": "Basic Research", "Value": {"Value": 30.0, "IsDynamic": false}}, {
            "Name": "Advanced Research",
            "Value": {"Value": 10.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "CompositesSkillBook", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Cooking Skill Book Recipe",
    "LaborInCalories": {"Value": 4000.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 15.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "Cooking",
        "Ingredients": [{
            "Name": "CulinaryResearchPaperBasicItem",
            "Value": {"Value": 10.0, "IsDynamic": false}
        }, {
            "Name": "MetallurgyResearchPaperBasicItem",
            "Value": {"Value": 5.0, "IsDynamic": false}
        }, {"Name": "Basic Research", "Value": {"Value": 10.0, "IsDynamic": false}}],
        "Products": [{"Name": "CookingSkillBook", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Cutting Edge Cooking Skill Book Recipe",
    "LaborInCalories": {"Value": 10000.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 60.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "CuttingEdgeCooking",
        "Ingredients": [{
            "Name": "CulinaryResearchPaperAdvancedItem",
            "Value": {"Value": 20.0, "IsDynamic": false}
        }, {
            "Name": "CulinaryResearchPaperModernItem",
            "Value": {"Value": 20.0, "IsDynamic": false}
        }, {
            "Name": "MetallurgyResearchPaperModernItem",
            "Value": {"Value": 10.0, "IsDynamic": false}
        }, {
            "Name": "AgricultureResearchPaperModernItem",
            "Value": {"Value": 10.0, "IsDynamic": false}
        }, {"Name": "Basic Research", "Value": {"Value": 30.0, "IsDynamic": false}}, {
            "Name": "Advanced Research",
            "Value": {"Value": 10.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "CuttingEdgeCookingSkillBook", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Electronics Skill Book Recipe",
    "LaborInCalories": {"Value": 10000.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 30.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "Electronics",
        "Ingredients": [{
            "Name": "MetallurgyResearchPaperAdvancedItem",
            "Value": {"Value": 10.0, "IsDynamic": false}
        }, {
            "Name": "EngineeringResearchPaperModernItem",
            "Value": {"Value": 10.0, "IsDynamic": false}
        }, {
            "Name": "MetallurgyResearchPaperModernItem",
            "Value": {"Value": 20.0, "IsDynamic": false}
        }, {"Name": "Basic Research", "Value": {"Value": 30.0, "IsDynamic": false}}, {
            "Name": "Advanced Research",
            "Value": {"Value": 20.0, "IsDynamic": false}
        }, {"Name": "Modern Research", "Value": {"Value": 20.0, "IsDynamic": false}}],
        "Products": [{"Name": "ElectronicsSkillBook", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Farming Skill Book Recipe",
    "LaborInCalories": {"Value": 500.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 5.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "Farming",
        "Ingredients": [{
            "Name": "GatheringResearchPaperBasicItem",
            "Value": {"Value": 2.0, "IsDynamic": false}
        }, {"Name": "GeologyResearchPaperBasicItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "FarmingSkillBook", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Fertilizers Skill Book Recipe",
    "LaborInCalories": {"Value": 1000.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 5.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "Fertilizers",
        "Ingredients": [{
            "Name": "AgricultureResearchPaperAdvancedItem",
            "Value": {"Value": 3.0, "IsDynamic": false}
        }, {"Name": "Basic Research", "Value": {"Value": 5.0, "IsDynamic": false}}],
        "Products": [{"Name": "FertilizersSkillBook", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Glassworking Skill Book Recipe",
    "LaborInCalories": {"Value": 4000.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 15.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "Glassworking",
        "Ingredients": [{
            "Name": "GeologyResearchPaperBasicItem",
            "Value": {"Value": 10.0, "IsDynamic": false}
        }, {
            "Name": "GeologyResearchPaperAdvancedItem",
            "Value": {"Value": 5.0, "IsDynamic": false}
        }, {
            "Name": "EngineeringResearchPaperAdvancedItem",
            "Value": {"Value": 5.0, "IsDynamic": false}
        }, {"Name": "Basic Research", "Value": {"Value": 10.0, "IsDynamic": false}}],
        "Products": [{"Name": "GlassworkingSkillBook", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Industry Skill Book Recipe",
    "LaborInCalories": {"Value": 10000.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 30.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "Industry",
        "Ingredients": [{
            "Name": "EngineeringResearchPaperAdvancedItem",
            "Value": {"Value": 10.0, "IsDynamic": false}
        }, {
            "Name": "EngineeringResearchPaperModernItem",
            "Value": {"Value": 20.0, "IsDynamic": false}
        }, {
            "Name": "MetallurgyResearchPaperModernItem",
            "Value": {"Value": 20.0, "IsDynamic": false}
        }, {"Name": "Basic Research", "Value": {"Value": 30.0, "IsDynamic": false}}, {
            "Name": "Advanced Research",
            "Value": {"Value": 20.0, "IsDynamic": false}
        }, {"Name": "Modern Research", "Value": {"Value": 10.0, "IsDynamic": false}}],
        "Products": [{"Name": "IndustrySkillBook", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Masonry Skill Book Recipe",
    "LaborInCalories": {"Value": 500.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 5.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "Masonry",
        "Ingredients": [{"Name": "GeologyResearchPaperBasicItem", "Value": {"Value": 3.0, "IsDynamic": false}}],
        "Products": [{"Name": "MasonrySkillBook", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Mechanics Skill Book Recipe",
    "LaborInCalories": {"Value": 10000.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 30.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "Mechanics",
        "Ingredients": [{
            "Name": "EngineeringResearchPaperAdvancedItem",
            "Value": {"Value": 10.0, "IsDynamic": false}
        }, {
            "Name": "MetallurgyResearchPaperAdvancedItem",
            "Value": {"Value": 10.0, "IsDynamic": false}
        }, {"Name": "Basic Research", "Value": {"Value": 20.0, "IsDynamic": false}}, {
            "Name": "Advanced Research",
            "Value": {"Value": 5.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "MechanicsSkillBook", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Milling Skill Book Recipe",
    "LaborInCalories": {"Value": 5000.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 15.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "Milling",
        "Ingredients": [{
            "Name": "DendrologyResearchPaperBasicItem",
            "Value": {"Value": 5.0, "IsDynamic": false}
        }, {
            "Name": "GeologyResearchPaperBasicItem",
            "Value": {"Value": 5.0, "IsDynamic": false}
        }, {
            "Name": "CulinaryResearchPaperBasicItem",
            "Value": {"Value": 5.0, "IsDynamic": false}
        }, {
            "Name": "EngineeringResearchPaperAdvancedItem",
            "Value": {"Value": 5.0, "IsDynamic": false}
        }, {"Name": "Basic Research", "Value": {"Value": 15.0, "IsDynamic": false}}],
        "Products": [{"Name": "MillingSkillBook", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Oil Drilling Skill Book Recipe",
    "LaborInCalories": {"Value": 10000.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 30.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "OilDrilling",
        "Ingredients": [{
            "Name": "AgricultureResearchPaperAdvancedItem",
            "Value": {"Value": 10.0, "IsDynamic": false}
        }, {
            "Name": "GeologyResearchPaperModernItem",
            "Value": {"Value": 10.0, "IsDynamic": false}
        }, {
            "Name": "DendrologyResearchPaperModernItem",
            "Value": {"Value": 10.0, "IsDynamic": false}
        }, {
            "Name": "EngineeringResearchPaperModernItem",
            "Value": {"Value": 10.0, "IsDynamic": false}
        }, {"Name": "Basic Research", "Value": {"Value": 30.0, "IsDynamic": false}}, {
            "Name": "Advanced Research",
            "Value": {"Value": 20.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "OilDrillingSkillBook", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Paper Milling Skill Book Recipe",
    "LaborInCalories": {"Value": 4000.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 15.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "PaperMilling",
        "Ingredients": [{
            "Name": "DendrologyResearchPaperAdvancedItem",
            "Value": {"Value": 5.0, "IsDynamic": false}
        }, {"Name": "Basic Research", "Value": {"Value": 5.0, "IsDynamic": false}}],
        "Products": [{"Name": "PaperMillingSkillBook", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Pottery Skill Book Recipe",
    "LaborInCalories": {"Value": 4000.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 15.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "Pottery",
        "Ingredients": [{
            "Name": "GeologyResearchPaperBasicItem",
            "Value": {"Value": 10.0, "IsDynamic": false}
        }, {
            "Name": "GeologyResearchPaperAdvancedItem",
            "Value": {"Value": 5.0, "IsDynamic": false}
        }, {
            "Name": "EngineeringResearchPaperAdvancedItem",
            "Value": {"Value": 5.0, "IsDynamic": false}
        }, {"Name": "Basic Research", "Value": {"Value": 10.0, "IsDynamic": false}}],
        "Products": [{"Name": "PotterySkillBook", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Smelting Skill Book Recipe",
    "LaborInCalories": {"Value": 4000.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 15.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "Smelting",
        "Ingredients": [{
            "Name": "MetallurgyResearchPaperBasicItem",
            "Value": {"Value": 10.0, "IsDynamic": false}
        }, {
            "Name": "DendrologyResearchPaperAdvancedItem",
            "Value": {"Value": 5.0, "IsDynamic": false}
        }, {
            "Name": "GeologyResearchPaperAdvancedItem",
            "Value": {"Value": 5.0, "IsDynamic": false}
        }, {"Name": "Basic Research", "Value": {"Value": 5.0, "IsDynamic": false}}],
        "Products": [{"Name": "SmeltingSkillBook", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Tailoring Skill Book Recipe",
    "LaborInCalories": {"Value": 1000.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 5.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "Tailoring",
        "Ingredients": [{
            "Name": "GatheringResearchPaperBasicItem",
            "Value": {"Value": 5.0, "IsDynamic": false}
        }, {"Name": "Basic Research", "Value": {"Value": 5.0, "IsDynamic": false}}],
        "Products": [{"Name": "TailoringSkillBook", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Chainsaw Recipe",
    "LaborInCalories": {"Value": 250.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "Chainsaw",
        "Ingredients": [{
            "Name": "FiberglassItem",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }, {"Name": "SteelBarItem", "Value": {"Value": 4.0, "IsDynamic": true}}, {
            "Name": "CombustionEngineItem",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "ChainsawItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Composite Bow Recipe",
    "LaborInCalories": {"Value": 250.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "CompositeBow",
        "Ingredients": [{
            "Name": "FiberglassItem",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }, {"Name": "SteelBarItem", "Value": {"Value": 15.0, "IsDynamic": true}}],
        "Products": [{"Name": "CompositeBowItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Iron Axe Recipe",
    "LaborInCalories": {"Value": 250.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "IronAxe",
        "Ingredients": [{"Name": "IronBarItem", "Value": {"Value": 4.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "IronAxeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Iron Hammer Recipe",
    "LaborInCalories": {"Value": 250.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "IronHammer",
        "Ingredients": [{"Name": "IronBarItem", "Value": {"Value": 4.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "IronHammerItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Iron Hoe Recipe",
    "LaborInCalories": {"Value": 250.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "IronHoe",
        "Ingredients": [{"Name": "IronBarItem", "Value": {"Value": 4.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "IronHoeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Iron Machete Recipe",
    "LaborInCalories": {"Value": 250.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "IronMachete",
        "Ingredients": [{"Name": "IronBarItem", "Value": {"Value": 4.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "IronMacheteItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Iron Pickaxe Recipe",
    "LaborInCalories": {"Value": 250.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "IronPickaxe",
        "Ingredients": [{"Name": "IronBarItem", "Value": {"Value": 4.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "IronPickaxeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Iron Rock Drill Recipe",
    "LaborInCalories": {"Value": 250.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "IronRockDrill",
        "Ingredients": [{"Name": "IronBarItem", "Value": {"Value": 4.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "IronRockDrillItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Iron Shovel Recipe",
    "LaborInCalories": {"Value": 250.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "IronShovel",
        "Ingredients": [{"Name": "IronBarItem", "Value": {"Value": 4.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "IronShovelItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Iron Sickle Recipe",
    "LaborInCalories": {"Value": 250.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "IronSickle",
        "Ingredients": [{"Name": "IronBarItem", "Value": {"Value": 4.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "IronSickleItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Modern Axe Recipe",
    "LaborInCalories": {"Value": 250.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "ModernAxe",
        "Ingredients": [{
            "Name": "FiberglassItem",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }, {"Name": "SteelBarItem", "Value": {"Value": 15.0, "IsDynamic": true}}],
        "Products": [{"Name": "ModernAxeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Modern Hammer Recipe",
    "LaborInCalories": {"Value": 250.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "ModernHammer",
        "Ingredients": [{
            "Name": "FiberglassItem",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }, {"Name": "SteelBarItem", "Value": {"Value": 15.0, "IsDynamic": true}}],
        "Products": [{"Name": "ModernHammerItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Modern Hoe Recipe",
    "LaborInCalories": {"Value": 250.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "ModernHoe",
        "Ingredients": [{
            "Name": "FiberglassItem",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }, {"Name": "SteelBarItem", "Value": {"Value": 15.0, "IsDynamic": true}}],
        "Products": [{"Name": "ModernHoeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Modern Machete Recipe",
    "LaborInCalories": {"Value": 250.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "ModernMachete",
        "Ingredients": [{
            "Name": "FiberglassItem",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }, {"Name": "SteelBarItem", "Value": {"Value": 15.0, "IsDynamic": true}}],
        "Products": [{"Name": "ModernMacheteItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Modern Pickaxe Recipe",
    "LaborInCalories": {"Value": 250.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "ModernPickaxe",
        "Ingredients": [{
            "Name": "FiberglassItem",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }, {"Name": "SteelBarItem", "Value": {"Value": 15.0, "IsDynamic": true}}],
        "Products": [{"Name": "ModernPickaxeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Modern Rock Drill Recipe",
    "LaborInCalories": {"Value": 250.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "ModernRockDrill",
        "Ingredients": [{"Name": "SteelBarItem", "Value": {"Value": 2.0, "IsDynamic": true}}, {
            "Name": "SteelGearItem",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "CombustionEngineItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "ModernRockDrillItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Modern Scythe Recipe",
    "LaborInCalories": {"Value": 250.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "ModernScythe",
        "Ingredients": [{
            "Name": "FiberglassItem",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }, {"Name": "SteelBarItem", "Value": {"Value": 15.0, "IsDynamic": true}}],
        "Products": [{"Name": "ModernScytheItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Modern Shovel Recipe",
    "LaborInCalories": {"Value": 250.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "ModernShovel",
        "Ingredients": [{
            "Name": "FiberglassItem",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }, {"Name": "SteelBarItem", "Value": {"Value": 15.0, "IsDynamic": true}}],
        "Products": [{"Name": "ModernShovelItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Recurve Bow Recipe",
    "LaborInCalories": {"Value": 250.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "RecurveBow",
        "Ingredients": [{
            "Name": "SteelBarItem",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }, {"Name": "CelluloseFiberItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "RecurveBowItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Steel Axe Recipe",
    "LaborInCalories": {"Value": 250.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "SteelAxe",
        "Ingredients": [{"Name": "SteelBarItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "SteelAxeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Steel Hammer Recipe",
    "LaborInCalories": {"Value": 250.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "SteelHammer",
        "Ingredients": [{"Name": "SteelBarItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "SteelHammerItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Steel Hoe Recipe",
    "LaborInCalories": {"Value": 250.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "SteelHoe",
        "Ingredients": [{"Name": "SteelBarItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "SteelHoeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Steel Machete Recipe",
    "LaborInCalories": {"Value": 250.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "SteelMachete",
        "Ingredients": [{"Name": "SteelBarItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "SteelMacheteItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Steel Pickaxe Recipe",
    "LaborInCalories": {"Value": 250.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "SteelPickaxe",
        "Ingredients": [{"Name": "SteelBarItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "SteelPickaxeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Steel Road Tool Recipe",
    "LaborInCalories": {"Value": 25.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "SteelRoadTool",
        "Ingredients": [{"Name": "SteelBarItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "SteelRoadToolItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Steel Shovel Recipe",
    "LaborInCalories": {"Value": 250.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "SteelShovel",
        "Ingredients": [{"Name": "SteelBarItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "SteelShovelItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Steel Sickle Recipe",
    "LaborInCalories": {"Value": 250.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "SteelSickle",
        "Ingredients": [{"Name": "SteelBarItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "SteelSickleItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Stone Axe Recipe",
    "LaborInCalories": {"Value": 10.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": false},
    "Recipes": [{
        "Name": "StoneAxe",
        "Ingredients": [{"Name": "Wood", "Value": {"Value": 4.0, "IsDynamic": false}}, {
            "Name": "Rock",
            "Value": {"Value": 10.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "StoneAxeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Stone Hammer Recipe",
    "LaborInCalories": {"Value": 10.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": false},
    "Recipes": [{
        "Name": "StoneHammer",
        "Ingredients": [{"Name": "Wood", "Value": {"Value": 4.0, "IsDynamic": false}}, {
            "Name": "Rock",
            "Value": {"Value": 10.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "StoneHammerItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Stone Machete Recipe",
    "LaborInCalories": {"Value": 10.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": false},
    "Recipes": [{
        "Name": "StoneMachete",
        "Ingredients": [{"Name": "Wood", "Value": {"Value": 4.0, "IsDynamic": false}}, {
            "Name": "Rock",
            "Value": {"Value": 10.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "StoneMacheteItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Stone Pickaxe Recipe",
    "LaborInCalories": {"Value": 10.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": false},
    "Recipes": [{
        "Name": "StonePickaxe",
        "Ingredients": [{"Name": "Wood", "Value": {"Value": 4.0, "IsDynamic": false}}, {
            "Name": "Rock",
            "Value": {"Value": 10.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "StonePickaxeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Stone Road Tool Recipe",
    "LaborInCalories": {"Value": 10.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": true},
    "Recipes": [{
        "Name": "StoneRoadTool",
        "Ingredients": [{"Name": "Wood", "Value": {"Value": 4.0, "IsDynamic": true}}, {
            "Name": "Rock",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "StoneRoadToolItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Stone Sickle Recipe",
    "LaborInCalories": {"Value": 10.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": false},
    "Recipes": [{
        "Name": "StoneSickle",
        "Ingredients": [{"Name": "Wood", "Value": {"Value": 4.0, "IsDynamic": false}}, {
            "Name": "Rock",
            "Value": {"Value": 10.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "StoneSickleItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Wooden Bow Recipe",
    "LaborInCalories": {"Value": 10.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": false},
    "Recipes": [{
        "Name": "WoodenBow",
        "Ingredients": [{"Name": "PlantFibersItem", "Value": {"Value": 30.0, "IsDynamic": false}}, {
            "Name": "Wood",
            "Value": {"Value": 16.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "WoodenBowItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Wooden Hoe Recipe",
    "LaborInCalories": {"Value": 10.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": false},
    "Recipes": [{
        "Name": "WoodenHoe",
        "Ingredients": [{"Name": "Wood", "Value": {"Value": 10.0, "IsDynamic": false}}],
        "Products": [{"Name": "WoodenHoeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Wooden Shovel Recipe",
    "LaborInCalories": {"Value": 10.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": false},
    "Recipes": [{
        "Name": "WoodenShovel",
        "Ingredients": [{"Name": "Wood", "Value": {"Value": 10.0, "IsDynamic": false}}],
        "Products": [{"Name": "WoodenShovelItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Crane Recipe",
    "LaborInCalories": {"Value": 3000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Crane",
        "Ingredients": [{
            "Name": "GearboxItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "CelluloseFiberItem", "Value": {"Value": 8.0, "IsDynamic": true}}, {
            "Name": "SteelPlateItem",
            "Value": {"Value": 16.0, "IsDynamic": true}
        }, {
            "Name": "AdvancedCombustionEngineItem",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }, {"Name": "RubberWheelItem", "Value": {"Value": 4.0, "IsDynamic": false}}, {
            "Name": "RadiatorItem",
            "Value": {"Value": 2.0, "IsDynamic": false}
        }, {"Name": "SteelAxleItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "CraneItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Excavator Recipe",
    "LaborInCalories": {"Value": 3000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 20.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Excavator",
        "Ingredients": [{
            "Name": "GearboxItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "CelluloseFiberItem", "Value": {"Value": 8.0, "IsDynamic": true}}, {
            "Name": "SteelPlateItem",
            "Value": {"Value": 16.0, "IsDynamic": true}
        }, {
            "Name": "AdvancedCombustionEngineItem",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }, {"Name": "RubberWheelItem", "Value": {"Value": 4.0, "IsDynamic": false}}, {
            "Name": "RadiatorItem",
            "Value": {"Value": 2.0, "IsDynamic": false}
        }, {"Name": "SteelAxleItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "ExcavatorItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Hand Plow Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "HandPlow",
        "Ingredients": [{"Name": "IronBarItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "HewnLog",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }, {"Name": "WoodBoard", "Value": {"Value": 50.0, "IsDynamic": true}}, {
            "Name": "WoodenWheelItem",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "HandPlowItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Powered Cart Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "PoweredCart",
        "Ingredients": [{"Name": "ClothItem", "Value": {"Value": 20.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 30.0, "IsDynamic": true}
        }, {"Name": "CastIronStoveItem", "Value": {"Value": 1.0, "IsDynamic": false}}, {
            "Name": "IronWheelItem",
            "Value": {"Value": 3.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "PoweredCartItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Skid Steer Recipe",
    "LaborInCalories": {"Value": 2500.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "SkidSteer",
        "Ingredients": [{
            "Name": "GearboxItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "CelluloseFiberItem", "Value": {"Value": 8.0, "IsDynamic": true}}, {
            "Name": "SteelPlateItem",
            "Value": {"Value": 16.0, "IsDynamic": true}
        }, {
            "Name": "AdvancedCombustionEngineItem",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }, {"Name": "RubberWheelItem", "Value": {"Value": 4.0, "IsDynamic": false}}, {
            "Name": "RadiatorItem",
            "Value": {"Value": 2.0, "IsDynamic": false}
        }, {"Name": "SteelAxleItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "SkidSteerItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Small Wood Cart Recipe",
    "LaborInCalories": {"Value": 50.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "SmallWoodCart",
        "Ingredients": [{"Name": "HewnLog", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 15.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "SmallWoodCartItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Steam Tractor Recipe",
    "LaborInCalories": {"Value": 1000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "SteamTractor",
        "Ingredients": [{"Name": "IronPlateItem", "Value": {"Value": 12.0, "IsDynamic": true}}, {
            "Name": "IronPipeItem",
            "Value": {"Value": 8.0, "IsDynamic": true}
        }, {"Name": "ScrewsItem", "Value": {"Value": 24.0, "IsDynamic": true}}, {
            "Name": "LeatherHideItem",
            "Value": {"Value": 20.0, "IsDynamic": true}
        }, {"Name": "Lumber", "Value": {"Value": 30.0, "IsDynamic": true}}, {
            "Name": "PortableSteamEngineItem",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }, {"Name": "IronWheelItem", "Value": {"Value": 4.0, "IsDynamic": false}}, {
            "Name": "IronAxleItem",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "SteamTractorItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Steam Truck Recipe",
    "LaborInCalories": {"Value": 1000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "SteamTruck",
        "Ingredients": [{"Name": "IronPlateItem", "Value": {"Value": 12.0, "IsDynamic": true}}, {
            "Name": "IronPipeItem",
            "Value": {"Value": 8.0, "IsDynamic": true}
        }, {"Name": "ScrewsItem", "Value": {"Value": 24.0, "IsDynamic": true}}, {
            "Name": "LeatherHideItem",
            "Value": {"Value": 20.0, "IsDynamic": true}
        }, {"Name": "Lumber", "Value": {"Value": 30.0, "IsDynamic": true}}, {
            "Name": "PortableSteamEngineItem",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }, {"Name": "IronWheelItem", "Value": {"Value": 4.0, "IsDynamic": false}}, {
            "Name": "IronAxleItem",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "SteamTruckItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Truck Recipe",
    "LaborInCalories": {"Value": 2000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Truck",
        "Ingredients": [{
            "Name": "GearboxItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "CelluloseFiberItem", "Value": {"Value": 8.0, "IsDynamic": true}}, {
            "Name": "SteelPlateItem",
            "Value": {"Value": 16.0, "IsDynamic": true}
        }, {"Name": "CombustionEngineItem", "Value": {"Value": 1.0, "IsDynamic": false}}, {
            "Name": "RubberWheelItem",
            "Value": {"Value": 4.0, "IsDynamic": false}
        }, {"Name": "RadiatorItem", "Value": {"Value": 1.0, "IsDynamic": false}}, {
            "Name": "SteelAxleItem",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "TruckItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Wheelbarrow Recipe",
    "LaborInCalories": {"Value": 75.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Wheelbarrow",
        "Ingredients": [{"Name": "HewnLog", "Value": {"Value": 2.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "WoodenWheelItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "WheelbarrowItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Wood Cart Recipe",
    "LaborInCalories": {"Value": 75.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "WoodCart",
        "Ingredients": [{"Name": "HewnLog", "Value": {"Value": 4.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 8.0, "IsDynamic": true}
        }, {"Name": "WoodenWheelItem", "Value": {"Value": 2.0, "IsDynamic": false}}],
        "Products": [{"Name": "WoodCartItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Wooden Elevator Recipe",
    "LaborInCalories": {"Value": 500.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "WoodenElevator",
        "Ingredients": [{
            "Name": "GearboxItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "CelluloseFiberItem", "Value": {"Value": 4.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 30.0, "IsDynamic": true}
        }, {"Name": "PortableSteamEngineItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "WoodenElevatorItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Adorned Ashlar Stone Bench Recipe",
    "LaborInCalories": {"Value": 400.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 8.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "AdornedAshlarStoneBench",
        "Ingredients": [{"Name": "GoldBarItem", "Value": {"Value": 6.0, "IsDynamic": true}}, {
            "Name": "AshlarStone",
            "Value": {"Value": 20.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "AdornedAshlarStoneBenchItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AdornedAshlarBasaltBench",
        "Ingredients": [{
            "Name": "AshlarBasaltItem",
            "Value": {"Value": 20.0, "IsDynamic": true}
        }, {"Name": "GoldBarItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "AdornedAshlarBasaltBenchItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AdornedAshlarGneissBench",
        "Ingredients": [{
            "Name": "AshlarGneissItem",
            "Value": {"Value": 20.0, "IsDynamic": true}
        }, {"Name": "GoldBarItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "AdornedAshlarGneissBenchItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AdornedAshlarGraniteBench",
        "Ingredients": [{
            "Name": "AshlarGraniteItem",
            "Value": {"Value": 20.0, "IsDynamic": true}
        }, {"Name": "GoldBarItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "AdornedAshlarGraniteBenchItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AdornedAshlarLimestoneBench",
        "Ingredients": [{
            "Name": "AshlarLimestoneItem",
            "Value": {"Value": 20.0, "IsDynamic": true}
        }, {"Name": "GoldBarItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "AdornedAshlarLimestoneBenchItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AdornedAshlarSandstoneBench",
        "Ingredients": [{
            "Name": "AshlarSandstoneItem",
            "Value": {"Value": 20.0, "IsDynamic": true}
        }, {"Name": "GoldBarItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "AdornedAshlarSandstoneBenchItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AdornedAshlarShaleBench",
        "Ingredients": [{
            "Name": "AshlarShaleItem",
            "Value": {"Value": 20.0, "IsDynamic": true}
        }, {"Name": "GoldBarItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "AdornedAshlarShaleBenchItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Adorned Ashlar Stone Chair Recipe",
    "LaborInCalories": {"Value": 400.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 6.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "AdornedAshlarStoneChair",
        "Ingredients": [{"Name": "GoldBarItem", "Value": {"Value": 4.0, "IsDynamic": true}}, {
            "Name": "AshlarStone",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "AdornedAshlarStoneChairItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AdornedAshlarBasaltChair",
        "Ingredients": [{
            "Name": "AshlarBasaltItem",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }, {"Name": "GoldBarItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "AdornedAshlarBasaltChairItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AdornedAshlarGneissChair",
        "Ingredients": [{
            "Name": "AshlarGneissItem",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }, {"Name": "GoldBarItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "AdornedAshlarGneissChairItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AdornedAshlarGraniteChair",
        "Ingredients": [{
            "Name": "AshlarGraniteItem",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }, {"Name": "GoldBarItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "AdornedAshlarGraniteChairItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AdornedAshlarLimestoneChair",
        "Ingredients": [{
            "Name": "AshlarLimestoneItem",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }, {"Name": "GoldBarItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "AdornedAshlarLimestoneChairItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AdornedAshlarSandstoneChair",
        "Ingredients": [{
            "Name": "AshlarSandstoneItem",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }, {"Name": "GoldBarItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "AdornedAshlarSandstoneChairItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AdornedAshlarShaleChair",
        "Ingredients": [{
            "Name": "AshlarShaleItem",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }, {"Name": "GoldBarItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "AdornedAshlarShaleChairItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Adorned Ashlar Stone Table Recipe",
    "LaborInCalories": {"Value": 400.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 8.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "AdornedAshlarStoneTable",
        "Ingredients": [{"Name": "GoldBarItem", "Value": {"Value": 8.0, "IsDynamic": true}}, {
            "Name": "AshlarStone",
            "Value": {"Value": 24.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "AdornedAshlarStoneTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AdornedAshlarBasaltTable",
        "Ingredients": [{
            "Name": "AshlarBasaltItem",
            "Value": {"Value": 24.0, "IsDynamic": true}
        }, {"Name": "GoldBarItem", "Value": {"Value": 8.0, "IsDynamic": true}}],
        "Products": [{"Name": "AdornedAshlarBasaltTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AdornedAshlarGneissTable",
        "Ingredients": [{
            "Name": "AshlarGneissItem",
            "Value": {"Value": 24.0, "IsDynamic": true}
        }, {"Name": "GoldBarItem", "Value": {"Value": 8.0, "IsDynamic": true}}],
        "Products": [{"Name": "AdornedAshlarGneissTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AdornedAshlarGraniteTable",
        "Ingredients": [{
            "Name": "AshlarGraniteItem",
            "Value": {"Value": 24.0, "IsDynamic": true}
        }, {"Name": "GoldBarItem", "Value": {"Value": 8.0, "IsDynamic": true}}],
        "Products": [{"Name": "AdornedAshlarGraniteTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AdornedAshlarLimestoneTable",
        "Ingredients": [{
            "Name": "AshlarLimestoneItem",
            "Value": {"Value": 24.0, "IsDynamic": true}
        }, {"Name": "GoldBarItem", "Value": {"Value": 8.0, "IsDynamic": true}}],
        "Products": [{"Name": "AdornedAshlarLimestoneTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AdornedAshlarSandstoneTable",
        "Ingredients": [{
            "Name": "AshlarSandstoneItem",
            "Value": {"Value": 24.0, "IsDynamic": true}
        }, {"Name": "GoldBarItem", "Value": {"Value": 8.0, "IsDynamic": true}}],
        "Products": [{"Name": "AdornedAshlarSandstoneTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AdornedAshlarShaleTable",
        "Ingredients": [{
            "Name": "AshlarShaleItem",
            "Value": {"Value": 24.0, "IsDynamic": true}
        }, {"Name": "GoldBarItem", "Value": {"Value": 8.0, "IsDynamic": true}}],
        "Products": [{"Name": "AdornedAshlarShaleTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Advanced Carpentry Table Recipe",
    "LaborInCalories": {"Value": 1000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 15.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "AdvancedCarpentryTable",
        "Ingredients": [{
            "Name": "SteelPlateItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }, {"Name": "SteelSawBladeItem", "Value": {"Value": 6.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "AdvancedCarpentryTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Advanced Masonry Table Recipe",
    "LaborInCalories": {"Value": 1000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 15.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "AdvancedMasonryTable",
        "Ingredients": [{
            "Name": "SteelPlateItem",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }, {"Name": "SteelSawBladeItem", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "AdvancedMasonryTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Amendments Recipe",
    "LaborInCalories": {"Value": 2000.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 5.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "Amendments",
        "Ingredients": [{"Name": "HewnLog", "Value": {"Value": 30.0, "IsDynamic": false}}, {
            "Name": "MortaredStone",
            "Value": {"Value": 30.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "AmendmentsItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Anvil Recipe",
    "LaborInCalories": {"Value": 300.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 8.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Anvil",
        "Ingredients": [{"Name": "IronBarItem", "Value": {"Value": 12.0, "IsDynamic": true}}, {
            "Name": "HewnLog",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "AnvilItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Arrastra Recipe",
    "LaborInCalories": {"Value": 300.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 5.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Arrastra",
        "Ingredients": [{"Name": "Wood", "Value": {"Value": 5.0, "IsDynamic": true}}, {
            "Name": "Rock",
            "Value": {"Value": 30.0, "IsDynamic": true}
        }, {"Name": "WoodBoard", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "ArrastraItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Ashlar Large Stone Fountain Recipe",
    "LaborInCalories": {"Value": 300.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "AshlarLargeStoneFountain",
        "Ingredients": [{"Name": "AshlarStone", "Value": {"Value": 20.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarLargeStoneFountainItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AshlarLargeBasaltFountain",
        "Ingredients": [{"Name": "AshlarBasaltItem", "Value": {"Value": 20.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarLargeBasaltFountainItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AshlarLargeGneissFountain",
        "Ingredients": [{"Name": "AshlarGneissItem", "Value": {"Value": 20.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarLargeGneissFountainItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AshlarLargeGraniteFountain",
        "Ingredients": [{"Name": "AshlarGraniteItem", "Value": {"Value": 20.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarLargeGraniteFountainItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AshlarLargeLimestoneFountain",
        "Ingredients": [{"Name": "AshlarLimestoneItem", "Value": {"Value": 20.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarLargeLimestoneFountainItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AshlarLargeSandstoneFountain",
        "Ingredients": [{"Name": "AshlarSandstoneItem", "Value": {"Value": 20.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarLargeSandstoneFountainItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AshlarLargeShaleFountain",
        "Ingredients": [{"Name": "AshlarShaleItem", "Value": {"Value": 20.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarLargeShaleFountainItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Ashlar Small Stone Fountain Recipe",
    "LaborInCalories": {"Value": 300.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "AshlarSmallStoneFountain",
        "Ingredients": [{"Name": "AshlarStone", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarSmallStoneFountainItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AshlarSmallBasaltFountain",
        "Ingredients": [{"Name": "AshlarBasaltItem", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarSmallBasaltFountainItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AshlarSmallGneissFountain",
        "Ingredients": [{"Name": "AshlarGneissItem", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarSmallGneissFountainItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AshlarSmallGraniteFountain",
        "Ingredients": [{"Name": "AshlarGraniteItem", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarSmallGraniteFountainItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AshlarSmallLimestoneFountain",
        "Ingredients": [{"Name": "AshlarLimestoneItem", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarSmallLimestoneFountainItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AshlarSmallSandstoneFountain",
        "Ingredients": [{"Name": "AshlarSandstoneItem", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarSmallSandstoneFountainItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AshlarSmallShaleFountain",
        "Ingredients": [{"Name": "AshlarShaleItem", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarSmallShaleFountainItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Ashlar Stone Bench Recipe",
    "LaborInCalories": {"Value": 300.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 6.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "AshlarStoneBench",
        "Ingredients": [{"Name": "AshlarStone", "Value": {"Value": 12.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarStoneBenchItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AshlarBasaltBench",
        "Ingredients": [{"Name": "AshlarBasaltItem", "Value": {"Value": 12.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarBasaltBenchItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AshlarGneissBench",
        "Ingredients": [{"Name": "AshlarGneissItem", "Value": {"Value": 12.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarGneissBenchItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AshlarGraniteBench",
        "Ingredients": [{"Name": "AshlarGraniteItem", "Value": {"Value": 12.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarGraniteBenchItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AshlarLimestoneBench",
        "Ingredients": [{"Name": "AshlarLimestoneItem", "Value": {"Value": 12.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarLimestoneBenchItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AshlarSandstoneBench",
        "Ingredients": [{"Name": "AshlarSandstoneItem", "Value": {"Value": 12.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarSandstoneBenchItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AshlarShaleBench",
        "Ingredients": [{"Name": "AshlarShaleItem", "Value": {"Value": 12.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarShaleBenchItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Ashlar Stone Chair Recipe",
    "LaborInCalories": {"Value": 300.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "AshlarStoneChair",
        "Ingredients": [{"Name": "AshlarStone", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarStoneChairItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AshlarBasaltChair",
        "Ingredients": [{"Name": "AshlarBasaltItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarBasaltChairItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AshlarGneissChair",
        "Ingredients": [{"Name": "AshlarGneissItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarGneissChairItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AshlarGraniteChair",
        "Ingredients": [{"Name": "AshlarGraniteItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarGraniteChairItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AshlarLimestoneChair",
        "Ingredients": [{"Name": "AshlarLimestoneItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarLimestoneChairItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AshlarSandstoneChair",
        "Ingredients": [{"Name": "AshlarSandstoneItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarSandstoneChairItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AshlarShaleChair",
        "Ingredients": [{"Name": "AshlarShaleItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarShaleChairItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Ashlar Stone Door Recipe",
    "LaborInCalories": {"Value": 300.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 3.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "AshlarStoneDoor",
        "Ingredients": [{"Name": "AshlarStone", "Value": {"Value": 8.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarStoneDoorItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AshlarBasaltDoor",
        "Ingredients": [{"Name": "AshlarBasaltItem", "Value": {"Value": 8.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarBasaltDoorItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AshlarGneissDoor",
        "Ingredients": [{"Name": "AshlarGneissItem", "Value": {"Value": 8.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarGneissDoorItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AshlarGraniteDoor",
        "Ingredients": [{"Name": "AshlarGraniteItem", "Value": {"Value": 8.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarGraniteDoorItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AshlarLimestoneDoor",
        "Ingredients": [{"Name": "AshlarLimestoneItem", "Value": {"Value": 8.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarLimestoneDoorItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AshlarSandstoneDoor",
        "Ingredients": [{"Name": "AshlarSandstoneItem", "Value": {"Value": 8.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarSandstoneDoorItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AshlarShaleDoor",
        "Ingredients": [{"Name": "AshlarShaleItem", "Value": {"Value": 8.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarShaleDoorItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Ashlar Stone Fireplace Recipe",
    "LaborInCalories": {"Value": 300.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "AshlarStoneFireplace",
        "Ingredients": [{"Name": "AshlarStone", "Value": {"Value": 24.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarStoneFireplaceItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AshlarBasaltFireplace",
        "Ingredients": [{"Name": "AshlarBasaltItem", "Value": {"Value": 24.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarBasaltFireplaceItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AshlarGneissFireplace",
        "Ingredients": [{"Name": "AshlarGneissItem", "Value": {"Value": 24.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarGneissFireplaceItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AshlarGraniteFireplace",
        "Ingredients": [{"Name": "AshlarGraniteItem", "Value": {"Value": 24.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarGraniteFireplaceItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AshlarLimestoneFireplace",
        "Ingredients": [{"Name": "AshlarLimestoneItem", "Value": {"Value": 24.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarLimestoneFireplaceItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AshlarSandstoneFireplace",
        "Ingredients": [{"Name": "AshlarSandstoneItem", "Value": {"Value": 24.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarSandstoneFireplaceItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AshlarShaleFireplace",
        "Ingredients": [{"Name": "AshlarShaleItem", "Value": {"Value": 24.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarShaleFireplaceItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Ashlar Stone Table Recipe",
    "LaborInCalories": {"Value": 300.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 6.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "AshlarStoneTable",
        "Ingredients": [{"Name": "AshlarStone", "Value": {"Value": 16.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarStoneTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AshlarBasaltTable",
        "Ingredients": [{"Name": "AshlarBasaltItem", "Value": {"Value": 16.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarBasaltTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AshlarGneissTable",
        "Ingredients": [{"Name": "AshlarGneissItem", "Value": {"Value": 16.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarGneissTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AshlarGraniteTable",
        "Ingredients": [{"Name": "AshlarGraniteItem", "Value": {"Value": 16.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarGraniteTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AshlarLimestoneTable",
        "Ingredients": [{"Name": "AshlarLimestoneItem", "Value": {"Value": 16.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarLimestoneTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AshlarSandstoneTable",
        "Ingredients": [{"Name": "AshlarSandstoneItem", "Value": {"Value": 16.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarSandstoneTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "AshlarShaleTable",
        "Ingredients": [{"Name": "AshlarShaleItem", "Value": {"Value": 16.0, "IsDynamic": true}}],
        "Products": [{"Name": "AshlarShaleTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Assembly Line Recipe",
    "LaborInCalories": {"Value": 400.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 40.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "AssemblyLine",
        "Ingredients": [{"Name": "ScrewsItem", "Value": {"Value": 8.0, "IsDynamic": true}}, {
            "Name": "IronBarItem",
            "Value": {"Value": 8.0, "IsDynamic": true}
        }, {"Name": "IronGearItem", "Value": {"Value": 8.0, "IsDynamic": true}}, {
            "Name": "PortableSteamEngineItem",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "AssemblyLineItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Bakery Oven Recipe",
    "LaborInCalories": {"Value": 300.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 8.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "BakeryOven",
        "Ingredients": [{"Name": "BrickItem", "Value": {"Value": 20.0, "IsDynamic": true}}, {
            "Name": "IronBarItem",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "BakeryOvenItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Ballot Box Recipe",
    "LaborInCalories": {"Value": 2000.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 5.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "BallotBox",
        "Ingredients": [{"Name": "HewnLog", "Value": {"Value": 30.0, "IsDynamic": false}}, {
            "Name": "MortaredStone",
            "Value": {"Value": 30.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "BallotBoxItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Bank Recipe",
    "LaborInCalories": {"Value": 2500.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 15.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Bank",
        "Ingredients": [{"Name": "GoldBarItem", "Value": {"Value": 12.0, "IsDynamic": true}}, {
            "Name": "IronBarItem",
            "Value": {"Value": 16.0, "IsDynamic": true}
        }, {"Name": "BrickItem", "Value": {"Value": 16.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 16.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "BankItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Bathtub Recipe",
    "LaborInCalories": {"Value": 400.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 6.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Bathtub",
        "Ingredients": [{"Name": "ClayItem", "Value": {"Value": 40.0, "IsDynamic": true}}, {
            "Name": "IronBarItem",
            "Value": {"Value": 20.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "BathtubItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Bison Mount Recipe",
    "LaborInCalories": {"Value": 500.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "BisonMount",
        "Ingredients": [{"Name": "ClothItem", "Value": {"Value": 20.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }, {"Name": "BisonCarcassItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "BisonMountItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Blast Furnace Recipe",
    "LaborInCalories": {"Value": 1000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 25.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "BlastFurnace",
        "Ingredients": [{"Name": "ScrewsItem", "Value": {"Value": 12.0, "IsDynamic": true}}, {
            "Name": "BrickItem",
            "Value": {"Value": 8.0, "IsDynamic": true}
        }, {"Name": "IronPlateItem", "Value": {"Value": 12.0, "IsDynamic": true}}, {
            "Name": "IronPipeItem",
            "Value": {"Value": 8.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "BlastFurnaceItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Bloomery Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Bloomery",
        "Ingredients": [{"Name": "SandItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "CrushedRock",
            "Value": {"Value": 20.0, "IsDynamic": true}
        }, {"Name": "WoodBoard", "Value": {"Value": 25.0, "IsDynamic": true}}],
        "Products": [{"Name": "BloomeryItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Board Of Elections Recipe",
    "LaborInCalories": {"Value": 2000.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 5.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "BoardOfElections",
        "Ingredients": [{"Name": "HewnLog", "Value": {"Value": 30.0, "IsDynamic": false}}, {
            "Name": "MortaredStone",
            "Value": {"Value": 30.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "BoardOfElectionsItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Bookshelf Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Bookshelf",
        "Ingredients": [{"Name": "PaperItem", "Value": {"Value": 50.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 14.0, "IsDynamic": true}
        }, {"Name": "WoodBoard", "Value": {"Value": 16.0, "IsDynamic": true}}],
        "Products": [{"Name": "BookshelfItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Brazier Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Brazier",
        "Ingredients": [{"Name": "IronBarItem", "Value": {"Value": 4.0, "IsDynamic": true}}, {
            "Name": "TallowItem",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "BrazierItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Brick Fireplace Recipe",
    "LaborInCalories": {"Value": 500.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "BrickFireplace",
        "Ingredients": [{"Name": "BrickItem", "Value": {"Value": 50.0, "IsDynamic": true}}, {
            "Name": "IronBarItem",
            "Value": {"Value": 20.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "BrickFireplaceItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Butchery Table Recipe",
    "LaborInCalories": {"Value": 500.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "ButcheryTable",
        "Ingredients": [{"Name": "WoodBoard", "Value": {"Value": 20.0, "IsDynamic": true}}, {
            "Name": "Wood",
            "Value": {"Value": 15.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "ButcheryTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Campfire Recipe",
    "LaborInCalories": {"Value": 35.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "Campfire",
        "Ingredients": [{"Name": "Wood", "Value": {"Value": 3.0, "IsDynamic": false}}, {
            "Name": "Rock",
            "Value": {"Value": 12.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "CampfireItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Campsite Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 6.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Campsite",
        "Ingredients": [{"Name": "ClothItem", "Value": {"Value": 24.0, "IsDynamic": true}}, {
            "Name": "Wood",
            "Value": {"Value": 20.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "CampsiteItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Candle Stand Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CandleStand",
        "Ingredients": [{"Name": "IronBarItem", "Value": {"Value": 3.0, "IsDynamic": true}}, {
            "Name": "TallowItem",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "CandleStandItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Capitol Recipe",
    "LaborInCalories": {"Value": 2000.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 5.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "Capitol",
        "Ingredients": [{"Name": "HewnLog", "Value": {"Value": 30.0, "IsDynamic": false}}],
        "Products": [{"Name": "CapitolItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Carpentry Table Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "CarpentryTable",
        "Ingredients": [{"Name": "Wood", "Value": {"Value": 20.0, "IsDynamic": false}}, {
            "Name": "Rock",
            "Value": {"Value": 20.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "CarpentryTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Carved Pumpkin Recipe",
    "LaborInCalories": {"Value": 50.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 3.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "CarvedPumpkin",
        "Ingredients": [{
            "Name": "TallowCandleItem",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }, {"Name": "PumpkinItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "CarvedPumpkinItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Cast Iron Stove Recipe",
    "LaborInCalories": {"Value": 500.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 6.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CastIronStove",
        "Ingredients": [{"Name": "IronBarItem", "Value": {"Value": 8.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 8.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "CastIronStoveItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Ceiling Candle Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CeilingCandle",
        "Ingredients": [{"Name": "IronBarItem", "Value": {"Value": 3.0, "IsDynamic": true}}, {
            "Name": "TallowItem",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "CeilingCandleItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Cement Kiln Recipe",
    "LaborInCalories": {"Value": 1000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 100.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CementKiln",
        "Ingredients": [{"Name": "GearboxItem", "Value": {"Value": 8.0, "IsDynamic": true}}, {
            "Name": "PistonItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "IronBarItem", "Value": {"Value": 24.0, "IsDynamic": true}}],
        "Products": [{"Name": "CementKilnItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Census Bureau Recipe",
    "LaborInCalories": {"Value": 2000.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 5.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "CensusBureau",
        "Ingredients": [{"Name": "HewnLog", "Value": {"Value": 30.0, "IsDynamic": false}}, {
            "Name": "MortaredStone",
            "Value": {"Value": 30.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "CensusBureauItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Combustion Generator Recipe",
    "LaborInCalories": {"Value": 1000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 15.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CombustionGenerator",
        "Ingredients": [{"Name": "PistonItem", "Value": {"Value": 4.0, "IsDynamic": true}}, {
            "Name": "IronBarItem",
            "Value": {"Value": 12.0, "IsDynamic": true}
        }, {"Name": "CombustionEngineItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "CombustionGeneratorItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Composite Lumber Bench Recipe",
    "LaborInCalories": {"Value": 300.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CompositeLumberBench",
        "Ingredients": [{"Name": "ScrewsItem", "Value": {"Value": 6.0, "IsDynamic": true}}, {
            "Name": "CompositeLumber",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "CompositeLumberBenchItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeBirchBench",
        "Ingredients": [{
            "Name": "CompositeBirchLumberItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }, {"Name": "ScrewsItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "CompositeBirchBenchItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeCedarBench",
        "Ingredients": [{
            "Name": "CompositeCedarLumberItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }, {"Name": "ScrewsItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "CompositeCedarBenchItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeCeibaBench",
        "Ingredients": [{
            "Name": "CompositeCeibaLumberItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }, {"Name": "ScrewsItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "CompositeCeibaBenchItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeFirBench",
        "Ingredients": [{
            "Name": "CompositeFirLumberItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }, {"Name": "ScrewsItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "CompositeFirBenchItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeHardwoodBench",
        "Ingredients": [{"Name": "ScrewsItem", "Value": {"Value": 6.0, "IsDynamic": true}}, {
            "Name": "HardwoodLumber",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "CompositeHardwoodBenchItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeJoshuaBench",
        "Ingredients": [{
            "Name": "CompositeJoshuaLumberItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }, {"Name": "ScrewsItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "CompositeJoshuaBenchItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeOakBench",
        "Ingredients": [{
            "Name": "CompositeOakLumberItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }, {"Name": "ScrewsItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "CompositeOakBenchItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositePalmBench",
        "Ingredients": [{
            "Name": "CompositePalmLumberItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }, {"Name": "ScrewsItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "CompositePalmBenchItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeRedwoodBench",
        "Ingredients": [{
            "Name": "CompositeRedwoodLumberItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }, {"Name": "ScrewsItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "CompositeRedwoodBenchItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeSaguaroBench",
        "Ingredients": [{
            "Name": "CompositeSaguaroLumberItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }, {"Name": "ScrewsItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "CompositeSaguaroBenchItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeSoftwoodBench",
        "Ingredients": [{"Name": "ScrewsItem", "Value": {"Value": 6.0, "IsDynamic": true}}, {
            "Name": "SoftwoodLumber",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "CompositeSoftwoodBenchItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeSpruceBench",
        "Ingredients": [{
            "Name": "CompositeSpruceLumberItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }, {"Name": "ScrewsItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "CompositeSpruceBenchItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Composite Lumber Chair Recipe",
    "LaborInCalories": {"Value": 300.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 3.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CompositeLumberChair",
        "Ingredients": [{"Name": "ScrewsItem", "Value": {"Value": 6.0, "IsDynamic": true}}, {
            "Name": "CompositeLumber",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "CompositeLumberChairItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeBirchChair",
        "Ingredients": [{
            "Name": "CompositeBirchLumberItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "ScrewsItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "CompositeBirchChairItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeCedarChair",
        "Ingredients": [{
            "Name": "CompositeCedarLumberItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "ScrewsItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "CompositeCedarChairItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeCeibaChair",
        "Ingredients": [{
            "Name": "CompositeCeibaLumberItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "ScrewsItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "CompositeCeibaChairItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeFirChair",
        "Ingredients": [{
            "Name": "CompositeFirLumberItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "ScrewsItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "CompositeFirChairItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeHardwoodChair",
        "Ingredients": [{"Name": "ScrewsItem", "Value": {"Value": 6.0, "IsDynamic": true}}, {
            "Name": "HardwoodLumber",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "CompositeHardwoodChairItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeJoshuaChair",
        "Ingredients": [{
            "Name": "CompositeJoshuaLumberItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "ScrewsItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "CompositeJoshuaChairItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeOakChair",
        "Ingredients": [{
            "Name": "CompositeOakLumberItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "ScrewsItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "CompositeOakChairItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositePalmChair",
        "Ingredients": [{
            "Name": "CompositePalmLumberItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "ScrewsItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "CompositePalmChairItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeRedwoodChair",
        "Ingredients": [{
            "Name": "CompositeRedwoodLumberItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "ScrewsItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "CompositeRedwoodChairItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeSaguaroChair",
        "Ingredients": [{
            "Name": "CompositeSaguaroLumberItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "ScrewsItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "CompositeSaguaroChairItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeSoftwoodChair",
        "Ingredients": [{"Name": "ScrewsItem", "Value": {"Value": 6.0, "IsDynamic": true}}, {
            "Name": "SoftwoodLumber",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "CompositeSoftwoodChairItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeSpruceChair",
        "Ingredients": [{
            "Name": "CompositeSpruceLumberItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "ScrewsItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "CompositeSpruceChairItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Composite Lumber Door Recipe",
    "LaborInCalories": {"Value": 300.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CompositeLumberDoor",
        "Ingredients": [{"Name": "ScrewsItem", "Value": {"Value": 4.0, "IsDynamic": true}}, {
            "Name": "CompositeLumber",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "CompositeLumberDoorItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeBirchDoor",
        "Ingredients": [{
            "Name": "CompositeBirchLumberItem",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "ScrewsItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "CompositeBirchDoorItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeCedarDoor",
        "Ingredients": [{
            "Name": "CompositeCedarLumberItem",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "ScrewsItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "CompositeCedarDoorItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeCeibaDoor",
        "Ingredients": [{
            "Name": "CompositeCeibaLumberItem",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "ScrewsItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "CompositeCeibaDoorItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeFirDoor",
        "Ingredients": [{
            "Name": "CompositeFirLumberItem",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "ScrewsItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "CompositeFirDoorItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeHardwoodDoor",
        "Ingredients": [{"Name": "ScrewsItem", "Value": {"Value": 4.0, "IsDynamic": true}}, {
            "Name": "HardwoodLumber",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "CompositeHardwoodDoorItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeJoshuaDoor",
        "Ingredients": [{
            "Name": "CompositeJoshuaLumberItem",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "ScrewsItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "CompositeJoshuaDoorItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeOakDoor",
        "Ingredients": [{
            "Name": "CompositeOakLumberItem",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "ScrewsItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "CompositeOakDoorItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositePalmDoor",
        "Ingredients": [{
            "Name": "CompositePalmLumberItem",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "ScrewsItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "CompositePalmDoorItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeRedwoodDoor",
        "Ingredients": [{
            "Name": "CompositeRedwoodLumberItem",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "ScrewsItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "CompositeRedwoodDoorItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeSaguaroDoor",
        "Ingredients": [{
            "Name": "CompositeSaguaroLumberItem",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "ScrewsItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "CompositeSaguaroDoorItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeSoftwoodDoor",
        "Ingredients": [{"Name": "ScrewsItem", "Value": {"Value": 4.0, "IsDynamic": true}}, {
            "Name": "SoftwoodLumber",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "CompositeSoftwoodDoorItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeSpruceDoor",
        "Ingredients": [{
            "Name": "CompositeSpruceLumberItem",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "ScrewsItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "CompositeSpruceDoorItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Composite Lumber Table Recipe",
    "LaborInCalories": {"Value": 300.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CompositeLumberTable",
        "Ingredients": [{"Name": "ScrewsItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "CompositeLumber",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "CompositeLumberTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeBirchTable",
        "Ingredients": [{
            "Name": "CompositeBirchLumberItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }, {"Name": "ScrewsItem", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "CompositeBirchTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeCedarTable",
        "Ingredients": [{
            "Name": "CompositeCedarLumberItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }, {"Name": "ScrewsItem", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "CompositeCedarTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeCeibaTable",
        "Ingredients": [{
            "Name": "CompositeCeibaLumberItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }, {"Name": "ScrewsItem", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "CompositeCeibaTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeFirTable",
        "Ingredients": [{
            "Name": "CompositeFirLumberItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }, {"Name": "ScrewsItem", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "CompositeFirTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeHardwoodTable",
        "Ingredients": [{"Name": "ScrewsItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "HardwoodLumber",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "CompositeHardwoodTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeJoshuaTable",
        "Ingredients": [{
            "Name": "CompositeJoshuaLumberItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }, {"Name": "ScrewsItem", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "CompositeJoshuaTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeOakTable",
        "Ingredients": [{
            "Name": "CompositeOakLumberItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }, {"Name": "ScrewsItem", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "CompositeOakTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositePalmTable",
        "Ingredients": [{
            "Name": "CompositePalmLumberItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }, {"Name": "ScrewsItem", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "CompositePalmTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeRedwoodTable",
        "Ingredients": [{
            "Name": "CompositeRedwoodLumberItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }, {"Name": "ScrewsItem", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "CompositeRedwoodTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeSaguaroTable",
        "Ingredients": [{
            "Name": "CompositeSaguaroLumberItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }, {"Name": "ScrewsItem", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "CompositeSaguaroTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeSoftwoodTable",
        "Ingredients": [{"Name": "ScrewsItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "SoftwoodLumber",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "CompositeSoftwoodTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "CompositeSpruceTable",
        "Ingredients": [{
            "Name": "CompositeSpruceLumberItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }, {"Name": "ScrewsItem", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "CompositeSpruceTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Computer Lab Recipe",
    "LaborInCalories": {"Value": 5000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 120.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "ComputerLab",
        "Ingredients": [{
            "Name": "ReinforcedConcreteItem",
            "Value": {"Value": 100.0, "IsDynamic": true}
        }, {"Name": "PlasticItem", "Value": {"Value": 100.0, "IsDynamic": true}}, {
            "Name": "AdvancedCircuitItem",
            "Value": {"Value": 50.0, "IsDynamic": true}
        }, {"Name": "CompositeLumber", "Value": {"Value": 100.0, "IsDynamic": true}}],
        "Products": [{"Name": "ComputerLabItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Construction Post Recipe",
    "LaborInCalories": {"Value": 15.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "ConstructionPost",
        "Ingredients": [{"Name": "Wood", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "ConstructionPostItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Contract Board Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 6.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "ContractBoard",
        "Ingredients": [{"Name": "HewnLog", "Value": {"Value": 15.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 12.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "ContractBoardItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Couch Recipe",
    "LaborInCalories": {"Value": 160.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Couch",
        "Ingredients": [{"Name": "ClothItem", "Value": {"Value": 15.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }, {"Name": "WoodBoard", "Value": {"Value": 15.0, "IsDynamic": true}}],
        "Products": [{"Name": "CouchItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Court Recipe",
    "LaborInCalories": {"Value": 2000.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 5.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "Court",
        "Ingredients": [{"Name": "HewnLog", "Value": {"Value": 30.0, "IsDynamic": false}}, {
            "Name": "MortaredStone",
            "Value": {"Value": 30.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "CourtItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Currency Exchange Recipe",
    "LaborInCalories": {"Value": 2500.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 15.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "CurrencyExchange",
        "Ingredients": [{"Name": "GoldBarItem", "Value": {"Value": 12.0, "IsDynamic": true}}, {
            "Name": "BrickItem",
            "Value": {"Value": 16.0, "IsDynamic": true}
        }, {"Name": "IronBarItem", "Value": {"Value": 16.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 40.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "CurrencyExchangeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Distribution Station Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "DistributionStation",
        "Ingredients": [{"Name": "HewnLog", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "DistributionStationItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Electric Lathe Recipe",
    "LaborInCalories": {"Value": 400.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 8.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "ElectricLathe",
        "Ingredients": [{
            "Name": "IronWheelItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "SteelPlateItem", "Value": {"Value": 12.0, "IsDynamic": true}}],
        "Products": [{"Name": "ElectricLatheItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Electric Machinist Table Recipe",
    "LaborInCalories": {"Value": 600.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 8.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "ElectricMachinistTable",
        "Ingredients": [{"Name": "SteelBarItem", "Value": {"Value": 12.0, "IsDynamic": true}}, {
            "Name": "IronPlateItem",
            "Value": {"Value": 12.0, "IsDynamic": true}
        }, {"Name": "IronGearItem", "Value": {"Value": 8.0, "IsDynamic": true}}],
        "Products": [{"Name": "ElectricMachinistTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Electric Planer Recipe",
    "LaborInCalories": {"Value": 400.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 8.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "ElectricPlaner",
        "Ingredients": [{"Name": "SteelPlateItem", "Value": {"Value": 12.0, "IsDynamic": true}}, {
            "Name": "RivetItem",
            "Value": {"Value": 12.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "ElectricPlanerItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Electric Stamping Press Recipe",
    "LaborInCalories": {"Value": 400.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 8.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "ElectricStampingPress",
        "Ingredients": [{"Name": "SteelBarItem", "Value": {"Value": 24.0, "IsDynamic": true}}],
        "Products": [{"Name": "ElectricStampingPressItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Electric Wall Lamp Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "ElectricWallLamp",
        "Ingredients": [{
            "Name": "SteelBarItem",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }, {"Name": "CopperWiringItem", "Value": {"Value": 5.0, "IsDynamic": true}}, {
            "Name": "LightBulbItem",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "ElectricWallLampItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Electric Water Pump Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 8.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "ElectricWaterPump",
        "Ingredients": [{"Name": "IronPipeItem", "Value": {"Value": 12.0, "IsDynamic": true}}, {
            "Name": "IronBarItem",
            "Value": {"Value": 8.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "ElectricWaterPumpItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Electronics Assembly Recipe",
    "LaborInCalories": {"Value": 500.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 25.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "ElectronicsAssembly",
        "Ingredients": [{"Name": "SteelBarItem", "Value": {"Value": 30.0, "IsDynamic": true}}, {
            "Name": "RivetItem",
            "Value": {"Value": 20.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "ElectronicsAssemblyItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Elk Mount Recipe",
    "LaborInCalories": {"Value": 500.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "ElkMount",
        "Ingredients": [{"Name": "ClothItem", "Value": {"Value": 20.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }, {"Name": "ElkCarcassItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "ElkMountItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Executive Office Recipe",
    "LaborInCalories": {"Value": 2000.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 5.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "ExecutiveOffice",
        "Ingredients": [{"Name": "HewnLog", "Value": {"Value": 30.0, "IsDynamic": false}}, {
            "Name": "MortaredStone",
            "Value": {"Value": 30.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "ExecutiveOfficeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Farmers Table Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "FarmersTable",
        "Ingredients": [{"Name": "DirtItem", "Value": {"Value": 20.0, "IsDynamic": true}}, {
            "Name": "HewnLog",
            "Value": {"Value": 8.0, "IsDynamic": true}
        }, {"Name": "WoodBoard", "Value": {"Value": 8.0, "IsDynamic": true}}],
        "Products": [{"Name": "FarmersTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Fishery Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Fishery",
        "Ingredients": [{"Name": "HewnLog", "Value": {"Value": 14.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 8.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "FisheryItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Fish Trap Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "FishTrap",
        "Ingredients": [{"Name": "PlantFibersItem", "Value": {"Value": 15.0, "IsDynamic": true}}, {
            "Name": "HewnLog",
            "Value": {"Value": 20.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "FishTrapItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Framed Glass Door Recipe",
    "LaborInCalories": {"Value": 190.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 8.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "FramedGlassDoor",
        "Ingredients": [{"Name": "GlassItem", "Value": {"Value": 40.0, "IsDynamic": true}}, {
            "Name": "SteelBarItem",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "FramedGlassDoorItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Froth Floatation Cell Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 5.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "FrothFloatationCell",
        "Ingredients": [{
            "Name": "SteelPlateItem",
            "Value": {"Value": 15.0, "IsDynamic": true}
        }, {"Name": "SteelPipeItem", "Value": {"Value": 20.0, "IsDynamic": true}}, {
            "Name": "AdvancedCircuitItem",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }, {"Name": "ElectricMotorItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "FrothFloatationCellItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Goat Mount Recipe",
    "LaborInCalories": {"Value": 500.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "GoatMount",
        "Ingredients": [{"Name": "ClothItem", "Value": {"Value": 20.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }, {"Name": "MountainGoatCarcassItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "GoatMountItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Government Office Recipe",
    "LaborInCalories": {"Value": 2000.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 5.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "GovernmentOffice",
        "Ingredients": [{"Name": "HewnLog", "Value": {"Value": 30.0, "IsDynamic": false}}, {
            "Name": "MortaredStone",
            "Value": {"Value": 30.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "GovernmentOfficeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Hewn Bench Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "HewnBench",
        "Ingredients": [{"Name": "HewnLog", "Value": {"Value": 18.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "HewnBenchItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "HewnHardwoodBench",
        "Ingredients": [{
            "Name": "HardwoodHewnLogItem",
            "Value": {"Value": 18.0, "IsDynamic": true}
        }, {"Name": "HardwoodBoardItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "HewnHardwoodBenchItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "HewnSoftwoodBench",
        "Ingredients": [{
            "Name": "SoftwoodHewnLogItem",
            "Value": {"Value": 18.0, "IsDynamic": true}
        }, {"Name": "SoftwoodBoardItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "HewnSoftwoodBenchItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Hewn Chair Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 3.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "HewnChair",
        "Ingredients": [{"Name": "PlantFibersItem", "Value": {"Value": 12.0, "IsDynamic": true}}, {
            "Name": "HewnLog",
            "Value": {"Value": 12.0, "IsDynamic": true}
        }, {"Name": "WoodBoard", "Value": {"Value": 12.0, "IsDynamic": true}}],
        "Products": [{"Name": "HewnChairItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "HewnHardwoodChair",
        "Ingredients": [{
            "Name": "HardwoodHewnLogItem",
            "Value": {"Value": 12.0, "IsDynamic": true}
        }, {"Name": "HardwoodBoardItem", "Value": {"Value": 12.0, "IsDynamic": true}}, {
            "Name": "PlantFibersItem",
            "Value": {"Value": 12.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "HewnHardwoodChairItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "HewnSoftwoodChair",
        "Ingredients": [{
            "Name": "SoftwoodHewnLogItem",
            "Value": {"Value": 12.0, "IsDynamic": true}
        }, {"Name": "SoftwoodBoardItem", "Value": {"Value": 12.0, "IsDynamic": true}}, {
            "Name": "PlantFibersItem",
            "Value": {"Value": 12.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "HewnSoftwoodChairItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Hewn Door Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "HewnDoor",
        "Ingredients": [{"Name": "HewnLog", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "HewnDoorItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Hewn Table Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "HewnTable",
        "Ingredients": [{"Name": "HewnLog", "Value": {"Value": 15.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "HewnTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "HewnHardwoodTable",
        "Ingredients": [{
            "Name": "HardwoodHewnLogItem",
            "Value": {"Value": 15.0, "IsDynamic": true}
        }, {"Name": "HardwoodBoardItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "HewnHardwoodTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "HewnSoftwoodTable",
        "Ingredients": [{
            "Name": "SoftwoodHewnLogItem",
            "Value": {"Value": 15.0, "IsDynamic": true}
        }, {"Name": "SoftwoodBoardItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "HewnSoftwoodTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Icebox Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Icebox",
        "Ingredients": [{"Name": "HewnLog", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 12.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "IceboxItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Jaw Crusher Recipe",
    "LaborInCalories": {"Value": 2000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 5.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "JawCrusher",
        "Ingredients": [{
            "Name": "SteelBarItem",
            "Value": {"Value": 25.0, "IsDynamic": true}
        }, {"Name": "SyntheticRubberItem", "Value": {"Value": 25.0, "IsDynamic": true}}, {
            "Name": "SteelGearItem",
            "Value": {"Value": 20.0, "IsDynamic": true}
        }, {"Name": "ElectricMotorItem", "Value": {"Value": 2.0, "IsDynamic": false}}],
        "Products": [{"Name": "JawCrusherItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Kiln Recipe",
    "LaborInCalories": {"Value": 600.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Kiln",
        "Ingredients": [{"Name": "MortarItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "MortaredStone",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "KilnItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Kitchen Recipe",
    "LaborInCalories": {"Value": 500.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Kitchen",
        "Ingredients": [{"Name": "IronBarItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "ClothItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "Lumber", "Value": {"Value": 12.0, "IsDynamic": true}}],
        "Products": [{"Name": "KitchenItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Laboratory Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 50.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Laboratory",
        "Ingredients": [{"Name": "SteelBarItem", "Value": {"Value": 20.0, "IsDynamic": true}}, {
            "Name": "GlassItem",
            "Value": {"Value": 15.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "LaboratoryItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Large Corrugated Steel Door Recipe",
    "LaborInCalories": {"Value": 800.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "LargeCorrugatedSteelDoor",
        "Ingredients": [{"Name": "SteelBarItem", "Value": {"Value": 50.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeCorrugatedSteelDoorItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Large Hanging Ashlar Stone Sign Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 5.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "LargeHangingAshlarStoneSign",
        "Ingredients": [{"Name": "AshlarStone", "Value": {"Value": 12.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeHangingAshlarStoneSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "LargeHangingAshlarBasaltSign",
        "Ingredients": [{"Name": "AshlarBasaltItem", "Value": {"Value": 12.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeHangingAshlarBasaltSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "LargeHangingAshlarGneissSign",
        "Ingredients": [{"Name": "AshlarGneissItem", "Value": {"Value": 12.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeHangingAshlarGneissSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "LargeHangingAshlarGraniteSign",
        "Ingredients": [{"Name": "AshlarGraniteItem", "Value": {"Value": 12.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeHangingAshlarGraniteSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "LargeHangingAshlarLimestoneSign",
        "Ingredients": [{"Name": "AshlarLimestoneItem", "Value": {"Value": 12.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeHangingAshlarLimestoneSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "LargeHangingAshlarSandstoneSign",
        "Ingredients": [{"Name": "AshlarSandstoneItem", "Value": {"Value": 12.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeHangingAshlarSandstoneSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "LargeHangingAshlarShaleSign",
        "Ingredients": [{"Name": "AshlarShaleItem", "Value": {"Value": 12.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeHangingAshlarShaleSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Large Hanging Lumber Sign Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 5.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "LargeHangingLumberSign",
        "Ingredients": [{"Name": "Lumber", "Value": {"Value": 8.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "LargeHangingLumberSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Large Hanging Mortared Stone Sign Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 5.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "LargeHangingMortaredStoneSign",
        "Ingredients": [{"Name": "MortaredStone", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeHangingMortaredStoneSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "LargeHangingMortaredGraniteSign",
        "Ingredients": [{"Name": "MortaredGraniteItem", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeHangingMortaredGraniteSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "LargeHangingMortaredLimestoneSign",
        "Ingredients": [{"Name": "MortaredLimestoneItem", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeHangingMortaredLimestoneSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "LargeHangingMortaredSandstoneSign",
        "Ingredients": [{"Name": "MortaredSandstoneItem", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeHangingMortaredSandstoneSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Large Lumber Door Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 8.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "LargeLumberDoor",
        "Ingredients": [{"Name": "Lumber", "Value": {"Value": 40.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 60.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "LargeLumberDoorItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Large Lumber Stockpile Recipe",
    "LaborInCalories": {"Value": 2000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 12.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "LargeLumberStockpile",
        "Ingredients": [{"Name": "Lumber", "Value": {"Value": 20.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 15.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "LargeLumberStockpileItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Large Standing Ashlar Stone Sign Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 5.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "LargeStandingAshlarStoneSign",
        "Ingredients": [{"Name": "AshlarStone", "Value": {"Value": 12.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeStandingAshlarStoneSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "LargeStandingAshlarBasaltSign",
        "Ingredients": [{"Name": "AshlarBasaltItem", "Value": {"Value": 12.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeStandingAshlarBasaltSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "LargeStandingAshlarGneissSign",
        "Ingredients": [{"Name": "AshlarGneissItem", "Value": {"Value": 12.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeStandingAshlarGneissSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "LargeStandingAshlarGraniteSign",
        "Ingredients": [{"Name": "AshlarGraniteItem", "Value": {"Value": 12.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeStandingAshlarGraniteSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "LargeStandingAshlarLimestoneSign",
        "Ingredients": [{"Name": "AshlarLimestoneItem", "Value": {"Value": 12.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeStandingAshlarLimestoneSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "LargeStandingAshlarSandstoneSign",
        "Ingredients": [{"Name": "AshlarSandstoneItem", "Value": {"Value": 12.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeStandingAshlarSandstoneSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "LargeStandingAshlarShaleSign",
        "Ingredients": [{"Name": "AshlarShaleItem", "Value": {"Value": 12.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeStandingAshlarShaleSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Large Standing Lumber Sign Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 5.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "LargeStandingLumberSign",
        "Ingredients": [{"Name": "Lumber", "Value": {"Value": 8.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "LargeStandingLumberSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Large Standing Mortared Stone Sign Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 5.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "LargeStandingMortaredStoneSign",
        "Ingredients": [{"Name": "MortaredStone", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeStandingMortaredStoneSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "LargeStandingMortaredGraniteSign",
        "Ingredients": [{"Name": "MortaredGraniteItem", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeStandingMortaredGraniteSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "LargeStandingMortaredLimestoneSign",
        "Ingredients": [{"Name": "MortaredLimestoneItem", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeStandingMortaredLimestoneSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "LargeStandingMortaredSandstoneSign",
        "Ingredients": [{"Name": "MortaredSandstoneItem", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeStandingMortaredSandstoneSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Large Windowed Lumber Door Recipe",
    "LaborInCalories": {"Value": 400.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "LargeWindowedLumberDoor",
        "Ingredients": [{"Name": "GlassItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 30.0, "IsDynamic": true}
        }, {"Name": "WoodBoard", "Value": {"Value": 60.0, "IsDynamic": true}}],
        "Products": [{"Name": "LargeWindowedLumberDoorItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Laser Recipe",
    "LaborInCalories": {"Value": 1500.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 100.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Laser",
        "Ingredients": [{"Name": "GoldBarItem", "Value": {"Value": 80.0, "IsDynamic": true}}, {
            "Name": "SteelBarItem",
            "Value": {"Value": 80.0, "IsDynamic": true}
        }, {"Name": "FramedGlassItem", "Value": {"Value": 80.0, "IsDynamic": true}}, {
            "Name": "AdvancedCircuitItem",
            "Value": {"Value": 40.0, "IsDynamic": true}
        }, {"Name": "ElectricMotorItem", "Value": {"Value": 2.0, "IsDynamic": false}}, {
            "Name": "RadiatorItem",
            "Value": {"Value": 10.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "LaserItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Lathe Recipe",
    "LaborInCalories": {"Value": 250.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 5.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Lathe",
        "Ingredients": [{"Name": "IronWheelItem", "Value": {"Value": 4.0, "IsDynamic": true}}, {
            "Name": "IronPlateItem",
            "Value": {"Value": 12.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "LatheItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Wooden Latrine Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Latrine",
        "Ingredients": [{"Name": "HewnLog", "Value": {"Value": 5.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 20.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "LatrineItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Limestone Bison Statue Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "LimestoneBisonStatue",
        "Ingredients": [{"Name": "LimestoneItem", "Value": {"Value": 200.0, "IsDynamic": true}}],
        "Products": [{"Name": "LimestoneBisonStatueItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Limestone Otter Statue Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 5.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "LimestoneOtterStatue",
        "Ingredients": [{"Name": "LimestoneItem", "Value": {"Value": 40.0, "IsDynamic": true}}],
        "Products": [{"Name": "LimestoneOtterStatueItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Limestone Owl Statue Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 5.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "LimestoneOwlStatue",
        "Ingredients": [{"Name": "LimestoneItem", "Value": {"Value": 40.0, "IsDynamic": true}}],
        "Products": [{"Name": "LimestoneOwlStatueItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Limestone Wolf Statue Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 7.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "LimestoneWolfStatue",
        "Ingredients": [{"Name": "LimestoneItem", "Value": {"Value": 80.0, "IsDynamic": true}}],
        "Products": [{"Name": "LimestoneWolfStatueItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Lumber Bench Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "LumberBench",
        "Ingredients": [{"Name": "NailItem", "Value": {"Value": 2.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 8.0, "IsDynamic": true}
        }, {"Name": "WoodBoard", "Value": {"Value": 5.0, "IsDynamic": true}}],
        "Products": [{"Name": "LumberBenchItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "HardwoodLumberBench",
        "Ingredients": [{"Name": "HardwoodLumberItem", "Value": {"Value": 8.0, "IsDynamic": true}}, {
            "Name": "NailItem",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "WoodBoard", "Value": {"Value": 5.0, "IsDynamic": true}}],
        "Products": [{"Name": "HardwoodLumberBenchItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SoftwoodLumberBench",
        "Ingredients": [{"Name": "SoftwoodLumberItem", "Value": {"Value": 8.0, "IsDynamic": true}}, {
            "Name": "NailItem",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }, {"Name": "WoodBoard", "Value": {"Value": 5.0, "IsDynamic": true}}],
        "Products": [{"Name": "SoftwoodLumberBenchItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Lumber Chair Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 3.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "LumberChair",
        "Ingredients": [{"Name": "NailItem", "Value": {"Value": 8.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "LumberChairItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "HardwoodLumberChair",
        "Ingredients": [{"Name": "HardwoodLumberItem", "Value": {"Value": 6.0, "IsDynamic": true}}, {
            "Name": "NailItem",
            "Value": {"Value": 8.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "HardwoodLumberChairItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SoftwoodLumberChair",
        "Ingredients": [{"Name": "SoftwoodLumberItem", "Value": {"Value": 6.0, "IsDynamic": true}}, {
            "Name": "NailItem",
            "Value": {"Value": 8.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "SoftwoodLumberChairItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Lumber Door Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "LumberDoor",
        "Ingredients": [{"Name": "Lumber", "Value": {"Value": 4.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 8.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "LumberDoorItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "HardwoodLumberDoor",
        "Ingredients": [{"Name": "HardwoodLumberItem", "Value": {"Value": 2.0, "IsDynamic": true}}, {
            "Name": "NailItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "HardwoodLumberDoorItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SoftwoodLumberDoor",
        "Ingredients": [{"Name": "SoftwoodLumberItem", "Value": {"Value": 2.0, "IsDynamic": true}}, {
            "Name": "NailItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "SoftwoodLumberDoorItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Lumber Stockpile Recipe",
    "LaborInCalories": {"Value": 1000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 8.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "LumberStockpile",
        "Ingredients": [{"Name": "Lumber", "Value": {"Value": 15.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "LumberStockpileItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Lumber Table Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "LumberTable",
        "Ingredients": [{"Name": "NailItem", "Value": {"Value": 8.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 18.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "LumberTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "HardwoodLumberTable",
        "Ingredients": [{
            "Name": "HardwoodLumberItem",
            "Value": {"Value": 18.0, "IsDynamic": true}
        }, {"Name": "NailItem", "Value": {"Value": 8.0, "IsDynamic": true}}],
        "Products": [{"Name": "HardwoodLumberTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SoftwoodLumberTable",
        "Ingredients": [{
            "Name": "SoftwoodLumberItem",
            "Value": {"Value": 18.0, "IsDynamic": true}
        }, {"Name": "NailItem", "Value": {"Value": 8.0, "IsDynamic": true}}],
        "Products": [{"Name": "SoftwoodLumberTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Machinist Table Recipe",
    "LaborInCalories": {"Value": 500.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 20.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "MachinistTable",
        "Ingredients": [{"Name": "IronBarItem", "Value": {"Value": 12.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 12.0, "IsDynamic": true}
        }, {"Name": "WoodBoard", "Value": {"Value": 12.0, "IsDynamic": true}}],
        "Products": [{"Name": "MachinistTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Masonry Table Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "MasonryTable",
        "Ingredients": [{"Name": "Rock", "Value": {"Value": 20.0, "IsDynamic": false}}, {
            "Name": "Wood",
            "Value": {"Value": 10.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "MasonryTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Mechanical Water Pump Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 8.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "MechanicalWaterPump",
        "Ingredients": [{"Name": "IronBarItem", "Value": {"Value": 8.0, "IsDynamic": true}}, {
            "Name": "IronPipeItem",
            "Value": {"Value": 8.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "MechanicalWaterPumpItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Mill Recipe",
    "LaborInCalories": {"Value": 600.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 8.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Mill",
        "Ingredients": [{"Name": "Rock", "Value": {"Value": 40.0, "IsDynamic": true}}, {
            "Name": "Wood",
            "Value": {"Value": 20.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "MillItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Mint Recipe",
    "LaborInCalories": {"Value": 1500.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 25.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Mint",
        "Ingredients": [{"Name": "BrickItem", "Value": {"Value": 16.0, "IsDynamic": true}}, {
            "Name": "GoldBarItem",
            "Value": {"Value": 8.0, "IsDynamic": true}
        }, {"Name": "Lumber", "Value": {"Value": 30.0, "IsDynamic": true}}],
        "Products": [{"Name": "MintItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Modern Double Street Light Recipe",
    "LaborInCalories": {"Value": 230.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 6.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "ModernDoubleStreetLight",
        "Ingredients": [{"Name": "SteelPlateItem", "Value": {"Value": 8.0, "IsDynamic": true}}, {
            "Name": "PlasticItem",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }, {"Name": "CopperWiringItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "LightBulbItem",
            "Value": {"Value": 2.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "ModernDoubleStreetLightItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Modern Street Light Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 6.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "ModernStreetLight",
        "Ingredients": [{"Name": "SteelPlateItem", "Value": {"Value": 6.0, "IsDynamic": true}}, {
            "Name": "PlasticItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "CopperWiringItem", "Value": {"Value": 6.0, "IsDynamic": true}}, {
            "Name": "LightBulbItem",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "ModernStreetLightItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Mortared Stone Bench Recipe",
    "LaborInCalories": {"Value": 50.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 3.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "MortaredStoneBench",
        "Ingredients": [{"Name": "MortaredStone", "Value": {"Value": 24.0, "IsDynamic": true}}],
        "Products": [{"Name": "MortaredStoneBenchItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "MortaredGraniteBench",
        "Ingredients": [{"Name": "MortaredGraniteItem", "Value": {"Value": 24.0, "IsDynamic": true}}],
        "Products": [{"Name": "MortaredGraniteBenchItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "MortaredLimestoneBench",
        "Ingredients": [{"Name": "MortaredLimestoneItem", "Value": {"Value": 24.0, "IsDynamic": true}}],
        "Products": [{"Name": "MortaredLimestoneBenchItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "MortaredSandstoneBench",
        "Ingredients": [{"Name": "MortaredSandstoneItem", "Value": {"Value": 24.0, "IsDynamic": true}}],
        "Products": [{"Name": "MortaredSandstoneBenchItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Mortared Stone Chair Recipe",
    "LaborInCalories": {"Value": 50.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "MortaredStoneChair",
        "Ingredients": [{"Name": "MortaredStone", "Value": {"Value": 15.0, "IsDynamic": true}}],
        "Products": [{"Name": "MortaredStoneChairItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "MortaredGraniteChair",
        "Ingredients": [{"Name": "MortaredGraniteItem", "Value": {"Value": 15.0, "IsDynamic": true}}],
        "Products": [{"Name": "MortaredGraniteChairItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "MortaredLimestoneChair",
        "Ingredients": [{"Name": "MortaredLimestoneItem", "Value": {"Value": 15.0, "IsDynamic": true}}],
        "Products": [{"Name": "MortaredLimestoneChairItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "MortaredSandstoneChair",
        "Ingredients": [{"Name": "MortaredSandstoneItem", "Value": {"Value": 15.0, "IsDynamic": true}}],
        "Products": [{"Name": "MortaredSandstoneChairItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Mortared Stone Door Recipe",
    "LaborInCalories": {"Value": 20.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "MortaredStoneDoor",
        "Ingredients": [{"Name": "MortaredStone", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "MortaredStoneDoorItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "MortaredGraniteDoor",
        "Ingredients": [{"Name": "MortaredGraniteItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "MortaredGraniteDoorItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "MortaredLimestoneDoor",
        "Ingredients": [{"Name": "MortaredLimestoneItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "MortaredLimestoneDoorItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "MortaredSandstoneDoor",
        "Ingredients": [{"Name": "MortaredSandstoneItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "MortaredSandstoneDoorItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Mortared Stone Fireplace Recipe",
    "LaborInCalories": {"Value": 50.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "MortaredStoneFireplace",
        "Ingredients": [{"Name": "MortaredStone", "Value": {"Value": 40.0, "IsDynamic": true}}],
        "Products": [{"Name": "MortaredStoneFireplaceItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "MortaredGraniteFireplace",
        "Ingredients": [{"Name": "MortaredGraniteItem", "Value": {"Value": 40.0, "IsDynamic": true}}],
        "Products": [{"Name": "MortaredGraniteFireplaceItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "MortaredLimestoneFireplace",
        "Ingredients": [{"Name": "MortaredLimestoneItem", "Value": {"Value": 40.0, "IsDynamic": true}}],
        "Products": [{"Name": "MortaredLimestoneFireplaceItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "MortaredSandstoneFireplace",
        "Ingredients": [{"Name": "MortaredSandstoneItem", "Value": {"Value": 40.0, "IsDynamic": true}}],
        "Products": [{"Name": "MortaredSandstoneFireplaceItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Mortared Stone Table Recipe",
    "LaborInCalories": {"Value": 50.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 6.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "MortaredStoneTable",
        "Ingredients": [{"Name": "MortaredStone", "Value": {"Value": 22.0, "IsDynamic": true}}],
        "Products": [{"Name": "MortaredStoneTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "MortaredGraniteTable",
        "Ingredients": [{"Name": "MortaredGraniteItem", "Value": {"Value": 22.0, "IsDynamic": true}}],
        "Products": [{"Name": "MortaredGraniteTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "MortaredLimestoneTable",
        "Ingredients": [{"Name": "MortaredLimestoneItem", "Value": {"Value": 22.0, "IsDynamic": true}}],
        "Products": [{"Name": "MortaredLimestoneTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "MortaredSandstoneTable",
        "Ingredients": [{"Name": "MortaredSandstoneItem", "Value": {"Value": 22.0, "IsDynamic": true}}],
        "Products": [{"Name": "MortaredSandstoneTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Oil Refinery Recipe",
    "LaborInCalories": {"Value": 700.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 20.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "OilRefinery",
        "Ingredients": [{"Name": "BrickItem", "Value": {"Value": 12.0, "IsDynamic": true}}, {
            "Name": "IronBarItem",
            "Value": {"Value": 16.0, "IsDynamic": true}
        }, {"Name": "IronGearItem", "Value": {"Value": 24.0, "IsDynamic": true}}],
        "Products": [{"Name": "OilRefineryItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Padded Chair Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 8.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "PaddedChair",
        "Ingredients": [{"Name": "ClothItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "HewnLog",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }, {"Name": "WoodBoard", "Value": {"Value": 20.0, "IsDynamic": true}}],
        "Products": [{"Name": "PaddedChairItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Round Pot Recipe",
    "LaborInCalories": {"Value": 75.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "PlanterPotRound",
        "Ingredients": [{"Name": "ClayItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "PlanterPotRoundItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Square Pot Recipe",
    "LaborInCalories": {"Value": 75.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "PlanterPotSquare",
        "Ingredients": [{"Name": "ClayItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "PlanterPotSquareItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Pump Jack Recipe",
    "LaborInCalories": {"Value": 120.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 20.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "PumpJack",
        "Ingredients": [{
            "Name": "ReinforcedConcreteItem",
            "Value": {"Value": 12.0, "IsDynamic": true}
        }, {"Name": "GearboxItem", "Value": {"Value": 4.0, "IsDynamic": true}}, {
            "Name": "PistonItem",
            "Value": {"Value": 8.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "PumpJackItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Real Estate Desk Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "RealEstateDesk",
        "Ingredients": [{"Name": "PlantFibersItem", "Value": {"Value": 40.0, "IsDynamic": true}}, {
            "Name": "HewnLog",
            "Value": {"Value": 15.0, "IsDynamic": true}
        }, {"Name": "WoodBoard", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "RealEstateDeskItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Refrigerator Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Refrigerator",
        "Ingredients": [{
            "Name": "SteelBarItem",
            "Value": {"Value": 16.0, "IsDynamic": true}
        }, {"Name": "BasicCircuitItem", "Value": {"Value": 8.0, "IsDynamic": true}}, {
            "Name": "RadiatorItem",
            "Value": {"Value": 3.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "RefrigeratorItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Registrar Recipe",
    "LaborInCalories": {"Value": 250.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 25.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Registrar",
        "Ingredients": [{"Name": "IronBarItem", "Value": {"Value": 20.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 16.0, "IsDynamic": true}
        }, {"Name": "WoodBoard", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "RegistrarItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Research Table Recipe",
    "LaborInCalories": {"Value": 250.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 5.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "ResearchTable",
        "Ingredients": [{"Name": "PlantFibersItem", "Value": {"Value": 30.0, "IsDynamic": false}}, {
            "Name": "Wood",
            "Value": {"Value": 30.0, "IsDynamic": false}
        }, {"Name": "Rock", "Value": {"Value": 40.0, "IsDynamic": false}}],
        "Products": [{"Name": "ResearchTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Robotic Assembly Line Recipe",
    "LaborInCalories": {"Value": 2000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 75.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "RoboticAssemblyLine",
        "Ingredients": [{
            "Name": "CementItem",
            "Value": {"Value": 30.0, "IsDynamic": true}
        }, {"Name": "ElectricMotorItem", "Value": {"Value": 5.0, "IsDynamic": true}}, {
            "Name": "ServoItem",
            "Value": {"Value": 12.0, "IsDynamic": true}
        }, {"Name": "SteelBarItem", "Value": {"Value": 30.0, "IsDynamic": true}}],
        "Products": [{"Name": "RoboticAssemblyLineItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Rocker Box Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 5.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "RockerBox",
        "Ingredients": [{"Name": "WoodBoard", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "Wood",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "RockerBoxItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Rolling Mill Recipe",
    "LaborInCalories": {"Value": 600.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 20.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "RollingMill",
        "Ingredients": [{"Name": "SteelBarItem", "Value": {"Value": 8.0, "IsDynamic": true}}, {
            "Name": "GearboxItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "RollingMillItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Rug Large Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "RugLarge",
        "Ingredients": [{
            "Name": "ClothItem",
            "Value": {"Value": 20.0, "IsDynamic": true}
        }, {"Name": "CelluloseFiberItem", "Value": {"Value": 15.0, "IsDynamic": true}}],
        "Products": [{"Name": "RugLargeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Rug Medium Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 8.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "RugMedium",
        "Ingredients": [{
            "Name": "ClothItem",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }, {"Name": "CelluloseFiberItem", "Value": {"Value": 5.0, "IsDynamic": true}}],
        "Products": [{"Name": "RugMediumItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Rug Small Recipe",
    "LaborInCalories": {"Value": 50.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 6.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "RugSmall",
        "Ingredients": [{"Name": "ClothItem", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "RugSmallItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Salt Basket Recipe",
    "LaborInCalories": {"Value": 160.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "SaltBasket",
        "Ingredients": [{"Name": "WoodBoard", "Value": {"Value": 20.0, "IsDynamic": true}}],
        "Products": [{"Name": "SaltBasketItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Sawmill Recipe",
    "LaborInCalories": {"Value": 1000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 6.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Sawmill",
        "Ingredients": [{"Name": "IronBarItem", "Value": {"Value": 4.0, "IsDynamic": true}}, {
            "Name": "Wood",
            "Value": {"Value": 16.0, "IsDynamic": true}
        }, {"Name": "IronSawBladeItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "SawmillItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Screening Machine Recipe",
    "LaborInCalories": {"Value": 500.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 5.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "ScreeningMachine",
        "Ingredients": [{"Name": "IronBarItem", "Value": {"Value": 40.0, "IsDynamic": true}}, {
            "Name": "ScrewsItem",
            "Value": {"Value": 15.0, "IsDynamic": true}
        }, {"Name": "IronGearItem", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "ScreeningMachineItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Screw Press Recipe",
    "LaborInCalories": {"Value": 500.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 5.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "ScrewPress",
        "Ingredients": [{"Name": "IronBarItem", "Value": {"Value": 25.0, "IsDynamic": true}}],
        "Products": [{"Name": "ScrewPressItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Sensor Based Belt Sorter Recipe",
    "LaborInCalories": {"Value": 1000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 5.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "SensorBasedBeltSorter",
        "Ingredients": [{
            "Name": "SteelPlateItem",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }, {"Name": "SteelGearboxItem", "Value": {"Value": 5.0, "IsDynamic": true}}, {
            "Name": "RivetItem",
            "Value": {"Value": 16.0, "IsDynamic": true}
        }, {"Name": "AdvancedCircuitItem", "Value": {"Value": 5.0, "IsDynamic": true}}, {
            "Name": "BasicCircuitItem",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }, {"Name": "ElectricMotorItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "SensorBasedBeltSorterItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Shaper Recipe",
    "LaborInCalories": {"Value": 500.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 5.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Shaper",
        "Ingredients": [{"Name": "IronPlateItem", "Value": {"Value": 12.0, "IsDynamic": true}}, {
            "Name": "ScrewsItem",
            "Value": {"Value": 30.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "ShaperItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Sheep Mount Recipe",
    "LaborInCalories": {"Value": 500.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "SheepMount",
        "Ingredients": [{"Name": "ClothItem", "Value": {"Value": 20.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }, {"Name": "BighornCarcassItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "SheepMountItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Shelf Cabinet Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "ShelfCabinet",
        "Ingredients": [{"Name": "Lumber", "Value": {"Value": 14.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 16.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "ShelfCabinetItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Sink Recipe",
    "LaborInCalories": {"Value": 180.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 8.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Sink",
        "Ingredients": [{"Name": "SteelBarItem", "Value": {"Value": 8.0, "IsDynamic": true}}, {
            "Name": "IronPlateItem",
            "Value": {"Value": 8.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "SinkItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Small Hanging Ashlar Stone Sign Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "SmallHangingAshlarStoneSign",
        "Ingredients": [{"Name": "AshlarStone", "Value": {"Value": 8.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallHangingAshlarStoneSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SmallHangingAshlarBasaltSign",
        "Ingredients": [{"Name": "AshlarBasaltItem", "Value": {"Value": 8.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallHangingAshlarBasaltSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SmallHangingAshlarGneissSign",
        "Ingredients": [{"Name": "AshlarGneissItem", "Value": {"Value": 8.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallHangingAshlarGneissSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SmallHangingAshlarGraniteSign",
        "Ingredients": [{"Name": "AshlarGraniteItem", "Value": {"Value": 8.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallHangingAshlarGraniteSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SmallHangingAshlarLimestoneSign",
        "Ingredients": [{"Name": "AshlarLimestoneItem", "Value": {"Value": 8.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallHangingAshlarLimestoneSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SmallHangingAshlarSandstoneSign",
        "Ingredients": [{"Name": "AshlarSandstoneItem", "Value": {"Value": 8.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallHangingAshlarSandstoneSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SmallHangingAshlarShaleSign",
        "Ingredients": [{"Name": "AshlarShaleItem", "Value": {"Value": 8.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallHangingAshlarShaleSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Small Hanging Lumber Sign Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 5.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "SmallHangingLumberSign",
        "Ingredients": [{"Name": "WoodBoard", "Value": {"Value": 6.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "SmallHangingLumberSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Small Hanging Mortared Stone Sign Recipe",
    "LaborInCalories": {"Value": 40.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 5.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "SmallHangingMortaredStoneSign",
        "Ingredients": [{"Name": "MortaredStone", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallHangingMortaredStoneSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SmallHangingMortaredGraniteSign",
        "Ingredients": [{"Name": "MortaredGraniteItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallHangingMortaredGraniteSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SmallHangingMortaredLimestoneSign",
        "Ingredients": [{"Name": "MortaredLimestoneItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallHangingMortaredLimestoneSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SmallHangingMortaredSandstoneSign",
        "Ingredients": [{"Name": "MortaredSandstoneItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallHangingMortaredSandstoneSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Small Sink Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "SmallSink",
        "Ingredients": [{"Name": "ClayItem", "Value": {"Value": 30.0, "IsDynamic": true}}, {
            "Name": "IronBarItem",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "SmallSinkItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Small Standing Ashlar Stone Sign Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "SmallStandingAshlarStoneSign",
        "Ingredients": [{"Name": "AshlarStone", "Value": {"Value": 8.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallStandingAshlarStoneSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SmallStandingAshlarBasaltSign",
        "Ingredients": [{"Name": "AshlarBasaltItem", "Value": {"Value": 8.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallStandingAshlarBasaltSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SmallStandingAshlarGneissSign",
        "Ingredients": [{"Name": "AshlarGneissItem", "Value": {"Value": 8.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallStandingAshlarGneissSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SmallStandingAshlarGraniteSign",
        "Ingredients": [{"Name": "AshlarGraniteItem", "Value": {"Value": 8.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallStandingAshlarGraniteSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SmallStandingAshlarLimestoneSign",
        "Ingredients": [{"Name": "AshlarLimestoneItem", "Value": {"Value": 8.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallStandingAshlarLimestoneSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SmallStandingAshlarSandstoneSign",
        "Ingredients": [{"Name": "AshlarSandstoneItem", "Value": {"Value": 8.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallStandingAshlarSandstoneSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SmallStandingAshlarShaleSign",
        "Ingredients": [{"Name": "AshlarShaleItem", "Value": {"Value": 8.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallStandingAshlarShaleSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Small Standing Lumber Sign Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 5.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "SmallStandingLumberSign",
        "Ingredients": [{"Name": "WoodBoard", "Value": {"Value": 6.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "SmallStandingLumberSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Small Standing Mortared Stone Sign Recipe",
    "LaborInCalories": {"Value": 40.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 5.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "SmallStandingMortaredStoneSign",
        "Ingredients": [{"Name": "MortaredStone", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallStandingMortaredStoneSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SmallStandingMortaredGraniteSign",
        "Ingredients": [{"Name": "MortaredGraniteItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallStandingMortaredGraniteSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SmallStandingMortaredLimestoneSign",
        "Ingredients": [{"Name": "MortaredLimestoneItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallStandingMortaredLimestoneSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }, {
        "Name": "SmallStandingMortaredSandstoneSign",
        "Ingredients": [{"Name": "MortaredSandstoneItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "SmallStandingMortaredSandstoneSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Small Stockpile Recipe",
    "LaborInCalories": {"Value": 10.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "SmallStockpile",
        "Ingredients": [{"Name": "Wood", "Value": {"Value": 5.0, "IsDynamic": false}}],
        "Products": [{"Name": "SmallStockpileItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Solar Generator Recipe",
    "LaborInCalories": {"Value": 1000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 20.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "SolarGenerator",
        "Ingredients": [{"Name": "SteelBarItem", "Value": {"Value": 12.0, "IsDynamic": true}}, {
            "Name": "ServoItem",
            "Value": {"Value": 8.0, "IsDynamic": true}
        }, {"Name": "BasicCircuitItem", "Value": {"Value": 8.0, "IsDynamic": true}}],
        "Products": [{"Name": "SolarGeneratorItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Stamp Mill Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 5.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "StampMill",
        "Ingredients": [{"Name": "IronBarItem", "Value": {"Value": 5.0, "IsDynamic": true}}, {
            "Name": "ScrewsItem",
            "Value": {"Value": 14.0, "IsDynamic": true}
        }, {"Name": "IronGearItem", "Value": {"Value": 8.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 14.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "StampMillItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Steam Engine Recipe",
    "LaborInCalories": {"Value": 750.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "SteamEngine",
        "Ingredients": [{"Name": "PistonItem", "Value": {"Value": 12.0, "IsDynamic": true}}, {
            "Name": "ScrewsItem",
            "Value": {"Value": 30.0, "IsDynamic": true}
        }, {"Name": "IronPlateItem", "Value": {"Value": 24.0, "IsDynamic": true}}, {
            "Name": "BoilerItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }, {"Name": "IronGearItem", "Value": {"Value": 16.0, "IsDynamic": true}}],
        "Products": [{"Name": "SteamEngineItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Steel Ceiling Light Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "SteelCeilingLight",
        "Ingredients": [{"Name": "SteelBarItem", "Value": {"Value": 8.0, "IsDynamic": true}}, {
            "Name": "PlasticItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }, {"Name": "CopperWiringItem", "Value": {"Value": 5.0, "IsDynamic": true}}, {
            "Name": "LightBulbItem",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "SteelCeilingLightItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Steel Floor Lamp Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "SteelFloorLamp",
        "Ingredients": [{"Name": "SteelBarItem", "Value": {"Value": 8.0, "IsDynamic": true}}, {
            "Name": "PlasticItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }, {"Name": "CopperWiringItem", "Value": {"Value": 5.0, "IsDynamic": true}}, {
            "Name": "LightBulbItem",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "SteelFloorLampItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Steel Table Lamp Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "SteelTableLamp",
        "Ingredients": [{"Name": "SteelBarItem", "Value": {"Value": 8.0, "IsDynamic": true}}, {
            "Name": "PlasticItem",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }, {"Name": "CopperWiringItem", "Value": {"Value": 5.0, "IsDynamic": true}}, {
            "Name": "LightBulbItem",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "SteelTableLampItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Stockpile Recipe",
    "LaborInCalories": {"Value": 25.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "Stockpile",
        "Ingredients": [{"Name": "Wood", "Value": {"Value": 10.0, "IsDynamic": false}}],
        "Products": [{"Name": "StockpileItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Stone Brazier Recipe",
    "LaborInCalories": {"Value": 40.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "StoneBrazier",
        "Ingredients": [{"Name": "Rock", "Value": {"Value": 15.0, "IsDynamic": true}}],
        "Products": [{"Name": "StoneBrazierItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Stop Sign Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "StopSign",
        "Ingredients": [{"Name": "IronBarItem", "Value": {"Value": 8.0, "IsDynamic": true}}],
        "Products": [{"Name": "StopSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Storage Chest Recipe",
    "LaborInCalories": {"Value": 25.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "StorageChest",
        "Ingredients": [{"Name": "Wood", "Value": {"Value": 10.0, "IsDynamic": false}}],
        "Products": [{"Name": "StorageChestItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Store Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 15.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "Store",
        "Ingredients": [{"Name": "Wood", "Value": {"Value": 10.0, "IsDynamic": false}}, {
            "Name": "Rock",
            "Value": {"Value": 10.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "StoreItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Stove Recipe",
    "LaborInCalories": {"Value": 1500.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Stove",
        "Ingredients": [{"Name": "SteelPlateItem", "Value": {"Value": 16.0, "IsDynamic": true}}, {
            "Name": "RivetItem",
            "Value": {"Value": 12.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "StoveItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Streetlamp Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 6.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Streetlamp",
        "Ingredients": [{"Name": "SteelBarItem", "Value": {"Value": 12.0, "IsDynamic": true}}, {
            "Name": "GlassItem",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }, {"Name": "CopperWiringItem", "Value": {"Value": 5.0, "IsDynamic": true}}, {
            "Name": "LightBulbItem",
            "Value": {"Value": 1.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "StreetlampItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Street Sign Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "StreetSign",
        "Ingredients": [{"Name": "IronBarItem", "Value": {"Value": 8.0, "IsDynamic": true}}],
        "Products": [{"Name": "StreetSignItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Stuffed Alligator Recipe",
    "LaborInCalories": {"Value": 500.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 12.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "StuffedAlligator",
        "Ingredients": [{"Name": "ClothItem", "Value": {"Value": 20.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }, {"Name": "AlligatorCarcassItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "StuffedAlligatorItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Stuffed Bison Recipe",
    "LaborInCalories": {"Value": 500.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 12.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "StuffedBison",
        "Ingredients": [{"Name": "ClothItem", "Value": {"Value": 20.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }, {"Name": "BisonCarcassItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "StuffedBisonItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Stuffed Elk Recipe",
    "LaborInCalories": {"Value": 500.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 12.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "StuffedElk",
        "Ingredients": [{"Name": "ClothItem", "Value": {"Value": 20.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }, {"Name": "ElkCarcassItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "StuffedElkItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Stuffed Goat Recipe",
    "LaborInCalories": {"Value": 500.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 12.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "StuffedGoat",
        "Ingredients": [{"Name": "ClothItem", "Value": {"Value": 20.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }, {"Name": "MountainGoatCarcassItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "StuffedGoatItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Stuffed Jaguar Recipe",
    "LaborInCalories": {"Value": 500.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 12.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "StuffedJaguar",
        "Ingredients": [{"Name": "ClothItem", "Value": {"Value": 20.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }, {"Name": "JaguarCarcassItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "StuffedJaguarItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Stuffed Wolf Recipe",
    "LaborInCalories": {"Value": 500.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 12.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "StuffedWolf",
        "Ingredients": [{"Name": "ClothItem", "Value": {"Value": 20.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }, {"Name": "WolfCarcassItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "StuffedWolfItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Tailoring Table Recipe",
    "LaborInCalories": {"Value": 600.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 25.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "TailoringTable",
        "Ingredients": [{"Name": "PlantFibersItem", "Value": {"Value": 50.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 20.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "TailoringTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Tallow Candle Recipe",
    "LaborInCalories": {"Value": 80.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "TallowCandle",
        "Ingredients": [{"Name": "TallowItem", "Value": {"Value": 3.0, "IsDynamic": false}}],
        "Products": [{"Name": "TallowCandleItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Tallow Lamp Recipe",
    "LaborInCalories": {"Value": 80.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "TallowLamp",
        "Ingredients": [{"Name": "ClayItem", "Value": {"Value": 2.0, "IsDynamic": true}}, {
            "Name": "TallowItem",
            "Value": {"Value": 3.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "TallowLampItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Tallow Wall Lamp Recipe",
    "LaborInCalories": {"Value": 80.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 3.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "TallowWallLamp",
        "Ingredients": [{"Name": "IronBarItem", "Value": {"Value": 1.0, "IsDynamic": true}}, {
            "Name": "TallowItem",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "TallowWallLampItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Tiny Stockpile Recipe",
    "LaborInCalories": {"Value": 5.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": false},
    "Recipes": [{
        "Name": "TinyStockpile",
        "Ingredients": [{"Name": "Wood", "Value": {"Value": 2.0, "IsDynamic": false}}],
        "Products": [{"Name": "TinyStockpileItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Toilet Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Toilet",
        "Ingredients": [{"Name": "ClayItem", "Value": {"Value": 30.0, "IsDynamic": true}}, {
            "Name": "IronBarItem",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "ToiletItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Tool Bench Recipe",
    "LaborInCalories": {"Value": 50.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 1.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "ToolBench",
        "Ingredients": [{"Name": "Wood", "Value": {"Value": 20.0, "IsDynamic": false}}, {
            "Name": "Rock",
            "Value": {"Value": 10.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "ToolBenchItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Torch Stand Recipe",
    "LaborInCalories": {"Value": 10.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "TorchStand",
        "Ingredients": [{"Name": "Wood", "Value": {"Value": 10.0, "IsDynamic": false}}],
        "Products": [{"Name": "TorchStandItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Transmission Pole Recipe",
    "LaborInCalories": {"Value": 800.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "TransmissionPole",
        "Ingredients": [{
            "Name": "SteelBarItem",
            "Value": {"Value": 8.0, "IsDynamic": true}
        }, {"Name": "CopperWiringItem", "Value": {"Value": 14.0, "IsDynamic": true}}, {
            "Name": "Lumber",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "TransmissionPoleItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Treasury Recipe",
    "LaborInCalories": {"Value": 2500.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 25.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Treasury",
        "Ingredients": [{"Name": "BrickItem", "Value": {"Value": 16.0, "IsDynamic": true}}, {
            "Name": "GoldBarItem",
            "Value": {"Value": 12.0, "IsDynamic": true}
        }, {"Name": "Lumber", "Value": {"Value": 60.0, "IsDynamic": true}}],
        "Products": [{"Name": "TreasuryItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Wainwright Table Recipe",
    "LaborInCalories": {"Value": 300.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 5.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "WainwrightTable",
        "Ingredients": [{"Name": "WoodBoard", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "HewnLog",
            "Value": {"Value": 15.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "WainwrightTableItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Wall Candle Recipe",
    "LaborInCalories": {"Value": 80.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 3.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "WallCandle",
        "Ingredients": [{"Name": "IronBarItem", "Value": {"Value": 2.0, "IsDynamic": true}}, {
            "Name": "TallowItem",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "WallCandleItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Washboard Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Washboard",
        "Ingredients": [{"Name": "WoodBoard", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "WashboardItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Washing Machine Recipe",
    "LaborInCalories": {"Value": 400.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "WashingMachine",
        "Ingredients": [{
            "Name": "SteelBarItem",
            "Value": {"Value": 12.0, "IsDynamic": true}
        }, {"Name": "BasicCircuitItem", "Value": {"Value": 6.0, "IsDynamic": true}}],
        "Products": [{"Name": "WashingMachineItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Waste Filter Recipe",
    "LaborInCalories": {"Value": 800.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 20.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "WasteFilter",
        "Ingredients": [{"Name": "SteelBarItem", "Value": {"Value": 12.0, "IsDynamic": true}}, {
            "Name": "CementItem",
            "Value": {"Value": 8.0, "IsDynamic": true}
        }, {"Name": "GearboxItem", "Value": {"Value": 4.0, "IsDynamic": true}}, {
            "Name": "PistonItem",
            "Value": {"Value": 2.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "WasteFilterItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Waterwheel Recipe",
    "LaborInCalories": {"Value": 300.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Waterwheel",
        "Ingredients": [{"Name": "HewnLog", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 5.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "WaterwheelItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Windmill Recipe",
    "LaborInCalories": {"Value": 300.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 10.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "Windmill",
        "Ingredients": [{"Name": "ClothItem", "Value": {"Value": 16.0, "IsDynamic": true}}, {
            "Name": "HewnLog",
            "Value": {"Value": 15.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "WindmillItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Wind Turbine Recipe",
    "LaborInCalories": {"Value": 2000.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 20.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "WindTurbine",
        "Ingredients": [{"Name": "SteelBarItem", "Value": {"Value": 8.0, "IsDynamic": true}}, {
            "Name": "GearboxItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "AdvancedCircuitItem", "Value": {"Value": 4.0, "IsDynamic": true}}],
        "Products": [{"Name": "WindTurbineItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Wooden Ceiling Light Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "WoodenCeilingLight",
        "Ingredients": [{"Name": "ClothItem", "Value": {"Value": 6.0, "IsDynamic": true}}, {
            "Name": "CopperWiringItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "Lumber", "Value": {"Value": 6.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "LightBulbItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "WoodenCeilingLightItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Wooden Elevator Call Post Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 5.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "WoodenElevatorCallPost",
        "Ingredients": [{"Name": "Lumber", "Value": {"Value": 8.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 6.0, "IsDynamic": true}
        }],
        "Products": [{"Name": "WoodenElevatorCallPostItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Wooden Fabric Bed Recipe",
    "LaborInCalories": {"Value": 300.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 6.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "WoodenFabricBed",
        "Ingredients": [{"Name": "ClothItem", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "NailItem",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }, {"Name": "Lumber", "Value": {"Value": 10.0, "IsDynamic": true}}],
        "Products": [{"Name": "WoodenFabricBedItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Wooden Floor Lamp Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "WoodenFloorLamp",
        "Ingredients": [{"Name": "ClothItem", "Value": {"Value": 8.0, "IsDynamic": true}}, {
            "Name": "CopperWiringItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "Lumber", "Value": {"Value": 8.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 10.0, "IsDynamic": true}
        }, {"Name": "LightBulbItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "WoodenFloorLampItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Wooden Straw Bed Recipe",
    "LaborInCalories": {"Value": 100.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 2.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "WoodenStrawBed",
        "Ingredients": [{"Name": "PlantFibersItem", "Value": {"Value": 40.0, "IsDynamic": true}}, {
            "Name": "HewnLog",
            "Value": {"Value": 15.0, "IsDynamic": true}
        }, {"Name": "WoodBoard", "Value": {"Value": 15.0, "IsDynamic": true}}],
        "Products": [{"Name": "WoodenStrawBedItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Wooden Table Lamp Recipe",
    "LaborInCalories": {"Value": 200.0, "IsDynamic": true},
    "CraftMinutes": {"Value": 4.0, "IsDynamic": true},
    "Recipes": [{
        "Name": "WoodenTableLamp",
        "Ingredients": [{"Name": "ClothItem", "Value": {"Value": 6.0, "IsDynamic": true}}, {
            "Name": "CopperWiringItem",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "Lumber", "Value": {"Value": 10.0, "IsDynamic": true}}, {
            "Name": "WoodBoard",
            "Value": {"Value": 4.0, "IsDynamic": true}
        }, {"Name": "LightBulbItem", "Value": {"Value": 1.0, "IsDynamic": false}}],
        "Products": [{"Name": "WoodenTableLampItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Workbench Recipe",
    "LaborInCalories": {"Value": 50.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 0.5, "IsDynamic": false},
    "Recipes": [{
        "Name": "Workbench",
        "Ingredients": [{"Name": "Wood", "Value": {"Value": 10.0, "IsDynamic": false}}],
        "Products": [{"Name": "WorkbenchItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}, {
    "Name": "Zoning Office Recipe",
    "LaborInCalories": {"Value": 2000.0, "IsDynamic": false},
    "CraftMinutes": {"Value": 5.0, "IsDynamic": false},
    "Recipes": [{
        "Name": "ZoningOffice",
        "Ingredients": [{"Name": "HewnLog", "Value": {"Value": 30.0, "IsDynamic": false}}, {
            "Name": "MortaredStone",
            "Value": {"Value": 30.0, "IsDynamic": false}
        }],
        "Products": [{"Name": "ZoningOfficeItem", "Value": {"Value": 1.0, "IsDynamic": false}}]
    }]
}];
