import {Recipe} from './Recipe';

export class RecipeGroup {

    label: string;
    recipes: Recipe[];

    constructor(label: string) {
       this.label = label;
    }

    addRecipe(recipe: Recipe): void {
        this.recipes.push(recipe);
    }

}
