import {StoreOffer} from './StoreOffer';

export class StoreOfferSummary {

    itemName: string;
    offers: StoreOffer[];

    expanded = false;

    constructor(itemName, offers) {
        this.itemName = itemName;
        this.offers = offers;
    }

    elligibleOffers(): StoreOffer[] {
        return this.offers.filter(offer => offer.enabled && offer.owner && offer.balance > 0 && offer.quantity > 0);
    }

    elligibleBuyOffers(): StoreOffer[] {
        return this.elligibleOffers().filter(offer => offer.buying);
    }

    elligibleSellOffers(): StoreOffer[] {
        return this.elligibleOffers().filter(offer => !offer.buying);
    }

    maxBuyOffer(): StoreOffer {
        return this.elligibleBuyOffers().reduce((minBuy, storeOffer) => {
                if (!minBuy || storeOffer.buying && storeOffer.price > minBuy.price && storeOffer.enabled) {
                    return storeOffer;
                } else {
                    return minBuy;
                }
            }, null
        );
    }

    minSellOffer(): StoreOffer {
        return this.elligibleSellOffers().reduce((maxSell, storeOffer) => {
                if (!maxSell || !storeOffer.buying && storeOffer.price < maxSell.price && storeOffer.enabled) {
                    return storeOffer;
                } else {
                    return maxSell;
                }
            }, null
        );
    }
}
