import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {EarthService} from '../../../service/earth.service';


@Component({
  selector: 'app-create-item',
  templateUrl: './create-item.component.html',
  styleUrls: ['./create-item.component.scss']
})
export class CreateItemComponent implements OnInit {
  ngOnInit(): void {
  }


  // createItemForm = this.fb.group({
  //   name: ['', Validators.required]
  // });
  //
  //
  // constructor(
  //     private fb: FormBuilder,
  //     private dialogRef: MatDialogRef<CreateItemComponent>,
  //     public earthService: EarthService,
  //     @Inject(MAT_DIALOG_DATA) public item: Item
  // ) {
  //
  // }
  //
  // ngOnInit(): void {
  //   if (this.item) {
  //     this.createItemForm.setValue(this.item);
  //   }
  // }
  //
  // get name(): FormControl {
  //   return this.createItemForm.get('name') as FormControl;
  // }
  //
  // buttonLabel(): string {
  //   return !this.item ? 'Add Custom Item' : 'Update Custom Item';
  // }
  //
  //
  // save(): void {
  //   // const formData = this.createItemForm.value;
  //   // const newItem =  new Item({name: formData.name});
  //   //
  //   // if (!this.item) {
  //   //   this.dialogRef.close(newItem);
  //   // } else {
  //   //   this.item.name = newItem.name;
  //   //   this.dialogRef.close();
  //   // }
  //
  //
  // }
  //
  // close(): void {
  //   this.dialogRef.close();
  // }


}
