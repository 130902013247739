import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EarthService} from '../../service/earth.service';
import {Recipe} from '../../models/Recipe';

@Component({
  selector: 'recipe-header',
  templateUrl: './recipe-header.component.html',
  styleUrls: ['./recipe-header.component.scss']
})
export class RecipeHeaderComponent implements OnInit {

  @Input('recipe') recipe: Recipe;

  @Output() deleted = new EventEmitter();
  @Output() updated = new EventEmitter();

  constructor(
      public earthService: EarthService,
  ) {

  }

  ngOnInit(): void {
  }

  calculateCost(): number {
    return 0;
  }


  deleteRecipe(): void{
    this.deleted.emit();
  }

  onUpdate(): void{
    this.updated.emit();
  }

}
